import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlainObject } from '../types/plainObject';
import { parseLocation } from './parseLocation';

export interface UseLocationQueryResult<T> {
  query: T;
  pathname: string;
  remove: (key: keyof T) => void;
  update: (url: string, keepQueries?: boolean) => void;
}

export function useLocationQuery<T = PlainObject>(): UseLocationQueryResult<
  Partial<T>
> {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const query: Partial<T> = React.useMemo(
    () => parseLocation<T>(search),
    [search],
  );

  const remove = React.useCallback(
    (key: keyof T): void => {
      if (!query[key]) {
        return;
      }

      const searchPart = `${key}=${query[key]}`;

      navigate(
        `${pathname}${search.replace(
          new RegExp(`(${searchPart})&?`, 'gi'),
          '',
        )}`,
        { replace: true },
      );
    },
    [pathname, query, search, navigate],
  );

  const update = React.useCallback(
    (url: string, keepQueries = false): void => {
      navigate(keepQueries ? `${url}${search}` : url);
    },
    [search, navigate],
  );

  return {
    pathname,
    query,
    remove,
    update,
  };
}
