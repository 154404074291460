/** @jsxImportSource @emotion/react */
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { DashboardTotalRevenueResponse } from '../../../types';
import { TotalRevenueGroupBy } from '../../../constants';
import { getApexOptions, formatDateDueToGroupBy } from './BarChart.utils';
import { useMemo } from 'react';
import { Skeleton, Box, Typography } from '@mui/material';
import { useBarChartStyles } from './BarChart.styles';

interface Props {
  fullScreen?: boolean;
  height: number;
  data: DashboardTotalRevenueResponse[] | null;
  groupBy: TotalRevenueGroupBy;
}

export function BarChart({
  fullScreen,
  height,
  data,
  groupBy,
}: Props): JSX.Element {
  const style = useBarChartStyles();

  const [series, categories, maxValue] = useMemo(() => {
    if (!data) {
      return [[], [], 10];
    }

    let max = 1;

    const Won: number[] = [];
    const Lost: number[] = [];
    const Missed: number[] = [];
    const Ignored: number[] = [];
    const RevenueData: number[] = [];

    const categories = data.map(
      ({ Date, WonCount, LostCount, IgnoredCount, MissedCount, Revenue }) => {
        Won.push(WonCount || 0);
        Lost.push(LostCount || 0);
        Ignored.push(IgnoredCount || 0);
        Missed.push(MissedCount || 0);
        RevenueData.push(Revenue || 0);

        const currentMax = Math.max(
          WonCount || 0,
          LostCount || 0,
          IgnoredCount || 0,
          MissedCount || 0,
        );

        max = max > currentMax ? max : currentMax;

        return formatDateDueToGroupBy(Date, groupBy);
      },
    );

    const resultMax =
      max < 300 ? Math.ceil(max / 10) * 10 : Math.ceil(max / 100) * 100;

    return [
      [
        {
          name: 'Won',
          type: 'column',
          data: Won,
          color: '#003B5C',
        },
        {
          name: 'Lost',
          type: 'column',
          data: Lost,
          color: '#00A3E0',
        },
        {
          name: 'Missed',
          type: 'column',
          data: Missed,
          color: '#C1C6C8',
        },
        {
          name: 'Ignored',
          type: 'column',
          data: Ignored,
          color: '#6D787F',
        },
        {
          name: 'Revenue',
          type: 'line',
          data: RevenueData,
          color: '#F8D61A',
        },
      ],
      categories,
      resultMax,
    ];
  }, [data, groupBy]);

  if (!data) {
    return (
      <Skeleton sx={{ height: 272 }} animation="wave" variant="rectangular" />
    );
  }

  if (data.length === 0) {
    return (
      <Typography padding={3} paddingTop={1}>
        No data
      </Typography>
    );
  }

  const options: ApexOptions = getApexOptions(categories, maxValue, fullScreen);

  return (
    <Box css={style} height={height}>
      <ReactApexChart
        selection={{ enabled: false }}
        toolbar={{ show: false }}
        options={options}
        series={series}
        type="bar"
        height={fullScreen ? height - 20 : height}
      />
    </Box>
  );
}
