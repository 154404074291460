import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useAppStyles = (): SerializedStyles => {
  const { palette } = useTheme();

  return css`
    .loader-exit {
      opacity: 1;
    }
    .loader-exit-active {
      opacity: 0;
      transition: opacity 300ms ease-in;
    }

    h5[aria-labelledby] {
      background-color: ${palette.background.paper} !important;
    }
  `;
};
