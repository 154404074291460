import { PODApiModel } from '../../types';

export function podNameFormatter({ PodCity, PodState }: PODApiModel): string {
  return `${PodCity}, ${PodState}`;
}

export function podNameMktFormatter(
  { PodCity }: PODApiModel,
  xMkt = false,
): string {
  return `${PodCity} ${xMkt ? 'X-' : ''}Mkt`;
}
