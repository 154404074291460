import * as yup from 'yup';
import { numberFormat } from '../../utils';

function getNumberYup(maxBid?: number | null) {
  const bidYup = yup
    .number()
    .typeError('The value must be a number')
    .label('Value');

  if (!maxBid) {
    return bidYup;
  }

  return bidYup.lessThan(
    maxBid,
    `The value must be less than ${numberFormat(maxBid || 0, {
      cutZero: false,
    })}`,
  );
}

export const getSubmitBidSchema = (maxBid?: number | null) =>
  yup.object({
    bid: getNumberYup(maxBid).required('Required field'),
    comments: yup.string().label('Comment').trim(),
  });
