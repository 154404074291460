import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { sortingDirection, TabName } from '../../constants';
import {
  fetchFreightQuotesData,
  freightQuotesSelectors,
  freightQuotesSlice,
  setQuoteToSubmit,
} from '../../redux/freightQuotes';
import {
  EmptyState,
  Filters,
  LoaderContainer,
  ToggleType,
} from '../../components';
import {
  filtersDataSelectors,
  filtersDataSlice,
  updateFilterRelations,
} from '../../redux/filtersData';
import { FiltersModel, FreightQuoteActionModel } from '../../types';
import React, { useMemo, useState } from 'react';
import { is } from '../../utils';
import { SgPagination } from './components/sgPagination/SgPagination';
import { defaultColumns, getWonLostColumns } from './SharedPage.const';
import { userDataSelectors } from '../../redux/userData';
import { QuoteActionDialogs } from '../quoteActionDialogs/QuoteActionDialogs';
import { ExtendedDataGrid } from '../dataGrid/ExtendedDataGrid';
import { useInlineBidRenders } from '../common/utils/useInlineBidRenders';

interface Props {
  tabName: TabName;
}

export function SharedPage({ tabName }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const [bulkMode, setBulkMode] = useState(false);

  const currentTabName = useAppSelector(
    freightQuotesSelectors.getCurrentTabName,
  );
  const currentTabParams = useAppSelector(
    freightQuotesSelectors.getCurrentTabParams,
  );

  const {
    portals = [],
    equipmentTypes = [],
    origins = [],
    destinations = [],
  } = useAppSelector(
    filtersDataSelectors.selectFiltersByPageName(tabName, currentTabParams),
  );

  const user = useAppSelector(userDataSelectors.getUserData);
  const data = useAppSelector(freightQuotesSelectors.getData);
  const total = useAppSelector(freightQuotesSelectors.getTotal);
  const filters = useAppSelector(freightQuotesSelectors.getFilters);
  const filtersLoading = useAppSelector(filtersDataSelectors.getLoading);
  const loading = useAppSelector(freightQuotesSelectors.getLoading);
  const quotesToIgnore = useAppSelector(
    freightQuotesSelectors.getQuotesToIgnore,
  );
  const quotesToSubmit = useAppSelector(
    freightQuotesSelectors.getQuoteToSubmit,
  );

  const hasActionsColumn =
    currentTabName === TabName.Submitted ||
    currentTabName === TabName.Open ||
    currentTabName === TabName.Ignored;

  const onFiltersChanged = (values?: Partial<FiltersModel>) => {
    dispatch(
      freightQuotesSlice.actions.setQuoteTabParams({
        tabName,
        params: '',
      }),
    );
    if (!values) {
      dispatch(filtersDataSlice.actions.resetFiltersByPage(tabName));
    }

    dispatch(
      fetchFreightQuotesData({
        tabName,
        filters: {
          skip: 0,
          filters: values
            ? {
                ...(filters?.filters || {}),
                ...values,
              }
            : {
                userId: filters?.filters.userId || undefined,
              },
        },
      }),
    );
  };

  const onPageChanged = (skip: number, top: number) => {
    dispatch(
      fetchFreightQuotesData({
        tabName,
        filters: {
          skip,
          top,
        },
      }),
    );
  };

  const onOrderChanged = (
    columnName?: keyof FreightQuoteActionModel,
    direction?: sortingDirection,
  ) => {
    dispatch(
      fetchFreightQuotesData({
        tabName,
        filters: {
          sortingColumn: columnName,
          sortingDirection: direction,
        },
      }),
    );
  };

  const setQuotesToIgnore = (quote: FreightQuoteActionModel[] | null) => {
    dispatch(freightQuotesSlice.actions.setQuotesToIgnore(quote));
  };

  const setQuoteItemToSubmit = (quotes: FreightQuoteActionModel[] | null) => {
    dispatch(setQuoteToSubmit(quotes));
  };

  const {
    inlineBidColumn,
    autoBidInColumn,
    renderActions,
    renderDetailsSubmit,
    focusedQuote,
    onRowExpanded,
  } = useInlineBidRenders('quotes', dispatch, currentTabName);

  const additionColumns =
    !!data &&
    (currentTabName === TabName.Won || currentTabName === TabName.Lost)
      ? getWonLostColumns(currentTabName)
      : [];

  const isTabAllowedToBid =
    currentTabName === TabName.Open ||
    currentTabName === TabName.Submitted ||
    currentTabName === TabName.Ignored;
  const bidColumn = !!data && isTabAllowedToBid ? [inlineBidColumn] : [];

  const additionalAutoBidInColumn =
    !!data && currentTabName === TabName.Open ? [autoBidInColumn] : [];

  const showTogglePanel =
    tabName === TabName.Submitted ||
    tabName === TabName.Won ||
    tabName === TabName.Lost ||
    tabName === TabName.Ignored;
  const showBulkBid =
    tabName === TabName.Open ||
    tabName === TabName.Submitted ||
    tabName === TabName.Ignored;

  const dataGrid = useMemo(() => {
    const rows = is.array(data) ? data : [];
    const hasFilters = !!Object.keys(filters?.filters || {}).length;

    return (
      <ExtendedDataGrid
        key={tabName}
        data={rows}
        filters={filters}
        total={total || 0}
        bulkBidButtonLabel={
          tabName === TabName.Submitted ? 'Edit bid' : 'Submit bid'
        }
        useBulkBid={showBulkBid}
        useBulkIgnore={tabName === TabName.Open}
        showToggleMode={showTogglePanel}
        toggleValue={
          is.nullOrUndefined(filters?.filters.userId)
            ? ToggleType.All
            : ToggleType.My
        }
        onBulkToggle={(bulkValue: boolean) => setBulkMode(bulkValue)}
        onToggle={(toggleValue) =>
          onFiltersChanged({
            userId: toggleValue === ToggleType.All ? undefined : user?.Id,
          })
        }
        onBulkSubmit={setQuoteItemToSubmit}
        onBulkIgnore={setQuotesToIgnore}
        orderBy={filters?.sortingColumn}
        orderDirection={filters?.sortingDirection}
        focusedItem={
          quotesToIgnore && quotesToIgnore.length === 1
            ? quotesToIgnore[0]
            : undefined
        }
        expandedItem={
          focusedQuote ||
          (quotesToSubmit && quotesToSubmit.length === 1
            ? quotesToSubmit[0]
            : undefined)
        }
        onExpand={onRowExpanded}
        columns={[
          ...defaultColumns,
          ...additionalAutoBidInColumn,
          ...additionColumns,
          ...bidColumn,
        ]}
        onOrderChanged={onOrderChanged}
        renderActions={hasActionsColumn ? renderActions : undefined}
        renderDetailsSubmit={
          isTabAllowedToBid ? renderDetailsSubmit : undefined
        }
      >
        {!loading && (
          <EmptyState
            reason={tabName}
            filtered={hasFilters}
            onActionClick={() => {
              dispatch(
                fetchFreightQuotesData({
                  tabName,
                  filters: {},
                }),
              );
            }}
          />
        )}
      </ExtendedDataGrid>
    );
  }, [filters, data, additionColumns, bidColumn, quotesToIgnore, loading]);

  const renderedFilters = useMemo(
    () =>
      filters && (
        <Filters
          allFilters
          filters={filters.filters}
          loading={loading}
          portals={portals}
          equipmentTypes={equipmentTypes}
          origins={origins}
          destinations={destinations}
          onChange={onFiltersChanged}
          onUpdateFilters={(v) => {
            dispatch(
              updateFilterRelations({
                pageName: tabName,
                pageTabName: tabName,
                filter: v || {},
              }),
            );
          }}
          showManagers
        />
      ),
    [tabName, filters, loading, portals, equipmentTypes, origins, destinations],
  );

  return (
    <LoaderContainer loading={loading || filtersLoading} fullPage>
      {renderedFilters}

      {dataGrid}

      <SgPagination
        disabled={bulkMode}
        onChange={onPageChanged}
        total={total}
        skip={filters?.skip}
        top={filters?.top}
      />

      <QuoteActionDialogs statusName={currentTabName} />
    </LoaderContainer>
  );
}
