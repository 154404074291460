import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useNotificationCardStyles = () => {
  const { palette } = useTheme();

  return css`
    .notificationUnread {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: -16px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${palette.primary.main};
      }
    }

    .descriptionItemTitle {
      width: 112px;
    }

    .descriptionItemValue {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
  `;
};
