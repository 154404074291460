import { PlainObject } from '../../types';

export const oDateFieldAliases: PlainObject = {
  shipDate: 'ShipDate',
  shipDateRange: 'ShipDate',
  deliveryDate: 'DeliveryDate',
  deliveryDateRange: 'DeliveryDate',
  bidDateRange: 'LastBidDateTime',
  portals: 'PortalId',
  shippers: 'ShipperId',
  equipmentTypes: 'EquipmentTypeId',
  origins: 'OriginZipCode',
  destinations: 'DestinationZipCode',
  dateFrom: 'LastBidDateTime',
  dateTo: 'LastBidDateTime',
  groupBy: 'groupby',
  userId: 'UserId',
  sourceId: 'SourceId',
  status: 'Status',
  distance: 'Distance',
  bid: 'Bid',
  mode: 'Mode',
  ruleStatus: 'Rule/Status',
  originPOD: 'OriginZipCode/DatPodId',
  destinationPOD: 'DestinationZipCode/DatPodId',
  accountManagers: 'Shipper/AccountManagerId',
};
