import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchDATPodsResult,
  fetchRules,
  fetchRulesEnums,
  rulesSelectors,
  rulesSlice,
} from '../../redux/rules';
import React, { useEffect, useMemo, useState } from 'react';
import { EmptyState, LoaderContainer } from '../../components';
import { Box, Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { routes, RuleStatus } from '../../constants';
import { RulesFilters } from '../../components/filters/RulesFilters';
import { fetchUsers, filtersDataSelectors } from '../../redux/filtersData';
import { RulesDataGrid } from '../../features';
import { ExtendedRuleEnum, RulesFiltersModel } from '../../types';
import { is, removeEmptyValues, useLocationQuery } from '../../utils';
import { PodsQueryProps, selectPodsByQuery } from './Rules.utils';

export function Rules(): JSX.Element {
  const dispatch = useAppDispatch();
  const { query } = useLocationQuery<PodsQueryProps>();
  const [tabIndex, setTabIndex] = useState(0);

  const {
    portals = [],
    equipmentTypes = [],
    users = [],
  } = useAppSelector(filtersDataSelectors.getFilters);
  const pods = useAppSelector(rulesSelectors.getRulesPODs);
  const appliedFilters = useAppSelector(rulesSelectors.getAppliedFilters);
  const { RuleMode: ruleModes, RuleStatus: ruleStatuses } = useAppSelector(
    rulesSelectors.getRulesEnums,
  );
  const rules = useAppSelector(rulesSelectors.getRulesList);
  const archivedRulesList = useAppSelector(rulesSelectors.getArchivedRulesList);
  const rulesCount = useAppSelector(rulesSelectors.getRulesCount);
  const archivedRulesCount = useAppSelector(
    rulesSelectors.getArchivedRulesCount,
  );

  const loadingEnums = useAppSelector(rulesSelectors.getLoadingEnums);
  const loadingPods = useAppSelector(rulesSelectors.getLoadingPods);
  const priorityLoading = useAppSelector(rulesSelectors.getPriorityLoading);
  const filtersLoading = useAppSelector(filtersDataSelectors.getLoading);

  const blockingLoading = loadingEnums || loadingPods || filtersLoading;
  const loading =
    useAppSelector(rulesSelectors.getLoading) ||
    blockingLoading ||
    priorityLoading;

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchRulesEnums());
    dispatch(fetchDATPodsResult());

    return () => {
      dispatch(rulesSlice.actions.resetList());
    };
  }, []);

  const onFiltersChanged = (
    values?: Partial<RulesFiltersModel>,
    newTabIndex = tabIndex,
  ) => {
    const filtersToFetch: Partial<RulesFiltersModel> = values
      ? removeEmptyValues({
          ...appliedFilters,
          ...values,
        })
      : {};

    dispatch(
      fetchRules({
        archived: newTabIndex === 1,
        filters: filtersToFetch,
      }),
    );
  };

  useEffect(() => {
    if (!pods || !pods.length || !ruleStatuses.length || !is.undefined(rules)) {
      return;
    }

    const firstFilter = selectPodsByQuery(pods, query);

    onFiltersChanged(firstFilter);
  }, [pods, ruleStatuses]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);

    onFiltersChanged(appliedFilters, newValue);
  };

  const isFiltersEmpty = is.empty(appliedFilters);
  const hasContent =
    !blockingLoading &&
    ((rules && (rules.length > 0 || !isFiltersEmpty)) ||
      (archivedRulesList && (archivedRulesList.length > 0 || !isFiltersEmpty)));

  const availableRuleStatuses = useMemo((): ExtendedRuleEnum[] => {
    return ruleStatuses.filter((item) => item.value !== RuleStatus.Deactivated);
  }, [ruleStatuses]);

  const overloadedFilters = useMemo((): Partial<RulesFiltersModel> => {
    if (tabIndex === 0) {
      return {};
    }

    return {
      ruleStatus: [],
    };
  }, [tabIndex, appliedFilters]);

  const renderContent = (): JSX.Element | null => {
    const rulesToRender = tabIndex === 0 ? rules : archivedRulesList;

    if (blockingLoading || !rulesToRender) {
      return null;
    }

    if (!rulesToRender.length) {
      return isFiltersEmpty ? (
        <EmptyState
          reason="rules"
          height="calc(100vh - 300px)"
          actionHref={routes.CreateRule}
        />
      ) : (
        <EmptyState reason="rules" filtered />
      );
    }

    return (
      <RulesDataGrid
        disablePriority={!isFiltersEmpty}
        ruleStatuses={ruleStatuses}
        ruleModes={ruleModes}
        rows={rulesToRender}
        archivedRules={tabIndex === 1}
      />
    );
  };

  const renderTabTitle = (title: string, count = 0) => (
    <Stack direction="row">
      <Typography color="common.black" variant="h5" marginRight={0.5}>
        {title}
      </Typography>
      <Typography color="secondary" variant="h5">
        {count}
      </Typography>
    </Stack>
  );

  return (
    <LoaderContainer loading={loading} fullPage>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginY={4}
        >
          <Typography variant="h1">Rules</Typography>
        </Stack>

        {hasContent && (
          <Box>
            <RulesFilters
              filters={appliedFilters}
              overloadedFilters={overloadedFilters}
              disableRuleStatus={tabIndex === 1}
              pods={pods}
              users={users}
              ruleModes={ruleModes}
              ruleStatuses={availableRuleStatuses}
              onChange={onFiltersChanged}
              equipmentTypes={equipmentTypes}
              portals={portals}
            />

            <Box
              sx={{
                position: 'relative',
              }}
            >
              <Tabs value={tabIndex} onChange={handleChange}>
                <Tab label={renderTabTitle('Active', rulesCount)} />
                <Tab label={renderTabTitle('Archived', archivedRulesCount)} />
              </Tabs>

              <Button
                variant="text"
                type="submit"
                size="medium"
                color="primary"
                href={routes.CreateRule}
                sx={{
                  position: 'absolute',
                  top: '6px',
                  right: 0,
                }}
              >
                <AddIcon fontSize="small" sx={{ marginRight: 1 }} />
                Create rule
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {renderContent()}
    </LoaderContainer>
  );
}
