export * from './app-config';
export * from './node-env';
export * from './separators';
export * from './server-status-code';
export * from './tabs';
export * from './stop-details-type';
export * from './quoteStatus';
export * from './messages';
export * from './itemsPerPage';
export * from './dashboardStat';
export * from './routes';
export * from './sortingDirections';
export * from './notification';
export * from './quotePortals';
export * from './rules';
export * from './rulesExpression';

export const LOCALHOST = 'localhost';
export const emptyValue = '-';

export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;

export const MILES_IN_METER = 0.000621;

export const CT_TIMEZONE = 'America/Chicago';
