import {
  DialogWrapper,
  DialogWrapperBaseProps,
} from '../../components/dialogWrapper/DialogWrapper';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { isoToDateTimeString, priceFormat } from '../../utils';
import { FreightQuoteBidsHistory } from '../../types';
import { ValueOrEmpty } from '../../components';
import { useMemo } from 'react';

interface Props extends DialogWrapperBaseProps {
  data: FreightQuoteBidsHistory[];
}

export function BidsHistoryDialog({ data, onClosed }: Props): JSX.Element {
  const items = useMemo(() => {
    if (!data || !data.length) {
      return [];
    }

    return [...data].sort((a, b) => a.BidAmount - b.BidAmount);
  }, [data]);

  const content = items.length ? (
    items.map(({ BidBy, BidDateTime, BidAmount }, index) => (
      <TableRow key={index}>
        <TableCell>
          <ValueOrEmpty>{BidBy}</ValueOrEmpty>
        </TableCell>
        <TableCell>{isoToDateTimeString(BidDateTime)}</TableCell>
        <TableCell align="right">{priceFormat(BidAmount)}</TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={3}>No data</TableCell>
    </TableRow>
  );

  return (
    <DialogWrapper onClosed={onClosed} title="Bids history">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell variant="head">Bid by</TableCell>
            <TableCell variant="head">Date & time</TableCell>
            <TableCell variant="head" align="right">
              Bid
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </DialogWrapper>
  );
}
