import React from 'react';
import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { plural } from '../../utils';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

interface Props {
  disabled?: boolean;
  showToggle?: boolean;
  showBulkBid?: boolean;
  count?: number;
  value?: ToggleType;
  onToggle?: (value: ToggleType) => void;
  onBulkBid?: () => void;
}

export enum ToggleType {
  All = 'all',
  My = 'my',
}

export function TogglePanel({
  count,
  disabled,
  showToggle,
  showBulkBid,
  value = ToggleType.All,
  onToggle = () => null,
  onBulkBid = () => null,
}: Props): JSX.Element {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: ToggleType,
  ) => {
    if (!newValue || value === newValue) {
      return;
    }

    onToggle(newValue);
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>
        {count} {plural(count, 'item')}
      </Box>

      <Stack direction="row" alignItems="center">
        {showBulkBid && (
          <Button
            disabled={disabled}
            startIcon={
              <LibraryAddCheckIcon
                fontSize="small"
                color={disabled ? 'disabled' : 'primary'}
              />
            }
            onClick={() => onBulkBid()}
          >
            Bulk bid
          </Button>
        )}

        {showToggle && (
          <Box marginLeft={2}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={value || ToggleType.All}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton sx={{ width: '72px' }} value={ToggleType.All}>
                {value === ToggleType.All && (
                  <CheckIcon sx={{ fontSize: '14px', marginRight: '3px' }} />
                )}
                All
              </ToggleButton>
              <ToggleButton sx={{ width: '72px' }} value={ToggleType.My}>
                {value === ToggleType.My && (
                  <CheckIcon sx={{ fontSize: '14px', marginRight: '3px' }} />
                )}
                My
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
