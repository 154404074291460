export function intFormatter(value: string | undefined): string {
  return isNaN(parseInt(value || '')) ? '' : String(parseInt(value || ''));
}

export function decimalFormatter(value: string | undefined): string {
  return isNaN(parseFloat(value || ''))
    ? ''
    : String(value || '')
        .replace(/[^\d.]+/gi, '')
        .replace(/\.+/gi, '.');
}
