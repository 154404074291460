import React, { useEffect, useMemo, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { FreightQuoteActionModel, PlainObject } from '../../../types';
import {
  getBidUser,
  is,
  isActionDisabled,
  isDisabledToSubmit,
} from '../../../utils';
import {
  smallErrorStartAdornment,
  smallStartAdornment,
} from '../../../components';
import { quoteStatusToTabName, TabName } from '../../../constants';

function parseBidValueToNumberFormat(bid: string): string {
  return bid.replace(/[$,a-z]/gi, '');
}

export function renderInlineBidInput(
  item: FreightQuoteActionModel,
  onChange: (q: FreightQuoteActionModel, value: string) => void,
  onFocus: (q: FreightQuoteActionModel) => void,
  onBlur: (q: FreightQuoteActionModel) => void,
  tabName?: TabName,
  disabled?: boolean,
  style?: PlainObject,
): string | JSX.Element {
  const alterValue = String(
    item.RequestedBid || item.RequestedSageBid || item.SageBid,
  );

  const [value, setValue] = useState<string>(
    (item.bid === undefined ? alterValue : String(item.bid)) || '',
  );

  useEffect(() => {
    if (item.bid === undefined) {
      setValue(alterValue || '');
    }
  }, [alterValue]);

  useEffect(() => {
    if (
      item.bid === undefined ||
      String(item.bid) === parseBidValueToNumberFormat(value)
    ) {
      return;
    }

    setValue(String(item.bid));
  }, [item.bid]);

  const currentTabName =
    tabName ||
    (item.Status ? quoteStatusToTabName[item.Status] : TabName.Missed);
  const disabledButtons =
    isActionDisabled(item) || isDisabledToSubmit(currentTabName, item);

  const input = useMemo(
    () => (
      <NumericFormat
        fullWidth
        name="bid"
        size="tiny"
        className="tinySize"
        placeholder="0.00"
        disabled={disabledButtons || disabled}
        sx={style}
        InputProps={{
          startAdornment: !!item.bidError
            ? smallErrorStartAdornment
            : smallStartAdornment,
        }}
        inputProps={{
          tabIndex: 0,
          style: { textAlign: 'right' },
        }}
        error={!!item.bidError}
        variant="outlined"
        allowLeadingZeros
        thousandSeparator=","
        decimalScale={2}
        fixedDecimalScale={!is.nullOrUndefined(alterValue)}
        prefix="$"
        value={value}
        onChange={(e) => {
          const newValue = ((e && e.target.value) || '').trim();

          setValue(newValue);
          onChange(item, parseBidValueToNumberFormat(newValue));
        }}
        onFocus={() => onFocus(item)}
        onBlur={() => onBlur(item)}
        customInput={TextField}
      />
    ),
    [value, alterValue, item.bidError, disabledButtons, disabled],
  );

  const bidUserName = getBidUser(item);

  return (
    <Tooltip
      arrow
      placement="top"
      hidden={!item.bidError && bidUserName === null}
      title={item.bidError || bidUserName || ''}
    >
      <Box sx={{ display: 'block', minWidth: '120px' }}>{input}</Box>
    </Tooltip>
  );
}
