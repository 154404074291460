import * as Yup from 'yup';
import { ExpressionAttribute, ExpressionOperator } from './RuleForm.const';
import {
  CAN_NOT_SAVE_RULE_ALL_IN_BIDDING_NULL,
  RuleMode,
  RuleTypeName,
} from '../../constants';
import { getBeginOfToday } from '../../utils/getBeginOfToday';
import { RuleExpressionsModel } from './RuleForm.types';
import { ShipperApiModel } from '../../types';
import { getCustomerExpression } from './RuleForm.utils';
import { is } from '../../utils';
import { ref } from 'yup';
import { isMoreThan, isTimeValid } from '../../utils/timeValidators';

function validateTypeByMode(mode: string): boolean {
  return Number(mode) === RuleMode.AutoBid || Number(mode) === RuleMode.Suggest;
}

export function isValidAllInBidding(
  expression?: RuleExpressionsModel,
  type?: RuleTypeName,
): boolean {
  // if AllInBidding !== null
  if (
    !expression ||
    !expression.value ||
    !is.nullOrUndefined((expression.value as ShipperApiModel).AllInBidding)
  ) {
    return true;
  }

  // AllInBidding - NULL or undefined
  return (
    type !== RuleTypeName.RatePerMile && type !== RuleTypeName.DATBasedRate
  );
}

function isAllInBiddingNull(
  expressions: RuleExpressionsModel[],
  mode: string,
): boolean {
  if (!validateTypeByMode(mode)) {
    return false;
  }

  const customerExpression = getCustomerExpression(expressions);

  if (!customerExpression || !customerExpression.value) {
    return false;
  }

  return is.nullOrUndefined(
    (customerExpression.value as ShipperApiModel).AllInBidding,
  );
}

export function isValidTypeByCustomer(
  expression?: RuleExpressionsModel,
  type?: RuleTypeName,
): boolean {
  if (!expression || !expression.value) {
    return true;
  }

  if ((expression.value as ShipperApiModel).SupportsFuelDefining) {
    return true;
  }

  if (
    type === RuleTypeName.RatePerMile &&
    (expression.value as ShipperApiModel).AllInBidding === true
  ) {
    return false;
  }

  if (
    type === RuleTypeName.DATBasedRate &&
    !(expression.value as ShipperApiModel).AllInBidding
  ) {
    return false;
  }

  return true;
}

const expressionsValueValidationRules = Yup.mixed()
  .when(['attribute', 'operator'], {
    is: (attribute: ExpressionAttribute, operator: ExpressionOperator) => {
      return (
        attribute === ExpressionAttribute.Distance &&
        operator !== ExpressionOperator.Between
      );
    },
    then: Yup.number()
      .typeError('Invalid number format')
      .min(0)
      .max(99999)
      .required(),
  })
  .when(['attribute', 'operator'], {
    is: (attribute: ExpressionAttribute, operator: ExpressionOperator) => {
      return (
        (attribute === ExpressionAttribute.Distance &&
          operator === ExpressionOperator.Between) ||
        attribute === ExpressionAttribute.TransitTime
      );
    },
    then: Yup.object().shape({
      from: Yup.number()
        .typeError('Invalid number format "From"')
        .min(0, 'Should be a whole positive number'),
      to: Yup.number()
        .moreThan(ref('from'), '"To" must be greater than "From"')
        .typeError('Invalid number format "To"')
        .required('"To" is a required field'),
    }),
  })
  .when(['attribute', 'operator'], {
    is: (attribute: ExpressionAttribute, operator: ExpressionOperator) => {
      return (
        attribute === ExpressionAttribute.PickUpTime &&
        operator === ExpressionOperator.Between
      );
    },
    then: Yup.object().shape({
      from: Yup.string()
        .required('"From" Pick Up Time is a required field')
        .test(
          'is-from-pickup-time-valid',
          '"From" Pick Up Time is not valid',
          (from) => isTimeValid(from || ''),
        ),
      to: Yup.string()
        .required('"To" Pick Up Time is a required field')
        .test(
          'is-to-pickup-time-valid',
          '"To" Pick Up Time is not valid. "To" Pick Up Time should be greater than "From" Pick Up Time',
          (to, context) => {
            const from = context.parent.from;
            if (!isTimeValid(from) || !isTimeValid(to || '')) {
              return false;
            }
            if (!isMoreThan(to || '', from)) {
              return false;
            }
            return true;
          },
        ),
    }),
  })
  .when(['attribute', 'operator'], {
    is: (attribute: ExpressionAttribute, operator: ExpressionOperator) => {
      return (
        attribute === ExpressionAttribute.DropTime &&
        operator === ExpressionOperator.Between
      );
    },
    then: Yup.object().shape({
      from: Yup.string()
        .required('"From" Drop Time is a required field')
        .test(
          'is-from-drop-time-valid',
          '"From" Drop Time is not valid',
          (from) => isTimeValid(from || ''),
        ),
      to: Yup.string()
        .required('"To" Drop Time is a required field')
        .test(
          'is-to-drop-time-valid',
          '"To" Drop Time is not valid. "To" Drop Time should be greater than "From" Drop Time',
          (to, context) => {
            const from = context.parent.from;
            if (!isTimeValid(from) || !isTimeValid(to || '')) {
              return false;
            }
            if (!isMoreThan(to || '', from)) {
              return false;
            }
            return true;
          },
        ),
    }),
  })
  .when('attribute', {
    is: ExpressionAttribute.Stops,
    then: Yup.number()
      .typeError('Invalid number format')
      .min(2)
      .max(99999)
      .required(),
  })
  .when('attribute', {
    is: (attribute: string) => {
      return (
        attribute === ExpressionAttribute.PickUpDate ||
        attribute === ExpressionAttribute.DeliveryDate ||
        attribute === ExpressionAttribute.EquipmentType ||
        attribute === ExpressionAttribute.OriginPod ||
        attribute === ExpressionAttribute.DestinationPod
      );
    },
    then: Yup.array().of(Yup.object()).min(1).required(),
  })
  .when('attribute', {
    is: ExpressionAttribute.Customer,
    then: Yup.object().required(),
  })
  .required()
  .label('Value');

const baseSchema = {
  ruleName: Yup.string()
    .min(2)
    .max(100)
    .label('Rule name')
    .required('Required field'),
  description: Yup.string()
    .max(500)
    .label('Description')
    .required('Required field'),
  startDate: Yup.date()
    .min(getBeginOfToday(), "Value shouldn't be earlier than today")
    .required('Required field'),

  type: Yup.mixed()
    .when(['expressions', 'mode'], {
      is: isAllInBiddingNull,
      then: Yup.string()
        .required('Required field')
        .oneOf(
          [String(RuleTypeName.RatePerTrip)],
          CAN_NOT_SAVE_RULE_ALL_IN_BIDDING_NULL,
        ),
    })
    .when('mode', {
      is: (mode: number) =>
        Number(mode) === RuleMode.AutoBid || Number(mode) === RuleMode.Suggest,
      then: Yup.string().required('Required field'),
    }),
  rateAmount: Yup.mixed()
    .when(['type', 'mode'], {
      is: (type: RuleTypeName, mode: number) =>
        (Number(mode) === RuleMode.AutoBid ||
          Number(mode) === RuleMode.Suggest) &&
        (Number(type) === RuleTypeName.RatePerTrip ||
          Number(type) === RuleTypeName.RatePerMile),
      then: Yup.number()
        .typeError('Invalid number format')
        .min(1, 'Value must be greater than 0')
        .required('Required field'),
    })
    .when('mode', {
      is: (mode: number) =>
        Number(mode) === RuleMode.AutoBid || Number(mode) === RuleMode.Suggest,
      then: Yup.number()
        .typeError('Invalid number format')
        .required('Required field'),
    }),

  bidMinLimit: Yup.mixed().when('bidMaxLimit', {
    is: (bidMaxLimit: number) => !!bidMaxLimit,
    then: Yup.number()
      .typeError('Invalid number format')
      .min(0, 'Should be a whole positive number')
      .max(
        ref('bidMaxLimit'),
        '"Not more than" cannot be smaller than "Not less than"',
      ),
    otherwise: Yup.number()
      .typeError('Invalid number format')
      .min(0, 'Should be a whole positive number'),
  }),
  bidMaxLimit: Yup.number()
    .typeError('Invalid number format')
    .positive('Should be a whole positive number')
    .min(1, 'Cannot be 0'),

  expressions: Yup.array().of(
    Yup.object().shape({
      attribute: Yup.string().required('Required field'),
      operator: Yup.number().required('Required field'),
      value: expressionsValueValidationRules,
    }),
  ),
};

export const ruleFormSchema = (hasRule: boolean, editMode: boolean) =>
  Yup.object().shape({
    mode: Yup.string().required('Required field'),
    endDate: Yup.mixed()
      .when('startDate', ((startDate: Date | null) => {
        if (!startDate || startDate < getBeginOfToday()) {
          return Yup.date()
            .min(getBeginOfToday(), "Value shouldn't be earlier than today")
            .required('Required field');
        }

        return Yup.date()
          .min(startDate, "Value shouldn't be earlier than Start date")
          .required('Required field');
      }) as any)
      .required('Required field'),
    ...(hasRule
      ? {}
      : {
          priority: Yup.string().required('Required field'),
        }),
    ...(editMode ? {} : baseSchema),
  });
