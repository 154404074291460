import React from 'react';
import { Box } from '@mui/material';

export function renderFilterTag(
  values: unknown[],
  disabled?: boolean,
): JSX.Element {
  return (
    <Box paddingRight={1} color={disabled ? 'text.disabled' : undefined}>
      {values.length} selected
    </Box>
  );
}
