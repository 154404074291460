/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';
import { PlainObject } from '../../types';
import { sortingDirection } from '../../constants';
import { ColumnModel } from './SgTable.types';

export interface TableCellBaseProps<T extends PlainObject> {
  orderBy?: keyof T;
  orderDirection?: sortingDirection;
  onOrderChanged?: (columnName?: keyof T, direction?: sortingDirection) => void;
}

type Props<T extends PlainObject> = TableCellBaseProps<T> &
  Pick<ColumnModel<T>, 'name'> &
  Partial<ColumnModel<T>>;

export function SgTableHeaderCell<T extends PlainObject>({
  name,
  nameForOrdering,
  align,
  headerStyles,
  onOrderChanged,

  orderBy,
  orderDirection,

  children,
}: PropsWithChildren<Props<T>>): JSX.Element | null {
  const createSortHandler = (property: keyof T) => () => {
    if (!onOrderChanged) {
      return;
    }

    const isAsc =
      orderBy === property && orderDirection === sortingDirection.Asc;

    if (orderBy === property && orderDirection === sortingDirection.Desc) {
      onOrderChanged();
      return;
    }

    onOrderChanged(
      property,
      isAsc ? sortingDirection.Desc : sortingDirection.Asc,
    );
  };

  const orderName = nameForOrdering || name;

  return (
    <TableCell
      variant="head"
      align={align}
      sx={headerStyles}
      sortDirection={orderBy === orderName ? orderDirection : false}
    >
      {onOrderChanged ? (
        <TableSortLabel
          active={orderBy === orderName}
          direction={orderBy === orderName ? orderDirection : 'asc'}
          onClick={createSortHandler(orderName)}
        >
          {children}
        </TableSortLabel>
      ) : (
        children
      )}
    </TableCell>
  );
}
