import { schedulingStoreKey } from './scheduling.const';
import { SchedulingState } from './scheduling.types';
import { ScheduleItem } from '../../types/scheduleItem';
import { RuleEnums } from '../rules/rules.types';

interface AppStateWithScheduling {
  [schedulingStoreKey]: SchedulingState;
}

const selectRulesState = (state: AppStateWithScheduling) => {
  return state[schedulingStoreKey];
};

const getLoading = (state: AppStateWithScheduling): boolean => {
  return selectRulesState(state).loading;
};

const getSchedule = (
  state: AppStateWithScheduling,
): ScheduleItem[] | undefined => {
  return selectRulesState(state).schedule;
};

const getEnums = (state: AppStateWithScheduling): RuleEnums => {
  return selectRulesState(state).enums;
};

export const schedulingSelectors = {
  getLoading,
  getSchedule,
  getEnums,
};
