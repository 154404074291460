import React from 'react';
import { FormikEntity, PlainObject } from '../../../types';
import { SGAutocomplete, SGAutocompleteProps } from './SGAutocomplete';
import { getFormikError } from '../../../utils';

interface Props<T, F extends PlainObject> extends SGAutocompleteProps<T> {
  formik: FormikEntity<F>;
}

export function FormikAutocomplete<T, F extends PlainObject>({
  formik,
  name,
  ...props
}: Props<T, F>): JSX.Element {
  const formikValue = name ? formik.getFieldProps(name).value : null;
  const formikProps =
    formik && name
      ? ({
          value: formikValue || [],
          onChange: (value: T[]) => formik.setFieldValue(name, value),
          error: getFormikError(formik, name),
        } as Partial<SGAutocompleteProps<T>>)
      : {};

  return <SGAutocomplete {...formikProps} {...props} name={name} />;
}
