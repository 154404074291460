import { rulesStoreKey } from './rules.const';
import { RuleEnums, RulesState } from './rules.types';
import {
  FreightQuoteModel,
  PODExtendedModel,
  RuleApiModel,
  RulesFiltersModel,
} from '../../types';
import { CreateRuleFormModel } from '../../features/ruleForm/RuleForm.types';

export interface AppStateWithRules {
  [rulesStoreKey]: RulesState;
}

const selectRulesState = (state: AppStateWithRules) => {
  return state[rulesStoreKey];
};

const getCreateRuleLoading = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).createRuleLoading;
};
const getLoading = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).loading;
};
const getPriorityLoading = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).priorityLoading;
};
const getLoadingEnums = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).loadingEnums;
};
const getLoadingPods = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).loadingPods;
};
const getLoadingRule = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).loadingRule;
};

const getRulesEnums = (state: AppStateWithRules): RuleEnums => {
  return selectRulesState(state).enums;
};

const getRulesPODs = (state: AppStateWithRules): PODExtendedModel[] => {
  return selectRulesState(state).pods;
};

const getAppliedFilters = (
  state: AppStateWithRules,
): Partial<RulesFiltersModel> => {
  return selectRulesState(state).appliedFilters;
};

const getCreatedRule = (state: AppStateWithRules): RuleApiModel | undefined => {
  return selectRulesState(state).createdRule;
};

const getRulesList = (state: AppStateWithRules): RuleApiModel[] | undefined => {
  return selectRulesState(state).rulesList;
};
const getArchivedRulesList = (
  state: AppStateWithRules,
): RuleApiModel[] | undefined => {
  return selectRulesState(state).archivedRulesList;
};

const getRulesCount = (state: AppStateWithRules): number => {
  return selectRulesState(state).rulesTotal;
};
const getArchivedRulesCount = (state: AppStateWithRules): number => {
  return selectRulesState(state).archivedRulesTotal;
};

const getRuleValidation = (
  state: AppStateWithRules,
): FreightQuoteModel[] | undefined => {
  const ruleValidation = selectRulesState(state).ruleValidation;

  return ruleValidation && ruleValidation.Items;
};

const getRuleValidationTotal = (state: AppStateWithRules): number => {
  const ruleValidation = selectRulesState(state).ruleValidation;

  return (ruleValidation && ruleValidation.TotalCount) || 0;
};

const getRuleToEdit = (
  state: AppStateWithRules,
): RuleApiModel | undefined | null => {
  return selectRulesState(state).ruleToEdit;
};

const getRuleToCopy = (
  state: AppStateWithRules,
): Partial<CreateRuleFormModel> | undefined => {
  return selectRulesState(state).copyRule;
};

const getDeletedRule = (
  state: AppStateWithRules,
): Partial<RuleApiModel> | undefined => {
  return selectRulesState(state).deletedRule;
};

export const rulesSelectors = {
  getRulesEnums,
  getRulesPODs,
  getCreateRuleLoading,
  getLoadingEnums,
  getLoadingPods,
  getLoadingRule,
  getAppliedFilters,
  getCreatedRule,
  getLoading,
  getPriorityLoading,
  getRulesList,
  getArchivedRulesList,
  getRuleToEdit,
  getRuleToCopy,
  getDeletedRule,
  getRuleValidation,
  getRuleValidationTotal,

  getRulesCount,
  getArchivedRulesCount,
};
