import { PropsWithChildren } from 'react';
import { Stack, useTheme } from '@mui/material';
import { UIGridContext, uiGridPaddings, UIGridSize } from './UIGrid.const';
import { StackProps } from '@mui/material/Stack/Stack';

interface Props {
  size?: UIGridSize;
}

export function UIRow({
  children,
  size = 'm',
  ...props
}: PropsWithChildren<Props & StackProps>): JSX.Element {
  const { spacing } = useTheme();
  const margin = (uiGridPaddings[size] / 2) * -1;

  return (
    <UIGridContext.Provider value={size}>
      <Stack
        direction="row"
        marginX={margin}
        width={`calc(100% + ${spacing(uiGridPaddings[size])})`}
        {...props}
        className="uiRow"
      >
        {children}
      </Stack>
    </UIGridContext.Provider>
  );
}
