import { PlainObject, SelectItemModel } from '../../types';
import { ruleTypeLabels, RuleTypeName } from '../../constants';

export const LOAD_MORE_RULES = 10;

export enum ExpressionAttribute {
  Customer = 'customer',
  EquipmentType = 'equipmentType',
  OriginPod = 'originPod',
  DestinationPod = 'destinationPod',
  PickUpDate = 'pickUpDate',
  DeliveryDate = 'deliveryDate',
  Distance = 'distance',
  Stops = 'stops',
  TransitTime = 'transitTime',
  PickUpTime = 'pickUpTime',
  DropTime = 'dropTime',
}

export const ruleExpressionAttributes = <PlainObject<SelectItemModel>>{
  [ExpressionAttribute.Customer]: {
    label: 'Customer',
    value: ExpressionAttribute.Customer,
  },
  [ExpressionAttribute.EquipmentType]: {
    label: 'Equipment type',
    value: ExpressionAttribute.EquipmentType,
  },
  [ExpressionAttribute.OriginPod]: {
    label: 'Origin POD',
    value: ExpressionAttribute.OriginPod,
  },
  [ExpressionAttribute.DestinationPod]: {
    label: 'Destination POD',
    value: ExpressionAttribute.DestinationPod,
  },
  [ExpressionAttribute.PickUpDate]: {
    label: 'Pick Up Date',
    value: ExpressionAttribute.PickUpDate,
  },
  [ExpressionAttribute.DeliveryDate]: {
    label: 'Delivery Date',
    value: ExpressionAttribute.DeliveryDate,
  },
  [ExpressionAttribute.Distance]: {
    label: 'Distance',
    value: ExpressionAttribute.Distance,
  },
  [ExpressionAttribute.Stops]: {
    label: 'Stops',
    value: ExpressionAttribute.Stops,
  },
  [ExpressionAttribute.TransitTime]: {
    label: 'Transit Time (minutes)',
    value: ExpressionAttribute.TransitTime,
  },
  [ExpressionAttribute.PickUpTime]: {
    label: 'Pick Up Time',
    value: ExpressionAttribute.PickUpTime,
  },
  [ExpressionAttribute.DropTime]: {
    label: 'Drop Time',
    value: ExpressionAttribute.DropTime,
  },
};

export const expressionAttributesItems = Object.values(
  ruleExpressionAttributes,
);

// RuleExpressionOperator
// 0- "IS EQUAL TO"
// 1- "IS NOT"
// 2- "IS ONE OF"
// 3- "IS NOT ONE OF"
// 4- "IS GREATER THAN"
// 5- "IS LESS THAN"

export enum ExpressionOperator {
  Equals, // IS EQUAL TO
  NotEquals, // IS NOT
  In, // IS EQUAL TO
  NotIn, // IS NOT
  GreaterThan, // IS GREATER THAN
  LessThan, // IS LESS THAN
  GreaterEqualThan, // IS GREATER OR EQUAL THAN
  LessEqualThan, // IS LESS OR EQUAL THAN

  Between = 99,
}

export const availableOperatorsForAttributes: PlainObject = {
  [ExpressionAttribute.Customer]: [
    ExpressionOperator.Equals,
    ExpressionOperator.NotEquals,
  ], // IS EQUAL TO | IS NOT

  [ExpressionAttribute.EquipmentType]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.OriginPod]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.DestinationPod]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF

  [ExpressionAttribute.PickUpDate]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF
  [ExpressionAttribute.DeliveryDate]: [
    ExpressionOperator.In,
    ExpressionOperator.NotIn,
  ], // IS ONE OF | IS NOT ONE OF

  [ExpressionAttribute.Distance]: [
    ExpressionOperator.Equals,
    ExpressionOperator.GreaterThan,
    ExpressionOperator.LessThan,
    ExpressionOperator.Between,
  ], // IS EQUAL TO | IS LESS THAN | IS GREATER THAN
  [ExpressionAttribute.Stops]: [
    ExpressionOperator.Equals,
    ExpressionOperator.GreaterThan,
    ExpressionOperator.LessThan,
  ], // IS EQUAL TO | IS LESS THAN | IS GREATER THAN
  [ExpressionAttribute.TransitTime]: [ExpressionOperator.Between],
  [ExpressionAttribute.PickUpTime]: [ExpressionOperator.Between],
  [ExpressionAttribute.DropTime]: [ExpressionOperator.Between],
};

export const ruleTypesOptions: SelectItemModel[] = [
  {
    value: RuleTypeName.RatePerTrip,
    label: ruleTypeLabels[RuleTypeName.RatePerTrip],
  },
  {
    value: RuleTypeName.RatePerMile,
    label: ruleTypeLabels[RuleTypeName.RatePerMile],
  },
  {
    value: RuleTypeName.DATBasedRate,
    label: ruleTypeLabels[RuleTypeName.DATBasedRate],
  },
];
