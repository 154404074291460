import { PodCoverageOrderBy } from './podCoverage.types';
import { PodCoverageItem } from '../../types';
import { sortingDirection } from '../../constants';

export function orderPodsByField(
  data: PodCoverageItem[],
  order: PodCoverageOrderBy,
): PodCoverageItem[] {
  const { orderBy = 'DatPod', orderDirection = sortingDirection.Asc } = order;

  let extractFunk: (v: PodCoverageItem) => string | number;

  // for PodRulesCount ordering
  extractFunk = (item) => item.PodRulesCount;

  if (
    orderBy === 'DatPodLanesAsDestination' ||
    orderBy === 'DatPodLanesAsOrigin'
  ) {
    extractFunk =
      orderBy === 'DatPodLanesAsOrigin'
        ? (item) => item.DatPodLanesAsOrigin.LanePodRulesCount
        : (item) => item.DatPodLanesAsDestination.LanePodRulesCount;
  }

  if (orderBy === 'DatPod') {
    extractFunk = (item) => item.DatPod.PodCity;
  }

  return [...data].sort((a, b) => {
    if (extractFunk(a) < extractFunk(b)) {
      return orderDirection === sortingDirection.Asc ? -1 : 1;
    }
    if (extractFunk(a) > extractFunk(b)) {
      return orderDirection === sortingDirection.Asc ? 1 : -1;
    }

    return 0;
  });
}
