import {
  CreateRuleFormModel,
  RuleExpressionsModel,
} from '../../RuleForm.types';
import { getExpressionByAttribute } from '../../RuleForm.utils';
import { ExpressionAttribute, ExpressionOperator } from '../../RuleForm.const';
import { separators } from '../../../../constants';
import {
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  ShipperApiModelExtended,
} from '../../../../types';
import { is } from '../../../../utils';

function getEquipmentType(
  expressions: Array<RuleExpressionsModel>,
): string | null {
  const equipmentType = getExpressionByAttribute<
    QuoteBaseEntityTypeUIExtended[]
  >(expressions, ExpressionAttribute.EquipmentType);

  const equipmentTypeOperator =
    equipmentType && equipmentType.operator === ExpressionOperator.NotIn
      ? 'not'
      : null;

  const equipmentTypeValues =
    equipmentType && equipmentType.value && equipmentType.value.length === 1
      ? equipmentType.value[0].Name
      : null;

  if (!equipmentTypeValues) {
    return null;
  }

  return [equipmentTypeOperator, equipmentTypeValues]
    .filter((v) => v)
    .join(separators.space);
}

function getOriginDestPOD(
  pods: RuleExpressionsModel<PODExtendedModel[]> | undefined,
  direction: string,
): string | null {
  const podsValues = (pods && pods.value) || [];
  const operator =
    pods &&
    pods.operator === ExpressionOperator.NotIn &&
    podsValues.length === 1
      ? 'not'
      : null;

  const title = podsValues.length
    ? podsValues.length === 1
      ? podsValues[0].PodCity
      : 'multiple PODs'
    : null;

  if (!title) {
    return null;
  }

  return [operator, direction, title].filter((v) => v).join(separators.space);
}

function getDistance(expressions: Array<RuleExpressionsModel>): string | null {
  const distanceExpression = getExpressionByAttribute(
    expressions,
    ExpressionAttribute.Distance,
  );
  const title = distanceExpression?.value
    ? is.rangeFilter(distanceExpression.value) &&
      distanceExpression.operator === ExpressionOperator.Between
      ? `between ${distanceExpression.value.from || '?'}${
          distanceExpression.value.to ? ' and ' : ''
        }${distanceExpression.value.to || ''}`
      : distanceExpression.value
    : null;
  const operator =
    distanceExpression &&
    distanceExpression.operator &&
    (distanceExpression.operator === ExpressionOperator.LessThan ||
      distanceExpression.operator === ExpressionOperator.GreaterThan)
      ? distanceExpression.operator === ExpressionOperator.GreaterThan
        ? '>'
        : '<'
      : null;

  if (!title) {
    return null;
  }

  return [operator, title].filter((v) => v).join('');
}

export function generateRuleName({ expressions }: CreateRuleFormModel): string {
  const customer = getExpressionByAttribute<ShipperApiModelExtended>(
    expressions,
    ExpressionAttribute.Customer,
  );

  const equipmentType = getEquipmentType(expressions);

  const originPod = getExpressionByAttribute<PODExtendedModel[]>(
    expressions,
    ExpressionAttribute.OriginPod,
  );
  const originPodText = getOriginDestPOD(originPod, 'from');

  const destinationPod = getExpressionByAttribute<PODExtendedModel[]>(
    expressions,
    ExpressionAttribute.DestinationPod,
  );
  const destinationPodText = getOriginDestPOD(destinationPod, 'to');

  const distanceText = getDistance(expressions);

  return [
    (customer && customer.value && customer.value.Name) || null,
    equipmentType,
    originPodText,
    destinationPodText,
    distanceText,
  ]
    .filter((v) => v)
    .join(separators.space);
}

export function getRuleNameDependencies({
  expressions,
}: CreateRuleFormModel): unknown[] {
  const customer = getExpressionByAttribute<ShipperApiModelExtended>(
    expressions,
    ExpressionAttribute.Customer,
  );

  const equipmentType = getExpressionByAttribute<
    QuoteBaseEntityTypeUIExtended[]
  >(expressions, ExpressionAttribute.EquipmentType);
  const originPod = getExpressionByAttribute<PODExtendedModel[]>(
    expressions,
    ExpressionAttribute.OriginPod,
  );
  const destinationPod = getExpressionByAttribute<PODExtendedModel[]>(
    expressions,
    ExpressionAttribute.DestinationPod,
  );

  const distanceExpression = getExpressionByAttribute(
    expressions,
    ExpressionAttribute.Distance,
  );

  return [
    customer && customer.operator,
    customer && customer.value?.Name,

    equipmentType && equipmentType.operator,
    equipmentType && equipmentType.value,

    originPod && originPod?.operator,
    originPod && originPod.value,

    destinationPod && destinationPod?.operator,
    destinationPod && destinationPod.value,

    distanceExpression && distanceExpression.operator,
    distanceExpression && distanceExpression.value,
  ];
}
