import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useHeaderStyles = (): SerializedStyles => {
  const { palette } = useTheme();

  return css`
    .powerBiButton.MuiButton-root.MuiButton-contained {
      background-color: ${palette.background.paper};
      padding: 7px 10px;
      min-width: 56px;

      &.powerBiButtonDisabled {
        background-color: ${palette.text.disabled};
      }

      &:hover {
        background-color: ${palette.warning.dark};
      }
    }
  `;
};
