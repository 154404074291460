import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { PlainObject } from '../../types';

interface Props {
  iconFontSize?: 'large' | 'medium' | 'small';
  iconStyles?: PlainObject;
}

export function WarningItem({
  children,
  iconFontSize,
  iconStyles = {},
}: PropsWithChildren<Props>): JSX.Element | null {
  if (!children) {
    return null;
  }

  return (
    <Stack
      direction="row"
      marginBottom={3}
      borderRadius="4px"
      padding="12px"
      sx={{ backgroundColor: 'warning.main' }}
    >
      <ErrorOutlineIcon
        fontSize={iconFontSize || 'small'}
        sx={{ marginTop: '2px', ...iconStyles }}
      />
      <Typography marginLeft={1}>{children}</Typography>
    </Stack>
  );
}
