import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rulesStoreKey } from './rules.const';
import {
  deleteRule,
  fetchDATPodsResult,
  fetchRules,
  fetchRulesEnums,
  fetchRuleValidation,
  getRule,
  saveRule,
  setPriority,
  setRuleStatus,
} from './rules.thunks';
import {
  RuleEnums,
  RulesResult,
  RulesState,
  SetRuleStatusInputModel,
} from './rules.types';
import { FreightQuoteModel, PODExtendedModel, RuleApiModel } from '../../types';
import { CreateRuleFormModel } from '../../features/ruleForm/RuleForm.types';
import { ServerListResponse } from '../../types/server-response';
import { initEnumsData } from '../../utils/ruleEnums';
import { RuleStatus } from '../../constants';

const initialState: RulesState = {
  loading: false,
  priorityLoading: false,
  loadingEnums: false,
  loadingPods: false,
  loadingRule: false,
  createRuleLoading: false,
  enums: {
    ...initEnumsData,
  },
  pods: [],
  appliedFilters: {},

  rulesTotal: 0,
  archivedRulesTotal: 0,
};

export const rulesSlice = createSlice({
  name: rulesStoreKey,
  initialState,
  reducers: {
    reset: (state) => {
      state.loadingEnums = false;
      state.loadingPods = false;
      state.createdRule = undefined;
      state.deletedRule = undefined;
      state.ruleValidation = undefined;
    },
    resetList: (state) => {
      state.loading = false;
      state.appliedFilters = {};
      state.rulesList = undefined;
    },
    resetValidations: (state) => {
      state.ruleValidation = undefined;
    },
    setCopy: (
      state,
      { payload }: PayloadAction<Partial<CreateRuleFormModel>>,
    ) => {
      state.ruleValidation = undefined;
      state.copyRule = {
        ...payload,
        ruleName: `${payload.ruleName} (copy)`,
      };
    },
    resetCopy: (state) => {
      state.copyRule = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRulesEnums.pending, (state) => {
        state.loadingEnums = true;
      })
      .addCase(
        fetchRulesEnums.fulfilled,
        (state, { payload }: PayloadAction<RuleEnums>) => {
          state.loadingEnums = false;

          state.enums = payload;
        },
      );

    builder
      .addCase(fetchDATPodsResult.pending, (state) => {
        state.loadingPods = true;
      })
      .addCase(
        fetchDATPodsResult.fulfilled,
        (state, { payload }: PayloadAction<PODExtendedModel[]>) => {
          state.loadingPods = false;
          state.pods = payload;
        },
      );

    builder
      .addCase(getRule.pending, (state) => {
        state.loadingRule = true;
      })
      .addCase(
        getRule.fulfilled,
        (state, { payload }: PayloadAction<RuleApiModel>) => {
          state.loadingRule = false;
          state.ruleToEdit = payload;
        },
      )
      .addCase(getRule.rejected, (state) => {
        state.loadingRule = false;
        state.ruleToEdit = null;
      });

    builder
      .addCase(saveRule.pending, (state) => {
        state.createRuleLoading = true;
      })
      .addCase(
        saveRule.fulfilled,
        (state, { payload }: PayloadAction<RuleApiModel>) => {
          state.createRuleLoading = false;
          state.createdRule = payload;
        },
      )
      .addCase(saveRule.rejected, (state) => {
        state.createRuleLoading = false;
      });

    builder
      .addCase(setRuleStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        setRuleStatus.fulfilled,
        (state, { payload }: PayloadAction<SetRuleStatusInputModel>) => {
          state.loading = false;

          // change counter on tabs
          state.rulesTotal =
            payload.status === RuleStatus.Deactivated
              ? state.rulesTotal - 1
              : state.rulesTotal + 1;
          state.archivedRulesTotal =
            payload.status === RuleStatus.Deactivated
              ? state.archivedRulesTotal + 1
              : state.archivedRulesTotal - 1;

          if (state.rulesList) {
            state.rulesList = state.rulesList.map((rule) =>
              rule.Id === payload.ruleId
                ? {
                    ...rule,
                    Status: payload.status,
                  }
                : rule,
            );
          }

          if (state.archivedRulesList) {
            state.archivedRulesList = state.archivedRulesList.map((rule) =>
              rule.Id === payload.ruleId
                ? {
                    ...rule,
                    Status: payload.status,
                  }
                : rule,
            );
          }

          if (state.ruleToEdit && state.ruleToEdit.Id === payload.ruleId) {
            state.ruleToEdit = {
              ...state.ruleToEdit,
              Status: payload.status,
            };
          }
        },
      )
      .addCase(setRuleStatus.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(setPriority.pending, (state) => {
        state.priorityLoading = true;
      })
      .addCase(setPriority.fulfilled, (state) => {
        state.priorityLoading = false;
      });

    builder
      .addCase(fetchRules.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchRules.fulfilled,
        (state, { payload }: PayloadAction<RulesResult>) => {
          state.loading = false;
          state.rulesList = payload.rules;
          state.archivedRulesList = payload.archivedRules;
          state.appliedFilters = payload.filters;

          state.rulesTotal = payload.rulesTotal;
          state.archivedRulesTotal = payload.archivedRulesTotal;
        },
      )
      .addCase(fetchRules.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(fetchRuleValidation.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchRuleValidation.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<
            ServerListResponse<FreightQuoteModel> & { firstFetch?: boolean }
          >,
        ) => {
          state.loading = false;

          state.ruleValidation = {
            ...payload,
            Items: [
              ...(payload.firstFetch ? [] : state.ruleValidation?.Items || []),
              ...payload.Items,
            ],
          };
        },
      )
      .addCase(fetchRuleValidation.rejected, (state) => {
        state.loading = false;
      });

    // delete
    builder
      .addCase(deleteRule.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteRule.fulfilled,
        (state, { payload }: PayloadAction<RuleApiModel>) => {
          state.loading = false;

          state.deletedRule = payload;
        },
      )
      .addCase(deleteRule.rejected, (state) => {
        state.loading = false;
      });
  },
});
