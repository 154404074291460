import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainPage from '../layouts/mainPage/MainPage';
import { NotFound } from '../pages/notFound/notFound';
import { Home } from '../pages/home/Home';
import { Dashboard } from '../pages/dashboard/Dashboard';
import { Search } from '../pages/search/Search';
import { routes } from '../constants';
import { RulePage } from '../pages/rule/RulePage';
import { fetchBaseFiltersData, fetchFiltersData } from '../redux/filtersData';
import { fetchAllNotifications } from '../redux/notification/notification.thunks';
import { useAppDispatch } from '../redux/hooks';
import { Rules } from '../pages/rules/Rules';
import { PodCoverage } from '../pages/podCoverage/PodCoverage';
import { Scheduling } from '../pages/scheduling/Scheduling';

const CustomRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(
      pathname === routes.Rules || pathname === routes.CreateRule
        ? fetchBaseFiltersData()
        : fetchFiltersData(),
    );
    dispatch(fetchAllNotifications());
  }, [pathname]);

  return (
    <Routes>
      <Route path={routes.CreateRule} element={<RulePage />} />
      <Route path={`${routes.Rules}/:ruleId`} element={<RulePage />} />
      <Route path={routes.CopyRule} element={<RulePage copy />} />

      <Route path="/" element={<MainPage />}>
        <Route path={routes.PodCoverage} element={<PodCoverage />} />
        <Route path={routes.Search} element={<Search />} />
        <Route path={routes.Rules} element={<Rules />} />
        <Route path={routes.Scheduling} element={<Scheduling />} />
        <Route path="/:page" element={<Home />} />
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default CustomRoutes;
