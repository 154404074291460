import {
  FreightQuotesState,
  FreightQuotesTabFilters,
  FreightQuotesTabState,
} from './freightQuotes.types';
import { freightQuotesStoreKey } from './freightQuotes.const';
import { FreightQuoteActionModel } from '../../types/freightQuote';
import { FreightQuotesStatusCount, ODataProps } from '../../types';
import { TabName } from '../../constants';

export interface AppStateWithFreightQuotes {
  [freightQuotesStoreKey]: FreightQuotesState;
}

const selectFreightQuotesState = (state: AppStateWithFreightQuotes) => {
  return state[freightQuotesStoreKey];
};

const getLoading = (state: AppStateWithFreightQuotes): boolean => {
  return selectFreightQuotesState(state).loading;
};

const getProcedureLoading = (state: AppStateWithFreightQuotes): boolean => {
  return selectFreightQuotesState(state).procedureLoading;
};

const getValidToSubmitLoading = (state: AppStateWithFreightQuotes): boolean => {
  return selectFreightQuotesState(state).validToSubmitLoading;
};

const getSubmitLoading = (state: AppStateWithFreightQuotes): boolean => {
  return selectFreightQuotesState(state).submitLoading;
};

const getQuotesToIgnore = (
  state: AppStateWithFreightQuotes,
): FreightQuoteActionModel[] | null => {
  return selectFreightQuotesState(state).quotesToIgnore;
};

const getQuoteToSubmit = (
  state: AppStateWithFreightQuotes,
): FreightQuoteActionModel[] | null => {
  return selectFreightQuotesState(state).quoteToSubmit;
};

const getTabsData = (
  state: unknown,
): Record<TabName, FreightQuotesTabState> => {
  return selectFreightQuotesState(state as AppStateWithFreightQuotes).tabsData;
};

const getData = (
  state: AppStateWithFreightQuotes,
): FreightQuoteActionModel[] | undefined => {
  const frState = selectFreightQuotesState(state);

  return !!frState.tabName
    ? frState.tabsData[frState.tabName].result
    : undefined;
};

const getFilters = (
  state: AppStateWithFreightQuotes,
): FreightQuotesTabFilters | undefined => {
  const tabName = getCurrentTabName(state);
  const tabParams = getCurrentTabParams(state);
  if (!tabName) return undefined;

  const request = selectFreightQuotesState(state).tabsData[tabName].request;
  if (tabParams === 'Hot') {
    return {
      ...request,
      filters: {},
    };
  }
  return request;
};

const getTotal = (state: AppStateWithFreightQuotes): number | undefined => {
  const tabName = getCurrentTabName(state);

  return !!tabName
    ? selectFreightQuotesState(state).tabsData[tabName].total
    : undefined;
};

const getCurrentTabName = (
  state: AppStateWithFreightQuotes,
): TabName | undefined => {
  return selectFreightQuotesState(state).tabName;
};

const getCurrentTabParams = (state: AppStateWithFreightQuotes): string => {
  const tabName = getCurrentTabName(state);

  return tabName
    ? selectFreightQuotesState(state).tabsData[tabName].params
    : '';
};

const getStatuses = (
  state: AppStateWithFreightQuotes,
): FreightQuotesStatusCount => {
  return selectFreightQuotesState(state).statusCount;
};

// search
const getAppliedFilters = (state: AppStateWithFreightQuotes): ODataProps => {
  return selectFreightQuotesState(state).searchState.appliedFilters;
};

const getSearchLoading = (state: AppStateWithFreightQuotes): boolean => {
  return selectFreightQuotesState(state).searchLoading;
};

const getSearchTotal = (state: AppStateWithFreightQuotes): number => {
  return selectFreightQuotesState(state).searchState.total;
};

const getSearchData = (
  state: AppStateWithFreightQuotes,
): FreightQuoteActionModel[] => {
  return selectFreightQuotesState(state).searchState.data;
};
// ------

export const freightQuotesSelectors = {
  getLoading,
  getValidToSubmitLoading,
  getData,
  getTotal,
  getStatuses,
  getCurrentTabName,
  getCurrentTabParams,
  getTabsData,
  getFilters,
  getProcedureLoading,
  getQuotesToIgnore,
  getQuoteToSubmit,
  getSubmitLoading,

  // search
  getSearchLoading,
  getAppliedFilters,
  getSearchTotal,
  getSearchData,
};
