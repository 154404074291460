import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { is } from '../../utils';
import { emptyValue } from '../../constants';

export const EmptyElement = (
  <Box component="span" sx={{ color: 'text.disabled' }}>
    &mdash;
  </Box>
);

interface Props {
  noValue?: string;
}

export function ValueOrEmpty({
  children,
  noValue,
}: PropsWithChildren<Props>): JSX.Element {
  if (is.nullOrUndefined(children) || children === emptyValue) {
    return noValue ? <>{noValue}</> : EmptyElement;
  }

  return <>{children}</>;
}
