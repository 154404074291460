import { SelectItemModel } from '../../../../types';
import {
  datActionLabel,
  DatActionValue,
  datRateLabel,
  DatRateDirection,
} from '../../../../constants';

export const datRateOptions: SelectItemModel[] = [
  {
    value: DatRateDirection.Min,
    label: datRateLabel[DatRateDirection.Min],
  },
  {
    value: DatRateDirection.Max,
    label: datRateLabel[DatRateDirection.Max],
  },
  {
    value: DatRateDirection.Avg,
    label: datRateLabel[DatRateDirection.Avg],
  },
];

export const datActionOptions: SelectItemModel[] = [
  {
    value: DatActionValue.Increase,
    label: datActionLabel[DatActionValue.Increase],
  },
  {
    value: DatActionValue.Decrease,
    label: datActionLabel[DatActionValue.Decrease],
  },
];
