import { UICell, UIRow } from '../../../../components/uiGrid';
import {
  FormikDatePicker,
  RadioButtonsGroup,
  SgSelect,
  SgTextField,
} from '../../../../components';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ExtendedRuleEnum,
  FormikEntity,
  SelectItemModel,
} from '../../../../types';
import { CreateRuleFormModel } from '../../RuleForm.types';
import { RuleMode, RulePriority } from '../../../../constants';
import {
  generateRuleName,
  getRuleNameDependencies,
} from './RuleMainForm.utils';

export interface Props {
  formik: FormikEntity<CreateRuleFormModel>;
  ruleModes: ExtendedRuleEnum[];
  ruleTypes: SelectItemModel[];
  editMode: boolean;
  hidePriority: boolean;
}

export function RuleMainForm({
  formik,
  ruleModes,
  ruleTypes,
  editMode,
  hidePriority,
}: Props): JSX.Element {
  const [autoGenerateName, setAutoGenerateName] = useState(() => {
    const autoRuleName = generateRuleName(formik.values);

    if (!editMode && autoRuleName === formik.values.ruleName) {
      return true;
    }

    return editMode || hidePriority ? false : true;
  });

  useEffect(() => {
    if (
      formik.values.mode === RuleMode.AutoIgnore &&
      formik.values.type !== undefined
    ) {
      formik.setFieldValue('type', undefined);
    }
  }, [formik.values.mode]);

  useEffect(() => {
    if (!autoGenerateName) {
      return;
    }

    const ruleName = generateRuleName(formik.values);

    if (ruleName === formik.values.ruleName) {
      return;
    }

    formik.setFieldValue('ruleName', ruleName);
  }, getRuleNameDependencies(formik.values));

  return (
    <UIRow size="l">
      <UICell colSpan={7}>
        <UIRow size="l" paddingTop={3}>
          <UICell colSpan={6}>
            <SgTextField
              label="Rule name"
              name="ruleName"
              placeholder="Enter rule name"
              formik={formik}
              onChange={() => {
                autoGenerateName && setAutoGenerateName(false);
              }}
            />
          </UICell>
          <UICell colSpan={3}>
            <SgSelect
              label="Mode"
              name="mode"
              placeholder="Select mode"
              items={ruleModes}
              formik={formik}
            />
          </UICell>
          <UICell colSpan={3}>
            {formik.values.mode !== undefined &&
              formik.values.mode !== RuleMode.AutoIgnore && (
                <SgSelect
                  label="Type"
                  name="type"
                  placeholder="Select type"
                  items={ruleTypes}
                  formik={formik}
                />
              )}
          </UICell>
        </UIRow>

        <UIRow size="l" paddingTop={3}>
          <UICell colSpan={3}>
            <FormikDatePicker
              label="Start date"
              name="startDate"
              formik={formik}
              disablePast
            />
          </UICell>
          <UICell colSpan={3}>
            <FormikDatePicker
              label="End date"
              name="endDate"
              formik={formik}
              disablePast
              shouldDisableDate={
                editMode
                  ? (day) => {
                      return day.getTime() < new Date().getTime();
                    }
                  : undefined
              }
            />
          </UICell>

          {!hidePriority && (
            <UICell colSpan={6}>
              <RadioButtonsGroup
                label="Priority"
                name="priority"
                formik={formik}
                items={[
                  {
                    label: 'Higher',
                    value: RulePriority.Higher,
                  },
                  {
                    label: 'Lower',
                    value: RulePriority.Lower,
                  },
                ]}
              />
            </UICell>
          )}
        </UIRow>

        <Box paddingTop={3}>
          <SgTextField
            multiline
            maxRows={4}
            label="Description"
            placeholder="Enter description"
            name="description"
            formik={formik}
          />
        </Box>
      </UICell>
    </UIRow>
  );
}
