import {
  NotificationState,
  ToasterNotificationModel,
} from './notification.slice';
import { notificationStoreKey } from './notification.const';
import { NotificationExtendedModel } from '../../types';

interface AppStateWithNotification {
  [notificationStoreKey]: NotificationState;
}

const selectNotificationState = (state: AppStateWithNotification) => {
  return state[notificationStoreKey];
};

const getNotification = (
  state: AppStateWithNotification,
): ToasterNotificationModel | null => {
  return selectNotificationState(state).notification;
};

const getUserNotification = (
  state: AppStateWithNotification,
): NotificationExtendedModel[] => {
  return selectNotificationState(state).notificationItems;
};

export const notificationSelectors = {
  getNotification,
  getUserNotification,
};
