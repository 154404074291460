let stamp: number;

const decline = () => {
  clearTimeout(stamp);
};

export function debounce(cb: () => void, timeout: number): () => void {
  clearTimeout(stamp);

  stamp = setTimeout(cb, timeout) as unknown as number;

  return () => {
    clearTimeout(stamp);
  };
}

debounce.decline = decline;
