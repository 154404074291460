import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filtersDataStoreKey } from './filtersData.const';
import {
  FilterRelationsResponse,
  FiltersDataModel,
  FiltersDataState,
} from './filtersData.types';
import {
  fetchBaseFiltersData,
  fetchFiltersData,
  fetchPortals,
  fetchUsers,
  updateFilterRelations,
} from './filtersData.thunks';
import { getShippersByPortals } from '../../utils';
import { UserSelectModel } from '../../types';

const initialState: FiltersDataState = {
  loading: false,
  baseLoading: false,
  usersLoading: false,
  filters: {},
  filtersOnPage: {},
};

export const filtersDataSlice = createSlice({
  name: filtersDataStoreKey,
  initialState,
  reducers: {
    resetFiltersByPage: (state, { payload }: PayloadAction<string>) => {
      state.filtersOnPage[payload] = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiltersData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchFiltersData.fulfilled,
        (state, { payload }: PayloadAction<Partial<FiltersDataModel>>) => {
          state.loading = false;
          state.filters = {
            ...state.filters,
            ...payload,
          };
        },
      );

    builder
      .addCase(fetchBaseFiltersData.pending, (state) => {
        state.baseLoading = true;
      })
      .addCase(
        fetchBaseFiltersData.fulfilled,
        (state, { payload }: PayloadAction<Partial<FiltersDataModel>>) => {
          state.baseLoading = false;
          state.filters = {
            ...state.filters,
            ...payload,
          };
        },
      );

    // portals
    builder.addCase(
      fetchPortals.fulfilled,
      (state, { payload }: PayloadAction<Partial<FiltersDataModel>>) => {
        if (!payload.portals) {
          return;
        }

        state.filters = {
          ...state.filters,
          ...payload,
          shippers: getShippersByPortals(payload.portals),
        };
      },
    );

    // users
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(
        fetchUsers.fulfilled,
        (state, { payload }: PayloadAction<UserSelectModel[]>) => {
          state.usersLoading = false;

          state.filters = {
            ...state.filters,

            users: payload,
          };
        },
      );

    // update relations
    builder
      .addCase(updateFilterRelations.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateFilterRelations.fulfilled,
        (state, { payload }: PayloadAction<FilterRelationsResponse>) => {
          state.loading = false;
          state.filtersOnPage[payload.forPage] = {
            ...(state.filtersOnPage[payload.forPage] || {}),
            ...payload.filters,
          };
        },
      )
      .addCase(updateFilterRelations.rejected, (state) => {
        state.loading = false;
      });
  },
});
