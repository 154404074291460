import { QuoteShortLocationUIExtended } from '../../types';
import { Box } from '@mui/material';

const EmptyValue = (
  <Box component="span" sx={{ color: 'text.disabled' }}>
    &mdash;
  </Box>
);

export function locationFormatter(
  location: QuoteShortLocationUIExtended | null,
  long?: boolean,
  defaultEmpty?: JSX.Element,
): string | JSX.Element;
export function locationFormatter(
  location: QuoteShortLocationUIExtended | null,
  long?: boolean,
  defaultEmpty?: string,
): string;
export function locationFormatter(
  location: QuoteShortLocationUIExtended | null,
  long?: boolean,
  defaultEmpty: string | JSX.Element = EmptyValue,
): string | JSX.Element {
  const { CityName, ProvinceAbbr, CountryName } = location || {};

  if (!CityName && !ProvinceAbbr) {
    return defaultEmpty;
  }

  const address = [CityName, ProvinceAbbr, long ? CountryName : null].filter(
    (v) => v,
  );

  return address.join(', ');
}
