import { useState } from 'react';
import { Box, Link, Stack, Typography } from '@mui/material';
import { SgLink, ValueOrEmpty } from '../../../../../../components';
import { weightFormat } from '../../../../../../utils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FreightQuoteActionModel } from '../../../../../../types';
import { CommoditiesDialog } from '../../../../../commoditiesDialog/CommoditiesDialog';
import { BidsHistoryDialog } from '../../../../../bidsHistoryDialog/BidsHistoryDialog';

interface Props {
  data: FreightQuoteActionModel;
}

export const renderDetailsSourceUrl = (
  href: string | null,
  title?: string,
): JSX.Element => {
  if (!href) {
    return <ValueOrEmpty />;
  }

  return (
    <Link href={href} target="_blank">
      {title || 'Visit website'} <OpenInNewIcon fontSize="small" />
    </Link>
  );
};

export function DetailsContent({ data }: Props): JSX.Element {
  const [openCommodities, setOpenCommodities] = useState(false);
  const [openBidsHistory, setOpenBidsHistory] = useState(false);

  const renderDetailsRow = (
    title: string,
    content?: JSX.Element | string | number | null,
  ) => (
    <Stack direction="row" sx={{ paddingTop: 1 }}>
      <Box sx={{ marginRight: 2, width: 'calc(35% - 16px)' }}>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box sx={{ width: '65%' }}>
        <Typography variant="h4">
          <ValueOrEmpty>{content}</ValueOrEmpty>
        </Typography>
      </Box>
    </Stack>
  );

  return (
    <Box>
      {renderDetailsRow('Source', data.Portal?.Name)}
      {renderDetailsRow('Source ID', data.SourceId)}
      {renderDetailsRow(
        'Commodities',
        <SgLink
          tooltip="No available data"
          disabled={!(data.Commodities || []).length}
          onClick={() => setOpenCommodities(true)}
        >
          View details
        </SgLink>,
      )}
      {renderDetailsRow('Weight', weightFormat(data.Weight))}
      {renderDetailsRow(
        'Equipment type',
        data.PortalEquipmentType || data.EquipmentType?.Name,
      )}
      {renderDetailsRow('PO Number', data.PONumber)}
      {renderDetailsRow('Source URL', renderDetailsSourceUrl(data.SourceURL))}
      {renderDetailsRow(
        'Bids history',
        <SgLink
          tooltip="No available data"
          disabled={!(data.BidsHistory || []).length}
          onClick={() => setOpenBidsHistory(true)}
        >
          View details
        </SgLink>,
      )}
      {renderDetailsRow('Comments', data.Comment)}

      {openCommodities && (
        <CommoditiesDialog
          data={data.Commodities || []}
          weight={data.Weight}
          onClosed={() => setOpenCommodities(false)}
        />
      )}

      {openBidsHistory && (
        <BidsHistoryDialog
          data={data.BidsHistory || []}
          onClosed={() => setOpenBidsHistory(false)}
        />
      )}
    </Box>
  );
}
