import { PlainObject } from '../../types';

export function EqualIcon({ style }: { style: PlainObject }): JSX.Element {
  return (
    <svg
      style={style}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="4" width="10" height="1" fill="#00090E" />
      <rect x="1" y="7" width="10" height="1" fill="#00090E" />
    </svg>
  );
}
