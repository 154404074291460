import { TabName } from '../../constants';
import { FreightQuoteActionModel } from '../../types';
import {
  freightQuotesSelectors,
  freightQuotesSlice,
  ignoreQuotes,
  setQuoteToSubmit,
  submitBid,
} from '../../redux/freightQuotes';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ConfirmationDialog } from '../confirmationDialog/ConfirmationDialog';
import { SubmitBidDialog } from '../submitBidDialog/SubmitBidDialog';
import React from 'react';
import { BidForm } from '../../types/bidToSubmit';

interface Props {
  statusName?: TabName;
}

export function QuoteActionDialogs({ statusName }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  const quotesToIgnore = useAppSelector(
    freightQuotesSelectors.getQuotesToIgnore,
  );
  const quoteToSubmit = useAppSelector(freightQuotesSelectors.getQuoteToSubmit);
  const procedureLoading = useAppSelector(
    freightQuotesSelectors.getProcedureLoading,
  );
  const submitLoading = useAppSelector(freightQuotesSelectors.getSubmitLoading);

  const setQuoteToIgnore = (quote: FreightQuoteActionModel[] | null) => {
    dispatch(freightQuotesSlice.actions.setQuotesToIgnore(quote));
  };

  const setQuoteItemToSubmit = (quote: FreightQuoteActionModel | null) => {
    dispatch(setQuoteToSubmit(quote && [quote]));
  };

  const submit = (data: BidForm) => {
    if (!quoteToSubmit || !quoteToSubmit.length) {
      return;
    }

    dispatch(submitBid({ quotes: quoteToSubmit, data }));
  };

  const onIgnore = () => {
    if (!quotesToIgnore) {
      return;
    }

    dispatch(ignoreQuotes(quotesToIgnore));
  };

  return (
    <>
      {quotesToIgnore && !!quotesToIgnore.length && (
        <ConfirmationDialog
          title="Ignore"
          message="Are you sure want to ignore this quote?"
          closeLabel="No"
          submitLabel="Yes, ignore"
          loading={procedureLoading}
          onSubmit={onIgnore}
          onClosed={() => setQuoteToIgnore(null)}
        />
      )}

      {quoteToSubmit && (
        <SubmitBidDialog
          currentTabName={statusName}
          loading={submitLoading}
          onSubmit={submit}
          quote={quoteToSubmit[0]}
          count={quoteToSubmit.length}
          onClosed={() => setQuoteItemToSubmit(null)}
        />
      )}
    </>
  );
}
