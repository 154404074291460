import { Box, Button, Stack } from '@mui/material';
import { SGAutocomplete } from '../formElements/autocomplete/SGAutocomplete';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import {
  ExtendedRuleEnum,
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  RulesFiltersModel,
  SearchFiltersModel,
  UserSelectModel,
} from '../../types';
import { getShippersByPortals, podNameMktFormatter } from '../../utils';
import { renderFilterTag } from './Filters.utils';
import React, { useMemo } from 'react';
import { FilterBaseProps } from './Filters.types';

interface Props extends Omit<FilterBaseProps, 'origins' | 'destinations'> {
  loading?: boolean;
  filters: Partial<RulesFiltersModel>;
  overloadedFilters?: Partial<RulesFiltersModel>;
  onChange: (values?: Partial<RulesFiltersModel>) => void;
  onUpdateFilters?: (values?: Partial<SearchFiltersModel>) => void;
  pods: PODExtendedModel[];
  users: UserSelectModel[];
  ruleModes: ExtendedRuleEnum[];
  ruleStatuses: ExtendedRuleEnum[];
  disableRuleStatus?: boolean;
}

export function RulesFilters({
  filters,
  overloadedFilters = {},
  onChange,
  loading,
  portals,
  pods,
  users,
  ruleModes,
  ruleStatuses,
  equipmentTypes,
  disableRuleStatus,
}: Props): JSX.Element {
  const styles = { width: '40%', marginRight: 2 };

  const hasFilters = Object.keys(filters).length > 0;

  const shippers = useMemo(() => {
    return getShippersByPortals(
      filters.portals && filters.portals.length ? filters.portals : portals,
    );
  }, [filters, portals]);

  const onValueChange = (newValues: unknown[], name?: string) => {
    onChange({
      [name as keyof RulesFiltersModel]: newValues,
    });
  };

  return (
    <Box
      sx={{
        padding: 3,
        paddingTop: 2,
        marginBottom: 2,
        borderRadius: '4px',
        backgroundColor: 'background.default',
      }}
    >
      <Stack direction="row">
        <Box sx={styles}>
          <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
            label="Customer"
            name="shippers"
            id="customer-filter"
            placeholder="All customers"
            disabled={loading}
            options={shippers}
            value={filters.shippers || []}
            getOptionLabel={(option) => (option && option.Name) || ''}
            getOptionDisabled={(option) => !!option?.disabled}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
            label="Equipment type"
            name="equipmentTypes"
            id="equipment-filter"
            placeholder="All types"
            disabled={loading}
            options={equipmentTypes}
            value={filters.equipmentTypes || []}
            getOptionLabel={(option) => (option && option.Name) || ''}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<PODExtendedModel>
            label="Origin POD"
            name="originPOD"
            placeholder="All PODs"
            disabled={loading}
            options={pods}
            value={filters.originPOD || []}
            getOptionLabel={(option) => podNameMktFormatter(option)}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) =>
              option.DatId === value.DatId
            }
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<PODExtendedModel>
            label="Destination POD"
            name="destinationPOD"
            placeholder="All PODs"
            disabled={loading}
            options={pods}
            value={filters.destinationPOD || []}
            getOptionLabel={(option) => podNameMktFormatter(option)}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) =>
              option.DatId === value.DatId
            }
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<ExtendedRuleEnum>
            label="Mode"
            name="mode"
            placeholder="All modes"
            disabled={loading}
            options={ruleModes}
            value={filters.mode || []}
            getOptionLabel={(option) => option.label}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) => option.Key === value.Key}
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<UserSelectModel>
            label="User"
            name="userId"
            placeholder="All users"
            disabled={loading}
            options={users || []}
            value={filters.userId || []}
            getOptionLabel={(option) =>
              `${option.FirstName} ${option.LastName}`
            }
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
          />
        </Box>

        <Box sx={styles}>
          <SGAutocomplete<ExtendedRuleEnum>
            label="Status"
            name="ruleStatus"
            placeholder="All statuses"
            disabled={loading || disableRuleStatus}
            options={ruleStatuses}
            value={overloadedFilters?.ruleStatus || filters.ruleStatus || []}
            getOptionLabel={(option) => option.label}
            renderTags={renderFilterTag}
            onChange={onValueChange}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Box>

        <Stack direction="row" alignItems="end">
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            disabled={!hasFilters}
            onClick={() => {
              if (!hasFilters) {
                return;
              }

              onChange();
            }}
            sx={{ minWidth: '40px', padding: 1 }}
          >
            <CleaningServicesOutlinedIcon fontSize="small" />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
