import { PodLaneItem } from '../../../../types';
import { sortingDirection } from '../../../../constants';

export interface PodLanesOrderBy {
  orderBy: keyof PodLaneItem;
  orderDirection: sortingDirection;
}

export function orderLanesByField(
  data: PodLaneItem[],
  order: PodLanesOrderBy,
): PodLaneItem[] {
  const { orderBy = 'DatPod', orderDirection = sortingDirection.Asc } = order;

  let extractFunk: (v: PodLaneItem) => string | number;

  // for PodRulesCount ordering
  extractFunk = (item) => item.DatPod.PodCity;

  if (orderBy === 'LaneRulesCount') {
    extractFunk = (item) => item.LaneRulesCount;
  }

  return [...data].sort((a, b) => {
    if (extractFunk(a) < extractFunk(b)) {
      return orderDirection === sortingDirection.Asc ? -1 : 1;
    }
    if (extractFunk(a) > extractFunk(b)) {
      return orderDirection === sortingDirection.Asc ? 1 : -1;
    }

    return 0;
  });
}
