import { AntSwitch, SgTable, SgTableRow } from '../../components';
import React, { useMemo } from 'react';
import { ExtendedRuleEnum, RuleApiModel } from '../../types';
import { getRulesGridColumns } from './RulesDataGrid.const';
import { getEnumLabels } from '../../utils';
import { Box, IconButton, Stack } from '@mui/material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { routes, RuleStatus } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';
import { setPriority, setRuleStatus } from '../../redux/rules';

interface Props {
  rows: RuleApiModel[];
  disablePriority?: boolean;
  ruleModes: ExtendedRuleEnum[];
  ruleStatuses: ExtendedRuleEnum[];
  archivedRules: boolean;
}

export function RulesDataGrid({
  rows,
  ruleModes,
  ruleStatuses,
  disablePriority,
  archivedRules,
}: Props): JSX.Element | null {
  const dispatch = useAppDispatch();

  const renderActions = (rowData: RuleApiModel): JSX.Element | null => {
    return (
      <Stack direction="row" alignItems="center">
        <Box>
          <IconButton
            color="primary"
            type="button"
            size="small"
            href={`${routes.Rules}/${rowData.Id}`}
          >
            <ModeEditOutlineOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Box>
        {rowData.Status !== RuleStatus.Draft && (
          <Box marginLeft={2}>
            <AntSwitch
              checked={rowData.Status === RuleStatus.Active}
              onChange={(e, checked) =>
                dispatch(
                  setRuleStatus({
                    ruleId: rowData.Id,
                    status: checked
                      ? RuleStatus.Active
                      : RuleStatus.Deactivated,
                  }),
                )
              }
            />
          </Box>
        )}
      </Stack>
    );
  };

  const columns = useMemo(() => {
    const ruleModeLabels = getEnumLabels(ruleModes);
    const ruleStatusLabels = getEnumLabels(ruleStatuses);

    return getRulesGridColumns(ruleModeLabels, ruleStatusLabels, archivedRules);
  }, [ruleModes, ruleStatuses]);

  return (
    <SgTable<RuleApiModel>
      draggable={!archivedRules}
      actionColumn
      columns={columns}
    >
      {rows.map((row) => (
        <SgTableRow<RuleApiModel>
          key={row.Id}
          rowData={row}
          columns={columns}
          renderActions={renderActions}
          name={row.Id}
          dragConfig={
            archivedRules
              ? undefined
              : {
                  disabled: disablePriority,
                  tableData: rows,
                  onNewIndex: (priorityIndex) =>
                    priorityIndex !== null &&
                    dispatch(
                      setPriority({
                        ruleId: row.Id,
                        priorityIndex,
                        rulesList: rows,
                      }),
                    ),
                }
          }
        />
      ))}
    </SgTable>
  );
}
