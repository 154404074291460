import { PlainObject } from '../../types';

export function FuelIcon({ style }: { style: PlainObject }): JSX.Element {
  return (
    <svg
      style={style}
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00033 0.333008C2.44699 3.36634 0.666992 5.98634 0.666992 8.19967C0.666992 11.5197 3.20033 13.6663 6.00033 13.6663C8.80033 13.6663 11.3337 11.5197 11.3337 8.19967C11.3337 5.98634 9.55366 3.36634 6.00033 0.333008ZM6.00033 12.333C3.76699 12.333 2.00033 10.6197 2.00033 8.19967C2.00033 6.63967 3.30033 4.57301 6.00033 2.10634C8.70033 4.57301 10.0003 6.63301 10.0003 8.19967C10.0003 10.6197 8.23366 12.333 6.00033 12.333ZM3.22033 8.33301C3.46699 8.33301 3.66699 8.50634 3.71366 8.74634C3.98699 10.2263 5.23366 10.733 6.14033 10.6597C6.42699 10.6463 6.66699 10.873 6.66699 11.1597C6.66699 11.4263 6.45366 11.6463 6.18699 11.6597C4.76699 11.7463 3.10699 10.933 2.72699 8.91301C2.67366 8.61301 2.91366 8.33301 3.22033 8.33301Z"
        fill="black"
      />
    </svg>
  );
}
