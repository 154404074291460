import { podCoverageStoreKey } from './podCoverage.const';
import { PodCoverageOrderBy, PodCoverageState } from './podCoverage.types';
import { PodCoverageItem } from '../../types';

interface AppStateWithRules {
  [podCoverageStoreKey]: PodCoverageState;
}

const selectRulesState = (state: AppStateWithRules) => {
  return state[podCoverageStoreKey];
};

const getLoading = (state: AppStateWithRules): boolean => {
  return selectRulesState(state).loading;
};

const getPodCoverage = (state: AppStateWithRules): PodCoverageItem[] => {
  return selectRulesState(state).data;
};

const getPodCoverageOrder = (state: AppStateWithRules): PodCoverageOrderBy => {
  return selectRulesState(state).order;
};

export const podCoverageSelectors = {
  getLoading,
  getPodCoverage,
  getPodCoverageOrder,
};
