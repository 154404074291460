import { Tooltip } from '@mui/material';
import { PropsWithChildren, ReactElement } from 'react';

interface Props {
  placement?: 'left' | 'right' | 'top';
  variant?: 'dark' | 'light';
  title?: string | null | JSX.Element;
}

export function CustomTooltip({
  title,
  placement = 'right',
  variant = 'light',
  children,
}: PropsWithChildren<Props>): JSX.Element {
  if (!title || !children) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      arrow
      placement={placement}
      PopperProps={
        variant === 'light'
          ? {
              className: 'customStopsTooltip',
            }
          : {}
      }
      title={title}
    >
      {children as ReactElement}
    </Tooltip>
  );
}
