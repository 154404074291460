import { css, SerializedStyles } from '@emotion/react';

export const useSchedulingDataGridStyles = (): SerializedStyles => {
  return css`
    .editOrReviewContainer {
      &.editMode {
        .reviewContainer {
          height: 0;
          padding: 0;
          overflow: hidden;
          display: block;
          pointer-events: none;
        }
      }
      &.reviewMode {
        .editContainer {
          height: 0;
          padding: 0;
          overflow: hidden;
          pointer-events: none;
        }
      }
    }
  `;
};
