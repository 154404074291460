import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  DialogWrapper,
  DialogWrapperMainProps,
} from '../../components/dialogWrapper/DialogWrapper';
import { BarChart } from '../../components/charts';
import React, { PropsWithChildren } from 'react';
import { DashboardTotalRevenueResponse } from '../../types';
import { TotalRevenueGroupBy } from '../../constants';
import { Box } from '@mui/material';

interface Props extends DialogWrapperMainProps {
  height: number;
  data: DashboardTotalRevenueResponse[] | null;
  groupBy: TotalRevenueGroupBy;
}

export function TotalRevenueDialog({
  height,
  data,
  groupBy,
  children,
  ...props
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <DialogWrapper
      noActions
      width={1300}
      title="Total revenue"
      closeIcon={FullscreenExitIcon}
      {...props}
    >
      <Box>{children}</Box>
      <BarChart fullScreen height={height} data={data} groupBy={groupBy} />
    </DialogWrapper>
  );
}
