import { Box, useTheme } from '@mui/material';
import { QuoteStatus } from '../../../../constants';
import { FreightQuoteActionModel } from '../../../../types';
import { QuoteErrorIcon } from '../../../../components/quoteErrorIcon/QuoteErrorIcon';
import { is } from '../../../../utils';

interface Props {
  quote?: FreightQuoteActionModel;
}

const progressStatusNames: Partial<Record<QuoteStatus, string>> = {
  [QuoteStatus.SubmitRequestFailed]: 'Submit failed',
  [QuoteStatus.SubmitRequested]: 'Submit requested',
};

export function StatusLabel({ quote }: Props): JSX.Element {
  const theme = useTheme();

  let status = quote && quote.Status;
  const bid = (quote && quote.SageBid) || null;

  if (
    status === QuoteStatus.SubmitRequested ||
    status === QuoteStatus.SubmitRequestFailed
  ) {
    status = is.nullOrUndefined(bid) ? QuoteStatus.Open : QuoteStatus.Submitted;
  }

  const colors = {
    [QuoteStatus.Open]: theme.palette.saganStatus.open,
    [QuoteStatus.SubmitRequested]: theme.palette.saganStatus.open,
    [QuoteStatus.SubmitRequestFailed]: theme.palette.saganStatus.open,
    [QuoteStatus.Submitted]: theme.palette.saganStatus.submitted,
    [QuoteStatus.Ignored]: theme.palette.saganStatus.ignored,
    [QuoteStatus.Won]: theme.palette.saganStatus.won,
    [QuoteStatus.Lost]: theme.palette.saganStatus.lost,
    [QuoteStatus.Missed]: theme.palette.saganStatus.missed,
  };

  const errorIcon = quote && <QuoteErrorIcon quote={quote} />;

  return (
    <Box position="relative">
      <Box
        sx={{
          display: 'inline',
          padding: '4px',
          borderRadius: '4px',
          whiteSpace: 'nowrap',
          backgroundColor:
            colors[status || QuoteStatus.Open] || colors[QuoteStatus.Open],
        }}
      >
        {(status && progressStatusNames[status]) || status}
      </Box>

      {errorIcon}
    </Box>
  );
}
