export const azureMapsLayerConfig = {
  iconOptions: {
    image: ['get', 'icon'],
    allowOverlap: true,
  },
  textOptions: {
    textField: ['get', 'title'],
    offset: [0, 1.2],
  },
  filter: [
    'any',
    ['==', ['geometry-type'], 'Point'],
    ['==', ['geometry-type'], 'MultiPoint'],
  ], //Only render Point or MultiPoints in this layer.
};

export const azureMapsStyles = {
  strokeColor: '#2272B9',
  strokeWidth: 3,
  lineJoin: 'round',
  lineCap: 'round',
};
