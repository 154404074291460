export enum TotalRevenueGroupBy {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export const TotalRevenueGroupLabels = {
  [TotalRevenueGroupBy.Month]: 'Monthly',
  [TotalRevenueGroupBy.Week]: 'Weekly',
  [TotalRevenueGroupBy.Day]: 'Daily',
};
