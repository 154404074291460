import { css, SerializedStyles } from '@emotion/react';
import { useTheme, alpha } from '@mui/material';
import { zIndexes } from '../../theme';

export const useLoaderStyles = (): SerializedStyles => {
  const { palette } = useTheme();

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.with-bg {
      background-color: ${alpha(palette.common.white, 0.4)};
      z-index: ${zIndexes.LOADER};
    }
    &.full-page-wrapper {
      position: fixed;
      z-index: ${zIndexes.LOADER};
    }

    .loader-container {
      text-align: center;
    }
  `;
};
