/** @jsxImportSource @emotion/react */
import { useNotFoundStyles } from './notFound.styles';
import { Typography } from '@mui/material';

export const NotFound = (): JSX.Element => {
  const styles = useNotFoundStyles();

  return (
    <div className="not-found-wrapper" css={styles}>
      <div className="nf-container">
        <Typography typography="h1" p={2} className="nf-status">
          404
        </Typography>
        <Typography typography="h2" p={1}>
          Page Not Fount
        </Typography>
        <div className="nf-link">Go back to the previous page</div>
      </div>
    </div>
  );
};
