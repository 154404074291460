import { ColumnModel, EmptyElement } from '../../components';
import {
  priceFormat,
  quoteDistanceFormatter,
  rowIsoToDateMonthString,
  rowLocationFormatter,
  rowSageBid,
} from '../../utils';
import { TimerCell } from './components/timerCell/TimerCell';
import { StopsTooltip } from '../dataGrid/components';
import { FreightQuoteActionModel } from '../../types';
import { TabName } from '../../constants';

export const defaultColumns: ColumnModel<FreightQuoteActionModel>[] = [
  {
    name: 'Shipper',
    label: 'Customer',
  },
  {
    name: 'OriginLocation',
    label: 'Origin',
    formatter: rowLocationFormatter,
  },
  {
    name: 'DestinationLocation',
    label: 'Destination',
    formatter: rowLocationFormatter,
  },
  {
    name: 'ShipDate',
    label: 'Pick up',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: rowIsoToDateMonthString,
  },
  {
    name: 'DeliveryDate',
    label: 'Delivery',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: rowIsoToDateMonthString,
  },
  {
    name: 'RespondBy',
    label: 'Respond by',
    headerStyles: { whiteSpace: 'nowrap' },
    styles: { whiteSpace: 'nowrap' },
    formatter: (quote) => TimerCell({ quote }),
  },
  {
    name: 'Distance',
    label: 'Distance (mi)',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: quoteDistanceFormatter,
  },
  {
    name: 'StopsCount',
    label: 'Stops',
    align: 'right',
    formatter: StopsTooltip,
  },
  {
    name: 'EquipmentType',
    label: 'Equipm. type',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) =>
      (data.EquipmentType && data.EquipmentType.Name) || EmptyElement,
  },
  {
    name: 'LowestBid',
    label: 'Lowest bid',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => priceFormat(data.LowestBid),
  },
];

export const getWonLostColumns = (
  tabName: TabName,
): ColumnModel<FreightQuoteActionModel>[] => [
  {
    name: 'SageBid',
    label: 'Sage bid',
    align: 'right',
    formatter: (item) => rowSageBid(item, false, tabName),
  },
];
