import { NotificationExtendedModel, NotificationModel } from '../../types';

export function setPropsToNotification(
  id: number | null,
  notifications: NotificationModel[],
  props: Partial<NotificationExtendedModel>,
): NotificationExtendedModel[] {
  return notifications.map((item) =>
    item.Id === id ? { ...item, ...props } : item,
  );
}
