import { SerializedStyles, css } from '@emotion/react';

export const useStopsContentStyles = (): SerializedStyles => {
  return css`
    .MuiSvgIcon-fontSizeSmall {
      font-size: 14px;
      margin: -2px 5px;
    }
  `;
};
