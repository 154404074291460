import {
  configureStore,
  ThunkAction,
  Action,
  PreloadedState,
} from '@reduxjs/toolkit';
import { userDataStoreKey, userDataSlice } from './userData';
import { freightQuotesStoreKey, freightQuotesSlice } from './freightQuotes';
import { filtersDataStoreKey, filtersDataSlice } from './filtersData';
import { notificationSlice, notificationStoreKey } from './notification';
import { dashboardStoreKey, dashboardSlice } from './dashboard';
import { rulesSlice, rulesStoreKey } from './rules';
import { podCoverageSlice, podCoverageStoreKey } from './podCoverage';
import { schedulingSlice, schedulingStoreKey } from './scheduling';

export const storeReducers = {
  [userDataStoreKey]: userDataSlice.reducer,
  [freightQuotesStoreKey]: freightQuotesSlice.reducer,
  [filtersDataStoreKey]: filtersDataSlice.reducer,
  [notificationStoreKey]: notificationSlice.reducer,
  [dashboardStoreKey]: dashboardSlice.reducer,
  [rulesStoreKey]: rulesSlice.reducer,
  [podCoverageStoreKey]: podCoverageSlice.reducer,
  [schedulingStoreKey]: schedulingSlice.reducer,
};

export const store = configureStore({
  reducer: storeReducers,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
