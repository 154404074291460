import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Loader } from './loader';

interface Props {
  loading?: boolean;
  fullPage?: boolean;
  avoidContent?: boolean;
}

export function LoaderContainer({
  loading,
  fullPage,
  avoidContent,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  return (
    <Box>
      {(!avoidContent || !loading) && children}
      {loading && <Loader withBg fullPage={fullPage} />}
    </Box>
  );
}
