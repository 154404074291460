import { PieCustomLayerProps } from '@nivo/pie';
import { PlainObject, DashboardBreakdownResponse } from '../../../types';
import { useMemo } from 'react';
import { is } from '../../../utils';
import {
  pieChartItemsOrder,
  pieChartItemsLabel,
  pieChartItemColors,
} from './PieChart.const';
import { PieDataItem } from './PieChart.types';

export function useGetChartData(data?: DashboardBreakdownResponse | null) {
  const summ = useMemo(() => {
    if (!data || !Object.keys(data).length) {
      return 0;
    }

    return Object.keys(data).reduce(
      (acum, key) =>
        acum + (data[key as unknown as keyof DashboardBreakdownResponse] || 0),
      0,
    );
  }, [data]);

  return useMemo((): [PieDataItem[] | null, PlainObject] => {
    if (!data || !Object.keys(data).length) {
      return [null, {}];
    }

    const legend: PlainObject = {};

    const dataArray = pieChartItemsOrder
      .map((key) => {
        const result = !is.nullOrUndefined(data[key])
          ? {
              id: pieChartItemsLabel[key],
              label: pieChartItemsLabel[key],
              value: data[key],
              percent: ((data[key] || 0) * 100) / summ,
              color: pieChartItemColors[key],
            }
          : null;

        if (result) {
          legend[key] = result;
        }

        return result;
      })
      .filter((v) => v) as PieDataItem[];

    return [dataArray, legend];
  }, [data]);
}

export function CenteredMetric({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<PlainObject>) {
  let total = 0;

  dataWithArc.forEach((datum) => {
    total += datum.value;
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '32px',
        fontWeight: 600,
        color: '#5B646A',
      }}
      color="#5B646A"
    >
      {total}
    </text>
  );
}
