/** @jsxImportSource @emotion/react */
import React from 'react';
import { SgTable, SgTableRow } from '../../components';
import { ScheduleItem } from '../../types/scheduleItem';
import { getSchedulingDataGridColumns } from './SchedulingDataGrid.const';
import { ScheduleGridItem } from './SchedulingDataGrid.types';
import { useScheduleForm } from './Scheduling.utils';
import { ExtendedRuleEnum } from '../../types';
import { ScheduleEditItem } from '../../redux/scheduling/scheduling.types';
import { SchedulingCondition } from '../../constants';
import { useSchedulingDataGridStyles } from './SchedulingDataGrid.styles';

interface Props {
  rows: ScheduleItem[];
  actionEnums: ExtendedRuleEnum[];
  conditionEnums: ExtendedRuleEnum[];
  onSaveSchedule: (item: ScheduleItem, formData: ScheduleEditItem) => void;
  toggleScheduleEdit: (item: ScheduleItem) => void;
}

export function SchedulingDataGrid({
  rows,
  actionEnums,
  conditionEnums,
  onSaveSchedule,
  toggleScheduleEdit,
}: Props): JSX.Element {
  const styles = useSchedulingDataGridStyles();
  const { values, onCancel, onEdit, onValueChange, validateBeforeSubmit } =
    useScheduleForm(rows);

  const onSubmit = (item: ScheduleGridItem) => {
    const errors = validateBeforeSubmit(item.Id);

    if (errors) {
      return;
    }

    const { condition, delay } = values[item.Id] || {};

    onSaveSchedule(item, {
      Condition: condition as SchedulingCondition,
      Delay: Number(delay),
    });
  };

  const columns = getSchedulingDataGridColumns(
    actionEnums,
    conditionEnums,
    onValueChange,
    (v: ScheduleGridItem) => {
      onEdit(v);
      toggleScheduleEdit(v);
    },
    (v: ScheduleGridItem) => {
      onCancel(v);
      toggleScheduleEdit(v);
    },
    onSubmit,
  );

  return (
    <div css={styles}>
      <SgTable<ScheduleGridItem> columns={columns}>
        {rows.map((row) => (
          <SgTableRow<ScheduleGridItem>
            key={row.Id}
            rowData={values[row.Id] ? { ...values[row.Id], ...row } : row}
            columns={columns}
          />
        ))}
      </SgTable>
    </div>
  );
}
