/** @jsxImportSource @emotion/react */
import { TableCell, TableRow } from '@mui/material';
import React, { PropsWithChildren, useRef } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDragService } from './services/DragService';
import { cn } from '../../utils';
import { useSgDraggableRowStyles } from './SgDraggableRow.styles';

interface Props {
  className?: string;
  cellCount: number;
  name: string | number;
  disabled?: boolean;
  onNewIndex?: (index: number | null) => void;
  deps?: unknown[];
}

export function SgDraggableRow({
  children,
  className,
  cellCount,
  name,
  onNewIndex,
  deps,
  disabled,
}: PropsWithChildren<Props>): JSX.Element {
  const styles = useSgDraggableRowStyles();
  const ref = useRef<HTMLTableRowElement>(null);
  const { dragStart, moving } = useDragService({
    name,
    deps,
    ref,
    preventLastAction: true,
    onIndexChanged: (i) => {
      onNewIndex && onNewIndex(i);
    },
  });

  return (
    <>
      <TableRow css={styles} className={cn(className)}>
        <TableCell className="emptyCell" colSpan={cellCount + 1} />
      </TableRow>

      <TableRow
        ref={ref}
        data-row-name={name}
        className={cn(
          className,
          moving && 'draggableRowMoving',
          'draggableRow',
          !moving && 'draggableRowInactive',
        )}
        style={moving ? { position: 'fixed' } : {}}
      >
        <TableCell sx={{ padding: '5px !important', backgroundColor: 'white' }}>
          <div
            onMouseDown={disabled ? undefined : dragStart}
            className={cn(!disabled ? 'dragElement' : 'inactiveDragElement')}
            style={{ height: '18px' }}
          >
            <DragIndicatorIcon
              fontSize="small"
              color={disabled ? 'disabled' : 'inherit'}
            />
          </div>
        </TableCell>

        {children}
      </TableRow>

      <TableRow css={styles} className={className}>
        <TableCell className="emptyCell" colSpan={cellCount + 1} />
      </TableRow>
    </>
  );
}
