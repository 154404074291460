import { useState } from 'react';
import { FreightQuoteActionModel } from '../../../types';
import { MINUTE } from '../../../constants';
import {
  fetchCarrierView,
  fetchLookupRates,
  fetchSimilarQuotes,
  freightQuotesSlice,
} from '../../../redux/freightQuotes';

export function useControlFocusedQuota(dispatch: (action: unknown) => void) {
  const [focusedQuote, setFocusedQuote] =
    useState<FreightQuoteActionModel | null>(null);

  const onRowExpanded = (quote: FreightQuoteActionModel, open: boolean) => {
    if (!open) {
      if (quote.Id === focusedQuote?.Id) {
        setFocusedQuote(null);
      }

      dispatch(freightQuotesSlice.actions.resetQuoteDetails([quote]));

      return;
    }

    if (
      !quote.timestampForSimilarQuotes ||
      quote.timestampForSimilarQuotes < new Date().getTime() - MINUTE
    ) {
      dispatch(freightQuotesSlice.actions.resetSimilarQuotes([quote]));
      dispatch(fetchSimilarQuotes(quote));
    }

    dispatch(fetchLookupRates(quote));
    dispatch(fetchCarrierView(quote));
  };

  return {
    focusedQuote,
    setFocusedQuote,
    onRowExpanded,
  };
}
