import { LinkBaseProps } from '@mui/material/Link/Link';
import { Link, Tooltip, useTheme } from '@mui/material';
import { Link as DomLink, LinkProps } from 'react-router-dom';
import { PropsWithChildren, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props extends LinkBaseProps {
  tooltip?: string;
  useNavigation?: boolean;
  disabled?: boolean;
}

export function SgLink({
  disabled,
  tooltip,
  onClick,
  href,
  target,
  useNavigation,
  children,
  ...props
}: PropsWithChildren<Props>): JSX.Element {
  const navigate = useNavigate();

  const onNavigate = (e: MouseEvent<HTMLAnchorElement>) => {
    if (href) {
      navigate(href);
    }
    if (onClick) {
      onClick(e);
    }
  };

  const additionalProps = disabled
    ? {
        sx: {
          cursor: 'default',
          outline: 'none',
          color: 'text.disabled',
          textDecorationColor: 'inherit',
        },
      }
    : {
        onClick: href && useNavigation ? onNavigate : onClick,
        href: useNavigation ? undefined : href,
        target,
        sx: {
          cursor: 'pointer',
        },
      };

  const link = (
    <Link variant="h4" {...props} {...additionalProps}>
      {children}
    </Link>
  );

  if (!disabled || !tooltip) {
    return link;
  }

  return (
    <Tooltip title={tooltip} placement="top" enterDelay={400}>
      {link}
    </Tooltip>
  );
}

export function SgCustomLink({
  children,
  ...props
}: PropsWithChildren<LinkProps>): JSX.Element {
  const { palette } = useTheme();

  const style = {
    color: palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
  };

  return (
    <DomLink {...props} style={style}>
      {children}
    </DomLink>
  );
}
