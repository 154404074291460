import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import { zIndexes } from './variables';

export default <Components<Theme>>{
  MuiAppBar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        '.MuiAutocomplete-input': {
          minWidth: '5px',
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      action: ({ theme }) => ({
        '.MuiIconButton-root': {
          color: theme.palette.common.white,
        },
      }),
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.noFloat': {
          borderRadius: 0,
          boxShadow: 'none',
        },
        '&.divider': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      }),
    },
  },
  MuiBadge: {
    styleOverrides: {
      root: {
        '.MuiBadge-badge': {
          border: '1px solid #FFFFFF',
          fontSize: '11px',
          lineHeight: '16px',
          top: '6px',
          right: '4px',
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&.iconButton': {
          minWidth: 'unset',

          '&.MuiButton-sizeMedium': {
            '&.MuiButton-contained': {
              paddingLeft: '8px',
              paddingRight: '8px',
            },
            '&.MuiButton-outlined': {
              paddingLeft: '7px',
              paddingRight: '7px',
            },
          },
        },
      },
      sizeSmall: ({ theme }) => ({
        '&.nopadding': {
          '&.MuiButton-outlinedPrimary, &.MuiButton-containedPrimary': {
            minWidth: 'unset',
            padding: '0px',
          },

          '&.MuiButton-outlinedPrimary': {
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.common.black,
          },
        },
      }),
      sizeTiny: {
        fontSize: '12px',
        lineHeight: '20px',
        padding: '4px 10px',
      },
    },
  },
  MuiCollapse: {
    styleOverrides: {
      root: {
        '&.fullHeight': {
          height: '100%',

          '.MuiCollapse-wrapper, MuiCollapse-wrapperInner': {
            height: '100%',
          },
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      root: {
        '&.staticRight': {
          width: '400px',
          left: 'auto',

          '.MuiBackdrop-root': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: '#FFF',

        '.PrivatePickersYear-root button': {
          ...theme.typography.h3,
          fontWeight: 400,
        },
        '.MuiCalendarPicker-root .MuiPickersDay-root': {
          backgroundColor: theme.palette.background.default,
        },
      }),
    },
  },
  MuiPaginationItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          border: `1px solid ${theme.palette.secondary.main}`,

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
        },
      }),
    },
  },
  MuiPopper: {
    styleOverrides: {
      root: {
        zIndex: `${zIndexes.DIALOG_POPPER} !important`,
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      root: {
        zIndex: `${zIndexes.DIALOG_POPPER} !important`,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '&.tinySize': {
          '.MuiInputBase-root': {
            'input.MuiInputBase-input': {
              padding: '4px 8px',
            },
            '&.MuiInputBase-adornedStart': {
              paddingLeft: '8px',

              'input.MuiInputBase-inputAdornedStart': {
                paddingLeft: '0',
              },
            },
          },
        },
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTableCell-root': {
          ...theme.typography.body1,
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,

        '&:not(:last-of-type)': {
          padding: theme.spacing(2, 1),
        },
        '&:last-of-type': {
          padding: theme.spacing(2, 3, 2, 1),
        },
        '&.action-cell': {
          padding: theme.spacing(2, 4, 2, 2),
        },
        '&.smallTableCell': {
          '&:not(:last-of-type)': {
            padding: theme.spacing(1),
          },
          '&:last-of-type': {
            padding: theme.spacing(1),
          },
          '&.action-cell': {
            padding: theme.spacing(1),
          },
        },
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTableCell-root': {
          ...theme.typography.body2,
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.common.white,
        },
        '&.smallTableHead .MuiTableCell-root': {
          padding: theme.spacing(1),
        },
      }),
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        overflow: 'visible !important',
      },
      scroller: {
        overflow: 'visible !important',
        borderBottom: '2px solid rgba(224, 224, 224, 1)',
      },
      indicator: {
        bottom: '-2px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
      arrow: {
        color: 'rgba(0, 0, 0, 0.85)',
      },
      popper: ({ theme }) => ({
        '&.white': {
          '.MuiTooltip-tooltip': {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.common.white,
            boxShadow: '0px 2px 8px rgb(0 0 0 / 20%)',
          },
          '.MuiTooltip-arrow': {
            color: theme.palette.common.white,

            '&::before': {
              boxShadow: '0px 2px 8px rgb(0 0 0 / 20%)',
            },
          },
        },
        '&.customStopsTooltip': {
          zIndex: 999,

          '.MuiTooltip-tooltip': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            maxWidth: '440px',
            boxShadow: '0px 2px 8px rgb(0 0 0 / 20%)',
            padding: theme.spacing(1, 1.5),
          },
          '.MuiTooltip-arrow': {
            color: theme.palette.common.white,

            '&::before': {
              boxShadow: '0px 2px 8px rgb(0 0 0 / 20%)',
            },
          },
        },
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.secondary.light,
        border: '0px',

        '&:hover': {
          backgroundColor: theme.palette.text.disabled,
          color: theme.palette.common.white,
        },

        '&.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.common.white,

          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#000000',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.noUnderline:after, &.noUnderline:before': {
          display: 'none',
        },
      },
      sizeSmall: {
        '.MuiSelect-select': {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
  },
  // MuiOutlinedInput: {
  //   styleOverrides: {
  //     root: ({ theme }) => ({
  //       '.MuiOutlinedInput-notchedOutline': {
  //         borderColor: theme.palette.secondary.main,
  //       },
  //     }),
  //   },
  // },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiCheckbox-colorPrimary:not(.Mui-checked):not(.MuiCheckbox-indeterminate) .MuiSvgIcon-root':
          {
            color: theme.palette.text.disabled,
          },
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        '&.MuiSvgIcon-fontSizeSmall': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '&.emptyValue .MuiSelect-select': {
          opacity: 0.4,
        },
      },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        zIndex: zIndexes.SNACKBAR,

        '.MuiAlert-root.MuiAlert-filledSuccess.draftAlertColor': {
          backgroundColor: theme.palette.secondary.main,
        },
      }),
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginTop: '-2px',

        '&.MuiInputAdornment-sizeTiny': {
          marginTop: '0',
        },

        '.MuiInputBase-root': {
          borderRadius: '4px',

          '&.MuiInputBase-colorWarning': {
            backgroundColor: theme.palette.background.paper,
          },
          '.MuiSelect-select.MuiInputBase-inputSizeSmall': {
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '6px',
            lineHeight: '16px',
            fontSize: '14px',
            height: '16px',
            minHeight: '16px',
            borderRadius: '4px',
          },
          '.MuiSelect-icon': {
            right: '2px',
          },
        },
      }),
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '&.MuiSwitch-sizeLarge': {
          width: '48px',
          height: '24px',

          '.MuiSwitch-switchBase': {
            '&.Mui-checked': {
              transform: 'translateX(24px)',
            },

            '.MuiSwitch-thumb': {
              width: '20px',
              height: '20px',
              borderRadius: '10px',
            },
          },

          '.MuiSwitch-track': {
            borderRadius: '12px',
          },
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.background.default,
        borderWidth: '2px',
      }),
    },
  },
};
