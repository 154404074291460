/** @jsxImportSource @emotion/react */
import { MouseEvent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Menu, Stack, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import saganIcon from '../../../../assets/sagan.svg';
import { cn } from '../../../../utils';
import { routes } from '../../../../constants';
import {
  useIsLocationContains,
  useIsTabContent,
} from '../../../../utils/hooks';
import { useToolbarButtonsStyles } from './ToolbarButtons.styles';

const automationPages = [routes.Rules, routes.PodCoverage, routes.Scheduling];

export function ToolbarButtons(): JSX.Element {
  const styles = useToolbarButtonsStyles();
  const isTabContent = useIsTabContent();
  const isAutomation = useIsLocationContains(automationPages);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" sx={{ flexGrow: 1 }} css={styles}>
      <Stack direction="row" alignItems="center">
        <img src={saganIcon} />
      </Stack>

      <Stack direction="row" alignItems="center" marginLeft={8}>
        <Box>
          <NavLink to="/" className={cn('headerLink')}>
            <Typography variant="body1" fontWeight={600}>
              Dashboard
            </Typography>
          </NavLink>
        </Box>
        <Box marginLeft={3}>
          <NavLink
            to="/open"
            end
            className={cn('headerLink', isTabContent && 'active')}
          >
            <Typography variant="body1" fontWeight={600}>
              Freight quotes
            </Typography>
          </NavLink>
        </Box>
        <Box marginLeft={3}>
          <NavLink to={routes.Search} className={cn('headerLink')}>
            <Typography variant="body1" fontWeight={600}>
              Search
            </Typography>
          </NavLink>
        </Box>

        <Box marginLeft={3}>
          <a
            onClick={handleClick}
            className={cn('headerLink', isAutomation && 'active')}
          >
            <Typography variant="body1" fontWeight={600}>
              Automation
              {open ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </Typography>
          </a>
        </Box>
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '4px' }}
        css={styles}
      >
        <li>
          <NavLink
            to={routes.Rules}
            onClick={handleClose}
            className="headerSubLink"
          >
            <Typography variant="h5">Rules</Typography>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.PodCoverage}
            onClick={handleClose}
            className="headerSubLink"
          >
            <Typography variant="h5">Pods coverage</Typography>
          </NavLink>
        </li>
        <li>
          <NavLink
            to={routes.Scheduling}
            onClick={handleClose}
            className="headerSubLink"
          >
            <Typography variant="h5">Scheduling</Typography>
          </NavLink>
        </li>
      </Menu>
    </Stack>
  );
}
