export enum NotificationStatus {
  Unread = 'Unread',
  Read = 'Read',
}
export enum NotificationType {
  SubmitFailed = 'SubmitFailed',
  LowerBidReceived = 'LowerBidReceived',
}

export enum SubmitFailedType {
  Common = 'Common',
  Withdrawn = 'Withdrawn',
  MaxBidExceeded = 'MaxBidExceeded',
}
