import { emptyValue, separators } from '../../constants';

interface Options {
  separator?: string;
  cutZero?: boolean;
  fractionDigits?: number;
}

Object.defineProperty(String.prototype, 'replaceIf', {
  value(
    searchValue: RegExp | string,
    replaceValue: string,
    condition: boolean,
  ): string {
    if (!condition) {
      return this;
    }

    return this.replace(searchValue, replaceValue);
  },
});

function toFixedComma(
  value?: string | number | null,
  fractionDigits = 2,
): string {
  const number = Number(value);

  if (value === null || isNaN(number)) {
    return emptyValue;
  }

  return number.toFixed(fractionDigits);
}

export function numberFormat(
  number?: string | number | null,
  {
    separator = separators.comma,
    cutZero = true,
    fractionDigits = 2,
  }: Options = {},
): string {
  const value = toFixedComma(number, fractionDigits);

  if (value === emptyValue) {
    return value;
  }

  return value
    .replaceIf(/\.0+$/g, '', cutZero && fractionDigits > 0) // removes zero signs at the end
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`)
    .replace(/(\..+)\s+/g, '$1')
    .toString();
}

export function weightFormat(
  number?: string | number | null,
  endSign = '',
): string {
  const value = numberFormat(number, {
    fractionDigits: 0,
  });

  if (value === emptyValue) {
    return value;
  }

  return `${value} lbs${endSign}`;
}

export function percentFormatter(number?: string | number | null): string {
  const value = numberFormat(number, {
    fractionDigits: 1,
  });

  if (value === emptyValue) {
    return value;
  }

  return `${value}%`;
}

export function toIntegerFormat(number?: number | string | null): string {
  return toFixedComma(number, 0);
}
