import { useMemo } from 'react';
import { is } from '../../utils';
import {
  ALREADY_WORKING_WITH_QUOTE,
  SUBMIT_BID_RESPOND_BY_TIME_PASSED,
  RATE_PER_MILE_MIN_PRICE,
  TabName,
} from '../../constants';
import { FreightQuoteActionModel } from '../../types';

export function useBidErrorNotification(
  quote: FreightQuoteActionModel,
  checkedBidPerMile: boolean,
  disableForm: boolean,
  isTimePassed: boolean,
  currentTabName?: TabName,
): string | null {
  return useMemo(() => {
    if (is.nullOrUndefined(quote.validToSubmit)) {
      return null;
    }

    if (disableForm && !isTimePassed && !quote.submitted) {
      return ALREADY_WORKING_WITH_QUOTE;
    }

    if (isTimePassed) {
      return SUBMIT_BID_RESPOND_BY_TIME_PASSED;
    }

    if (checkedBidPerMile) {
      return RATE_PER_MILE_MIN_PRICE;
    }

    return null;
  }, [quote, currentTabName, checkedBidPerMile]);
}
