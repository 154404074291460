import Axios from 'axios';
import { AppConfig } from '../types';
import { ServerStatusCode, loginRequest } from '../constants';
import { msalInstance } from '../index';
import { AuthenticationResult } from '@azure/msal-common';
import { IPublicClientApplication } from '@azure/msal-browser';
import { msalServiceInstance } from './msal-service';

export class InterceptorService {
  private static instance: IPublicClientApplication;

  public static async init(
    appConfig: AppConfig,
    pca: IPublicClientApplication,
  ): Promise<void> {
    Axios.defaults.baseURL = `${appConfig.apiBaseUrl}`;

    const account = msalInstance.getActiveAccount();

    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.',
      );
    }

    InterceptorService.instance = pca;

    const response: AuthenticationResult =
      await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });

    InterceptorService.addRequestInterceptor(response);
    InterceptorService.addResponseInterceptor();
  }

  private static addRequestInterceptor(auth: AuthenticationResult): void {
    Axios.interceptors.request.use((config) => {
      const newConfig = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${auth.accessToken}`,
          '9c61f121-fcdd-4d3d-8add-59c7f4deae77': auth.idToken,
          'Access-Control-Allow-Origin': '*',
        },
      };

      return newConfig;
    });
  }

  private static addResponseInterceptor(): void {
    Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === ServerStatusCode.UNAUTHORIZED) {
          // sessionStorage.clear();
          msalServiceInstance.logout();
        }

        return Promise.reject(error);
      },
    );
  }
}
