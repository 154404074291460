export const avatarMediumSize = 45;
export const drawerWidth = 256;
export const fadeInDuration = 450;
export const tableRowHeight = 72;

export const zIndexes = {
  SNACKBAR: 9999999999,
  DIALOG: 999999999,
  DIALOG_POPPER: 999999999,
  HEADER_POPPER: 99999999,
  HEADER: 9999999,
  LOADER: 999999,
  DRAG: 99999,
};
