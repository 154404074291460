import { UserDataState } from './userData.slice';
import { UserBaseModel } from '../../types';
import { userDataStoreKey } from './userData.const';
import { ServerAppConfig } from '../../types/serverAppConfig';

interface AppStateWithUserData {
  [userDataStoreKey]: UserDataState;
}

const selectUserDataState = (state: AppStateWithUserData) => {
  return state[userDataStoreKey];
};

const getUserData = (state: AppStateWithUserData): UserBaseModel | null => {
  return selectUserDataState(state).user;
};

const getConfig = (
  state: AppStateWithUserData,
): ServerAppConfig | undefined => {
  return selectUserDataState(state).config;
};

const getUserLoading = (state: AppStateWithUserData): boolean => {
  return selectUserDataState(state).loading;
};

const getUserPermissionDenied = (state: AppStateWithUserData): boolean => {
  return selectUserDataState(state).permissionDenied;
};

export const userDataSelectors = {
  getUserData,
  getConfig,
  getUserLoading,
  getUserPermissionDenied,
};
