/** @jsxImportSource @emotion/react */
import {
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Box,
} from '@mui/material';
import { StopDetailModel } from '../../types';
import { cn, locationFormatter, isoToDateString } from '../../utils';
import { useStopsGridStyles } from './StopsGrid.styles';
import { stopDetailTypeLabel } from './StopsGrid.const';
import { EmptyElement } from '../valueOrEmpty/ValueOrEmpty';

export function StopsGrid({
  stops,
}: {
  stops: StopDetailModel[];
}): JSX.Element {
  const styles = useStopsGridStyles();

  if (!stops.length) {
    return <Box>No stops data</Box>;
  }

  const renderStopPoint = (value: number, isLast?: boolean) => (
    <div
      className={cn(
        'stopPoint',
        value === 1 && 'firstStopPoint',
        isLast && 'lastStopPoint',
      )}
    >
      <div className="stopPointContent">{value}</div>
    </div>
  );

  const content = stops.map((stop, index) => (
    <TableRow key={stop.Id}>
      <TableCell width={15}>
        {renderStopPoint(index + 1, index + 1 === stops.length)}
      </TableCell>
      <TableCell>
        <Typography variant="h4">
          {locationFormatter(stop.Location, true)}
        </Typography>

        <Typography variant="h5" color={'secondary.main'}>
          {stop.Address}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h5">{isoToDateString(stop.Date)}</Typography>

        <Typography variant="h5" color={'secondary.main'}>
          {stop.TimeWithTimeZone || EmptyElement}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h5">
          {stopDetailTypeLabel[stop.StopDetailType] || stop.StopDetailType}
        </Typography>
      </TableCell>
    </TableRow>
  ));

  return (
    <Table css={styles}>
      <TableHead>
        <TableRow>
          <TableCell width={15} variant="head" />
          <TableCell variant="head">Stop address</TableCell>
          <TableCell variant="head">Date & time</TableCell>
          <TableCell variant="head">Stop type</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{content}</TableBody>
    </Table>
  );
}
