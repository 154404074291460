import React from 'react';
import { SgTable, SgTableRow } from '../../components';
import { PodCoverageItem, RuleApiModel } from '../../types';
import { podCoverageGridColumns } from './PodCoverageDataGrid.const';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  podCoverageSelectors,
  podCoverageSlice,
} from '../../redux/podCoverage';
import { sortingDirection } from '../../constants';
import { removeEmptyValues } from '../../utils';

interface Props {
  rows: PodCoverageItem[];
}

export function PodCoverageDataGrid({ rows }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const order = useAppSelector(podCoverageSelectors.getPodCoverageOrder);

  const onOrderChanged = (
    orderBy?: keyof PodCoverageItem,
    orderDirection?: sortingDirection,
  ) => {
    dispatch(
      podCoverageSlice.actions.setOrder(
        removeEmptyValues({
          orderBy,
          orderDirection: orderDirection || sortingDirection.Asc,
        }),
      ),
    );
  };

  return (
    <SgTable<PodCoverageItem>
      orderBy={order.orderBy}
      orderDirection={order.orderDirection}
      onOrderChanged={onOrderChanged}
      columns={podCoverageGridColumns}
    >
      {rows.map((row) => (
        <SgTableRow<PodCoverageItem>
          key={row.DatPod.DatId}
          rowData={row}
          columns={podCoverageGridColumns}
        />
      ))}
    </SgTable>
  );
}
