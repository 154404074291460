import { is, numberFormat } from '../../utils';
import { emptyValue } from '../../constants';
import { EmptyElement } from '../../components';

export function valueOrEmpty(
  value?: number | string | null,
  defaultEmptyValue: string | JSX.Element = EmptyElement,
): string | JSX.Element {
  if (value === undefined || value === null) {
    return defaultEmptyValue;
  }

  return String(value);
}

export function percentFormat(
  value?: number | string | null,
  defaultEmptyValue: string | JSX.Element = EmptyElement,
): string | JSX.Element {
  if (value === undefined || value === null) {
    return defaultEmptyValue;
  }

  return `${value || 0}%`;
}

export function priceFormat(
  value?: number | string | null,
  shortName?: boolean | string,
  defaultEmptyValue?: string,
): string;
export function priceFormat(
  value?: number | string | null,
  shortName?: boolean | string,
  defaultEmptyValue: string | JSX.Element = EmptyElement,
): string | JSX.Element {
  const price = numberFormat(value, { cutZero: false });

  if (price === emptyValue) {
    return defaultEmptyValue;
  }

  if (is.string(shortName)) {
    return `${shortName}${price}`;
  }

  return shortName ? `${price} USD` : `$${price}`;
}

export function pricePerMileFormat(
  distance: number | null,
  bid: number | null,
): string | null {
  if (!distance || !bid) {
    return null;
  }

  return priceFormat(bid / distance, false, '') || null;
}
