/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PlainObject } from '../../types';
import { useSgTableStyles } from './SgTable.styles';
import { ColumnModel, SgTableCommonProps } from './SgTable.types';
import { SgTableHeaderCell, TableCellBaseProps } from './SgTableHeaderCell';

export interface SgTableBaseProps<T extends PlainObject>
  extends SgTableCommonProps,
    TableCellBaseProps<T> {
  columns: ColumnModel<T>[];
}

export interface SgTableProps<T extends PlainObject>
  extends SgTableBaseProps<T> {
  stickyHeaderTop?: number;

  actionColumn?: boolean;
  indeterminate?: boolean;
  checked?: boolean;
  onSelectAll?: SwitchBaseProps['onChange'];
}

export function SgTable<T extends PlainObject>({
  size = 'medium',
  draggable,
  actionColumn,
  indeterminate,
  checked,
  onSelectAll,

  selectable = false,
  expandable = false,
  orderBy,
  orderDirection,
  columns,
  onOrderChanged,
  children,
}: PropsWithChildren<SgTableProps<T>>): JSX.Element | null {
  const styles = useSgTableStyles();

  return (
    <Table css={styles} sx={{ minWidth: 650 }} stickyHeader>
      <TableHead className={`${size}TableHead`}>
        <TableRow>
          {draggable && (
            <TableCell
              sx={{ paddingLeft: '14px', paddingRight: 0 }}
              variant="head"
            />
          )}
          {selectable && (
            <TableCell sx={{ padding: '0px !important' }}>
              <Checkbox
                indeterminate={indeterminate}
                checked={checked}
                onChange={onSelectAll}
              />
            </TableCell>
          )}
          {expandable && (
            <TableCell
              sx={{ paddingLeft: '14px', paddingRight: 0 }}
              variant="head"
            />
          )}

          {columns.map((column) => (
            <SgTableHeaderCell
              key={column.name as string}
              {...column}
              orderBy={orderBy}
              orderDirection={orderDirection}
              onOrderChanged={onOrderChanged}
            >
              {column.label}
            </SgTableHeaderCell>
          ))}

          {actionColumn && <TableCell variant="head" className="action-cell" />}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
}
