export enum PortalId {
  E2Open = 1,
  Emerge,
  OTMKraftHeinz,
  OTMDarigold,
  SpotNow,
  CarrierPoint,
  Transplace,
}

export enum ShipperId {
  MAPLEHURST_BAKERIES = 1,
  BAY_VALLEY_FOODS = 2,
  //SC JOHNSON & SON, INC.
  SC_JOHNSON = 3,
  HEARTLAND_COCA_COLA = 4,
  ALDI = 5,
  // Metal Exchange Corp
  MetalExchange = 6,
  Kraft = 8,
  DARIGOLD = 948,
  Niagara = 949,
  BARILLA_AMERICA = 950,
  // Northern Tool & Equipment
  NorthernTool = 955,
  BodyArmor = 956,
  // Ryder Logistics and Transportation
  RyderLogistics = 954,
  BLUETRITON_BRANDS = 958,
  MARS_PETCARE_INBOUND = 959,
  ESSEX_GROUP = 960,
}
