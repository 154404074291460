import React, { useEffect, useMemo } from 'react';
import { RuleForm } from '../../features';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchDATPodsResult,
  fetchRulesEnums,
  getRule,
  rulesSelectors,
  rulesSlice,
} from '../../redux/rules';
import { LoaderContainer } from '../../components';
import { filtersDataSelectors } from '../../redux/filtersData';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../constants';
import { useLocationQuery } from '../../utils';
import { PodsQueryProps, selectPodsByQuery } from '../rules/Rules.utils';
import { CreateRuleFormModel } from '../../features/ruleForm/RuleForm.types';
import { ExpressionAttribute } from '../../features/ruleForm/RuleForm.const';

interface Props {
  copy?: boolean;
}

export function RulePage({ copy }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { query } = useLocationQuery<PodsQueryProps>();
  const { ruleId } = useParams<{ ruleId?: string }>();

  const createRuleLoading = useAppSelector(rulesSelectors.getCreateRuleLoading);
  const createdRule = useAppSelector(rulesSelectors.getCreatedRule);
  const loadingEnums = useAppSelector(rulesSelectors.getLoadingEnums);
  const loadingPods = useAppSelector(rulesSelectors.getLoadingPods);
  const loadingRule = useAppSelector(rulesSelectors.getLoadingRule);
  const commonLoading = useAppSelector(rulesSelectors.getLoading);
  const ruleToEdit = useAppSelector(rulesSelectors.getRuleToEdit);
  const ruleToCopy = useAppSelector(rulesSelectors.getRuleToCopy);
  const pods = useAppSelector(rulesSelectors.getRulesPODs);

  const filtersLoading = useAppSelector(filtersDataSelectors.getLoading);
  const { shippers, equipmentTypes } = useAppSelector(
    filtersDataSelectors.getFilters,
  );

  useEffect(() => {
    dispatch(fetchRulesEnums());
    dispatch(fetchDATPodsResult());

    if (ruleId) {
      dispatch(getRule(ruleId));
    }

    return () => {
      dispatch(rulesSlice.actions.reset());
    };
  }, []);

  useEffect(() => {
    if (!createdRule) {
      return;
    }

    navigate(routes.Rules);
  }, [createdRule]);

  useEffect(() => {
    if (copy && !ruleToCopy) {
      navigate(routes.Rules);
    }
  }, []);

  const initialFormData = useMemo((): Partial<CreateRuleFormModel> => {
    const initPods = selectPodsByQuery(pods, query);

    const expressions = [];

    if (initPods.originPOD) {
      expressions.push({
        attribute: ExpressionAttribute.OriginPod,
        operator: 2,
        value: initPods.originPOD,
        _rowId: 1,
      });
    }

    if (initPods.destinationPOD) {
      expressions.push({
        attribute: ExpressionAttribute.DestinationPod,
        operator: 2,
        value: initPods.destinationPOD,
        _rowId: 2,
      });
    }

    return { expressions };
  }, [pods]);

  const dataLoading =
    loadingEnums || loadingPods || filtersLoading || loadingRule;
  const loading =
    (!copy && (dataLoading || commonLoading)) || createRuleLoading;

  const getFormContent = () => {
    if (ruleId) {
      return (
        !!(ruleToEdit && pods && pods.length && shippers && equipmentTypes) && (
          <RuleForm
            key="edit"
            rule={ruleToEdit}
            pods={pods}
            shippers={shippers}
            equipmentTypes={equipmentTypes}
            loading={dataLoading}
            disabled={loading}
          />
        )
      );
    }

    if (copy) {
      return (
        !!(pods && pods.length && shippers && equipmentTypes) && (
          <RuleForm
            key="copy"
            ruleCopy={ruleToCopy}
            pods={pods}
            shippers={shippers || []}
            equipmentTypes={equipmentTypes || []}
            loading={false}
            disabled={loading}
          />
        )
      );
    }

    return (
      <RuleForm
        key={pods && pods.length ? 'create' : 'create-loading'}
        initialFormData={initialFormData}
        ruleCopy={copy ? ruleToCopy : undefined}
        pods={pods}
        shippers={shippers || []}
        equipmentTypes={equipmentTypes || []}
        loading={dataLoading}
        disabled={loading}
      />
    );
  };

  return (
    <LoaderContainer loading={loading} fullPage>
      {getFormContent()}
    </LoaderContainer>
  );
}
