import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { podCoverageStoreKey } from './podCoverage.const';
import { notificationSlice } from '../notification';
import { apiEndpoint } from '../../constants/api';
import { PodCoverageItem } from '../../types';

export const fetchPodCoverage = createAsyncThunk(
  `${podCoverageStoreKey}/fetchPodCoverage`,
  async (_: void, store): Promise<PodCoverageItem[]> => {
    try {
      const { data } = await axios.get<PodCoverageItem[]>(
        apiEndpoint.datPodsCoverage(),
      );

      if (!data) {
        throw Error('Failed request');
      }

      return data;
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return [];
    }
  },
);
