import {
  ODataProps,
  PlainObject,
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  RulesFiltersModel,
} from '../../types';
import {
  FiltersODataResult,
  filtersToOData,
  ODataItemValueType,
} from './oDataBuilder';
import { oDateFieldAliases } from './oDataBuilder.const';
import { removeEmptyValues } from '../removeEmptyValues';
import { is } from '../is';

/*
"Filter": "FilterExpressions/any(
  r: r/Attribute eq 'ShipperId' and (r/Value eq '3' or r/Value eq '5')
) and (Mode eq 1) and (UserId eq 3)"
 */

export function rulesFiltersToOData({
  filters,
  ...other
}: ODataProps<Partial<RulesFiltersModel>>): Partial<FiltersODataResult> {
  const {
    shippers,
    equipmentTypes,
    originPOD,
    destinationPOD,
    ...otherFilters
  } = filters;

  const oData = filtersToOData({
    filters: otherFilters as unknown as PlainObject<ODataItemValueType>,
    ...other,
  });

  const specFilters: PlainObject<
    Array<QuoteBaseEntityTypeUIExtended | PODExtendedModel>
  > = removeEmptyValues({
    shippers,
    equipmentTypes,
    originPOD,
    destinationPOD,
  });

  if (is.empty(specFilters)) {
    return oData;
  }

  // [ "FilterExpressions/any(r: r/Attribute eq 'ShipperId' and (r/Value eq '3' or r/Value eq '5'))" ]
  const expressions = Object.keys(specFilters).map((filterName) => {
    // ["r/Value eq 3", "r/Value eq 5"]
    const values = specFilters[filterName].map(
      (subFilter: QuoteBaseEntityTypeUIExtended | PODExtendedModel) => {
        if ('Id' in subFilter) {
          return `r/Value eq '${subFilter.Id}'`;
        }

        return `r/Value eq '${subFilter.DatId}'`;
      },
    );

    return `FilterExpressions/any(r: r/Attribute eq '${
      oDateFieldAliases[filterName] || filterName
    }' and (${values.join(' or ')}))`;
  });

  const expressionsQuery = expressions.join(' and ');

  return {
    ...oData,
    Filter: oData.Filter
      ? `${expressionsQuery} and ${oData.Filter}`
      : expressionsQuery,
  };
}
