import { QuoteBaseEntityTypeUIExtended, QuotePortalUIExtended } from '../types';
import _uniqBy from 'lodash/uniqBy';

export function getShippersByPortals(
  portals: QuotePortalUIExtended[],
): QuoteBaseEntityTypeUIExtended[] {
  const availableShippers = (portals || []).reduce(
    (acum, { Shippers }) => [...acum, ...(Shippers || [])],
    [] as QuoteBaseEntityTypeUIExtended[],
  );

  return _uniqBy(availableShippers, 'Id').sort((a, b) =>
    String(a.Name).toLowerCase() > String(b.Name).toLowerCase() ? 1 : -1,
  );
}
