/** @jsxImportSource @emotion/react */
import { MutableRefObject } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useLoaderStyles } from './loader.styles';
import { cn } from '../../utils';

interface LoaderProps {
  nodeRef?: MutableRefObject<null>;
  fullPage?: boolean;
  withBg?: boolean;
  title?: string;
}

export const Loader = ({
  fullPage = false,
  withBg = false,
  title,
  nodeRef,
}: LoaderProps): JSX.Element => {
  const styles = useLoaderStyles();

  const titleRender = title && (
    <Typography typography="h2" pt={3}>
      {title}
    </Typography>
  );

  return (
    <div
      ref={nodeRef}
      css={styles}
      className={cn(
        (fullPage || withBg) && 'with-bg',
        fullPage ? 'full-page-wrapper' : '',
      )}
    >
      <div className="loader-container">
        <CircularProgress />
        {titleRender}
      </div>
    </div>
  );
};
