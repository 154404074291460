/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRangeInputStyles } from './RangeInput.styles';
import { RangeFilter } from '../../../types';
import { removeEmptyValues } from '../../../utils';

interface Props {
  value: RangeFilter;
  onChange: (value?: RangeFilter) => void;
  label: string;
  labelTo?: string;
  placeholder: string;
  rangePlaceholder: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  valueFormatter?: (v?: string) => string;
}

const MAX_VALUE_LENGTH = 9;

export function RangeInput({
  label,
  labelTo = 'max',
  placeholder,
  rangePlaceholder,
  valueFormatter,
  value,
  onChange,
  disabled,
  error,
  helperText,
}: Props): JSX.Element {
  const styles = useRangeInputStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState<RangeFilter>(value);

  const showValue =
    Object.keys(value).length > 0
      ? `${value.from || '0'} - ${value.to || labelTo}`
      : placeholder;

  useEffect(() => {
    setRangeValue(value || []);
  }, [value]);

  const onRangeValueChange = (value: string, name: keyof RangeFilter) => {
    setRangeValue(
      removeEmptyValues({
        ...rangeValue,
        [name]: String(value).slice(0, MAX_VALUE_LENGTH),
      }),
    );
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="group-by-select-label">{label}</InputLabel>
      <Select
        labelId="group-by-select-label"
        id="group-by-select"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={0}
        renderValue={() => showValue}
        disabled={disabled}
        error={error}
      >
        <MenuItem sx={{ display: 'none' }} value="0" />

        <Box css={styles}>
          <Box>
            <TextField
              fullWidth
              disabled={disabled}
              label="From"
              placeholder={rangePlaceholder}
              variant="standard"
              value={rangeValue.from || ''}
              onChange={(e) =>
                onRangeValueChange(
                  valueFormatter
                    ? valueFormatter(e.target.value)
                    : e.target.value,
                  'from',
                )
              }
            />
          </Box>
          <Box marginTop={1}>
            <TextField
              fullWidth
              disabled={disabled}
              label="To"
              placeholder={rangePlaceholder}
              variant="standard"
              value={rangeValue.to || ''}
              onChange={(e) =>
                onRangeValueChange(
                  valueFormatter
                    ? valueFormatter(e.target.value)
                    : e.target.value,
                  'to',
                )
              }
            />
          </Box>

          <Stack direction="row" marginTop={2}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={() => {
                onChange();
                setRangeValue({});
                setIsOpen(false);
              }}
              sx={{ padding: '2px' }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={() => {
                onChange(
                  !rangeValue.from
                    ? {
                        ...rangeValue,
                        from: '0',
                      }
                    : rangeValue,
                );
                setIsOpen(false);
              }}
              sx={{ padding: '2px', marginLeft: 1 }}
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </Select>
      {helperText && (
        <FormHelperText error={error}>
          <>{helperText}</>
        </FormHelperText>
      )}
    </FormControl>
  );
}
