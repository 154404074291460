import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useLookUpRateGridStyles = (): SerializedStyles => {
  const { palette, spacing } = useTheme();

  return css`
    width: 100%;

    table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        background-color: ${palette.background.default};
        height: ${spacing(3)};
        padding: 2px;
        padding-left: ${spacing(1)};
        padding-right: ${spacing(1)};
        overflow: hidden;
      }

      td {
        border-bottom: 4px solid #fff;
      }
    }
  `;
};
