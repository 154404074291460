export enum RuleMode {
  AutoBid,
  Suggest,
  AutoIgnore,
}
export enum SchedulingAction {
  AutoBid,
  AutoIgnore,
}
export enum SchedulingCondition {
  AfterLoadArrives,
  BeforeRespondBy,
}

export enum RuleModeValue {
  AutoBid = 'AutoBid',
  Suggest = 'Suggest',
  AutoIgnore = 'AutoIgnore',
}

export enum RuleStatus {
  Deactivated,
  Active,
  Draft,
}

export enum RuleType {
  RatePerTrip,
  RatePerMile,
  DatMinBased,
  DatAvgBased,
  DatMaxBased,
}

export enum RuleRateActionMode {
  AddAbsolute,
  SubstractAbsolute,
  AddPercentage,
  SubstractPercentage,
}

export enum RulePriority {
  Higher = 'higher',
  Lower = 'lower',
}

export enum RuleTypeName {
  RatePerTrip,
  RatePerMile,
  DATBasedRate,
}

export const ruleTypeLabels = {
  [RuleType.RatePerTrip]: 'Rate per trip',
  [RuleType.RatePerMile]: 'Rate per mile',
  [RuleType.DatMinBased]: 'DAT-based rate',
  [RuleType.DatAvgBased]: 'DAT-based rate',
  [RuleType.DatMaxBased]: 'DAT-based rate',
};

// UI client constants

export enum DATRateValue {
  USD,
  Percent,
}

export const datRateValueLabel = {
  [DATRateValue.USD]: 'USD',
  [DATRateValue.Percent]: '%',
};

export enum DatRateDirection {
  Min,
  Max,
  Avg,
}

export enum DatActionValue {
  Increase,
  Decrease,
}

export const datRateLabel = {
  [DatRateDirection.Min]: 'Min',
  [DatRateDirection.Max]: 'Max',
  [DatRateDirection.Avg]: 'Avg',
};

export const datActionLabel = {
  [DatActionValue.Increase]: '+',
  [DatActionValue.Decrease]: '-',
};

// relations
export const ruleTypeToRuleTypeName = {
  [RuleType.RatePerTrip]: RuleTypeName.RatePerTrip,
  [RuleType.RatePerMile]: RuleTypeName.RatePerMile,
  [RuleType.DatMinBased]: RuleTypeName.DATBasedRate,
  [RuleType.DatMaxBased]: RuleTypeName.DATBasedRate,
  [RuleType.DatAvgBased]: RuleTypeName.DATBasedRate,
};
export const ruleTypeToDATRateDirection = {
  [RuleType.RatePerTrip]: DatRateDirection.Min,
  [RuleType.RatePerMile]: DatRateDirection.Min,
  [RuleType.DatMinBased]: DatRateDirection.Min,
  [RuleType.DatMaxBased]: DatRateDirection.Max,
  [RuleType.DatAvgBased]: DatRateDirection.Avg,
};
export const ruleRateActionModeToDATActionValue = {
  [RuleRateActionMode.SubstractPercentage]: DatActionValue.Decrease,
  [RuleRateActionMode.SubstractAbsolute]: DatActionValue.Decrease,
  [RuleRateActionMode.AddPercentage]: DatActionValue.Increase,
  [RuleRateActionMode.AddAbsolute]: DatActionValue.Increase,
};
export const ruleRateActionModeToDATRateValue = {
  [RuleRateActionMode.SubstractPercentage]: DATRateValue.Percent,
  [RuleRateActionMode.SubstractAbsolute]: DATRateValue.USD,
  [RuleRateActionMode.AddPercentage]: DATRateValue.Percent,
  [RuleRateActionMode.AddAbsolute]: DATRateValue.USD,
};
