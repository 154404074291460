import { appConfigApi, userInfo } from './user';
import {
  freightQuotes,
  freightQuotesByTab,
  freightQuotesOpen,
  ignoreQuote,
  ignoreQuotes,
  similarQuotes,
  statusCount,
} from './freightQuotes';
import {
  customerLocationFilterValues,
  equipmentTypes,
  locations,
  portals,
  shippers,
} from './filters';
import { bulkSubmitBid, submitBid } from './submitBid';
import { carrierView, lookupRates } from './lookupRates';
import { breakdownData, performanceData, totalRevenueData } from './dashboard';
import {
  notifications,
  readAllNotifications,
  readNotifications,
} from './notifications';
import {
  createRule,
  datPodsCoverage,
  deleteRule,
  editRule,
  editRulePriority,
  rules,
  validateRule,
} from './rules';
import { datPods, getEnums } from './servise';
import { editSchedule, schedules } from './schedule';

export const apiEndpoint = {
  userInfo,
  appConfigApi,
  freightQuotesOpen,
  freightQuotesByTab,
  freightQuotes,
  similarQuotes,
  statusCount,
  ignoreQuote,
  ignoreQuotes,

  equipmentTypes,
  portals,
  shippers,
  locations,
  customerLocationFilterValues,

  submitBid,
  bulkSubmitBid,
  lookupRates,
  carrierView,

  breakdownData,
  totalRevenueData,
  performanceData,

  notifications,
  readNotifications,
  readAllNotifications,

  rules,
  editRulePriority,
  createRule,
  editRule,
  validateRule,
  deleteRule,
  datPodsCoverage,

  schedules,
  editSchedule,

  datPods,
  getEnums,
};
