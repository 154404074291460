import { EmptyState } from '../../components';
import { DataGrid, DataGridEvents } from '../dataGrid/DataGrid';
import React from 'react';
import { FreightQuoteActionModel, ODataProps } from '../../types';
import { searchGridColumns } from './SearchDataGrid.const';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { freightQuotesSelectors } from '../../redux/freightQuotes';
import { QuoteStatus, quoteStatusToTabName, TabName } from '../../constants';
import { QuoteActionDialogs } from '../quoteActionDialogs/QuoteActionDialogs';
import { is } from '../../utils';
import { useInlineBidRenders } from '../common/utils/useInlineBidRenders';

interface Props extends DataGridEvents {
  rows: FreightQuoteActionModel[];
  loading: boolean;
  filters: ODataProps;
}

export function SearchDataGrid({
  rows,
  loading,
  filters,
  ...events
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const quoteToSubmit = useAppSelector(freightQuotesSelectors.getQuoteToSubmit);

  const { renderDetailsSubmit, onRowExpanded } = useInlineBidRenders(
    'search',
    dispatch,
  );

  if (is.empty(filters.filters)) {
    return <EmptyState reason="search" />;
  }

  if (!loading && !rows.length) {
    return <EmptyState reason="nothingFound" />;
  }

  const isRenderDetailsSubmit = (rowData: FreightQuoteActionModel): boolean => {
    return (
      rowData.Status === QuoteStatus.Submitted ||
      rowData.Status === QuoteStatus.Open ||
      rowData.Status === QuoteStatus.SubmitRequested ||
      rowData.Status === QuoteStatus.SubmitRequestFailed ||
      rowData.Status === QuoteStatus.Ignored
    );
  };

  return (
    <>
      <DataGrid
        orderBy={filters?.sortingColumn}
        orderDirection={filters?.sortingDirection}
        data={rows}
        columns={searchGridColumns}
        detailsSubmitColSpan={2}
        renderDetailsSubmit={renderDetailsSubmit}
        isRenderDetailsSubmit={isRenderDetailsSubmit}
        onExpand={onRowExpanded}
        {...events}
      />

      <QuoteActionDialogs
        statusName={
          quoteToSubmit && quoteToSubmit[0].Status
            ? quoteStatusToTabName[quoteToSubmit[0].Status]
            : TabName.Missed
        }
      />
    </>
  );
}
