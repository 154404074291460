import { SubmitFailedType } from './notification';

export const PORTAL_IS_NOT_AVAILABLE =
  'We encountered an issue while submitting a Bid. Please, visit the Source portal or Submit again later';
export const QUOTE_IS_MISSED = 'The bid was missed earlier than submitted';
export const SOMETHING_WENT_WRONG = 'Something went wrong';
export const QUERY_IS_TOO_LONG = 'The filter request is too long';
export const BID_SUBMITTED = 'The bid has been sent';
export const THE_SOURCE_IS_NOT_AVAILABLE = 'The source is not available';
export const RATE_PER_MILE_MIN_PRICE =
  'The rate per mile for the entered amount is less than 50¢. Are you sure want to submit this Bid?';
export const SUBMIT_BID_RESPOND_BY_TIME_PASSED =
  'Respond by time has passed. A bid cannot be submitted';
export const RESPOND_BY_TIME_PASSED =
  'Bid cannot be submitted for this Freight Quote. Bids are no longer accepted. Awaiting for the updated status';
// 'Respond by time has passed. Bid cannot be submitted for this Freight Quote. Awaiting for the updated status';

export const ALREADY_WORKING_WITH_QUOTE =
  'Your colleague is already working on this Freight Quote. Please, select the next Freight Quote';
export const ALREADY_WORKING_WITH_QUOTE_SHORT =
  'Your colleague already works on this Freight Quote';
export const USER_ALREADY_WORKING_WITH_QUOTE_SHORT =
  'already works on this Freight Quote';
export const BID_WAS_SUBMITTED_NOT_IN_SAGAN =
  'Cannot show the user. Bid was submitted not in Sagan';
export const BID_WAS_SUBMITTED_BY_UNKNOWN_USER =
  'Bid was submitted by unknown user';
export const SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE =
  'The source portal did not return the zip codes of locations. The DAT data could be inaccurate';
export const SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE_ON_MAP =
  'The source portal did not return the zip codes of locations. The route and distance could be inaccurate';

export const RATE_PER_TRIP_TYPE_ALL_IN =
  'This customer requires adding Fuel Surcharge in a price. All In rate to be populated';
export const INSERT_LINE_HAUL_ONLY_RATE_HERE =
  'Insert Line Haul Only rate here. Fuel Surcharge to be calculated by Sagan';
export const SAGAN_OPERATES_WITH_DAT_RATES = `Sagan operates with the DAT rates per trip (not per mile). The entered amount (or %) will be added (or subtracted) to (or from) DAT rate per trip`;
export const RATE_PER_TRIP_TYPE_LINE_HAUL_ONLY =
  'This customer requires to bid Line Haul Only';

export const PC_MILER_DISTANCE_MESSAGE = `Source portal did not return the distance for this load. The distance was retrieved from PC Miler and represents distance and a driving route that a vehicle would take to minimize the total distance traveled while still following a reasonable route (PC Miler practical route type)`;
export const CAN_NOT_SAVE_RULE_ALL_IN_BIDDING_NULL = `Cannot save a rule. We don't know if Customer requires All-In or Line Haul Only`;
export const RULE_CAN_NOT_BE_CREATED_NO_FUEL_INFO = `rule cannot be created for this customer because there is no info how they calculate the fuel surcharge. Try to create a Rule of another type`;

export const notificationReason = {
  lowerBid: 'A competitor gave a lower price for the next Freight Quote:',
  reasonBuType: {
    [SubmitFailedType.Withdrawn]:
      "The Bid hasn't been submitted because it was withdrawn or expired.",
    [SubmitFailedType.Common]: "Unfortunately, your bid hasn't been submitted.",
    [SubmitFailedType.MaxBidExceeded]:
      "Unfortunately, your bid hasn't been submitted because it is higher than Max allowed Bid for this Freight Quote.",
  },
};
