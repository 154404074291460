/** @jsxImportSource @emotion/react */
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { NotificationExtendedModel } from '../../../../types';
import CloseIcon from '@mui/icons-material/Close';
import { NotificationCard } from './NotificationCard';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  readAllUserNotifications,
  readUserNotifications,
} from '../../../../redux/notification/notification.thunks';
import { notificationSlice } from '../../../../redux/notification';
import { NotificationStatus } from '../../../../constants';
import { SgLink } from '../../../../components';
import { useNotificationBarStyles } from './NotificationBar.styles';

interface Props {
  notifications: NotificationExtendedModel[];
  open: boolean;
  onClose: () => void;
}

export function NotificationBar({ notifications, open, onClose }: Props) {
  const styles = useNotificationBarStyles();
  const dispatch = useAppDispatch();

  const unreadNotifications = notifications.filter(
    (n) => !n.opened && n.Status !== NotificationStatus.Read,
  );

  const onRead = (notification: NotificationExtendedModel) => {
    if (
      notification.opened ||
      notification.Status === NotificationStatus.Read
    ) {
      return;
    }

    dispatch(readUserNotifications(notification.Id));
  };

  const setReadStatus = (notification: NotificationExtendedModel) => {
    dispatch(notificationSlice.actions.markNotificationOpened(notification.Id));
  };

  const onReadAll = () => {
    dispatch(readAllUserNotifications());
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ zIndex: 9999999 }}
      elevation={3}
      css={styles}
      keepMounted
    >
      <Box sx={{ width: 400, paddingX: 3, paddingY: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row">
            <Typography marginRight={3} variant="h3">
              Notifications
            </Typography>
            <SgLink
              disabled={!unreadNotifications.length}
              onClick={() => onReadAll()}
            >
              Mark all as read
            </SgLink>
          </Stack>

          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{ paddingTop: 2 }}>
          {notifications.map((notification) => (
            <NotificationCard
              key={notification.Id}
              notification={notification}
              onLinkClick={() => onClose()}
              onOpen={(open) =>
                open ? onRead(notification) : setReadStatus(notification)
              }
            />
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}
