import { FreightQuoteActionModel } from '../../types';
import { FreightQuotesState } from './freightQuotes.types';
import { TabName } from '../../constants';

export function updateQuoteProps(
  item: FreightQuoteActionModel,
  props: Partial<FreightQuoteActionModel>,
): FreightQuoteActionModel {
  const { Status, Portal, lookUpRates } = props;

  return {
    ...item,
    Status: Status || item.Status,
    Portal: Portal || item.Portal,
    lookUpRates: lookUpRates || item.lookUpRates,
  };
}

export function updateQuotesProps(
  items: FreightQuoteActionModel[],
  props: Partial<FreightQuoteActionModel>,
): FreightQuoteActionModel[] {
  return items.map((item) => updateQuoteProps(item, props));
}

export function setForQuote(
  items: FreightQuoteActionModel[],
  currentItem: Pick<FreightQuoteActionModel, 'Id'> | null,
  fields: Partial<FreightQuoteActionModel>,
): FreightQuoteActionModel[] {
  if (!currentItem) {
    return items;
  }

  return items.map((item) =>
    item.Id === currentItem.Id ? updateQuoteProps(item, fields) : item,
  );
}

export function setQuotesProps(
  items: FreightQuoteActionModel[],
  itemsToUpdate: Pick<FreightQuoteActionModel, 'Id'>[],
  props: Partial<FreightQuoteActionModel>,
): FreightQuoteActionModel[] {
  if (!itemsToUpdate.length || !items.length) {
    return items;
  }

  return items.map((item) =>
    itemsToUpdate.find(({ Id }) => item.Id === Id) !== undefined
      ? { ...item, ...props }
      : item,
  );
}

export function setQuotesPropsToState(
  state: FreightQuotesState,
  itemsToUpdate: Pick<FreightQuoteActionModel, 'Id'>[],
  props: Partial<FreightQuoteActionModel>,
) {
  const tabName = state.tabName || TabName.Open;
  const quotes = state.tabsData[tabName].result;
  const searchData = state.searchState.data;

  if (quotes && quotes.length) {
    state.tabsData[tabName].result = setQuotesProps(
      quotes,
      itemsToUpdate,
      props,
    );
  }

  if (searchData && searchData.length) {
    state.searchState.data = setQuotesProps(searchData, itemsToUpdate, props);
  }

  // todo: after tests
  // if (!state.quoteToSubmit || !state.quoteToSubmit.length) {
  //   return;
  // }
  //
  // state.quoteToSubmit = updateQuotesProps(state.quoteToSubmit, props);
}
