import { Box, InputAdornment, Typography } from '@mui/material';
import * as React from 'react';
import { FormikEntity, FreightQuoteActionModel } from '../../../types';
import { getRateTypeByCustomers } from '../../../utils/rateTypesByCustomers';
import { StatusBadge } from '../../../components/statusBadge/StatusBadge';
import { SgSimpleSelect } from '../select/SgSelect';
import { DATRateOptions } from './Adornments.const';
import { DATRateValue } from '../../../constants';

export const submitStartAdornment = (
  <InputAdornment disableTypography position="start">
    <StatusBadge>USD</StatusBadge>
  </InputAdornment>
);
export const smallStartAdornment = (
  <InputAdornment disableTypography position="start">
    <StatusBadge type="disabled" typographyVariant="h6" size="tiny">
      USD
    </StatusBadge>
  </InputAdornment>
);
export const smallErrorStartAdornment = (
  <InputAdornment disableTypography position="start">
    <StatusBadge type="error" typographyVariant="h6" size="tiny">
      USD
    </StatusBadge>
  </InputAdornment>
);

export function getRateValueStartAdornment<T>(
  formik: FormikEntity<T>,
  name: string,
  disabled?: boolean,
): JSX.Element {
  return (
    <InputAdornment disableTypography position="start">
      <SgSimpleSelect
        variant="filled"
        size="small"
        name={name}
        className="noUnderline"
        color="warning"
        items={DATRateOptions}
        formik={formik}
        disabled={disabled}
        defaultValue={DATRateValue.USD}
      />
    </InputAdornment>
  );
}

export function renderRateTypeByCustomers(
  quote: FreightQuoteActionModel,
): React.ReactNode {
  const rateTypeByCustomers = getRateTypeByCustomers(quote);

  if (rateTypeByCustomers === null) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        padding: '2px 6px',
        borderRadius: '4px',
        border: `1px solid ${theme.palette.divider}`,
        color: theme.palette.common.black,
      })}
    >
      <Typography variant="subtitle2" lineHeight="16px">
        {rateTypeByCustomers}
      </Typography>
    </Box>
  );
}

export function renderEndAdornment(
  quote: FreightQuoteActionModel,
): React.ReactNode {
  const rateTypeByCustomers = renderRateTypeByCustomers(quote);

  if (!rateTypeByCustomers) {
    return null;
  }

  return (
    <InputAdornment disableTypography position="end">
      {rateTypeByCustomers}
    </InputAdornment>
  );
}
