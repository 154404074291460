import { zIndexes } from '../../../../theme';
import { Box, Button, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from '../../../confirmationDialog/ConfirmationDialog';
import { routes } from '../../../../constants';
import { FormikEntity } from '../../../../types';
import { CreateRuleFormModel } from '../../RuleForm.types';
import { CustomTooltip } from '../../../../components';

interface Props {
  onSave: () => void;
  onPublish: () => void;
  onCopy?: () => void;
  loading: boolean;
  formik: FormikEntity<CreateRuleFormModel>;
  editMode: boolean;
  copyMode: boolean;
  showSaveAsDraft?: boolean;
  rulePublishError?: string;
  isPublished: boolean;
  showDelete?: boolean;
  onDelete?: () => void;
}

export function RuleFormFooter({
  loading,
  formik,
  onSave,
  onPublish,
  onCopy,
  editMode,
  copyMode,
  isPublished,
  showSaveAsDraft,
  rulePublishError,
  showDelete,
  onDelete,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const disabledSaveAsDraft =
    (!formik.isValid && formik.submitCount > 0) ||
    (!copyMode && !formik.dirty) ||
    loading;
  const disabledSave =
    !!rulePublishError ||
    (!formik.isValid && formik.submitCount > 0) ||
    (!editMode && !copyMode && !formik.dirty) ||
    (isPublished && !formik.dirty) ||
    loading;
  const dirty = formik.dirty;

  const publishButton = (
    <Box marginLeft={2}>
      <Button
        variant="contained"
        type="submit"
        disabled={disabledSave}
        onClick={onPublish}
      >
        {editMode ? 'Save & publish' : 'Publish'}
      </Button>
    </Box>
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingX={6}
      paddingY={3}
      position="fixed"
      width="100%"
      bottom="0"
      zIndex={zIndexes.HEADER}
      bgcolor={palette.common.white}
      borderTop={`4px solid ${palette.background.default}`}
    >
      <Box>
        {showDelete && (
          <Button
            variant="contained"
            type="button"
            color="error"
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Delete
          </Button>
        )}
      </Box>

      <Stack direction="row" justifyContent="end" alignItems="center">
        <Box>
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              dirty ? setShowConfirmation(true) : navigate(routes.Rules);
            }}
          >
            Cancel
          </Button>
        </Box>
        {editMode && (
          <Box marginLeft={2}>
            <Button
              variant="outlined"
              type="button"
              disabled={!disabledSaveAsDraft || loading}
              onClick={() => setShowCopyConfirmation(true)}
            >
              Create a copy
            </Button>
          </Box>
        )}
        {showSaveAsDraft && (
          <Box marginLeft={2}>
            <Button
              variant="outlined"
              type="submit"
              disabled={disabledSaveAsDraft}
              onClick={onSave}
            >
              Save as draft
            </Button>
          </Box>
        )}

        {rulePublishError ? (
          <CustomTooltip
            title={rulePublishError}
            placement="top"
            variant="dark"
          >
            {publishButton}
          </CustomTooltip>
        ) : (
          publishButton
        )}
      </Stack>

      {showConfirmation && (
        <ConfirmationDialog
          title="Cancel"
          message={
            editMode
              ? 'Are you sure want to cancel editing this rule? All changes will be lost.'
              : 'Are you sure want to cancel creation this rule? All progress will be cleared.'
          }
          closeLabel="No"
          submitLabel="Yes, cancel"
          preventSubmit
          onSubmit={() => navigate(routes.Rules)}
          onClosed={() => setShowConfirmation(false)}
        />
      )}

      {showCopyConfirmation && (
        <ConfirmationDialog
          title="Create a copy"
          message="Are you sure creating a new rule by copying this one?"
          closeLabel="No"
          submitLabel="Yes, create"
          preventSubmit
          onSubmit={onCopy}
          onClosed={() => setShowCopyConfirmation(false)}
        />
      )}

      {showDeleteConfirmation && showDelete && (
        <ConfirmationDialog
          title="Delete"
          message="Are you sure you want to delete this rule?"
          closeLabel="No"
          submitLabel="Yes, delete"
          submitColor="error"
          preventSubmit
          onSubmit={onDelete}
          onClosed={() => setShowDeleteConfirmation(false)}
        />
      )}
    </Stack>
  );
}
