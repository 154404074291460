/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { BulkBidPanel, TogglePanel, ToggleType } from '../../components';
import { DataGrid, DataGridProps } from './DataGrid';
import { FreightQuoteActionModel } from '../../types';
import { BulkBidError } from './components/bulkBidError/BulkBidError';
import { useAppSelector } from '../../redux/hooks';
import { freightQuotesSelectors } from '../../redux/freightQuotes';
import { FreightQuotesTabFilters } from '../../redux/freightQuotes/freightQuotes.types';

interface Props extends DataGridProps {
  total: number;
  filters?: FreightQuotesTabFilters;
  useBulkBid?: boolean;
  bulkBidButtonLabel?: string;
  useBulkIgnore?: boolean;
  showToggleMode?: boolean;
  toggleValue?: ToggleType;
  onToggle?: (value: ToggleType) => void;
  onBulkToggle?: (value: boolean) => void;
  onBulkSubmit?: (quotes: FreightQuoteActionModel[]) => void;
  onBulkIgnore?: (quotes: FreightQuoteActionModel[]) => void;
}

export function ExtendedDataGrid({
  filters,
  useBulkBid = false,
  useBulkIgnore = false,
  bulkBidButtonLabel,
  showToggleMode = false,
  total,
  toggleValue,
  onToggle = () => null,
  onBulkSubmit = () => null,
  onBulkIgnore = () => null,
  onBulkToggle = () => null,
  children,
  data,
  ...gridProps
}: PropsWithChildren<Props>): JSX.Element | null {
  const [bulkMode, setBulkMode] = useState(false);
  const [errorType, setErrorType] = useState<null | 'bid' | 'ignore'>(null);
  const [selectedItems, setSelectedItems] = useState<FreightQuoteActionModel[]>(
    [],
  );

  const quoteToSubmit = useAppSelector(freightQuotesSelectors.getQuoteToSubmit);
  const quotesToIgnore = useAppSelector(
    freightQuotesSelectors.getQuotesToIgnore,
  );

  const clearAll = () => {
    if (!bulkMode) {
      return;
    }

    setSelectedItems([]);
    setBulkMode(false);
    onBulkToggle(false);
  };

  useEffect(() => {
    if (!quoteToSubmit || quoteToSubmit.length === 0) {
      clearAll();
    }
  }, [quoteToSubmit]);

  useEffect(() => {
    if (!quotesToIgnore || quotesToIgnore.length === 0) {
      clearAll();
    }
  }, [quotesToIgnore]);

  useEffect(() => {
    setSelectedItems([]);
  }, [filters]);

  const isValid = () => {
    const idsMap = selectedItems.reduce<Record<number, true>>(
      (acum, item) => ({
        ...acum,
        [item.Id]: true,
      }),
      {},
    );

    const quotes = data.filter((quote) => idsMap[quote.Id]);

    const isInvalid = quotes.some(
      (quote) =>
        quote.Shipper !== quotes[0].Shipper ||
        (quote.EquipmentType && quote.EquipmentType.Name) !==
          (quotes[0].EquipmentType && quotes[0].EquipmentType.Name) ||
        quote.StopsCount !== quotes[0].StopsCount,
    );

    return isInvalid;
  };

  const checkIsValidAndSubmit = () => {
    const isInvalid = isValid();

    if (isInvalid) {
      setErrorType('bid');
      return;
    }

    onBulkSubmit(selectedItems);
  };

  const checkIsValidAndIgnore = () => {
    const isInvalid = isValid();

    if (isInvalid) {
      setErrorType('ignore');
      return;
    }

    onBulkIgnore(selectedItems);
  };

  const content = useMemo(
    () =>
      !data || !data.length ? (
        children
      ) : (
        <DataGrid
          data={data}
          {...gridProps}
          disableActions={bulkMode}
          selected={selectedItems}
          onSelect={setSelectedItems}
          selectable={bulkMode}
        />
      ),
    [gridProps, data, selectedItems, bulkMode],
  );

  return (
    <>
      {!bulkMode && (
        <TogglePanel
          disabled={!data || !data.length}
          showBulkBid={useBulkBid}
          showToggle={showToggleMode}
          count={total}
          value={toggleValue}
          onToggle={onToggle}
          onBulkBid={() => {
            setBulkMode(true);
            onBulkToggle(true);
          }}
        />
      )}

      {bulkMode && (
        <BulkBidPanel
          disabled={!selectedItems.length}
          bulkBidButtonLabel={bulkBidButtonLabel}
          showIgnore={useBulkIgnore}
          count={selectedItems.length}
          onSubmit={checkIsValidAndSubmit}
          onIgnore={checkIsValidAndIgnore}
          onCancel={() => {
            setBulkMode(false);
            onBulkToggle(false);
            setSelectedItems([]);
          }}
        />
      )}

      {content}

      {errorType && (
        <BulkBidError
          errorType={errorType}
          onClosed={() => setErrorType(null)}
        />
      )}
    </>
  );
}
