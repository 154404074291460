import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import { SelectProps } from '@mui/material/Select/Select';
import { FormikEntity, PlainObject, SelectItemModel } from '../../../types';
import { cn, getFormikError, is } from '../../../utils';

const EMPTY = 'emptyValue';

interface Props<T extends PlainObject> extends SelectProps {
  items: SelectItemModel[];
  label?: string;
  formik?: FormikEntity<T>;
}

function getValue<T extends PlainObject>(
  name?: string,
  formik?: FormikEntity<T>,
  value?: unknown,
  defaultValue?: unknown,
): number | string | null {
  const formikValue = formik && name ? formik.getFieldProps(name).value : null;
  const propValue =
    value || (is.nullOrUndefined(formikValue) ? defaultValue : formikValue);

  return is.nullOrUndefined(propValue) ? EMPTY : propValue;
}

export function SgSimpleSelect<T extends PlainObject>({
  items,
  formik,
  placeholder,
  onChange,
  defaultValue,
  ...selectProps
}: Props<T>): JSX.Element {
  const name = selectProps.name;
  const inputValue = getValue<T>(name, formik, selectProps.value, defaultValue);

  const formikProps =
    formik && name
      ? ({
          onChange: (e, v) => {
            formik?.setFieldValue(name, e.target.value);

            if (onChange) {
              onChange(e, v);
            }
          },
          onBlur: formik.handleBlur,
        } as Partial<SelectProps>)
      : {};

  return (
    <Select
      labelId="group-by-select-label"
      id="group-by-select"
      renderValue={inputValue === EMPTY ? () => placeholder : undefined}
      {...formikProps}
      {...selectProps}
      value={inputValue}
    >
      <MenuItem sx={{ display: 'none' }} value={EMPTY} />

      {items.map(({ value, label, disabled }) => (
        <MenuItem key={value} value={value} disabled={disabled}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

export function SgSelect<T extends PlainObject>({
  label: selectLabel,
  items,
  formik,
  defaultValue,
  ...selectProps
}: Props<T>) {
  const name = selectProps.name;
  const inputValue = getValue<T>(name, formik, selectProps.value, defaultValue);
  const error =
    (formik && name && Boolean(getFormikError(formik, name))) ||
    selectProps.error;
  const helperText = (formik && name && getFormikError(formik, name)) || '';

  return (
    <FormControl
      variant="standard"
      className={cn(inputValue === EMPTY && 'emptyValue')}
      fullWidth
      error={error}
      disabled={selectProps.disabled}
    >
      {selectLabel && (
        <InputLabel
          id="group-by-select-label"
          error={error}
          disabled={selectProps.disabled}
        >
          {selectLabel}
        </InputLabel>
      )}

      <SgSimpleSelect
        {...selectProps}
        formik={formik}
        items={items}
        defaultValue={defaultValue}
      />

      <FormHelperText error={error}>
        <>{helperText}</>
      </FormHelperText>
    </FormControl>
  );
}
