import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationStoreKey } from './notification.const';
import { AlertColor } from '@mui/material/Alert/Alert';
import { NotificationStatus, SOMETHING_WENT_WRONG } from '../../constants';
import { NotificationExtendedModel, NotificationModel } from '../../types';
import {
  fetchAllNotifications,
  readAllUserNotifications,
  readUserNotifications,
} from './notification.thunks';
import { setPropsToNotification } from './notification.utils';

export interface ToasterNotificationModel {
  message: string;
  type: AlertColor | 'draft';
  open?: boolean;
}

export interface NotificationState {
  notification: ToasterNotificationModel | null;

  notificationItems: NotificationExtendedModel[];
}

const initialState: NotificationState = {
  notification: null,
  notificationItems: [],
};

export const notificationSlice = createSlice({
  name: notificationStoreKey,
  initialState,
  reducers: {
    success(state, { payload }: PayloadAction<string>) {
      state.notification = { message: payload, type: 'success', open: true };
    },
    error(state, { payload }: PayloadAction<string>) {
      state.notification = { message: payload, type: 'error', open: true };
    },
    info(state, { payload }: PayloadAction<string>) {
      state.notification = { message: payload, type: 'info', open: true };
    },
    draft(state, { payload }: PayloadAction<string>) {
      state.notification = { message: payload, type: 'draft', open: true };
    },
    networkError(state) {
      state.notification = {
        message: SOMETHING_WENT_WRONG,
        type: 'error',
        open: true,
      };
    },
    setNotification(
      state,
      { payload }: PayloadAction<ToasterNotificationModel>,
    ) {
      state.notification = { ...payload, open: true };
    },
    removeNotification(state) {
      if (!state.notification) {
        return;
      }

      state.notification.open = false;
    },
    markNotificationOpened(state, { payload }: PayloadAction<number>) {
      state.notificationItems = setPropsToNotification(
        payload,
        state.notificationItems,
        { opened: true },
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAllNotifications.fulfilled,
      (state, { payload }: PayloadAction<NotificationModel[]>) => {
        state.notificationItems = payload;
      },
    );

    builder.addCase(
      readUserNotifications.fulfilled,
      (state, { payload }: PayloadAction<number | null>) => {
        state.notificationItems = setPropsToNotification(
          payload,
          state.notificationItems,
          { Status: NotificationStatus.Read },
        );
      },
    );

    builder.addCase(
      readAllUserNotifications.fulfilled,
      (state, { payload }: PayloadAction<boolean>) => {
        if (!payload) {
          return;
        }

        state.notificationItems = state.notificationItems.map((item) => ({
          ...item,
          Status: NotificationStatus.Read,
        }));
      },
    );
  },
});
