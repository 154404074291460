import { is } from './is';

export const plural = (
  count?: number | null,
  word?: string,
  pluralWord?: string,
) => {
  if (is.nullOrUndefined(count)) {
    return word || '';
  }

  if (pluralWord) {
    return count === 1 ? word : pluralWord;
  }

  return (word || '') + (count === 1 ? '' : 's');
};
