import { PaletteOptions } from '@mui/material/styles/createPalette';

export default <PaletteOptions>{
  common: {
    black: '#00090E',
  },
  sagan: {
    main: '#003B5C',
    contrastText: '#8B959C',
  },
  saganStatus: {
    open: '#FDF1B2',
    submitted: '#D2EFFF',
    ignored: '#D5D9DB',
    lost: 'rgba(224, 63, 63, 0.2)',
    missed: '#F5F6F6',
    won: 'rgba(10, 131, 0, 0.2)',
  },
  primary: {
    dark: '#005483', // Primary
    main: '#006DAA', // Shade/blue
    light: '#00A3E0', // Primary
  },
  secondary: {
    main: '#5B646A',
    light: '#F5F6F6',
  },
  background: {
    badge: '#EAECED',
    default: '#F5F6F6',
    paper: '#FAE053',
    panel: '#F9F9F9',
    blue: '#E5F6FF',
  },
  error: {
    main: '#E03F3F',
  },
  warning: {
    dark: '#F8D61A',
    main: '#FDF1B2',
  },
  success: {
    main: '#0A8300',
  },
  text: {
    primary: '#000000',
    secondary: '#02090C',
    disabled: '#C1C6C8',
  },
  divider: '#E6E8E9',
};
