import { DashboardBreakdownResponse } from '../../../types';

export const pieChartItemsOrder: Array<keyof DashboardBreakdownResponse> = [
  'MissedCount',
  'WonCount',
  'IgnoredCount',
  'OpenCount',
  'LostCount',
  'SubmittedCount',
];

export const pieLegendItemsOrder: Array<keyof DashboardBreakdownResponse> = [
  'WonCount',
  'LostCount',
  'MissedCount',
  'IgnoredCount',
  'OpenCount',
  'SubmittedCount',
];

export const pieChartItemsLabel = {
  MissedCount: 'Missed',
  WonCount: 'Won',
  IgnoredCount: 'Ignored',
  OpenCount: 'Open',
  LostCount: 'Lost',
  SubmittedCount: 'Submitted',
};

export const pieChartItemColors = {
  MissedCount: '#C1C6C8',
  WonCount: '#003B5C',
  IgnoredCount: '#6D787F',
  OpenCount: '#FFD207',
  LostCount: '#00A3E0',
  SubmittedCount: '#0A8300',
};
