import { Box, Stack, TextField, Typography } from '@mui/material';
import {
  DialogWrapper,
  DialogWrapperMainProps,
} from '../../components/dialogWrapper/DialogWrapper';
import { useFormik } from 'formik';
import { getSubmitBidSchema } from '../common/submitBidSchema';
import { BidForm } from '../../types/bidToSubmit';
import { FreightQuoteActionModel } from '../../types';
import { renderEndAdornment, submitStartAdornment } from '../../components';
import { useEffect, useState } from 'react';
import { QuoteStatus, TabName } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';
import { fetchSimilarQuotes } from '../../redux/freightQuotes';
import { CommonSubmitContent } from './components/commonSubmitContent/SubmitCommonContent';
import { isValidBidPerMile } from '../../utils';

interface Props extends DialogWrapperMainProps {
  quote: FreightQuoteActionModel;
  count: number;
  currentTabName: TabName | undefined;
  onSubmit: (values: BidForm) => void;
}

export function SubmitBidDialog({
  onSubmit,
  quote,
  count,
  currentTabName,
  ...props
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const [checkedBidPerMile, setCheckedBidPerMile] = useState(false);

  useEffect(() => {
    dispatch(fetchSimilarQuotes(quote));
  }, []);

  const {
    values,
    errors,
    submitCount,
    isValid,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik<BidForm>({
    validateOnBlur: false,
    initialValues: {},
    validationSchema: getSubmitBidSchema(quote.MaxBid),
    validateOnMount: false,
    onSubmit: (newValues) => {
      if (
        !checkedBidPerMile &&
        !isValidBidPerMile(quote.Distance, newValues.bid)
      ) {
        setCheckedBidPerMile(true);
        return;
      }

      onSubmit(newValues);
    },
  });

  useEffect(() => {
    if (checkedBidPerMile && isValidBidPerMile(quote.Distance, values.bid)) {
      setCheckedBidPerMile(false);
    }
  }, [values.bid]);

  const isFormValid = submitCount === 0 || isValid;
  const isTimePassed =
    !!quote.respondByTimer && !quote.respondByTimer.minutesLeft;
  const disableForm =
    isTimePassed ||
    (currentTabName === TabName.Open &&
      quote.Status !== QuoteStatus.Open &&
      quote.Status !== QuoteStatus.Ignored &&
      quote.Status !== QuoteStatus.SubmitRequestFailed);

  return (
    <DialogWrapper
      noPadding
      actionsPadding={3}
      width={420}
      title={currentTabName === TabName.Submitted ? 'Edit bid' : 'Submit bid'}
      closeLabel="Cancel"
      disabledSubmit={!isFormValid || disableForm || !quote.validToSubmit}
      onSubmit={handleSubmit}
      {...props}
      loading={!quote.validToSubmit || props.loading}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Box paddingX={4} paddingBottom={3}>
          {count > 1 && (
            <Stack direction="row" marginBottom={2}>
              <Typography variant="h4" fontWeight={400} marginRight={0.5}>
                Selected quotes:
              </Typography>
              <Typography variant="h4"> {count}</Typography>
            </Stack>
          )}

          <CommonSubmitContent
            quote={quote}
            currentTabName={currentTabName}
            wrongBidPerMile={checkedBidPerMile}
            commentValue={values.comments || ''}
            commentError={(!isFormValid && errors.comments) || undefined}
            count={count}
            onCommentChange={handleChange}
            setSuggestedBid={(newBid) => setFieldValue('bid', newBid || '')}
          >
            <TextField
              fullWidth
              disabled={disableForm}
              name="bid"
              label="Get paid"
              id="bid-input"
              size="medium"
              placeholder="0.00"
              autoFocus
              value={values.bid || ''}
              onChange={handleChange}
              error={!!errors.bid && !isFormValid}
              helperText={!isFormValid && errors.bid}
              InputProps={{
                startAdornment: submitStartAdornment,
                endAdornment: renderEndAdornment(quote),
              }}
              variant="standard"
            />
          </CommonSubmitContent>
        </Box>
      </Box>
    </DialogWrapper>
  );
}
