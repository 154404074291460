import { TextField } from '@mui/material';
import React from 'react';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import { FormikEntity, PlainObject } from '../../../types';
import { getFormikError, is } from '../../../utils';

interface Props<T extends PlainObject> extends StandardTextFieldProps {
  valueFormatter?: (v?: string) => string | null;
  formik?: FormikEntity<T>;
}

export function SgTextField<T extends PlainObject>({
  formik,
  valueFormatter,
  onChange,
  ...props
}: Props<T>): JSX.Element {
  const name = props.name;
  const formikValue = formik && name && formik.getFieldProps(name).value;
  const textFieldValue = is.nullOrUndefined(formikValue) ? '' : formikValue;
  const formikProps =
    formik && name
      ? ({
          value: textFieldValue,
          onChange: (e) => {
            const v = valueFormatter
              ? valueFormatter(e.target.value)
              : e.target.value;

            formik?.setFieldValue(name, v);

            if (onChange) {
              onChange(e);
            }
          },
          onBlur: formik.handleBlur,
          error: Boolean(getFormikError(formik, name)),
          helperText: getFormikError(formik, name),
        } as Partial<StandardTextFieldProps>)
      : {};

  return (
    <TextField
      fullWidth
      variant="standard"
      {...formikProps}
      {...props}
      InputLabelProps={{
        shrink: true,
        ...props.InputLabelProps,
      }}
    />
  );
}
