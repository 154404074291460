import { SerializedStyles, css } from '@emotion/react';

export const useBarChartStyles = (): SerializedStyles => {
  return css`
    .apexcharts-legend {
      .apexcharts-legend-series:not(:last-of-type) {
        .apexcharts-legend-marker {
          border-radius: 0 !important;
        }
      }

      .apexcharts-legend-marker {
        margin-right: 8px;
      }

      .apexcharts-legend-text {
        font-size: 14px !important;
      }
    }
  `;
};
