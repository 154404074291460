import { RuleEnums, RuleEnumsResponse } from '../redux/rules/rules.types';
import { ExtendedRuleEnum } from '../types';

export const initEnumsData: RuleEnums = {
  AutomatedProcessingSchedulingAction: [],
  AutomatedProcessingSchedulingCondition: [],
  RuleExpressionSpecialDate: [],
  FreightQuoteStatusEnum: [],
  RuleExpressionOperator: [],
  RuleMode: [],
  RuleRateActionMode: [],
  RuleStatus: [],
  RuleType: [],
};

export function ruleEnumsResponseToData(
  response: RuleEnumsResponse[],
): RuleEnums {
  return response.reduce(
    (acum, item): RuleEnums => ({
      ...acum,
      [item.EnumName]: (item.EnumValues || []).map(
        (value): ExtendedRuleEnum => ({
          ...value,
          value: value.Key,
          label: value.Description,
        }),
      ),
    }),
    initEnumsData,
  );
}
