import { Box } from '@mui/material';
import { FreightQuoteActionModel } from '../../../../types';
import { SgTable, SgTableRow } from '../../../../components';
import React from 'react';
import { ruleValidationColumns } from './ValidationGrid.const';

interface Props {
  list: FreightQuoteActionModel[];
}

export function ValidationGrid({ list }: Props): JSX.Element {
  return (
    <Box>
      <SgTable<FreightQuoteActionModel>
        size="small"
        columns={ruleValidationColumns}
      >
        {list.map((row) => (
          <SgTableRow<FreightQuoteActionModel>
            key={row.Id}
            size="small"
            rowData={row}
            columns={ruleValidationColumns}
          />
        ))}
      </SgTable>
    </Box>
  );
}
