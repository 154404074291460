import React from 'react';
import { FormikEntity, PlainObject } from '../../../types';
import { SgDatePicker } from './SgDatePicker';
import { getFormikError } from '../../../utils';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker';

interface Props<F extends PlainObject>
  extends Omit<
    DatePickerProps<Date, Date>,
    'renderInput' | 'onChange' | 'value'
  > {
  label: string;
  name: string;
  formik: FormikEntity<F>;
}

export function FormikDatePicker<F extends PlainObject>({
  formik,
  name,
  label,
  ...props
}: Props<F>): JSX.Element {
  const formikValue =
    (formik && name && formik.getFieldProps(name).value) || null;
  const formikProps = {
    value: formikValue,
    onChange: (value: Date | null) =>
      formik.setFieldValue(name, value || undefined),
    textFieldProps: {
      onBlur: formik.handleBlur,
      error: Boolean(getFormikError(formik, name)),
      helperText: getFormikError(formik, name),
    },
  };

  return <SgDatePicker label={label} {...props} {...formikProps} />;
}
