import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dashboardStoreKey } from './dashboard.const';
import { apiEndpoint } from '../../constants/api';
import { DashboardChartsData } from './dashboard.types';
import { notificationSlice } from '../notification';
import {
  filtersToOData,
  ODataItemValueType,
} from '../../utils/oDataBuilder/oDataBuilder';
import { FiltersModel, PlainObject } from '../../types';
import { TotalRevenueGroupBy } from '../../constants';
import { is } from '../../utils';

export const fetchDashboardData = createAsyncThunk(
  `${dashboardStoreKey}/fetchDashboardData`,
  async (
    { groupBy, ...filters }: Partial<FiltersModel>,
    store,
  ): Promise<DashboardChartsData | null> => {
    try {
      const otData = filtersToOData(
        {
          filters: filters as PlainObject<ODataItemValueType>,
        },
        true,
      );

      const { data: breakdownData } = await axios.post(
        apiEndpoint.breakdownData(),
        otData,
      );
      const { data: totalRevenueData } = await axios.post(
        apiEndpoint.totalRevenueData(),
        { ...otData, GroupBy: groupBy || TotalRevenueGroupBy.Day },
      );
      const { data: performanceData } = await axios.post(
        apiEndpoint.performanceData(),
        otData,
      );

      return {
        filters: {
          groupBy,
          ...filters,
        },
        breakdownData:
          !breakdownData ||
          is.undefined(Object.values(breakdownData).find((v) => Number(v) > 0)) // if each value is 0
            ? null
            : breakdownData,
        totalRevenueData,
        performanceData,
      };
    } catch (e) {
      store.dispatch(notificationSlice.actions.networkError());
    }

    return null;
  },
);
