import { ColumnModel } from '../../components';
import { PodCoverageItem, RuleApiModel } from '../../types';
import { renderDatPodLanes } from './PodCoverageDataGrid.utils';
import { podNameMktFormatter } from '../../utils';
import { Typography } from '@mui/material';

export const podCoverageGridColumns: ColumnModel<PodCoverageItem>[] = [
  {
    name: 'DatPod',
    label: 'POD name',
    formatter: (item) => podNameMktFormatter(item.DatPod),
  },
  {
    name: 'DatPodLanesAsOrigin',
    label: 'Origin',
    align: 'right',
    formatter: renderDatPodLanes,
  },
  {
    name: 'DatPodLanesAsDestination',
    label: 'Destination',
    align: 'right',
    formatter: renderDatPodLanes,
  },
  {
    name: 'PodRulesCount',
    label: 'Rules (active)',
    align: 'right',
    formatter: (item) =>
      item.PodRulesCount && (
        <Typography variant="h4">{item.PodRulesCount}</Typography>
      ),
  },
  {
    name: 'action',
    label: '',
    align: 'right',
    styles: { width: '8%' },
    formatter: (item) => '',
  },
];
