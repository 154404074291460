import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { PlainObject } from '../../../types';
import { is } from '../../../utils';

export function saAutocompleteMapValueToOption<T, M>(
  value: AutocompleteValue<M, true, false, false> | undefined,
  items: T[],
  transformer: (item: T) => M,
): AutocompleteValue<T, true, false, false> | undefined {
  const transformedItems = items.reduce(
    (acum: PlainObject<T>, item: T): PlainObject<T> => {
      const key = transformer(item);

      return {
        ...acum,
        [is.number(key) ? key : String(key)]: item,
      };
    },
    {} as PlainObject<T>,
  );

  return (
    value &&
    value
      .filter((v) => v !== null)
      .map(
        (valueItem) =>
          transformedItems[
            is.number(valueItem) ? valueItem : String(valueItem)
          ],
      )
  );
}

export function saAutocompleteMapOptionToValue<T, M>(
  value: AutocompleteValue<T, true, false, false> | undefined,
  transformer: (item: T) => M,
): AutocompleteValue<M, true, false, false> {
  return (value && value.map(transformer)) || [];
}

export function getGroupsFromValueMap<T>(
  value: T[],
  groupBy?: (value: T) => string,
): PlainObject {
  return value.reduce(
    (acum, item) =>
      groupBy
        ? {
            ...acum,
            [groupBy(item)]: true,
          }
        : acum,
    {},
  );
}

export function getEnabledGroups<T>(
  options: T[],
  groupBy?: (value: T) => string,
): PlainObject {
  return options.reduce(
    (acum, item) =>
      groupBy
        ? {
            ...acum,
            [groupBy(item)]: !(item as PlainObject).disabled
              ? true
              : !!acum[groupBy(item)],
          }
        : acum,
    {} as PlainObject<boolean>,
  );
}
