import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import {
  BaseFilters,
  FiltersModel,
  QuoteBaseEntityTypeUIExtended,
  QuoteShortLocationUIExtended,
  SearchFiltersModel,
  ShipperApiModel,
} from '../../types';
import {
  getShippersByPortals,
  removeEmptyValues,
  getManagersByPortals,
} from '../../utils';
import { renderFilterTag } from './Filters.utils';
import React, { useEffect, useState } from 'react';
import { TotalRevenueGroupBy, TotalRevenueGroupLabels } from '../../constants';
import { RenderLocationAutocomplete } from './components/locationAutocomplete/LocationAutocomplete';
import { FilterBaseProps } from './Filters.types';
import { SGAutocomplete, SgDatePicker } from '../formElements';

interface Props extends FilterBaseProps {
  loading?: boolean;
  periodMode?: boolean;
  showGroupBy?: boolean;
  allFilters?: boolean;
  backgroundColor?: string;
  filters: Partial<FiltersModel>;
  onChange: (values?: Partial<FiltersModel>) => void;
  onUpdateFilters?: (values?: Partial<SearchFiltersModel>) => void;
  showManagers?: boolean;
}

export function Filters({
  filters,
  periodMode,
  showGroupBy,
  onChange,
  onUpdateFilters = () => null,
  loading,
  allFilters,
  backgroundColor,
  portals,
  equipmentTypes,
  origins,
  destinations,
  showManagers = false,
}: Props): JSX.Element {
  const styles = { width: allFilters ? '25%' : '40%', marginRight: 2 };
  const periodStyles = { width: '170px', minWidth: '170px', marginRight: 2 };
  const hasFilters = Object.keys(filters).length > 0;

  const [shippers, setShippers] = useState<QuoteBaseEntityTypeUIExtended[]>([]);
  const [shippersOptions, setShippersOptions] = useState<
    QuoteBaseEntityTypeUIExtended[]
  >([]);
  const [accountManagers, setAccountManagers] = useState<
    QuoteBaseEntityTypeUIExtended[]
  >([]);
  const [accountManagersOptions, setAccountManagersOptions] = useState<
    QuoteBaseEntityTypeUIExtended[]
  >([]);

  useEffect(() => {
    const shippersByPortals = getShippersByPortals(portals);
    setShippers(shippersByPortals);
    setShippersOptions(shippersByPortals);

    const managersByPortals = getManagersByPortals(portals, filters);
    setAccountManagers(managersByPortals);
    if (!filters.shippers?.length) {
      setAccountManagersOptions(managersByPortals);
    } else {
      updateAccountManagersOptions(filters.shippers, managersByPortals);
    }
  }, [JSON.stringify(portals), filters]);

  const changeValueAndUpdateFilter = (values: Partial<SearchFiltersModel>) => {
    const { origins, destinations, shippers, accountManagers } = {
      ...filters,
      ...values,
    };

    onUpdateFilters(
      removeEmptyValues({ origins, destinations, shippers, accountManagers }),
    );
    onChange(values);
  };

  const updateAccountManagerFilter = (
    accountManagers: QuoteBaseEntityTypeUIExtended[],
  ) => {
    const accountManagersIds = accountManagers.map(({ Id }) => Id);
    const shippersByAccountManager = (shippers as ShipperApiModel[]).filter(
      ({ AccountManager }) =>
        accountManagersIds.includes(AccountManager?.Id as number) ||
        AccountManager === null,
    );

    setShippersOptions(
      accountManagers.length ? shippersByAccountManager : shippers,
    );
    changeValueAndUpdateFilter({ accountManagers });
  };

  const updateAccountManagersOptions = (
    shippers: QuoteBaseEntityTypeUIExtended[],
    managers?: QuoteBaseEntityTypeUIExtended[],
  ) => {
    const availableAccountManagers = managers?.length
      ? managers
      : accountManagers;
    const anyShipperWithoutManager = (shippers as ShipperApiModel[]).some(
      ({ AccountManager }) => AccountManager === null,
    );
    if (anyShipperWithoutManager) {
      setAccountManagersOptions(availableAccountManagers);
    } else {
      const accountManagersIds = (shippers as ShipperApiModel[]).map(
        ({ AccountManager }) => AccountManager?.Id,
      );
      const accountManagersByShippers = availableAccountManagers.filter(
        ({ Id }) => accountManagersIds.includes(Id),
      );
      setAccountManagersOptions(
        shippers.length && accountManagersByShippers.length
          ? accountManagersByShippers
          : availableAccountManagers,
      );
    }
  };

  const firstDateName = periodMode ? 'dateFrom' : 'shipDate';
  const secondDateName = periodMode ? 'dateTo' : 'deliveryDate';

  const renderLocationAutocomplete = (
    label: string,
    name: keyof BaseFilters,
    locationValue: QuoteShortLocationUIExtended[],
    options: QuoteShortLocationUIExtended[],
  ): JSX.Element => (
    <Box sx={styles}>
      <RenderLocationAutocomplete
        label={label}
        name={name}
        disabled={loading}
        options={options}
        value={locationValue}
        onChange={(newValues) => {
          changeValueAndUpdateFilter({
            [name]: newValues && newValues.length ? newValues : undefined,
          });
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        padding: 3,
        paddingTop: 2,
        marginBottom: 2,
        borderRadius: '4px',
        backgroundColor: backgroundColor || 'background.default',
      }}
    >
      <Stack direction="row">
        {showManagers && (
          <Box sx={styles}>
            <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
              label="Account manager"
              name="accountManagers"
              id="accountManagers-filter"
              placeholder="All managers"
              selectAllLabel="All"
              disabled={loading}
              options={accountManagersOptions}
              value={filters.accountManagers?.filter(({ Id }) => Id) || []}
              getOptionLabel={(option) => (option && option.Name) || ''}
              getOptionDisabled={(option) => !!option?.disabled}
              renderTags={renderFilterTag}
              onChange={updateAccountManagerFilter}
              isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
            />
          </Box>
        )}
        <Box sx={styles}>
          <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
            label="Customer"
            name="shippers"
            id="customer-filter"
            placeholder="All customers"
            selectAllLabel="All"
            disabled={loading}
            options={shippersOptions}
            value={filters.shippers || []}
            getOptionLabel={(option) => (option && option.Name) || ''}
            getOptionDisabled={(option) => !!option?.disabled}
            renderTags={renderFilterTag}
            onChange={(newValues) => {
              if (showManagers) {
                updateAccountManagersOptions(newValues);
              }
              changeValueAndUpdateFilter({ shippers: newValues });
            }}
            isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
          />
        </Box>

        {allFilters &&
          renderLocationAutocomplete(
            'Origin location',
            'origins',
            filters.origins || [],
            origins,
          )}
        {allFilters &&
          renderLocationAutocomplete(
            'Destination location',
            'destinations',
            filters.destinations || [],
            destinations,
          )}

        <Box sx={styles}>
          <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
            label="Equipment type"
            name="equipmentTypes"
            id="equipment-filter"
            placeholder="All types"
            disabled={loading}
            options={equipmentTypes}
            value={filters.equipmentTypes || []}
            getOptionLabel={(option) => (option && option.Name) || ''}
            renderTags={renderFilterTag}
            onChange={(newValues) => {
              onChange({
                equipmentTypes: newValues.filter((v) => v),
              });
            }}
            isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
          />
        </Box>

        {showGroupBy && (
          <Box sx={styles}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="group-by-select-label">Period</InputLabel>
              <Select
                labelId="group-by-select-label"
                id="group-by-select"
                value={filters.groupBy}
                onChange={(e) =>
                  onChange({
                    groupBy: e.target.value as TotalRevenueGroupBy,
                  })
                }
              >
                {Object.keys(TotalRevenueGroupLabels).map((groupKey) => (
                  <MenuItem key={groupKey} value={groupKey}>
                    {
                      TotalRevenueGroupLabels[
                        groupKey as unknown as TotalRevenueGroupBy
                      ]
                    }
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        <Box sx={periodStyles}>
          <SgDatePicker
            label={periodMode ? 'Start date' : 'Pick up date'}
            value={filters[firstDateName] || null}
            disabled={loading}
            onChange={(newValue: Date | null) => {
              onChange({
                [firstDateName]: newValue ? newValue.toISOString() : undefined,
              });
            }}
          />
        </Box>

        <Box sx={periodStyles}>
          <SgDatePicker
            label={periodMode ? 'End date' : 'Delivery date'}
            value={filters[secondDateName] || null}
            disabled={loading}
            onChange={(newValue: Date | null) => {
              onChange({
                [secondDateName]: newValue ? newValue.toISOString() : undefined,
              });
            }}
          />
        </Box>
        <Stack direction="row" alignItems="end">
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            disabled={!hasFilters}
            onClick={() => {
              if (!hasFilters) {
                return;
              }

              onChange();
            }}
            sx={{ minWidth: '40px', padding: 1 }}
          >
            <CleaningServicesOutlinedIcon fontSize="small" />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
