/** @jsxImportSource @emotion/react */
import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import {
  QuoteStatus,
  TabName,
  THE_SOURCE_IS_NOT_AVAILABLE,
} from '../../constants';
import { FreightQuoteActionModel } from '../../types';
import { isActionDisabled, isQuotePortalNotAvailable } from '../../utils';
import { QuoteErrorIcon } from '../quoteErrorIcon/QuoteErrorIcon';
import { PropsWithChildren } from 'react';

interface Props {
  currentTabName?: TabName;
  disabled?: boolean;
  disabledSubmit?: boolean;
  loading?: boolean;
  disableError?: boolean;
  rowData: FreightQuoteActionModel;
  onIgnore: (rowData: FreightQuoteActionModel) => void;
  onSubmit: (rowData: FreightQuoteActionModel) => void;
}

export function QuoteActions({
  currentTabName,
  disabled,
  disabledSubmit,
  disableError,
  rowData,
  onIgnore,
  onSubmit,
  children,
  loading,
}: PropsWithChildren<Props>): JSX.Element {
  const isPortalNotAvailable = isQuotePortalNotAvailable(rowData);
  const disabledButtons = isActionDisabled(rowData) || disabled || loading;

  const errorIcon = disableError ? null : <QuoteErrorIcon quote={rowData} />;

  const renderSubmitButton = () => {
    const submitButton = (
      <Button
        className="iconButton"
        variant="contained"
        disabled={disabledButtons || disabledSubmit}
        size="medium"
        onClick={() => onSubmit(rowData)}
      >
        <CheckIcon fontSize="small" sx={{ opacity: loading ? 0 : 1 }} />
        {loading && (
          <CircularProgress size={15} sx={{ position: 'absolute' }} />
        )}
      </Button>
    );

    if (
      isPortalNotAvailable ||
      rowData.Status === QuoteStatus.SubmitRequested
    ) {
      return (
        <Tooltip
          enterDelay={300}
          placement="top"
          title={
            isPortalNotAvailable ? THE_SOURCE_IS_NOT_AVAILABLE : 'Submitting...'
          }
          tabIndex={0}
        >
          <Box>{submitButton}</Box>
        </Tooltip>
      );
    }

    return submitButton;
  };

  return (
    <Stack direction="row" justifyContent="end" position="relative">
      {children}
      {currentTabName === TabName.Open && (
        <Button
          className="iconButton"
          disabled={disabledButtons}
          size="medium"
          variant="outlined"
          sx={{ marginRight: 2 }}
          onClick={() => onIgnore(rowData)}
          tabIndex={-1}
        >
          <RemoveIcon fontSize="small" />
        </Button>
      )}
      {(currentTabName === TabName.Open ||
        currentTabName === TabName.Ignored ||
        currentTabName === TabName.Submitted) &&
        renderSubmitButton()}
      {errorIcon}
    </Stack>
  );
}
