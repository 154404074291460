import { TwelveHoursClockTimeModel } from '../features/ruleForm/RuleForm.types';
import { is } from '../utils/is';

export function isTimeValid(time: string): boolean {
  if (!time) {
    return false;
  }
  const regexp = new RegExp(/(\d{2}):(\d{2})\s*(a|p)m/);
  if (!regexp.test(time)) {
    return false;
  }
  const match = regexp.exec(time);
  const hours = parseInt(match ? match[1] : '');
  const minutes = parseInt(match ? match[2] : '');
  if (isNaN(hours) || isNaN(minutes)) {
    return false;
  }
  if (hours > 12 || hours < 1) {
    return false;
  }
  if (minutes > 59 || minutes < 0) {
    return false;
  }
  return true;
}

export function getTime(time: string): TwelveHoursClockTimeModel {
  if (is.falsy(time)) {
    return {};
  }
  const regexp = new RegExp(/(\d{2}):(\d{2})\s*(a|p)m/);
  if (!regexp.test(time)) {
    console.log('time: ' + regexp.test(time || ''));
    return {};
  }
  const match = regexp.exec(time);
  const hours = parseInt(match ? match[1] : '');
  const minutes = parseInt(match ? match[2] : '');
  if (isNaN(hours) || isNaN(minutes)) {
    return {};
  }
  if (hours > 12 || hours < 1) {
    return {};
  }
  if (minutes > 59 || minutes < 0) {
    return {};
  }
  return {
    minutes: minutes,
    hours: hours,
    meridiem: match ? `${match[3].toLowerCase()}m` : '',
  };
}

export function isMoreThan(to: string, from: string): boolean {
  const fromTime = getTime(from);
  const toTime = getTime(to);
  if (is.empty(fromTime) || is.empty(toTime)) {
    return false;
  }
  // if from equals to it is valid case
  if (
    fromTime.hours === toTime.hours &&
    fromTime.minutes === toTime.minutes &&
    fromTime.meridiem?.toLowerCase() === toTime.meridiem?.toLowerCase()
  ) {
    return false;
  }
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const fromDate = new Date(
    year,
    month,
    day,
    calculateTotalHours(fromTime),
    fromTime.minutes,
  );
  const toDate = new Date(
    year,
    month,
    day,
    calculateTotalHours(toTime),
    toTime.minutes,
  );
  return toDate > fromDate;
}

function calculateTotalHours(dayTime: TwelveHoursClockTimeModel): number {
  if (dayTime.meridiem?.toLowerCase() == 'am' && dayTime.hours == 12) {
    return 0;
  }
  if (dayTime.meridiem?.toLowerCase() == 'pm' && dayTime.hours == 12) {
    return dayTime.hours;
  }
  if (dayTime.meridiem?.toLowerCase() == 'am') {
    return dayTime.hours ?? 0;
  } else {
    return (dayTime.hours ?? 0) + 12;
  }
}
