import { PodCoverageItem } from '../../types';
import { useTheme } from '@mui/material';
import { PodLanesDialog } from './components/podLanesDialog/PodLanesDialog';
import { useState } from 'react';
import { EmptyElement } from '../../components';

interface Props {
  pod: PodCoverageItem;
  name: keyof Pick<
    PodCoverageItem,
    'DatPodLanesAsOrigin' | 'DatPodLanesAsDestination'
  >;
}

function DatPodLanes({ pod, name }: Props): JSX.Element {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const style = {
    color: palette.primary.main,
    fontWeight: 600,
    cursor: 'pointer',
  };

  const value = pod[name].LanePodRulesCount;

  if (!value) {
    return <span>{EmptyElement}</span>;
  }

  return (
    <span style={style} onClick={() => setOpen(true)}>
      {value}

      {open && (
        <PodLanesDialog pod={pod} name={name} onClosed={() => setOpen(false)} />
      )}
    </span>
  );
}

export function renderDatPodLanes(
  pod: PodCoverageItem,
  name: keyof PodCoverageItem,
): JSX.Element {
  return (
    <DatPodLanes
      pod={pod}
      name={
        name as keyof Pick<
          PodCoverageItem,
          'DatPodLanesAsOrigin' | 'DatPodLanesAsDestination'
        >
      }
    />
  );
}
