import { format, addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { StringDate } from '../../types';
import { EmptyElement } from '../../components';
import { emptyValue } from '../../constants';

function isValidDate(date: Date | null): boolean {
  return !!date && date?.toString() !== 'Invalid Date';
}

function utcDate(date: Date): Date {
  return date; //addMinutes(date, date.getTimezoneOffset());
}

function dateWithTimeZone(
  dateString: string,
  timezone?: string | null,
): string {
  if (!timezone) {
    return dateString;
  }

  return `${dateString}, ${timezone}`;
}

export function isoToDateString(
  dateString?: StringDate | Date,
  timezone?: string | null,
): string | JSX.Element {
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return EmptyElement;
  }
  return dateWithTimeZone(format(utcDate(date), 'MM/dd/yyyy'), timezone);
}

export function isoToTimeString(
  dateString: StringDate | Date,
  timezone?: string | null,
): string | JSX.Element {
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return EmptyElement;
  }

  return dateWithTimeZone(format(utcDate(date), 'ha'), timezone);
}

export function isoToDateTimeString(
  dateString: StringDate | Date,
  timezone?: string | null,
  round?: boolean,
  separator = ' •',
): string | JSX.Element {
  //05/14/2022 • 10:14 AM
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return EmptyElement;
  }

  return dateWithTimeZone(
    format(
      addMinutes(utcDate(date), round ? 1 : 0),
      `MM/dd/yyyy${separator} h:mm a`,
    ),
    timezone,
  );
}

export function isoToDateHourString(
  dateString: StringDate | Date,
  timezone?: string | null,
  round?: boolean,
): string | JSX.Element {
  //05/14/2022 • 10 AM
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return EmptyElement;
  }

  return dateWithTimeZone(
    format(addMinutes(utcDate(date), round ? 1 : 0), 'MM/dd/yyyy • h a'),
    timezone,
  );
}

export function isoToMonthYearString(dateString: StringDate | Date): string {
  //Aug, 2022
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return emptyValue;
  }

  return format(utcDate(date), 'MMM, yyyy');
}

export function isoToMonthDateString(dateString: StringDate | Date): string {
  //08/23
  const date = new Date(dateString || '');

  if (!isValidDate(date)) {
    return emptyValue;
  }

  return format(utcDate(date), 'MM/dd');
}

export function toYearMonthDateString(date: Date): string {
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${
    day < 10 ? '0' : ''
  }${day}`;
}

export function toNoTimezoneISOString(
  dateValue: Date,
  removeTimeZone?: boolean,
): string {
  const noTZDate = new Date(
    dateValue.getTime() + new Date().getTimezoneOffset() * 60 * 1000,
  );
  const date = removeTimeZone ? noTZDate : dateValue;

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  const time = [
    `0${hours}`.slice(-2),
    `0${minutes}`.slice(-2),
    `0${seconds}`.slice(-2),
  ];
  const millisec = `000${milliseconds}`.slice(-3);

  return `${date.getFullYear()}-${month < 10 ? '0' : ''}${month}-${
    day < 10 ? '0' : ''
  }${day}T${time.join(':')}.${millisec}`;
}

export function toCurrentTimezoneDate(dateString: string): Date {
  return new Date(
    new Date(dateString).getTime() + new Date().getTimezoneOffset() * 60 * 1000,
  );
}

export function timeAgo(dateString: StringDate): string | JSX.Element {
  if (!dateString || new Date(dateString) >= new Date()) {
    return '';
  }

  const date = new Date(dateString).getTime();
  const dateNow = new Date().getTime();

  const diffH = (dateNow - date) / (1000 * 60 * 60);
  const diffM = (dateNow - date) / (1000 * 60);

  if (diffM < 60) {
    return `${diffM.toFixed(0)}m ago`;
  }

  if (diffH < 6) {
    return `${diffH.toFixed(0)}h ago`;
  }

  return isoToDateHourString(dateString);
}

export function isoDateFormatter(
  dateString: StringDate | Date,
  formatString: string,
  timeZone?: string | null,
): string {
  const date = new Date(dateString || '');
  if (!isValidDate(date)) {
    return emptyValue;
  }

  return timeZone
    ? formatInTimeZone(date, timeZone, formatString)
    : format(date, formatString);
}
