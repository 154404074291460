/** @jsxImportSource @emotion/react */
import {
  Drawer,
  Stack,
  Typography,
  Box,
  IconButton,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Link,
} from '@mui/material';
import { QuotePortalUIExtended } from '../../../../types';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { QuotePortalStatus } from '../../../../constants';
import { useSourceControlStyles } from './SourceControl.styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
  portals: QuotePortalUIExtended[];
  open: boolean;
  onClose: () => void;
}

export function SourceControl({ portals, open, onClose }: Props) {
  const styles = useSourceControlStyles();

  const renderPortalName = (portal: QuotePortalUIExtended) =>
    portal.BaseUrl ? (
      <Link href={portal.BaseUrl} target="_blank">
        {portal.Name} <OpenInNewIcon fontSize="small" />
      </Link>
    ) : (
      portal.Name
    );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ zIndex: 9999999 }}
      elevation={3}
      css={styles}
    >
      <Box sx={{ width: 400, paddingX: 3, paddingY: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">Source monitor</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{ paddingTop: 2 }}>
          <Table>
            <TableBody>
              {(portals || []).map((portal) => (
                <TableRow key={portal.Name}>
                  <TableCell width={300}>
                    <Stack direction="row" alignItems="center">
                      <Box sx={{ marginRight: 2 }}>
                        {portal.PortalStatus === QuotePortalStatus.Available ? (
                          <DoneIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </Box>
                      <Typography variant="body1">
                        {renderPortalName(portal)}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell width={180}>
                    {portal.PortalStatus === QuotePortalStatus.Available ? (
                      <Typography variant="body2">Active</Typography>
                    ) : (
                      <Typography variant="body2" color="error">
                        Not available
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Drawer>
  );
}
