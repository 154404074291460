import React, { useEffect } from 'react';
import { LoaderContainer, SearchFilters, TogglePanel } from '../../components';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { SearchDataGrid } from '../../features/searchDataGrid/SearchDataGrid';
import {
  filtersDataSelectors,
  filtersDataSlice,
  updateFilterRelations,
} from '../../redux/filtersData';
import { SgPagination } from '../../features/sharedPage/components/sgPagination/SgPagination';
import { FreightQuoteActionModel, SearchFiltersModel } from '../../types';
import { pageNames, sortingDirection } from '../../constants';
import {
  is,
  removeEmptyValues,
  useEachMinute,
  useLocationQuery,
} from '../../utils';
import {
  fetchSearchResult,
  freightQuotesSelectors,
  freightQuotesSlice,
} from '../../redux/freightQuotes';

export function Search(): JSX.Element {
  const dispatch = useAppDispatch();
  const { query } = useLocationQuery<{ sourceId: string }>();

  const filtersLoading = useAppSelector(filtersDataSelectors.getLoading);
  const data = useAppSelector(freightQuotesSelectors.getSearchData);
  const searchLoading = useAppSelector(freightQuotesSelectors.getSearchLoading);
  const appliedFilters = useAppSelector(
    freightQuotesSelectors.getAppliedFilters,
  );
  const total = useAppSelector(freightQuotesSelectors.getSearchTotal);

  const {
    portals = [],
    equipmentTypes = [],
    origins = [],
    destinations = [],
  } = useAppSelector(
    filtersDataSelectors.selectFiltersByPageName(pageNames.Search),
  );

  const lading = filtersLoading || searchLoading;

  useEachMinute(() => {
    dispatch(freightQuotesSlice.actions.setCurrentTime());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(freightQuotesSlice.actions.resetSearch());
      dispatch(filtersDataSlice.actions.resetFiltersByPage(pageNames.Search));
    };
  }, []);

  const onPageChanged = (skip: number, top: number) => {
    dispatch(
      fetchSearchResult({
        ...appliedFilters,
        skip,
        top,
      }),
    );
  };

  const onOrderChanged = (
    columnName?: keyof FreightQuoteActionModel,
    direction?: sortingDirection,
  ) => {
    dispatch(
      fetchSearchResult({
        ...appliedFilters,
        sortingColumn: columnName,
        sortingDirection: direction,
      }),
    );
  };

  const onFilterChanged = (filterValues?: Partial<SearchFiltersModel>) => {
    if (is.empty(filterValues)) {
      dispatch(freightQuotesSlice.actions.resetSearchFilters());
      dispatch(filtersDataSlice.actions.resetFiltersByPage(pageNames.Search));

      return;
    }

    dispatch(
      fetchSearchResult({
        ...appliedFilters,
        filters: removeEmptyValues(filterValues || {}),
      }),
    );
  };

  useEffect(() => {
    if (query.sourceId) {
      onFilterChanged({ sourceId: query.sourceId });
    }
  }, [query.sourceId]);

  return (
    <LoaderContainer loading={lading} fullPage>
      <Box>
        <Typography variant="h1" marginY={4}>
          Search
        </Typography>

        <SearchFilters
          filters={appliedFilters.filters}
          onChange={onFilterChanged}
          onUpdateFilters={(v) => {
            dispatch(
              updateFilterRelations({
                pageName: pageNames.Search,
                filter: v || {},
              }),
            );
          }}
          disabled={lading}
          equipmentTypes={equipmentTypes}
          portals={portals}
          origins={origins}
          destinations={destinations}
        />

        {total > 0 && <TogglePanel count={total}></TogglePanel>}

        <SearchDataGrid
          rows={data || []}
          filters={appliedFilters}
          loading={lading}
          onOrderChanged={onOrderChanged}
        />

        {total > 0 && (
          <SgPagination
            onChange={onPageChanged}
            total={total}
            skip={appliedFilters?.skip}
            top={appliedFilters?.top}
          />
        )}
      </Box>
    </LoaderContainer>
  );
}
