import { TabName } from './tabs';

export enum QuoteStatus {
  Open = 'Open',
  Won = 'Won',
  Lost = 'Lost',
  SubmitRequested = 'SubmitRequested',
  SubmitRequestFailed = 'SubmitRequestFailed',
  Submitted = 'Submitted',
  Missed = 'Missed',
  Ignored = 'Ignored',
}

export enum QuoteStatusIndex {
  Open = 1,
  Submitted,
  Won,
  Lost,
  Ignored,
  Missed,
  SubmitRequested,
  SubmitRequestFailed,
}

export const quoteStatusToTabName: Record<QuoteStatus, TabName> = {
  [QuoteStatus.Open]: TabName.Open,
  [QuoteStatus.Submitted]: TabName.Submitted,
  [QuoteStatus.SubmitRequested]: TabName.Submitted,
  [QuoteStatus.SubmitRequestFailed]: TabName.Submitted,
  [QuoteStatus.Won]: TabName.Won,
  [QuoteStatus.Lost]: TabName.Lost,
  [QuoteStatus.Ignored]: TabName.Ignored,
  [QuoteStatus.Missed]: TabName.Missed,
};

export enum QuotePortalStatus {
  Available = 'Available',
  NotAvailable = 'NotAvailable',
}

export enum SubmitQuoteStatus {
  Success = 'Success',
  Error = 'Error',
}
