import { PODExtendedModel, RulesFiltersModel } from '../../types';
import { separators } from '../../constants';
import { podNameFormatter, removeEmptyValues } from '../../utils';

export interface PodsQueryProps {
  originPOD: string;
  destinationPOD: string;
}

// query?: string - can be an array of POD names separated by ';'
function selectPods(
  pods: PODExtendedModel[],
  query?: string,
): PODExtendedModel[] | undefined {
  if (!query) {
    return;
  }

  const queryPods = query.split(separators.semicolon);

  return pods.filter((pod) => queryPods.includes(pod.DatId));
}

export function selectPodsByQuery(
  pods: PODExtendedModel[],
  query: Partial<PodsQueryProps>,
): Partial<RulesFiltersModel> {
  return removeEmptyValues({
    originPOD: selectPods(pods, query.originPOD),
    destinationPOD: selectPods(pods, query.destinationPOD),
  });
}
