import { PropsWithChildren } from 'react';
import { Box, useTheme } from '@mui/material';
import { UIGridContext, uiGridPaddings } from './UIGrid.const';
import { BoxProps } from '@mui/material/Box/Box';

interface Props {
  colSpan?: number;
}

export function UICell({
  children,
  colSpan,
  ...props
}: PropsWithChildren<Props & BoxProps>): JSX.Element {
  const { spacing } = useTheme();

  return (
    <UIGridContext.Consumer>
      {(size = 'm') => {
        const margin = uiGridPaddings[size];
        const styles = {
          width: colSpan
            ? `calc(${(100 / 12) * colSpan}% - ${spacing(margin)})`
            : 'auto',
          marginRight: margin / 2,
          marginLeft: margin / 2,
          ...(props.sx || {}),
        };

        return (
          <Box {...props} className="uiCell" sx={styles}>
            {children}
          </Box>
        );
      }}
    </UIGridContext.Consumer>
  );
}
