/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  IconButton,
  MenuItem,
  Menu,
  Divider,
  Avatar,
  Typography,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useSignOutButtonStyles } from './SignOutButton.styles';
import { UserBaseModel } from '../../../../types';
import { msalServiceInstance } from '../../../../services/msal-service';
import { zIndexes } from '../../../../theme';

interface Props {
  user: UserBaseModel | null;
}

export const SignOutButton = ({ user }: Props) => {
  const styles = useSignOutButtonStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogoutAccount = () => {
    setAnchorEl(null);

    msalServiceInstance.logout();
  };

  const getUserAbbr = () => {
    if (!user) {
      return '';
    }

    const { FirstName, LastName } = user;

    return `${FirstName?.[0] || ''}${LastName?.[0] || ''}`;
  };
  const userName =
    user && `${user.FirstName || ''} ${user.LastName || ''}`.trim();

  return (
    <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
        sx={{ ml: 3 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {user ? (
          <Avatar sx={{ width: 32, height: 32 }}>{getUserAbbr()}</Avatar>
        ) : (
          <PersonIcon color="inherit" />
        )}

        <KeyboardArrowDownIcon fontSize="small" />
      </IconButton>
      <Menu
        css={styles}
        sx={{ zIndex: zIndexes.HEADER_POPPER }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {user && [
          <Typography key={1} sx={{ padding: '6px 16px 14px' }}>
            {userName}
          </Typography>,
          <Divider key={2} />,
        ]}
        <MenuItem onClick={handleLogoutAccount}>Logout</MenuItem>
      </Menu>
    </div>
  );
};
