import { css, SerializedStyles } from '@emotion/react';

export const useAccordionGroupStyles = (): SerializedStyles => {
  return css`
    .MuiButtonBase-root {
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    .MuiAccordionDetails-root {
      padding: 4px 0 8px 16px;
    }
  `;
};
