import { LOCALHOST, QuotePortalStatus, ServerStatusCode } from '../constants';
import { DateRangeFilter, FreightQuoteModel, RangeFilter } from '../types';
import { ServerValidationErrorResponse } from '../types/server-response';

export const is = {
  localhost: window.location.hostname.includes(LOCALHOST),
  validDate: (value: unknown): value is Date =>
    value instanceof Date && value.toString() !== 'Invalid Date',
  null: (value: unknown): value is null => value === null,
  undefined: (value: unknown): value is undefined =>
    typeof value === 'undefined' || value === undefined,
  nullOrUndefined: (value: unknown): value is null | undefined =>
    is.null(value) || is.undefined(value),
  truthy: (value: unknown): value is true => !!value,
  falsy: (value: unknown): value is false => !value,
  false: (value: unknown): value is false => value === false,
  string: (value: unknown): value is string => typeof value === 'string',
  number: (value: unknown): value is number => typeof value === 'number',
  boolean: (value: unknown): value is boolean => typeof value === 'boolean',
  date: (value: unknown): value is Date => value instanceof Date,
  object: (value: unknown): value is Record<string, unknown> =>
    typeof value === 'object',
  array: <T>(value: unknown): value is T[] => Array.isArray(value),
  empty: (value: unknown): boolean => {
    if (is.nullOrUndefined(value)) {
      return true;
    }

    if (Array.isArray(value) || is.string(value)) {
      return value.length === 0;
    }

    if (is.date(value)) {
      return !isValidDate(value);
    }

    return is.object(value) && Object.keys(value).length === 0;
  },
  quotePortalNotAvailable: (quote?: FreightQuoteModel | null): boolean =>
    !!(quote && quote.Portal?.PortalStatus === QuotePortalStatus.NotAvailable),
  rangeFilter: (filter: unknown): filter is RangeFilter =>
    is.object(filter) && ('from' in filter || 'to' in filter),
  dateRangeFilter: (filter: unknown): filter is DateRangeFilter =>
    is.object(filter) && ('fromDate' in filter || 'toDate' in filter),

  serverValidationError: (
    response: unknown,
  ): response is ServerValidationErrorResponse => {
    return (
      is.object(response) &&
      response.status === ServerStatusCode.BAD_REQUEST &&
      Array.isArray(response.data) &&
      response.data[0] &&
      'Message' in response.data[0]
    );
  },
};

export function isValidDate(date: Date): boolean {
  return String(date) !== 'Invalid Date';
}

export function isInRange(
  value: number,
  from?: number | string,
  to?: number | string,
): boolean {
  const fromN = from ? Number(from) : undefined;
  const toN = to ? Number(to) : undefined;

  if (is.number(fromN) && is.number(toN)) {
    return value >= fromN && value <= toN;
  }

  if (is.number(fromN) || is.number(toN)) {
    return is.number(fromN) ? value >= fromN : value <= (toN as number);
  }

  return false;
}
