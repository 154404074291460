import { FreightQuoteActionModel } from '../../types';
import { DataGridRow } from './components/dataGridRow/DataGridRow';
import { sortingDirection } from '../../constants';
import React, { useMemo } from 'react';
import { isActionDisabled, removeEmptyValues } from '../../utils';
import { ColumnModel, SgTable } from '../../components';

export interface DataGridActions {
  selectable?: boolean;
  renderActions?: (
    item: FreightQuoteActionModel,
    disabled?: boolean,
  ) => JSX.Element | null;
  disableActions?: boolean;
  renderDetailsSubmit?: (
    item: FreightQuoteActionModel,
    disabled?: boolean,
  ) => JSX.Element | null;
  detailsSubmitColSpan?: number;
  isRenderDetailsSubmit?: (item: FreightQuoteActionModel) => boolean;
  onExpand?: (quote: FreightQuoteActionModel, open: boolean) => void;
}

export interface DataGridEvents extends DataGridActions {
  onOrderChanged?: (
    columnName?: keyof FreightQuoteActionModel,
    direction?: sortingDirection,
  ) => void;
}

export interface DataGridProps extends DataGridEvents {
  orderBy?: keyof FreightQuoteActionModel;
  orderDirection?: sortingDirection;
  columns: ColumnModel<FreightQuoteActionModel>[];
  focusedItem?: FreightQuoteActionModel;
  expandedItem?: FreightQuoteActionModel;
  data: FreightQuoteActionModel[];
  selected?: FreightQuoteActionModel[];
  onSelect?: (quotes: FreightQuoteActionModel[]) => void;
}

export function DataGrid({
  selectable = false,
  orderBy,
  orderDirection,
  columns,
  onOrderChanged,

  selected,
  onSelect = () => null,
  data,
  renderActions,
  focusedItem,
  expandedItem,
  onExpand,
  ...otherProps
}: DataGridProps): JSX.Element | null {
  const selectedItems = (selected || []).reduce<
    Record<number, FreightQuoteActionModel>
  >(
    (acum, item) => ({
      ...acum,
      [item.Id]: item,
    }),
    {},
  );

  const contentRows = useMemo(() => {
    return data.map((item) => (
      <DataGridRow
        selectable={selectable}
        selected={!!selectedItems[item.Id]}
        onSelect={(value) =>
          onSelect(
            Object.values(
              removeEmptyValues({
                ...selectedItems,
                [item.Id]: value ? item : undefined,
              }),
            ),
          )
        }
        onExpand={onExpand}
        focused={focusedItem && focusedItem.Id === item.Id}
        expanded={expandedItem && expandedItem.Id === item.Id}
        key={item.Id}
        rowData={item}
        columns={columns}
        renderActions={renderActions}
        {...otherProps}
      />
    ));
  }, [data, columns, focusedItem, selectable, selectedItems]);

  if (data.length === 0) {
    return null;
  }

  const isAnyQuoteChecked = !!Object.keys(selectedItems).length;
  const isAllQuotesChecked = data.length === Object.keys(selectedItems).length;

  const onSelectAll = (value: boolean) => {
    if (!value || isAnyQuoteChecked) {
      onSelect([]);
      return;
    }

    onSelect(data.filter((q) => !isActionDisabled(q)));
  };

  return (
    <>
      <SgTable
        expandable
        actionColumn={!!renderActions}
        indeterminate={!isAllQuotesChecked && isAnyQuoteChecked}
        checked={isAllQuotesChecked}
        onSelectAll={(e, value) => onSelectAll(value)}
        selectable={selectable}
        orderBy={orderBy}
        orderDirection={orderDirection}
        columns={columns}
        onOrderChanged={onOrderChanged}
      >
        {contentRows}
      </SgTable>
    </>
  );
}
