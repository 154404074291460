import { numberFormat, plural } from '../../../../utils';
import { Typography } from '@mui/material';
import { FreightQuoteActionModel } from '../../../../types';

export function renderLookupRatesDescription({
  StopsCount,
  Portal,
  Distance,
  lookUpRates,
}: FreightQuoteActionModel): JSX.Element | null {
  if (!lookUpRates) {
    return null;
  }

  const lookupRateDistance = (
    <>
      {lookUpRates.Distance ? numberFormat(lookUpRates.Distance) : 0}{' '}
      {plural(lookUpRates.Distance, 'mile')}
    </>
  );
  const portalDistance = (
    <>
      {Distance ? numberFormat(Distance) : 0} {plural(Distance, 'mile')}
    </>
  );

  if (StopsCount > 2) {
    if (!Distance) {
      return (
        <Typography variant="h4">
          Distance is missing in {Portal?.Name}. The rates below is for{' '}
          {lookupRateDistance} (from Origin to Destination directly)
        </Typography>
      );
    }

    return (
      <Typography variant="h4">
        The rates below is for {portalDistance}. The distance has been taken
        from {Portal?.Name}
      </Typography>
    );
  }

  if (!lookUpRates.Distance) {
    return (
      <Typography variant="h4">
        Distance is missing in DAT. {Portal?.Name} returned {portalDistance} for
        this route
      </Typography>
    );
  }

  return (
    <Typography variant="h4">
      The rates below is for {lookupRateDistance}. The distance has been taken
      from DAT
    </Typography>
  );
}
