import { FormikEntity, PlainObject } from '../types';
import { is } from './is';

const INVALID_VALUE = 'Invalid value';

export function getFormikError<T extends PlainObject>(
  formik: FormikEntity<T>,
  fieldName: string,
): string | undefined {
  const meta = formik.getFieldMeta(fieldName);

  const error =
    ((meta.touched || formik.submitCount > 0) && meta.error) || undefined;

  if (is.object(error) && Object.values(error).length) {
    const deepError = Object.values(error)[0];

    return is.string(deepError) ? deepError : INVALID_VALUE;
  }

  return error ? (is.string(error) ? error : INVALID_VALUE) : undefined;
}
