import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { filtersDataStoreKey } from './filtersData.const';
import { apiEndpoint } from '../../constants/api';
import {
  FilterRelationsApi,
  FilterRelationsResponse,
  FiltersDataModel,
  UpdateFilterRelationsArgs,
} from './filtersData.types';
import { filtersDataSelectors } from './filtersData.selectors';
import { filtersToOData } from '../../utils/oDataBuilder/oDataBuilder';
import {
  transformLocationByState,
  updateRelations,
  UsersResponseToSelectModel,
} from './filtersData.utils';
import { notificationSlice } from '../notification';
import { QUERY_IS_TOO_LONG } from '../../constants';
import { tabApiNames } from '../../constants/api/freightQuotes';
import { getShippersByPortals, removeEmptyValues } from '../../utils';
import { fetchAllNotifications } from '../notification/notification.thunks';
import { UserSelectModel } from '../../types';

export const fetchPortals = createAsyncThunk(
  `${filtersDataStoreKey}/fetchPortals`,
  async (update: boolean, store): Promise<Partial<FiltersDataModel>> => {
    try {
      const filters = filtersDataSelectors.getFilters(store.getState());

      // to avoid double loading of portals on page start
      if (update && (!filters.portals || !filters.portals.length)) {
        throw new Error();
      }

      const { data: portals } = await axios.get(apiEndpoint.portals());

      store.dispatch(fetchAllNotifications());

      if (!portals) {
        throw Error('Failed user data');
      }

      return {
        portals,
      };
    } catch (err) {
      throw new Error(err as any);
    }
  },
);

export const fetchUsers = createAsyncThunk(
  `${filtersDataStoreKey}/fetchUsers`,
  async (_: void, store): Promise<UserSelectModel[]> => {
    try {
      const { data: users } = await axios.get('/Rules/Users');

      if (!users) {
        throw Error('Failed user data');
      }

      return UsersResponseToSelectModel(users);
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return [];
    }
  },
);

export const fetchBaseFiltersData = createAsyncThunk(
  `${filtersDataStoreKey}/fetchBaseFiltersData`,
  async (_: void, store): Promise<Partial<FiltersDataModel>> => {
    try {
      const { data: equipmentTypes } = await axios.get(
        apiEndpoint.equipmentTypes(),
      );
      const { data: portals } = await axios.get(apiEndpoint.portals());

      if (!equipmentTypes || !portals) {
        throw Error('Failed user data');
      }

      return {
        equipmentTypes,
        portals,
        shippers: getShippersByPortals(portals),
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return {};
    }
  },
);

export const fetchFiltersData = createAsyncThunk(
  `${filtersDataStoreKey}/fetchFiltersData`,
  async (_: void, store): Promise<Partial<FiltersDataModel>> => {
    try {
      store.dispatch(fetchBaseFiltersData());

      const {
        data: { OriginLocations, DestinationLocations },
      } = await axios.post<FilterRelationsApi>(
        apiEndpoint.customerLocationFilterValues(''),
      );

      if (!OriginLocations || !DestinationLocations) {
        throw Error('Failed user data');
      }

      return {
        origins: transformLocationByState(OriginLocations),
        destinations: transformLocationByState(DestinationLocations),
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return {};
    }
  },
);

export const updateFilterRelations = createAsyncThunk(
  `${filtersDataStoreKey}/updateFilterRelations`,
  async (
    { pageName, pageTabName, filter }: UpdateFilterRelationsArgs,
    store,
  ): Promise<FilterRelationsResponse> => {
    try {
      const appliedFilters = filtersDataSelectors.getFilters(store.getState());
      const requestedFilters = removeEmptyValues(filter);
      const tabData =
        pageTabName && !!Object.keys(requestedFilters).length
          ? {
              Tab: tabApiNames[pageTabName],
            }
          : {};

      const oDataShippers =
        requestedFilters.shippers &&
        filtersToOData({ filters: { shippers: requestedFilters.shippers } })
          .Filter;
      const oDataOrigins =
        requestedFilters.origins &&
        filtersToOData({ filters: { origins: requestedFilters.origins } })
          .Filter;
      const oDataDestinations =
        requestedFilters.destinations &&
        filtersToOData({
          filters: { destinations: requestedFilters.destinations },
        }).Filter;
      const oDataManagers =
        requestedFilters.accountManagers &&
        filtersToOData({
          filters: { accountManagers: requestedFilters.accountManagers },
        }).Filter;

      const { data } = await axios.post<FilterRelationsApi>(
        apiEndpoint.customerLocationFilterValues(),
        removeEmptyValues({
          OriginFilter: oDataOrigins,
          DestinationFilter: oDataDestinations,
          ShipperFilter: oDataShippers,
          AccountManagerFilter: oDataManagers,
          ...tabData,
        }),
      );

      const result = updateRelations(appliedFilters, data);

      return {
        forPage: pageName,
        filters: result,
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.error(QUERY_IS_TOO_LONG));

      throw new Error(err as any);
    }
  },
);
