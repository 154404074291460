import { css } from '@emotion/react';

export const useNavigationTabCardStyles = () => {
  return css`
    text-decoration: none;

    .MuiPaper-root {
      background: #f5f6f6;
      border: none;
      cursor: pointer;
    }

    .icon-container {
      width: 52px;
      height: 52px;
      margin-right: 12px;
      justify-content: center;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 4px;
    }

    &.active .MuiPaper-root {
      background: #fae053;

      .icon-container {
        background: rgba(255, 255, 255, 0.6);
      }
    }

    &:not(.active) .MuiPaper-root:hover {
      background: #eceded;

      .icon-container {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  `;
};
