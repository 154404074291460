/** @jsxImportSource @emotion/react */
import { StopsGrid } from '../../../../components';
import { Typography, Link, Box, Tooltip } from '@mui/material';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { FreightQuoteActionModel } from '../../../../types';
import { useState } from 'react';
import { MapDialog } from '../../../mapDialog/MapDialog';
import { useStopsContentStyles } from './StopsContent.styles';

interface Props {
  quote: FreightQuoteActionModel;
  onLinkClick?: () => void;
}

export function StopsContentView({ quote, onLinkClick }: Props): JSX.Element {
  const styles = useStopsContentStyles();

  return (
    <Box css={styles}>
      <StopsGrid stops={quote.StopDetails || []} />

      <Typography variant="h4" marginTop={1} marginLeft="28px">
        <Link
          target="_blank"
          sx={{ cursor: 'pointer' }}
          onClick={() => onLinkClick && onLinkClick()}
        >
          <MapOutlinedIcon fontSize="small" /> View on map
        </Link>
      </Typography>
    </Box>
  );
}

export function StopsTooltip(quote: FreightQuoteActionModel): JSX.Element {
  const [openMapDialog, setOpenMapDialog] = useState(false);

  return (
    <>
      <Tooltip
        arrow
        placement="right"
        PopperProps={{
          className: 'customStopsTooltip',
        }}
        title={
          <div style={{ width: '420px', paddingBottom: '8px' }}>
            <StopsContentView
              quote={quote}
              onLinkClick={() => setOpenMapDialog(true)}
            />
          </div>
        }
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            padding: '6px 5px',
            borderRadius: '4px',
            display: 'inline',
          })}
        >
          {quote.StopsCount}
        </Typography>
      </Tooltip>

      {openMapDialog && (
        <MapDialog quote={quote} onClosed={() => setOpenMapDialog(false)} />
      )}
    </>
  );
}

export function StopsContent({ quote }: Props): JSX.Element {
  const [openMapDialog, setOpenMapDialog] = useState(false);

  return (
    <>
      <StopsContentView
        quote={quote}
        onLinkClick={() => setOpenMapDialog(true)}
      />

      {openMapDialog && (
        <MapDialog quote={quote} onClosed={() => setOpenMapDialog(false)} />
      )}
    </>
  );
}
