import { PlainObject } from '../types';
import { is } from './is';

export function removeEmptyValues(value: PlainObject): PlainObject {
  return Object.keys(value).reduce((acum, key): PlainObject => {
    if (is.empty(value[key])) {
      return acum;
    }

    return {
      ...acum,
      [key]: value[key],
    };
  }, <PlainObject>{});
}
