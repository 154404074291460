/** @jsxImportSource @emotion/react */
import { Box, Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FreightQuoteActionModel } from '../../../../types';
import { is } from '../../../../utils';
import { useDataGridDetailsStyles } from './DataGridDetails.styles';
import React, { SyntheticEvent, useState } from 'react';
import {
  EmptyState,
  LoaderContainer,
  SgPlainTable,
  SgTab,
  SgTabPanel,
  SgTabs,
  WarningItem,
} from '../../../../components';
import { StopsContent } from '../stopsContent/StopsContent';
import {
  DetailsContent,
  renderDetailsSourceUrl,
} from './components/detailsContent/DetailsContent';
import { LookUpRates } from '../../../submitBidDialog/components';
import {
  carrierViewColumns,
  similarQuotesColumns,
} from './DataGridDetails.const';
import { QuoteStatus } from '../../../../constants';

interface Props {
  data: FreightQuoteActionModel;
  renderDetailsSubmit?: (item: FreightQuoteActionModel) => JSX.Element | null;
}

export function DataGridDetails({ data }: Props): JSX.Element {
  const isPassedQuote =
    data.Status === QuoteStatus.Missed ||
    data.Status === QuoteStatus.Lost ||
    data.Status === QuoteStatus.Won;

  const [tabValue, setTabValue] = useState(isPassedQuote ? 4 : 0);
  const styles = useDataGridDetailsStyles();

  const alert = is.quotePortalNotAvailable(data) && (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
      borderRadius="4px"
      padding={1}
      marginBottom={2}
      sx={{ backgroundColor: 'warning.main' }}
    >
      <ErrorOutlineIcon sx={{ fontSize: '20px' }} />
      <Typography variant="body1" marginLeft={2}>
        Source not available. Please visit
      </Typography>
      <Typography variant="h4" marginLeft="4px">
        {renderDetailsSourceUrl(data.SourceURL, data.Portal?.Name)}
      </Typography>
    </Stack>
  );

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const stops = (data.StopDetails && data.StopDetails.length) || 0;

  const gridTabStyle = {
    maxHeight: '500px',
    overflow: 'auto',
    position: 'relative',
    minHeight: '160px',
  };

  const renderStopsWarning = () => {
    const warningTextStart = `Please, notice! Current Load has `;
    const warningTextEnd =
      ' DAT Postings below have only two stops. Take this into account when considering the rate for this load.';

    return (
      Boolean(data.carrierView?.length && data.StopsCount > 2) && (
        <WarningItem iconFontSize="medium" iconStyles={{ marginTop: 0 }}>
          {warningTextStart}
          <Typography
            fontWeight={600}
            sx={{ display: 'inline-block' }}
          >{`${data.StopsCount} stops.`}</Typography>
          {warningTextEnd}
        </WarningItem>
      )
    );
  };

  return (
    <Box
      sx={{
        paddingTop: 1,
        paddingBottom: 3,
        minHeight: '250px',
      }}
      css={styles}
    >
      <Box>
        {alert}

        <Box paddingBottom={2}>
          <SgTabs value={tabValue} onChange={handleTabChange}>
            {!isPassedQuote && [
              <SgTab key={0} value={0} label="DAT rate view" />,
              <SgTab key={1} value={1} label="DAT carrier view" />,
              <SgTab key={2} value={2} label="Similar won" />,
              <SgTab key={3} value={3} label="Similar lost" />,
            ]}
            <SgTab value={4} label="Stops" />
            <SgTab value={5} label="Details" />
          </SgTabs>
        </Box>

        <SgTabPanel value={tabValue} index={0}>
          <Box maxWidth="680px" position="relative">
            <LoaderContainer loading={is.undefined(data.lookUpRates)}>
              <LookUpRates quote={data} />
            </LoaderContainer>
          </Box>
        </SgTabPanel>

        <SgTabPanel value={tabValue} index={1}>
          <Box sx={gridTabStyle}>
            <LoaderContainer
              avoidContent
              loading={is.undefined(data.carrierView)}
            >
              {renderStopsWarning()}
              <SgPlainTable
                size="small"
                columns={carrierViewColumns}
                rows={data.carrierView || []}
              >
                <EmptyState
                  reason="datCarrierView"
                  message={data.carrierViewError}
                  height="170px"
                  minHeight="170px"
                />
              </SgPlainTable>
            </LoaderContainer>
          </Box>
        </SgTabPanel>

        <SgTabPanel value={tabValue} index={2}>
          <Box sx={gridTabStyle}>
            <LoaderContainer
              avoidContent
              loading={is.undefined(data.wonSimilarQuotes)}
            >
              <SgPlainTable
                size="small"
                columns={similarQuotesColumns}
                rows={data.wonSimilarQuotes || []}
              >
                <EmptyState
                  reason={stops > 2 ? 'similarWon60' : 'similarWon'}
                  height="170px"
                  minHeight="170px"
                />
              </SgPlainTable>
            </LoaderContainer>
          </Box>
        </SgTabPanel>

        <SgTabPanel value={tabValue} index={3}>
          <Box sx={gridTabStyle}>
            <LoaderContainer
              avoidContent
              loading={is.undefined(data.lostSimilarQuotes)}
            >
              <SgPlainTable
                size="small"
                columns={similarQuotesColumns}
                rows={data.lostSimilarQuotes || []}
              >
                <EmptyState
                  reason={stops > 2 ? 'similarLost60' : 'similarLost'}
                  height="170px"
                  minHeight="170px"
                />
              </SgPlainTable>
            </LoaderContainer>
          </Box>
        </SgTabPanel>

        <SgTabPanel value={tabValue} index={4}>
          <Box maxWidth="680px">
            <StopsContent quote={data} />
          </Box>
        </SgTabPanel>

        <SgTabPanel value={tabValue} index={5}>
          <Box maxWidth="1000px">
            <DetailsContent data={data} />
          </Box>
        </SgTabPanel>
      </Box>
    </Box>
  );
}
