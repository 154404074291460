import React, { MutableRefObject, useEffect, useState } from 'react';
import { zIndexes } from '../../../theme';
import {
  closeEmptyPlaceByClientY,
  fixCellsSizes,
  openEmptyPlaceByClientY,
  unfixCellsSizes,
} from './DragService.utils';
import { AutoScrollService } from './AutoScrollService';

let index = 0;
const dragElements: Record<
  string | number,
  { name: string | number; index: number }
> = {};

interface Config {
  name: string | number;
  deps?: unknown[];
  preventLastAction?: boolean;
  onIndexChanged: (newIndex: number | null) => void;
  ref: MutableRefObject<HTMLTableRowElement | null>;
}

interface ServiceResult {
  index: number;
  moving: boolean;
  dragStart: (e: React.MouseEvent<HTMLDivElement>) => void;
  clearDragAction: () => void;
}

export function useDragService({
  name,
  ref,
  deps,
  onIndexChanged,
  preventLastAction,
}: Config): ServiceResult {
  const [moving, setMoving] = useState<boolean>(false);
  const [localIndex] = useState(() => {
    index++;

    dragElements[name] = {
      name,
      index,
    };

    return index;
  });

  const clearDragAction = () => {
    if (!ref.current || !ref.current.style) {
      return;
    }

    ref.current.style.top = '';
    ref.current.style.left = '';
    ref.current.style.zIndex = '';

    setMoving(false);
    closeEmptyPlaceByClientY();
    unfixCellsSizes(ref.current);
  };

  const dragStart = (startEvent: React.MouseEvent) => {
    if (!ref.current || !ref.current.style) {
      throw new Error('mousemove: ref.current is undefined');
    }

    const { x, y } = ref.current.getBoundingClientRect();

    const shiftX = startEvent.clientX - x + 1;
    const shiftY = startEvent.clientY - y;

    let elementTop = startEvent.clientY - shiftY;
    const elementLeft = startEvent.clientX - shiftX;

    ref.current.style.top = `${elementTop}px`;
    ref.current.style.left = `${elementLeft}px`;
    ref.current.style.zIndex = String(zIndexes.SNACKBAR);

    fixCellsSizes(ref.current);
    setMoving(true);
    const startIndex = openEmptyPlaceByClientY(startEvent.clientY, true);

    let currentIndex: number | null = null;

    const autoScrollService = new AutoScrollService('pageWrapper');

    const mousemoveCb = (e: MouseEvent) => {
      if (!ref.current || !ref.current.style) {
        throw new Error('mousemove: ref.current is undefined');
      }

      const newTop = e.clientY - shiftY;
      const newLeft = e.clientX - shiftX;

      if (elementTop !== newTop) {
        elementTop = newTop;
        const newIndex = openEmptyPlaceByClientY(e.clientY);

        currentIndex = newIndex === null ? currentIndex : newIndex;
      }

      autoScrollService.setCursorY(e.clientY);

      ref.current.style.top = `${newTop}px`;
      ref.current.style.left = `${newLeft}px`;
    };
    const mouseupCb = () => {
      document.removeEventListener('mousemove', mousemoveCb);
      document.removeEventListener('mouseup', mouseupCb);

      autoScrollService.stop();

      if (
        !preventLastAction ||
        currentIndex === null ||
        startIndex === currentIndex
      ) {
        clearDragAction();
      } else if (ref.current) {
        ref.current.style.zIndex = String(zIndexes.DRAG);
      }

      if (startIndex !== currentIndex && currentIndex !== null) {
        onIndexChanged(currentIndex);
      }
    };

    document.addEventListener('mousemove', mousemoveCb);
    document.addEventListener('mouseup', mouseupCb);
  };

  useEffect(() => {
    clearDragAction();
  }, [...(deps || [])]);

  return {
    index: localIndex,
    moving,
    dragStart,
    clearDragAction,
  };
}

/*

let scrollDistance = 1000;
let scrollStep = 100;
let target = $0;

function customScroll() {
    target.scrollBy(0, scrollDistance < scrollStep ? scrollDistance : scrollStep);

    scrollDistance = scrollDistance - scrollStep;

    if (scrollDistance > 0) {
        window.requestAnimationFrame(customScroll);
    }
}

window.requestAnimationFrame(customScroll);


 */
