import { SerializedStyles, css } from '@emotion/react';

export const useDialogWrapperStyles = (width: number): SerializedStyles => {
  return css`
    .MuiDialog-paper {
      width: 100%;
      max-width: ${width}px;
    }
  `;
};
