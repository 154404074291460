import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  DialogWrapper,
  DialogWrapperBaseProps,
} from '../../../../components/dialogWrapper/DialogWrapper';
import { PodCoverageItem, PodLaneItem } from '../../../../types';
import { podNameMktFormatter } from '../../../../utils';
import { SgCustomLink, SgTableHeaderCell } from '../../../../components';
import { routes, sortingDirection } from '../../../../constants';
import { useMemo, useState } from 'react';
import { orderLanesByField, PodLanesOrderBy } from './PodLanesDialog.utils';
import { useNavigate } from 'react-router-dom';

interface Props extends DialogWrapperBaseProps {
  pod: PodCoverageItem;
  name: keyof Pick<
    PodCoverageItem,
    'DatPodLanesAsOrigin' | 'DatPodLanesAsDestination'
  >;
}

export function PodLanesDialog({
  pod,
  name,
  ...dialogProps
}: Props): JSX.Element {
  const nav = useNavigate();

  const titleAs = name === 'DatPodLanesAsOrigin' ? 'Origin' : 'Destination';
  const columnTitle = name === 'DatPodLanesAsOrigin' ? 'Destination' : 'Origin';
  const direction = name === 'DatPodLanesAsOrigin' ? 'from' : 'to';

  const routeKey1 =
    name === 'DatPodLanesAsOrigin' ? 'originPOD' : 'destinationPOD';
  const routeKey2 =
    name === 'DatPodLanesAsOrigin' ? 'destinationPOD' : 'originPOD';

  const emptyStateCreateRule = `${routes.CreateRule}?${routeKey1}=${pod.DatPod.DatId}`;

  const [order, setOrder] = useState<PodLanesOrderBy>({
    orderBy: 'DatPod',
    orderDirection: sortingDirection.Asc,
  });

  const orderedLanes = useMemo(() => {
    return orderLanesByField(pod[name].DatPodLanes, order);
  }, [order]);

  const getLink = (route: string, lane: PodLaneItem): string => {
    return `${route}?${routeKey1}=${pod.DatPod.DatId}&${routeKey2}=${lane.DatPod.DatId}`;
  };

  const onOrderChanged = (
    orderBy?: keyof PodLaneItem,
    orderDirection?: sortingDirection,
  ) => {
    setOrder({
      orderBy: orderBy || order.orderBy,
      orderDirection: orderDirection || sortingDirection.Asc,
    });
  };

  const renderDialogContent = () => (
    <>
      <Stack direction="row" marginBottom={1}>
        <Typography variant="h5" marginRight={0.5}>
          {titleAs}:
        </Typography>
        <Typography variant="h4">{podNameMktFormatter(pod.DatPod)}</Typography>
      </Stack>

      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <SgTableHeaderCell
              name="DatPod"
              orderBy={order.orderBy}
              orderDirection={order.orderDirection}
              onOrderChanged={onOrderChanged}
            >
              {columnTitle}
            </SgTableHeaderCell>
            <SgTableHeaderCell
              name="LaneRulesCount"
              align="center"
              orderBy={order.orderBy}
              orderDirection={order.orderDirection}
              onOrderChanged={onOrderChanged}
            >
              Rules
            </SgTableHeaderCell>

            <TableCell variant="head" />
          </TableRow>
        </TableHead>
        <TableBody>
          {orderedLanes.map((lane) => (
            <TableRow key={lane.DatPod.DatId}>
              <TableCell>
                <Typography variant="h5" sx={{ whiteSpace: 'nowrap' }}>
                  {podNameMktFormatter(lane.DatPod)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {lane.LaneRulesCount ? (
                  <SgCustomLink
                    target="_blank"
                    to={getLink(routes.Rules, lane)}
                  >
                    {lane.LaneRulesCount}
                  </SgCustomLink>
                ) : (
                  lane.LaneRulesCount
                )}
              </TableCell>
              <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                <SgCustomLink to={getLink(routes.CreateRule, lane)}>
                  <Button size="small" startIcon={<AddIcon fontSize="small" />}>
                    Add rule
                  </Button>
                </SgCustomLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );

  const content = orderedLanes.length ? (
    renderDialogContent()
  ) : (
    <Typography variant="h5">
      There are no rules that are covering any Lane {direction}{' '}
      {podNameMktFormatter(pod.DatPod)}
    </Typography>
  );

  const additionalDialogProps: Partial<DialogWrapperBaseProps> =
    orderedLanes.length
      ? {}
      : {
          width: 416,
          submitLabel: 'Create rule',
          preventSubmit: true,
          onSubmit: () => nav(emptyStateCreateRule),
        };

  return (
    <DialogWrapper
      {...dialogProps}
      {...additionalDialogProps}
      title={
        orderedLanes.length
          ? `Lanes (${
              name === 'DatPodLanesAsOrigin'
                ? pod.DatPodLanesAsOrigin.LanePodsCount
                : pod.DatPodLanesAsDestination.LanePodsCount
            })`
          : 'Lanes'
      }
    >
      {content}
    </DialogWrapper>
  );
}
