import { useRef, useEffect, useState, useMemo } from 'react';
import {
  DialogWrapper,
  DialogWrapperBaseProps,
} from '../../components/dialogWrapper/DialogWrapper';
import { FreightQuoteActionModel } from '../../types';
import { StopsGrid, EmptyElement, WarningItem } from '../../components';
import { Box, Skeleton, Typography, Stack } from '@mui/material';
import { initializeAzureMap } from './MapDialog.utils';
import { is, numberFormat } from '../../utils';
import { SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE_ON_MAP } from '../../constants';

interface Props extends DialogWrapperBaseProps {
  quote: FreightQuoteActionModel;
}

export function MapDialog({ quote, onClosed }: Props): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<null>();
  const [miDistance, setMiDistance] = useState<number | null | undefined>();

  useEffect(() => {
    if (ref.current && map) {
      return;
    }

    if (!ref.current) {
      setMap(null);

      return;
    }

    initializeAzureMap(quote, 'bingMap', (distance) => {
      setMiDistance(distance);
    });
  }, [ref, map]);

  const distanceLabel = useMemo(() => {
    if (is.undefined(miDistance)) {
      return (
        <Skeleton sx={{ height: 24 }} animation="wave" variant="rectangular" />
      );
    }

    const distance =
      miDistance === null
        ? EmptyElement
        : `${numberFormat(miDistance, {
            fractionDigits: 0,
          })} miles`;

    return (
      <Stack direction="row">
        <Typography variant="subtitle2" fontWeight={600}>
          Total distance:
        </Typography>
        <Typography marginLeft={0.5} variant="subtitle2">
          {distance}
        </Typography>
      </Stack>
    );
  }, [miDistance]);

  return (
    <DialogWrapper width={800} onClosed={onClosed} title="Route">
      <WarningItem>
        {!quote.HasZipCodesFromPortal &&
          SOURCE_PORTAL_DID_NOT_RETURN_THE_ZIP_CODE_ON_MAP}
      </WarningItem>

      <div ref={ref} style={{ height: '400px' }} id="bingMap" />

      <Box marginTop={1}>{distanceLabel}</Box>

      <Box marginTop={1}>
        <StopsGrid stops={quote.StopDetails || []} />
      </Box>
    </DialogWrapper>
  );
}
