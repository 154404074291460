import { FreightQuoteActionModel, FreightQuoteModel } from '../types';

export function mergeQuotesData(
  newQuotes: FreightQuoteModel[],
  oldQuotes?: FreightQuoteActionModel[],
): FreightQuoteActionModel[] {
  return newQuotes.map((quote) => {
    if (!oldQuotes || !oldQuotes.length) {
      return quote;
    }

    const oldQuote = oldQuotes.find((q) => q.Id === quote.Id);

    if (!oldQuote) {
      return quote;
    }

    const {
      disabled,
      submitted,
      lookUpRates,
      carrierView,
      carrierViewError,
      wonSimilarQuotes,
      lostSimilarQuotes,
    } = oldQuote;

    return {
      ...quote,

      disabled,
      submitted,
      lookUpRates,
      carrierView,
      carrierViewError,
      wonSimilarQuotes,
      lostSimilarQuotes,
    };
  });
}
