import { FreightQuoteActionModel } from '../types';
import { QuotePortalStatus, QuoteStatus, TabName } from '../constants';
import { is } from './is';

export function isQuotePortalNotAvailable(
  quote: FreightQuoteActionModel,
): boolean {
  return quote?.Portal?.PortalStatus !== QuotePortalStatus.Available;
}

export function isQuoteCanNotBeSubmitted(
  quote: FreightQuoteActionModel,
): boolean {
  return (
    is.false(quote.CanBeSubmitted) ||
    (!!quote.respondByTimer && !quote.respondByTimer.minutesLeft)
  );
}

export function isActionDisabled(quote: FreightQuoteActionModel): boolean {
  return (
    isQuotePortalNotAvailable(quote) ||
    isQuoteCanNotBeSubmitted(quote) ||
    quote.disabled ||
    quote.Status === QuoteStatus.SubmitRequested ||
    quote.Status === QuoteStatus.Won ||
    quote.Status === QuoteStatus.Lost ||
    quote.Status === QuoteStatus.Missed
  );
}

export function isDisabledToSubmit(
  tabName: TabName,
  quote: FreightQuoteActionModel,
) {
  return (
    tabName === TabName.Open &&
    quote.Status !== QuoteStatus.Open &&
    quote.Status !== QuoteStatus.Ignored &&
    quote.Status !== QuoteStatus.SubmitRequestFailed
  );
}
