import { PublicClientApplication } from '@azure/msal-browser/dist/app/PublicClientApplication';
import { msalInstance } from '../index';

class MSALService {
  private instance?: PublicClientApplication;

  setInstance(instance: PublicClientApplication) {
    this.instance = instance;
  }

  logout() {
    if (!this.instance) {
      return;
    }

    this.instance.logoutRedirect({
      account: msalInstance.getActiveAccount(),
    });
  }
}

export const msalServiceInstance = new MSALService();
