import { ColumnModel, EmptyElement } from '../../components';
import {
  renderActions,
  renderScheduleCondition,
  renderScheduleDelay,
} from './SchedulingDataGrid.utils';
import {
  ScheduleFormValue,
  ScheduleGridItem,
} from './SchedulingDataGrid.types';
import { ExtendedRuleEnum } from '../../types';

const MIN_WIDTH = '90px';

const style = {
  minWidth: MIN_WIDTH,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const getSchedulingDataGridColumns = (
  actionEnums: ExtendedRuleEnum[],
  conditionEnums: ExtendedRuleEnum[],
  onValueChange: (id: number, value: Partial<ScheduleFormValue>) => void,
  onEdit: (v: ScheduleGridItem) => void,
  onCancel: (v: ScheduleGridItem) => void,
  onSubmit: (v: ScheduleGridItem) => void,
): ColumnModel<ScheduleGridItem>[] => [
  {
    name: 'Shipper',
    label: 'Name',
    useTooltip: true,

    styles: { ...style },
    headerStyles: { ...style },
    formatter: (data) => data.Shipper?.Name,
  },
  {
    name: 'Action',
    label: 'Action',

    styles: { ...style },
    headerStyles: { ...style },
    formatter: (data) =>
      actionEnums[data.Action]
        ? actionEnums[data.Action].Description
        : EmptyElement,
  },
  {
    name: 'Condition',
    label: 'Condition',

    styles: { ...style, maxWidth: '330px', width: '330px' },
    headerStyles: { ...style },
    formatter: (data) =>
      renderScheduleCondition(data, conditionEnums, onValueChange),
  },
  {
    name: 'Delay',
    label: 'Delay (min)',
    align: 'right',

    styles: { ...style },
    headerStyles: { ...style },
    formatter: (data) => renderScheduleDelay(data, onValueChange),
  },
  {
    name: 'action',
    label: '',
    align: 'right',

    styles: { ...style, maxWidth: '150px' },
    headerStyles: { ...style, maxWidth: '150px' },
    formatter: (data) => renderActions(data, onEdit, onCancel, onSubmit),
  },
];
