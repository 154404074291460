import { TimerCell } from '../sharedPage/components/timerCell/TimerCell';
import {
  priceFormat,
  quoteDistanceFormatter,
  rowLocationFormatter,
  rowSageBid,
} from '../../utils';
import { StopsTooltip } from '../dataGrid/components';
import { ColumnModel, EmptyElement } from '../../components';
import { StatusLabel } from './components/statusLabel/StatusLabel';
import { FreightQuoteActionModel } from '../../types';

export const searchGridColumns: ColumnModel<FreightQuoteActionModel>[] = [
  {
    name: 'SourceId',
    label: 'Source ID',
  },
  {
    name: 'Shipper',
    label: 'Customer',
  },
  {
    name: 'OriginLocation',
    label: 'Origin',
    formatter: rowLocationFormatter,
  },
  {
    name: 'DestinationLocation',
    label: 'Destination',
    formatter: rowLocationFormatter,
  },
  {
    name: 'RespondBy',
    label: 'Respond by',
    styles: { whiteSpace: 'nowrap' },
    formatter: (quote) => TimerCell({ quote }),
  },
  {
    name: 'Distance',
    label: 'Distance (mi)',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: quoteDistanceFormatter,
  },
  {
    name: 'StopsCount',
    label: 'Stops',
    align: 'right',
    formatter: StopsTooltip,
  },
  {
    name: 'LowestBid',
    label: 'Lowest bid',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => priceFormat(data.LowestBid),
  },
  {
    name: 'SageBid',
    label: 'Sage bid',
    align: 'right',
    formatter: (item) => rowSageBid(item, true),
  },
  {
    name: 'Id',
    label: 'User',
    formatter: (quote) => (quote.User && quote.User.Email) || EmptyElement,
  },
  {
    name: 'Status',
    label: 'Status',
    action: true,
    formatter: (quote) => <StatusLabel quote={quote} />,
  },
];
