/** @jsxImportSource @emotion/react */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  LowerBidMessageModel,
  NotificationExtendedModel,
  SubmitFailedMessageModel,
} from '../../../../types';
import {
  notificationReason,
  NotificationStatus,
  NotificationType,
  SubmitFailedType,
} from '../../../../constants';
import { cn, priceFormat, timeAgo } from '../../../../utils';
import { useNotificationCardStyles } from './NotificationCard.styles';
import { PropsWithChildren } from 'react';
import { SgLink } from '../../../../components';

interface Props {
  notification: NotificationExtendedModel;
  onOpen: (expand: boolean) => void;
  onLinkClick: () => void;
}

function isNotificationSubmitFailed(
  type: NotificationType,
  message: SubmitFailedMessageModel | LowerBidMessageModel,
): message is SubmitFailedMessageModel {
  return (
    type === NotificationType.SubmitFailed && 'SubmitFailedType' in message
  );
}

function getTitle({ Type, Message }: NotificationExtendedModel): string {
  if (isNotificationSubmitFailed(Type, Message)) {
    if (Message.SubmitFailedType === SubmitFailedType.Withdrawn) {
      return `${Message.Shipper}: failed to submit a Bid. Freight Quote was withdrawn or expired`;
    }

    return `${Message.Shipper}: Bid was not submitted`;
  }

  if (Type === NotificationType.LowerBidReceived) {
    return "Lower competitor's Bid";
  }

  return 'Unknown';
}

interface ItemProps {
  title: string;
  message?: string;
}

function DescriptionItem({
  title,
  message,
  children,
}: PropsWithChildren<ItemProps>): JSX.Element {
  return (
    <Stack direction="row">
      <Typography
        variant="subtitle2"
        color="secondary"
        className="descriptionItemTitle"
      >
        • {title}
      </Typography>
      <Typography className="descriptionItemValue" variant="subtitle2">
        {children || String(message)}
      </Typography>
    </Stack>
  );
}

function DescriptionBody({
  title,
  children,
}: PropsWithChildren<ItemProps>): JSX.Element {
  return (
    <Box>
      <Typography variant="subtitle2" color="secondary" fontWeight={600}>
        {title}
      </Typography>

      {children}
    </Box>
  );
}

export function NotificationCard({ notification, onOpen, onLinkClick }: Props) {
  const styles = useNotificationCardStyles();

  const isUnread =
    notification.Status === NotificationStatus.Unread && !notification.opened;

  const renderClickHere = (sourceId: string, text: string) => (
    <Typography marginTop={1} variant="subtitle2">
      Click{' '}
      <SgLink
        onClick={onLinkClick}
        useNavigation
        href={`/search?sourceId=${sourceId}`}
      >
        here
      </SgLink>{' '}
      to {text}
    </Typography>
  );

  const renderLowerBid = (message: LowerBidMessageModel) => (
    <DescriptionBody title={notificationReason.lowerBid}>
      <Box>
        <DescriptionItem title="Source ID" message={message.SourceId} />
        <DescriptionItem title="Customer" message={message.Shipper} />
        <DescriptionItem title="Origin" message={message.Origin} />
        <DescriptionItem title="Destination" message={message.Destination} />
        <DescriptionItem
          title="Equipment Type"
          message={message.EquipmentType}
        />
        <DescriptionItem
          title="Sage Bid"
          message={priceFormat(message.SageBid, true, '-')}
        />
        <DescriptionItem
          title="Competitor's Bid"
          message={priceFormat(message.CompetitorsBid, true, '-')}
        />

        {renderClickHere(message.SourceId, 'give a better price')}
      </Box>
    </DescriptionBody>
  );

  const renderSubmitFailed = (message: SubmitFailedMessageModel) => (
    <DescriptionBody
      title={notificationReason.reasonBuType[message.SubmitFailedType]}
    >
      <Box>
        <Typography variant="subtitle2" color="secondary" fontWeight={600}>
          Bid details:
        </Typography>

        {message.SubmitFailedType !== SubmitFailedType.MaxBidExceeded && (
          <DescriptionItem title="URL">
            <SgLink fontSize={12} href={message.Url} target="_blank">
              {message.Url}
            </SgLink>
          </DescriptionItem>
        )}
        <DescriptionItem title="Customer" message={message.Shipper} />
        <DescriptionItem title="Freight quote id" message={message.SourceId} />
        <DescriptionItem
          title="Bid amount"
          message={priceFormat(message.SageBid, true, '-')}
        />

        {message.SubmitFailedType === SubmitFailedType.Common && (
          <Typography marginTop={1} variant="subtitle2">
            Please try to submit the bid on the portal directly.
          </Typography>
        )}

        {message.SubmitFailedType === SubmitFailedType.MaxBidExceeded &&
          renderClickHere(message.SourceId, 're-submit')}
      </Box>
    </DescriptionBody>
  );

  return (
    <Box css={styles}>
      <Accordion
        disableGutters
        className="noFloat divider"
        onChange={(e, v) => onOpen(v)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            className={cn(
              'notificationHeader',
              isUnread && 'notificationUnread',
            )}
          >
            <Typography
              variant="h4"
              fontWeight={isUnread ? 600 : 400}
              marginBottom={0.5}
            >
              {getTitle(notification)}
            </Typography>
            <Typography variant="subtitle2" color="secondary">
              {timeAgo(notification.CreateDateTimeUtc)}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isNotificationSubmitFailed(notification.Type, notification.Message)
            ? renderSubmitFailed(notification.Message)
            : renderLowerBid(notification.Message)}

          {notification.Message.Details && (
            <Typography marginTop={1} variant="subtitle2">
              {notification.Message.Details}
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
