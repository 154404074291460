import { PlainObject } from '../../types';
import { useState } from 'react';
import {
  ScheduleFormErrors,
  ScheduleFormItem,
  ScheduleFormValue,
} from './SchedulingDataGrid.types';
import { ScheduleItem } from '../../types/scheduleItem';
import { is, removeEmptyValues } from '../../utils';
import { getScheduleFormSchema } from './Scheduling.schema';

interface ScheduleForm {
  values: PlainObject<ScheduleFormItem>;
  onValueChange: (id: number, value: Partial<ScheduleFormValue>) => void;
  validateBeforeSubmit: (id: number) => null | ScheduleFormErrors;
  onEdit: (item: ScheduleItem) => void;
  onCancel: (item: ScheduleItem) => void;
}

function getScheduleItemById(
  id: number,
  items: ScheduleItem[],
): ScheduleItem | undefined {
  return items.find(({ Id }) => Id === id);
}

function validateForm(
  schedulesData: ScheduleItem[],
  itemId: number,
  formValues: ScheduleFormValue,
): null | ScheduleFormErrors {
  const scheduleItem = getScheduleItemById(itemId, schedulesData);

  if (!scheduleItem) {
    return {
      delay: 'This customer does not support scheduling',
    };
  }

  try {
    getScheduleFormSchema(scheduleItem, formValues.condition).validateSync(
      formValues,
      {
        abortEarly: false,
      },
    );
  } catch (err) {
    const errors = (err.inner || []).reduce(
      (acum: ScheduleFormErrors, error: PlainObject) => ({
        ...acum,
        [error.path]: error.message,
      }),
      {},
    );

    if (Object.keys(errors).length) {
      return errors;
    }
  }

  return null;
}

export function useScheduleForm(schedulesData: ScheduleItem[]): ScheduleForm {
  const [values, setValues] = useState<PlainObject<ScheduleFormItem>>({});

  const onValueChange = (id: number, value: Partial<ScheduleFormValue>) => {
    const newValue: ScheduleFormItem = {
      ...(values[id] || {}),
      ...value,
    };

    if (values[id] && values[id].submitted) {
      const { condition, delay } = newValue;

      const errors = validateForm(schedulesData, id, { condition, delay });

      setValues({
        ...values,
        [id]: {
          ...newValue,
          errors: errors || undefined,
        },
      });

      return;
    }

    setValues({
      ...values,
      [id]: newValue,
    });
  };
  const validateBeforeSubmit = (id: number): null | ScheduleFormErrors => {
    const { condition, delay } = values[id] || {};

    const errors = validateForm(schedulesData, id, { condition, delay });

    setValues({
      ...values,
      [id]: {
        ...(values[id] || {}),
        errors: errors || undefined,
        submitted: true,
      },
    });

    return errors;
  };
  const onEdit = (item: ScheduleItem) => {
    setValues({
      ...values,
      [item.Id]: {
        condition: is.nullOrUndefined(item.Condition)
          ? undefined
          : item.Condition,
        delay: is.nullOrUndefined(item.Delay) ? undefined : item.Delay,
      },
    });
  };
  const onCancel = (item: ScheduleItem) => {
    setValues(
      removeEmptyValues({
        ...values,
        [item.Id]: {},
      }),
    );
  };

  return {
    values,
    onValueChange,
    validateBeforeSubmit,
    onEdit,
    onCancel,
  };
}
