import { NavigationClient, NavigationOptions } from '@azure/msal-browser';

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
  constructor() {
    super();
  }

  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    if (options.noHistory) {
      location.replace(relativePath);
    } else {
      location.href = relativePath;
    }

    return false;
  }
}
