import { DialogWrapper } from '../../../../components/dialogWrapper/DialogWrapper';
import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  errorType: 'bid' | 'ignore';
  onClosed: () => void;
}

export function BulkBidError({ onClosed, errorType }: Props): JSX.Element {
  return (
    <DialogWrapper
      width={415}
      title={`Bulk ${errorType === 'bid' ? 'bid' : 'ignore'} error`}
      closeLabel="Ok, got it"
      closeVariant="contained"
      onClosed={onClosed}
    >
      <Typography>
        Selected loads are different. Freight Quotes for a Bulk{' '}
        {errorType === 'bid' ? 'bid' : 'ignore'} must have the same:
      </Typography>

      <ul style={{ marginTop: 0, paddingLeft: '20px' }}>
        <li>Customer</li>
        <li>Equipment Type</li>
        <li>Number of stops</li>
      </ul>

      <Typography>Please, check and re-submit.</Typography>
    </DialogWrapper>
  );
}
