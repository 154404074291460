import Container from '@mui/material/Container';
import { EmptyState } from '../../components';
import { Grid } from '@mui/material';
import * as React from 'react';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import { freightQuotesSelectors } from '../../redux/freightQuotes';

interface Props {
  bgcolor?: string;
  paddingTop?: string;
  paddingBottom?: string | number;
}

export function PageWrapper({
  bgcolor = 'common.white',
  paddingTop = '68px',
  paddingBottom = 4,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  const container = useRef<HTMLDivElement>(null);

  const permissionDenied = useAppSelector(
    userDataSelectors.getUserPermissionDenied,
  );
  const filters = useAppSelector(freightQuotesSelectors.getFilters);

  useEffect(() => {
    if (!container || !container.current) {
      return;
    }

    container.current.scrollTo({ top: 0 });
  }, [filters?.skip, filters?.top]);

  return (
    <Grid
      id="pageWrapper"
      ref={container}
      container
      justifyContent="center"
      sx={{
        bgcolor,
        mt: paddingTop,
        minWidth: '1380px',
        height: `calc(100vh - ${paddingTop})`,
        overflow: 'auto',
      }}
    >
      <Container
        maxWidth="xl"
        component="main"
        sx={{
          bgcolor,
          pb: paddingBottom,
          minHeight: `calc(100vh - ${paddingTop})`,
          position: 'relative',
          overflow: 'initial',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {permissionDenied ? (
          <EmptyState fullHeight reason="access" />
        ) : (
          children
        )}
      </Container>
    </Grid>
  );
}
