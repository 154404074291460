import { UICell, UIRow } from '../../../../components/uiGrid';
import {
  FormikAutocomplete,
  FormikPlainAutocomplete,
  RangeInput,
  TimeRangeInput,
  SgSelect,
  SgTextField,
} from '../../../../components';
import {
  availableOperatorsForAttributes,
  ExpressionAttribute,
  ExpressionOperator,
} from '../../RuleForm.const';
import React, { useEffect, useMemo } from 'react';
import { RuleExpressionsFormProps } from '../ruleExpressionsForm/RuleExpressionsForm';
import {
  PODApiModel,
  QuoteBaseEntityTypeUIExtended,
  RangeFilter,
  SelectItemModel,
} from '../../../../types';
import { CreateRuleFormModel } from '../../RuleForm.types';
import { getFormikError, intFormatter, is } from '../../../../utils';

interface Props extends RuleExpressionsFormProps {
  index: number;
  pods: PODApiModel[];
  equipmentTypes: QuoteBaseEntityTypeUIExtended[];
  shippers: QuoteBaseEntityTypeUIExtended[];
  attributeOptions: SelectItemModel[];
}

export function RuleExpressionsItem({
  formik,
  expressionOperators,
  shippers,
  equipmentTypes,
  pods,
  index,
  attributeOptions,
  specialDates,
}: Props): JSX.Element {
  const attribute: string = formik.getFieldProps(
    `expressions.${index}.attribute`,
  ).value;
  const operator: ExpressionOperator = formik.getFieldProps(
    `expressions.${index}.operator`,
  ).value;

  const operatorOptions = useMemo(() => {
    if (!attribute || !availableOperatorsForAttributes[attribute]) {
      return [];
    }

    return [
      ...expressionOperators,
      {
        value: ExpressionOperator.Between,
        label: 'BETWEEN',
      },
    ].filter((expressionOperator) =>
      availableOperatorsForAttributes[attribute].includes(
        expressionOperator.value,
      ),
    );
  }, [attribute, expressionOperators]);

  useEffect(() => {
    if (!operatorOptions || operatorOptions.length !== 1) {
      return;
    }

    formik.setFieldValue(
      `expressions.${index}.operator`,
      operatorOptions[0].value,
    );
  }, [attribute, expressionOperators]);

  const renderBaseEntityAutocomplete = (
    options: QuoteBaseEntityTypeUIExtended[],
    key: string,
  ) => {
    const props = {
      key,
      label: 'Value',
      placeholder: 'Select value',
      name: `expressions.${index}.value`,
      options: options,
      formik: formik,
    };

    return attribute === ExpressionAttribute.Customer ? (
      <FormikPlainAutocomplete<
        QuoteBaseEntityTypeUIExtended,
        CreateRuleFormModel
      >
        {...props}
        getOptionLabel={(option) => (option && option.Name) || ''}
        isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
      />
    ) : (
      <FormikAutocomplete<QuoteBaseEntityTypeUIExtended, CreateRuleFormModel>
        {...props}
        getOptionLabel={(option) => (option && option.Name) || ''}
        isOptionEqualToValue={(option, value) => option?.Id === value?.Id}
      />
    );
  };

  const renderPodsAutocomplete = (options: PODApiModel[], key: string) => (
    <FormikAutocomplete<PODApiModel, CreateRuleFormModel>
      key={key}
      label="Value"
      placeholder="Select value"
      name={`expressions.${index}.value`}
      options={options}
      formik={formik}
      getOptionLabel={(option) => `${option.PodCity} Mkt`}
      isOptionEqualToValue={(option, value) => option.DatId === value.DatId}
    />
  );

  const renderNumberInput = (key: string, disabled?: boolean) => (
    <SgTextField
      key={key}
      label="Value"
      name={`expressions.${index}.value`}
      placeholder="Enter value"
      formik={formik}
      disabled={disabled}
      valueFormatter={intFormatter}
    />
  );

  const renderRangeInput = () => {
    const name = `expressions.${index}.value`;
    const rangeValue: RangeFilter | undefined =
      formik.getFieldProps(name).value;

    const fieldError =
      getFormikError(formik, `${name}.from`) ||
      getFormikError(formik, `${name}.to`) ||
      getFormikError(formik, name);

    const error: string | undefined = is.object(fieldError)
      ? ((fieldError.to || fieldError.from || 'Invalid value') as string)
      : fieldError && String(fieldError);

    return (
      <RangeInput
        label="Value"
        labelTo=""
        placeholder="Enter range"
        rangePlaceholder="Enter distance"
        value={rangeValue || {}}
        onChange={(newValue?: RangeFilter) =>
          formik.setFieldValue(name, newValue)
        }
        error={Boolean(error)}
        helperText={error}
        valueFormatter={intFormatter}
      />
    );
  };

  const renderTimeRangeInput = () => {
    const name = `expressions.${index}.value`;
    const rangeValue: RangeFilter | undefined =
      formik.getFieldProps(name).value;

    const fieldError =
      getFormikError(formik, `${name}.from`) ||
      getFormikError(formik, `${name}.to`) ||
      getFormikError(formik, name);

    const error: string | undefined = is.object(fieldError)
      ? ((fieldError.to || fieldError.from || 'Invalid value') as string)
      : fieldError && String(fieldError);

    return (
      <TimeRangeInput
        label="Value"
        labelTo=""
        placeholder="Enter time range"
        value={rangeValue || {}}
        onChange={(newValue?: RangeFilter) =>
          formik.setFieldValue(name, newValue)
        }
        error={Boolean(error)}
        helperText={error}
      />
    );
  };

  const renderDateInput = (key: string) => (
    <FormikAutocomplete<SelectItemModel, CreateRuleFormModel>
      key={key}
      placeholder="Select value"
      label="Value"
      name={`expressions.${index}.value`}
      options={specialDates}
      formik={formik}
      getOptionLabel={(option) => (option && option.label) || ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
    />
  );

  const valueByAttribute = useMemo(() => {
    switch (attribute) {
      case ExpressionAttribute.Customer:
      case ExpressionAttribute.EquipmentType:
        return renderBaseEntityAutocomplete(
          attribute === ExpressionAttribute.Customer
            ? shippers
            : equipmentTypes,
          attribute,
        );

      case ExpressionAttribute.OriginPod:
      case ExpressionAttribute.DestinationPod:
        return renderPodsAutocomplete(pods, attribute);

      case ExpressionAttribute.PickUpDate:
      case ExpressionAttribute.DeliveryDate:
        return renderDateInput(attribute);

      case ExpressionAttribute.Stops:
        return renderNumberInput(attribute);

      case ExpressionAttribute.Distance:
      case ExpressionAttribute.TransitTime:
        return operator === ExpressionOperator.Between
          ? renderRangeInput()
          : renderNumberInput(attribute);
      case ExpressionAttribute.PickUpTime:
        return renderTimeRangeInput();
      case ExpressionAttribute.DropTime:
        return renderTimeRangeInput();
    }

    return renderNumberInput(attribute, true);
  }, [attribute, pods, shippers, equipmentTypes, formik]);

  return (
    <UIRow size="m">
      <UICell colSpan={4}>
        <SgSelect
          label="Attribute"
          placeholder="Select attribute"
          name={`expressions.${index}.attribute`}
          items={attributeOptions}
          formik={formik}
          disabled={index === 0}
          onChange={() => {
            formik.setFieldValue(`expressions.${index}.value`, undefined);
            formik.setFieldValue(`expressions.${index}.operator`, undefined);
            formik.setFieldError(`expressions.${index}.value`, undefined);
            formik.setFieldError(`expressions.${index}.operator`, undefined);
            formik.setFieldTouched(`expressions.${index}.value`, false);
            formik.setFieldTouched(`expressions.${index}.operator`, false);
          }}
        />
      </UICell>

      <UICell colSpan={4}>
        <SgSelect
          label="Operator"
          placeholder="Select operator"
          name={`expressions.${index}.operator`}
          items={operatorOptions}
          formik={formik}
          disabled={index === 0 || !attribute}
          onChange={(e) => {
            if (
              attribute === ExpressionAttribute.Distance &&
              (e.target.value === ExpressionOperator.Between ||
                operator === ExpressionOperator.Between)
            ) {
              formik.setFieldValue(`expressions.${index}.value`, undefined);
              formik.setFieldError(`expressions.${index}.value`, undefined);
            }
          }}
        />
      </UICell>

      <UICell colSpan={4}>{valueByAttribute}</UICell>
    </UIRow>
  );
}
