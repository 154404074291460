import { AppConfig, NodeEnvs } from '../types';
import { RedirectRequest } from '@azure/msal-browser';
import { NodeEnv } from './node-env';

const redirectUri = location.href;

const env = process.env as unknown as NodeEnvs;
const {
  REACT_APP_ENV,
  REACT_APP_CLIENT_ID_PROD,
  REACT_APP_CLIENT_ID_DEV,
  REACT_APP_SCOPE_API_KEY_PROD,
  REACT_APP_SCOPE_API_KEY_DEV,
  REACT_APP_AZURE_MAP_KEY,
} = env;

const isProduction = REACT_APP_ENV === NodeEnv.Production;

export const appConfig: AppConfig = {
  apiBaseUrl: isProduction
    ? 'https://sgn-apim-beapi-prd-eus-01.azure-api.net/'
    : 'https://sgn-apim-beapi-dev-eus-01.azure-api.net/',
  host: location.host,
  azureMapsKey: REACT_APP_AZURE_MAP_KEY,
  msalAuth: {
    skipAuthorityMetadataCache: false,
    clientId: isProduction ? REACT_APP_CLIENT_ID_PROD : REACT_APP_CLIENT_ID_DEV,
    authority:
      'https://login.microsoftonline.com/674a4224-0389-4896-b056-525bb74699e9',
    redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  msalAuthCache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    // secureCookies: true,
  },

  ...env,
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    isProduction ? REACT_APP_SCOPE_API_KEY_PROD : REACT_APP_SCOPE_API_KEY_DEV,
  ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
