import { FreightQuoteActionModel } from '../../../../types';
import { Button, Tooltip, Typography } from '@mui/material';
import {
  is,
  isActionDisabled,
  isDisabledToSubmit,
  priceFormat,
} from '../../../../utils';
import { DescriptionItem, EmptyElement } from '../../../../components';
import { RuleModeValue, TabName } from '../../../../constants';

interface Props {
  quote: FreightQuoteActionModel;
  disabled?: boolean;
  onSubmit: (bid: number | string) => void;
}

export function AutoBidInTimer({
  quote,
  disabled,
  onSubmit,
}: Props): JSX.Element {
  if (is.nullOrUndefined(quote.autoBidIn) || !quote.AutomatedProcessing) {
    return EmptyElement;
  }
  const disabledButtons =
    isActionDisabled(quote) || isDisabledToSubmit(TabName.Open, quote);

  const isAutoBid =
    quote.AutomatedProcessing?.RuleMode === RuleModeValue.AutoBid;
  const isAutoIgnore =
    quote.AutomatedProcessing?.RuleMode === RuleModeValue.AutoIgnore;
  const readyToSubmit = !is.nullOrUndefined(
    quote.AutomatedProcessing?.SuggestedBid,
  );

  return (
    <>
      <Tooltip
        arrow
        placement="left"
        PopperProps={{
          className: 'customStopsTooltip',
        }}
        title={
          <div style={{ width: '140px', paddingBottom: '4px' }}>
            {isAutoBid && (
              <DescriptionItem
                sx={{ marginBottom: 1 }}
                title="Bid"
                message={priceFormat(quote.AutomatedProcessing?.SuggestedBid)}
              />
            )}
            {isAutoIgnore && (
              <DescriptionItem
                sx={{ marginBottom: 1 }}
                title="Rule mode"
                message="Auto-Ignore"
              />
            )}

            <DescriptionItem
              sx={{ marginBottom: 1 }}
              title="Rule name"
              message={quote.AutomatedProcessing?.RuleName}
            />
            <DescriptionItem
              sx={{ marginBottom: 1 }}
              title="User"
              message={quote.AutomatedProcessing?.RuleAuthorEmail}
            />
            {isAutoBid && (
              <Button
                variant="contained"
                type="button"
                size="tiny"
                disabled={disabled || !readyToSubmit || disabledButtons}
                onClick={() =>
                  onSubmit(quote.AutomatedProcessing?.SuggestedBid as string)
                }
              >
                Bid now
              </Button>
            )}
          </div>
        }
      >
        <Typography
          variant="h5"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.default,
            padding: '6px 5px',
            borderRadius: '4px',
            display: 'inline',
            whiteSpace: 'nowrap',
          })}
        >
          {quote.autoBidIn?.string}
        </Typography>
      </Tooltip>
    </>
  );
}
