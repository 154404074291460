import { ServerStatusCode } from '../constants/server-status-code';

export enum ServerResponseStatus {
  Error = 'Error',
  Success = 'Success',
}

export interface DefaultErrorResponse {
  message: string;
  status: ServerResponseStatus;
}

export interface ServerListResponse<T> {
  Items: T[];
  TotalCount: number;
  Request: Partial<{
    Filer: null | string;
    OrderBy: null | string;
    Skip: number;
    Take: number;
  }>;
}

export interface ServerValidationError {
  Detail: unknown;
  Key: string;
  Message: string;
  Value: unknown;
}

export interface ServerValidationErrorResponse {
  data: ServerValidationError[];
  headers: { 'x-status-reason': string };
  status: ServerStatusCode;
  statusText: string;
}
