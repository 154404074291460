export enum TabName {
  Open = 'open',
  Submitted = 'submitted',
  Won = 'won',
  Lost = 'lost',
  Ignored = 'ignored',
  Missed = 'missed',
}

export const tabNamesList = [
  TabName.Open,
  TabName.Submitted,
  TabName.Won,
  TabName.Lost,
  TabName.Ignored,
  TabName.Missed,
];

export const pageNames = {
  ...TabName,
  Search: 'search',
};
