import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { LoaderContainer } from '../../components';
import { SchedulingDataGrid } from '../../features/schedulingDataGrid/SchedulingDataGrid';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  editSchedule,
  fetchSchedule,
  schedulingSelectors,
  schedulingSlice,
} from '../../redux/scheduling';

export function Scheduling(): JSX.Element {
  const dispatch = useAppDispatch();

  const schedule = useAppSelector(schedulingSelectors.getSchedule);
  const {
    AutomatedProcessingSchedulingAction,
    AutomatedProcessingSchedulingCondition,
  } = useAppSelector(schedulingSelectors.getEnums);
  const loading = useAppSelector(schedulingSelectors.getLoading);

  useEffect(() => {
    dispatch(fetchSchedule());

    return () => {
      dispatch(schedulingSlice.actions.reset());
    };
  }, []);

  return (
    <LoaderContainer loading={loading} fullPage>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginY={4}
        >
          <Typography variant="h1">Scheduling</Typography>
        </Stack>
      </Box>

      {schedule && (
        <SchedulingDataGrid
          actionEnums={AutomatedProcessingSchedulingAction}
          conditionEnums={AutomatedProcessingSchedulingCondition}
          rows={schedule}
          onSaveSchedule={(item, data) =>
            dispatch(editSchedule({ item, formData: data }))
          }
          toggleScheduleEdit={(item) =>
            dispatch(schedulingSlice.actions.toggleScheduleEdit(item))
          }
        />
      )}
    </LoaderContainer>
  );
}
