import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { BarChart, PerformanceChart, PieChart } from '../../components/charts';
import React, { useEffect, useState } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { fetchPortals, filtersDataSelectors } from '../../redux/filtersData';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { Filters, LoaderContainer } from '../../components';
import { fetchDashboardData } from '../../redux/dashboard/dashboard.thunks';
import { dashboardSelectors, dashboardSlice } from '../../redux/dashboard';
import { FiltersModel } from '../../types';
import { getInitDateFilter } from './Dashboard.utils';
import { TotalRevenueGroupBy, TotalRevenueGroupLabels } from '../../constants';
import { TotalRevenueDialog } from '../../features/TotalRevenueDialog/TotalRevenueDialog';
import { filtersToOData } from '../../utils/oDataBuilder/oDataBuilder';
import { is } from '../../utils';

export function Dashboard(): JSX.Element {
  const dispatch = useAppDispatch();
  const [openTotalRevenue, setOpenTotalRevenue] = useState(false);
  const styles = {
    bgcolor: 'common.white',
    height: '400px',
    borderRadius: '4px',
  };

  const {
    portals = [],
    equipmentTypes = [],
    origins = [],
    destinations = [],
  } = useAppSelector(filtersDataSelectors.getFilters);
  const loading = useAppSelector(dashboardSelectors.getLoading);
  const filters = useAppSelector(dashboardSelectors.getFilters);
  const breakdownData = useAppSelector(dashboardSelectors.getBreakdownData);
  const totalRevenueData = useAppSelector(
    dashboardSelectors.getTotalRevenueData,
  );
  const performanceData = useAppSelector(dashboardSelectors.getPerformanceData);

  const groupBy = filters.groupBy || TotalRevenueGroupBy.Day;

  const fetchData = (
    filterValue?: Partial<FiltersModel>,
    clearGroupBy?: boolean,
  ) => {
    const initFilters = {
      ...getInitDateFilter(clearGroupBy ? undefined : groupBy),
    };
    const isFirstFetch = is.empty(filters);

    const newFilters = filterValue
      ? {
          ...initFilters,
          ...filters,
          ...(filterValue || {}),
        }
      : {
          ...initFilters,
        };

    try {
      filtersToOData({ filters: newFilters }, true);

      if (!isFirstFetch) {
        dispatch(fetchPortals(true));
      }
      dispatch(fetchDashboardData(newFilters));
    } catch (e) {}
  };

  useEffect(() => {
    fetchData({});

    return () => {
      dispatch(dashboardSlice.actions.reset());
    };
  }, []);

  const renderTotalRevenuePart = () => (
    <Stack
      style={{ paddingBottom: '10px' }}
      direction="row"
      alignItems="baseline"
      justifyContent="space-between"
      paddingX={3}
    >
      <Typography variant="h3" paddingTop={3}>
        Total revenue
      </Typography>
      <Stack direction="row" alignItems="baseline">
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            id="groupBySelect"
            value={groupBy}
            onChange={(e) =>
              fetchData({
                groupBy: e.target.value as TotalRevenueGroupBy,
              })
            }
          >
            {Object.keys(TotalRevenueGroupLabels).map((groupKey) => (
              <MenuItem key={groupKey} value={groupKey}>
                {
                  TotalRevenueGroupLabels[
                    groupKey as unknown as TotalRevenueGroupBy
                  ]
                }
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <Button
            disabled={!totalRevenueData}
            variant="outlined"
            size="small"
            color="secondary"
            onClick={() => setOpenTotalRevenue(true)}
            sx={{ padding: '5px', minWidth: '32px' }}
          >
            <FullscreenIcon fontSize="small" />
          </Button>
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <LoaderContainer loading={loading} fullPage>
      <Box>
        <Typography variant="h1" marginY={4}>
          Dashboard
        </Typography>

        <Filters
          periodMode
          backgroundColor="common.white"
          filters={{ ...filters, groupBy }}
          onChange={fetchData}
          loading={false}
          portals={portals}
          equipmentTypes={equipmentTypes}
          origins={origins}
          destinations={destinations}
        />

        <Box marginTop={3}>
          <Stack direction="row" justifyContent="space-between" spacing={3}>
            <Box width="33%" sx={styles}>
              <Typography
                variant="h3"
                paddingX={3}
                paddingTop={3}
                paddingBottom={1}
              >
                Breakdown
              </Typography>

              <PieChart height={272} data={breakdownData} />
            </Box>
            <Box width="33%" sx={styles}>
              {renderTotalRevenuePart()}

              <BarChart
                height={322}
                data={totalRevenueData}
                groupBy={groupBy}
              />
            </Box>
            <Box width="33%" sx={styles}>
              <Typography
                variant="h3"
                paddingX={3}
                paddingTop={3}
                paddingBottom={1}
              >
                Performance, %
              </Typography>

              <PerformanceChart performance={performanceData} />
            </Box>
          </Stack>
        </Box>
      </Box>

      {openTotalRevenue && (
        <TotalRevenueDialog
          height={600}
          data={totalRevenueData}
          groupBy={groupBy}
          onClosed={() => setOpenTotalRevenue(false)}
        >
          <Filters
            showGroupBy
            periodMode
            filters={filters}
            onChange={(f) => fetchData(f, true)}
            loading={false}
            portals={portals}
            equipmentTypes={equipmentTypes}
            origins={origins}
            destinations={destinations}
          />
        </TotalRevenueDialog>
      )}
    </LoaderContainer>
  );
}
