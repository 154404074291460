import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { plural } from '../../utils';

interface Props {
  disabled?: boolean;
  showIgnore?: boolean;
  bulkBidButtonLabel?: string;
  count?: number;
  onSubmit: () => void;
  onIgnore?: () => void;
  onCancel: () => void;
}

export function BulkBidPanel({
  count,
  disabled,
  showIgnore,
  bulkBidButtonLabel = 'Submit bid',
  onSubmit,
  onIgnore,
  onCancel,
}: Props): JSX.Element {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box>
        {count} {plural(count, 'item')} selected
      </Box>

      <Stack direction="row">
        <Button
          size="small"
          variant="outlined"
          onClick={() => onCancel()}
          sx={{ marginRight: 2 }}
        >
          Cancel
        </Button>
        {showIgnore && (
          <Button
            disabled={disabled}
            size="small"
            variant="outlined"
            onClick={() => onIgnore && onIgnore()}
            sx={{ marginRight: 2 }}
          >
            Ignore
          </Button>
        )}
        <Button
          variant="contained"
          disabled={disabled}
          size="small"
          onClick={() => onSubmit()}
        >
          {bulkBidButtonLabel} {count ? `(${count})` : ''}
        </Button>
      </Stack>
    </Stack>
  );
}
