import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import {
  freightQuotesSelectors,
  freightQuotesSlice,
  setNewPage,
} from '../../redux/freightQuotes';
import { useParams } from 'react-router-dom';
import { TabName } from '../../constants';
import { SharedPage } from '../../features';
import { useEachMinute } from '../../utils';
import { Box } from '@mui/material';
import { SubHeader } from '../../components';

export function Home() {
  const dispatch = useAppDispatch();
  const { page } = useParams<{ page?: TabName }>();

  const currentTabName = useAppSelector(
    freightQuotesSelectors.getCurrentTabName,
  );

  const currentTabParams = useAppSelector(
    freightQuotesSelectors.getCurrentTabParams,
  );

  useEachMinute(() => {
    dispatch(freightQuotesSlice.actions.setCurrentTime());
  }, [page || TabName.Open]);

  useEffect(() => {
    if (page) {
      dispatch(setNewPage(page));
    }
  }, [page, currentTabParams]);

  return useMemo(() => {
    if (!currentTabName) {
      return null;
    }

    return (
      <Box>
        <SubHeader />
        <SharedPage tabName={currentTabName} key={currentTabName} />
      </Box>
    );
  }, [currentTabName]);
}
