import * as React from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { PageLayout } from '../pageLayout/PageLayout';
import { useIsDashboard } from '../../utils/hooks';
import { PageWrapper } from '../pageWrapper/PageWrapper';
import { useAppDispatch } from '../../redux/hooks';
import { useInterval } from '../../utils';
import { updateStatusCounters } from '../../redux/freightQuotes';

const MainPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDashboard = useIsDashboard();

  useEffect(() => {
    dispatch(updateStatusCounters());
  }, []);

  useInterval(
    () => {
      dispatch(updateStatusCounters());
    },
    10000,
    [],
  );
  return (
    <PageLayout>
      <PageWrapper
        bgcolor={isDashboard ? 'background.default' : 'common.white'}
      >
        <>
          <Outlet />
        </>
      </PageWrapper>
    </PageLayout>
  );
};

export default MainPage;
