import { UICell, UIRow } from '../../components/uiGrid';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  isValidAllInBidding,
  isValidTypeByCustomer,
  ruleFormSchema,
} from './RuleForm.schema';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  deleteRule,
  fetchRuleValidation,
  rulesSelectors,
  rulesSlice,
  saveRule,
  setRuleStatus,
} from '../../redux/rules';
import { CreateRuleFormModel } from './RuleForm.types';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { RuleExpressionsForm } from './components/ruleExpressionsForm/RuleExpressionsForm';
import {
  ExpressionAttribute,
  LOAD_MORE_RULES,
  ruleTypesOptions,
} from './RuleForm.const';
import { PageWrapper } from '../../layouts/pageWrapper/PageWrapper';
import { InfoHeader } from '../../components/infoHeader/InfoHeader';
import { RuleFormFooter } from './components/footer/RuleFormFooter';
import { RuleMainForm } from './components/mainForm/RuleMainForm';
import { RateForm } from './components/rateForm/RateForm';
import {
  CAN_NOT_SAVE_RULE_ALL_IN_BIDDING_NULL,
  DatActionValue,
  DatRateDirection,
  DATRateValue,
  routes,
  RuleMode,
  RuleStatus,
  RuleTypeName,
} from '../../constants';
import {
  PlainObject,
  PODExtendedModel,
  QuoteBaseEntityTypeUIExtended,
  RuleApiModel,
} from '../../types';
import {
  getCustomerExpression,
  getRulePublishError,
  transformRuleApiToForm,
} from './RuleForm.utils';
import { getEnumLabels } from '../../utils';
import { renderStatusFormatter } from '../rulesDataGrid/RulesDataGrid.utils';
import { AntSwitch } from '../../components';
import { useNavigate } from 'react-router-dom';
import { ValidationGrid } from './components/validationGrid/ValidationGrid';
import { notificationSlice } from '../../redux/notification';
import { ConfirmationDialog } from '../confirmationDialog/ConfirmationDialog';
import { getChanges } from '../../utils/getChanges';

interface Props {
  initialFormData?: Partial<CreateRuleFormModel>;
  loading: boolean;
  disabled: boolean;
  rule?: RuleApiModel;
  ruleCopy?: Partial<CreateRuleFormModel>;
  shippers: QuoteBaseEntityTypeUIExtended[];
  equipmentTypes: QuoteBaseEntityTypeUIExtended[];
  pods: PODExtendedModel[];
}

export function RuleForm({
  initialFormData = {},
  loading,
  disabled,
  rule,
  shippers,
  equipmentTypes,
  pods,
  ruleCopy,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [publish, setPublish] = useState(false);
  const [ruleValuesToEdit, setRuleValuesToEdit] =
    useState<Partial<CreateRuleFormModel> | null>(null);
  const [canRuleBePublished, setCanRuleBePublished] = useState(true);
  const [isCopy] = useState(!!ruleCopy);

  const {
    RuleExpressionSpecialDate: specialDates,
    RuleMode: ruleModes,
    RuleExpressionOperator: expressionOperators,
    RuleStatus: ruleStatuses,
  } = useAppSelector(rulesSelectors.getRulesEnums);
  const ruleValidation = useAppSelector(rulesSelectors.getRuleValidation);
  const ruleValidationTotal = useAppSelector(
    rulesSelectors.getRuleValidationTotal,
  );
  const deletedRule = useAppSelector(rulesSelectors.getDeletedRule);

  const isAlreadyPublishedRule = !!rule && rule.Status !== RuleStatus.Draft;
  const isPublishedAndActive = !!rule && rule.Status === RuleStatus.Active;

  const existedRuleValues = useMemo(() => {
    if (!rule) {
      return {};
    }

    return transformRuleApiToForm(
      rule,
      shippers,
      equipmentTypes,
      pods,
      specialDates,
    );
  }, [rule, pods, shippers, equipmentTypes]);

  const saveEditedForm = (values: Partial<CreateRuleFormModel>) => {
    if (!rule) {
      return;
    }

    dispatch(
      saveRule({
        rule: {
          ...values,
        },
        status: publish
          ? rule.Status === RuleStatus.Draft
            ? RuleStatus.Active
            : undefined
          : RuleStatus.Draft,
        ruleId: String(rule.Id),
      }),
    );

    setRuleValuesToEdit(null);
  };

  const savePublishedRule = (recalculateBids: boolean) => {
    if (!rule) {
      return;
    }

    saveEditedForm({
      ...ruleValuesToEdit,
      recalculateBids,
    });

    setRuleValuesToEdit(null);
  };

  const onEditedForm = (
    values: Partial<CreateRuleFormModel>,
    changedFields: PlainObject,
  ) => {
    if (!rule) {
      return;
    }

    // recalculate quotes only if rule Active and changed any field except ruleName and description
    if (isPublishedAndActive) {
      const filteredChanges = Object.keys(changedFields).filter(
        (key) => key !== 'ruleName' && key !== 'description',
      );

      if (filteredChanges.length) {
        setRuleValuesToEdit(values);

        return;
      }
    }

    saveEditedForm(values);
  };

  const formik = useFormik<CreateRuleFormModel>({
    initialValues: {
      datRate: DatRateDirection.Min,
      datRateAction: DatActionValue.Increase,
      rateValue: DATRateValue.USD,

      ...initialFormData,

      expressions: [
        {
          attribute: ExpressionAttribute.Customer,
          operator: 0,
          _rowId: 0,
        },
        ...(initialFormData?.expressions || []),
      ],
      ...existedRuleValues,
      ...(ruleCopy || {}),
    },
    validateOnBlur: true,
    validationSchema: ruleFormSchema(!!rule, false),
    onSubmit: (values) => {
      const valuesToSave =
        values.mode === RuleMode.AutoIgnore ||
        values.type === RuleTypeName.RatePerTrip
          ? {
              ...values,
              bidMinLimit: null,
              bidMaxLimit: null,
            }
          : values;

      if (!!rule) {
        onEditedForm(
          valuesToSave,
          getChanges(formik.initialValues, formik.values),
        );
        return;
      }

      dispatch(
        saveRule({
          rule: valuesToSave,
          status: publish ? RuleStatus.Active : RuleStatus.Draft,
        }),
      );
    },
  });

  useEffect(() => {
    if (ruleCopy) {
      formik.setValues({ ...formik.values, ...ruleCopy });
    }

    dispatch(rulesSlice.actions.resetCopy());
  }, []);

  useEffect(() => {
    if (!deletedRule) {
      return;
    }

    navigate(routes.Rules);
  }, [deletedRule]);

  useEffect(() => {
    if (!ruleValidation) {
      return;
    }

    dispatch(rulesSlice.actions.resetValidations());
  }, [formik.values]);

  const customerExpression = getCustomerExpression(formik.values.expressions);

  useEffect(() => {
    if (!isValidAllInBidding(customerExpression, formik.values.type)) {
      dispatch(
        notificationSlice.actions.error(CAN_NOT_SAVE_RULE_ALL_IN_BIDDING_NULL),
      );
      return;
    }

    if (isValidTypeByCustomer(customerExpression, formik.values.type)) {
      setCanRuleBePublished(true);

      return;
    }

    setCanRuleBePublished(false);
    dispatch(
      notificationSlice.actions.error(getRulePublishError(formik.values.type)),
    );
  }, [formik.values.type, customerExpression?.value]);

  const expressions = formik.values.expressions;

  const title = isCopy
    ? 'Create a copy rule'
    : !!rule
    ? 'Edit rule'
    : 'Create rule';
  const author = rule?.User?.Email ? `Author: ${rule.User.Email}` : undefined;
  const lastModified = rule?.LastChangeDateTime
    ? `Saved: ${rule.LastChangeDateTime}`
    : undefined;

  const badge = useMemo(() => {
    const ruleStatusLabels = getEnumLabels(ruleStatuses);

    return renderStatusFormatter(
      rule ? rule.Status : RuleStatus.Draft,
      ruleStatusLabels,
    );
  }, [rule, ruleStatuses]);

  const formContent = !loading && (
    <Box>
      <RuleMainForm
        editMode={isAlreadyPublishedRule}
        hidePriority={!!rule}
        formik={formik}
        ruleModes={ruleModes}
        ruleTypes={ruleTypesOptions}
      />

      <UIRow size="l" marginTop={3}>
        <UICell colSpan={7} bgcolor="background.default" borderRadius="4px">
          <Box padding={3}>
            {!!expressionOperators.length && (
              <>
                <RuleExpressionsForm
                  formik={formik}
                  ruleModes={ruleModes}
                  specialDates={specialDates}
                  expressionOperators={expressionOperators}
                />

                <Button
                  sx={{ marginTop: 2 }}
                  startIcon={<AddIcon fontSize="medium" color="primary" />}
                  onClick={() =>
                    formik.setFieldValue('expressions', [
                      ...expressions,
                      { _rowId: performance.now() + expressions.length },
                    ])
                  }
                >
                  Add clause
                </Button>
              </>
            )}
          </Box>
        </UICell>

        <UICell colSpan={4}>
          <RateForm formik={formik} />
        </UICell>
      </UIRow>

      <Box marginTop={3}>
        <Button
          size="small"
          variant="contained"
          type="button"
          onClick={() =>
            dispatch(
              fetchRuleValidation({ rule: formik.values, firstFetch: true }),
            )
          }
        >
          Validate
          <PlayArrowOutlinedIcon fontSize="small" />
        </Button>
      </Box>
    </Box>
  );

  const validationContent = !loading && (
    <Box marginTop={3}>
      <Divider />

      {ruleValidation ? (
        <>
          <Typography
            marginTop={3}
            marginBottom={1}
            variant="h4"
            color="secondary"
          >
            {ruleValidation.length
              ? 'Validation results are limited by first 15 rows'
              : 'Nothing has been found by the required parameters within the last 60 days'}
          </Typography>

          {!!ruleValidation.length && (
            <>
              <ValidationGrid list={ruleValidation} />

              <Stack direction="row" justifyContent="center" padding={2}>
                <Button
                  variant="outlined"
                  type="button"
                  size="small"
                  disabled={ruleValidation.length >= ruleValidationTotal}
                  onClick={() =>
                    dispatch(
                      fetchRuleValidation({
                        rule: formik.values,
                        skip: ruleValidation.length,
                        take: LOAD_MORE_RULES,
                      }),
                    )
                  }
                >
                  <ArrowDownwardIcon sx={{ marginRight: 1 }} fontSize="small" />
                  Load more ({LOAD_MORE_RULES})
                </Button>
              </Stack>
            </>
          )}
        </>
      ) : (
        <Box>
          <Typography variant="h3" textAlign="center" marginTop={3}>
            No validation results to display
          </Typography>
          <Typography variant="h5" color="secondary" textAlign="center">
            Setup a rule to get validation results.
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <InfoHeader title={title} info1={author} info2={lastModified}>
        <Stack direction="row" alignItems="center">
          {badge}
          {isAlreadyPublishedRule && rule?.Status !== RuleStatus.Draft && (
            <Box marginLeft={3}>
              <AntSwitch
                size="large"
                disabled={disabled}
                checked={rule?.Status === RuleStatus.Active}
                onChange={(e, checked) =>
                  dispatch(
                    setRuleStatus({
                      ruleId: rule?.Id || 0,
                      status: checked
                        ? RuleStatus.Active
                        : RuleStatus.Deactivated,
                    }),
                  )
                }
              />
            </Box>
          )}
        </Stack>
      </InfoHeader>

      <PageWrapper paddingTop="92px" paddingBottom={14}>
        {formContent}

        {validationContent}
      </PageWrapper>

      <RuleFormFooter
        rulePublishError={
          canRuleBePublished
            ? undefined
            : getRulePublishError(formik.values.type)
        }
        showSaveAsDraft={!rule || rule.Status === RuleStatus.Draft}
        editMode={!!rule}
        copyMode={isCopy}
        formik={formik}
        loading={loading || disabled}
        isPublished={!!rule && rule.Status !== RuleStatus.Draft}
        onSave={() => setPublish(false)}
        onCopy={() => {
          dispatch(rulesSlice.actions.setCopy(formik.values));
          navigate(routes.CopyRule);
        }}
        onPublish={() => setPublish(true)}
        showDelete={rule && rule.Status === RuleStatus.Draft}
        onDelete={() => rule && dispatch(deleteRule(rule?.Id))}
      />

      {ruleValuesToEdit && (
        <ConfirmationDialog
          title="Save & publish"
          message="Would you like Bids to be re-calculated?"
          closeLabel="No"
          submitLabel="Yes"
          preventSubmit
          onSubmit={() => savePublishedRule(true)}
          onDecline={() => savePublishedRule(false)}
          onClosed={() => setRuleValuesToEdit(null)}
        />
      )}
    </form>
  );
}
