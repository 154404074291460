import { css } from '@emotion/react';

export const useNotificationBarStyles = () => {
  return css`
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }

    .MuiTableCell-root {
      padding: 12px 8px;

      .MuiTypography-body1 {
        cursor: default;

        .MuiLink-root {
          cursor: pointer;
        }
      }
    }

    .MuiSvgIcon-root {
      display: block;
    }
  `;
};
