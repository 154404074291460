import { Stack, Box, Typography } from '@mui/material';
import { NavigationTabCard } from '../navigationTabCard/NavigationTabCard';
import SignpostOutlinedIcon from '@mui/icons-material/SignpostOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useParams } from 'react-router-dom';
import { TabName } from '../../constants';
import { useAppSelector } from '../../redux/hooks';
import { freightQuotesSelectors } from '../../redux/freightQuotes';
import { FreightQuotesStatusCount } from '../../types';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { OpenHotIcon } from '../icons/OpenHotIcon';
interface TabModel {
  countKey: keyof FreightQuotesStatusCount;
  link: TabName;
  label: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  additionalIcon?: JSX.Element;
}

export const routes: TabModel[] = [
  {
    link: TabName.Open,
    label: 'Open',
    icon: SignpostOutlinedIcon,
    countKey: 'OpenCount',
    additionalIcon: <OpenHotIcon />,
  },
  {
    link: TabName.Submitted,
    label: 'Submitted',
    icon: CheckCircleOutlineIcon,
    countKey: 'SubmittedCount',
  },
  {
    link: TabName.Won,
    label: 'Won',
    icon: EmojiEventsOutlinedIcon,
    countKey: 'WonCount',
  },
  {
    link: TabName.Lost,
    label: 'Lost',
    icon: CancelOutlinedIcon,
    countKey: 'LostCount',
  },
  {
    link: TabName.Ignored,
    label: 'Ignored',
    icon: DoDisturbOnOutlinedIcon,
    countKey: 'IgnoredCount',
  },
  {
    link: TabName.Missed,
    label: 'Missed',
    icon: AccessTimeOutlinedIcon,
    countKey: 'MissedCount',
  },
];

export function SubHeader(): JSX.Element {
  const { page } = useParams<{ page?: TabName }>();
  const statuses = useAppSelector(freightQuotesSelectors.getStatuses);

  const tabs = routes.map(({ link, label, countKey, icon, additionalIcon }) => {
    const IconComponent = icon;

    return (
      <NavigationTabCard
        sx={{ marginLeft: 2, width: '160px' }}
        key={link}
        link={link}
        label={label}
        count={(statuses || {})[countKey] || '-'}
        to={`/${link}`}
        active={link === TabName.Open && !page}
        additionalIcon={additionalIcon}
      >
        <IconComponent color="secondary" />
      </NavigationTabCard>
    );
  });

  return (
    <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
      <Stack direction="row" alignItems="center">
        <Typography variant="h1" sx={{ width: '297px', minWidth: '210px' }}>
          Freight quotes
        </Typography>

        {tabs}
      </Stack>
    </Box>
  );
}
