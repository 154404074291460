import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { rulesStoreKey } from './rules.const';
import {
  CreateRuleInputModel,
  RuleEnums,
  RuleEnumsResponse,
  RulesResult,
  RulesValidationInput,
  SetPriorityInputModel,
  SetRuleStatusInputModel,
} from './rules.types';
import {
  getNewPriorities,
  RulePodResponseToData,
  transformRuleFormToData,
} from './rules.utils';
import { notificationSlice } from '../notification';
import {
  ExtendedRuleEnum,
  FreightQuoteModel,
  PODApiModel,
  PODExtendedModel,
  RuleApiModel,
  RulesFiltersModel,
} from '../../types';
import { RuleStatus } from '../../constants';
import { filtersToOData } from '../../utils/oDataBuilder/oDataBuilder';
import { apiEndpoint } from '../../constants/api';
import { ServerListResponse } from '../../types/server-response';
import { rulesFiltersToOData } from '../../utils/oDataBuilder/rulesODataBuilder';
import { initEnumsData, ruleEnumsResponseToData } from '../../utils/ruleEnums';
import { getServerValidationError } from '../../utils/getServerValidationError';
import { AppStateWithRules, rulesSelectors } from './rules.selectors';

export const fetchRulesEnums = createAsyncThunk(
  `${rulesStoreKey}/fetchRulesEnums`,
  async (_: void, store): Promise<RuleEnums> => {
    try {
      const { data } = await axios.get<RuleEnumsResponse[]>(
        apiEndpoint.getEnums(),
      );

      if (!data) {
        throw Error('Failed request');
      }

      return ruleEnumsResponseToData(data);
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return initEnumsData;
    }
  },
);

export const fetchDATPodsResult = createAsyncThunk(
  `${rulesStoreKey}/fetchDATPodsResult`,
  async (_: void, store): Promise<PODExtendedModel[]> => {
    try {
      const { data } = await axios.get<PODApiModel[]>(apiEndpoint.datPods());

      if (!data) {
        throw Error('Failed request');
      }

      return RulePodResponseToData(data);
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      return [];
    }
  },
);

export const getRule = createAsyncThunk(
  `${rulesStoreKey}/getRule`,
  async (ruleId: string, store): Promise<RuleApiModel> => {
    try {
      const otDataString = filtersToOData({
        filters: { 'Rule/Id': Number(ruleId) },
      });
      const { data } = await axios.post(apiEndpoint.rules(), otDataString);

      if (!data || !data[0]) {
        throw Error('Failed request');
      }

      return data[0];
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw Error('Failed request');
    }
  },
);

export const saveRule = createAsyncThunk(
  `${rulesStoreKey}/saveRule`,
  async (
    { rule, status, ruleId }: CreateRuleInputModel,
    store,
  ): Promise<RuleApiModel> => {
    try {
      const { data } = ruleId
        ? await axios.post<RuleApiModel>(
            apiEndpoint.editRule(ruleId),
            transformRuleFormToData(rule, status),
          )
        : await axios.post<RuleApiModel>(
            apiEndpoint.createRule(),
            transformRuleFormToData(rule, status),
          );

      if (!data) {
        throw Error('Failed request');
      }

      store.dispatch(
        status === RuleStatus.Draft
          ? notificationSlice.actions.draft('The rule has been saved as draft')
          : notificationSlice.actions.success(
              'The rule has been saved & published',
            ),
      );

      return data;
    } catch (err) {
      const validationError = getServerValidationError(err);

      store.dispatch(notificationSlice.actions.error(validationError));

      throw Error('Failed request');
    }
  },
);

export const setRuleStatus = createAsyncThunk(
  `${rulesStoreKey}/setRuleStatus`,
  async (
    { ruleId, status }: SetRuleStatusInputModel,
    store,
  ): Promise<SetRuleStatusInputModel> => {
    try {
      const { data } = await axios.post<RuleApiModel>(
        apiEndpoint.editRule(ruleId),
        {
          Status: status,
        },
      );

      if (!data) {
        throw Error('Failed request');
      }

      return { ruleId, status };
    } catch (err) {
      const validationError = getServerValidationError(err);

      store.dispatch(notificationSlice.actions.error(validationError));

      throw Error('Failed request');
    }
  },
);

export const deleteRule = createAsyncThunk(
  `${rulesStoreKey}/deleteRule`,
  async (ruleId: string | number, store): Promise<RuleApiModel> => {
    try {
      const { data } = await axios.post<RuleApiModel>(
        apiEndpoint.deleteRule(ruleId),
      );

      if (!data) {
        throw Error('Failed request');
      }

      return data;
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw Error('Failed request');
    }
  },
);

export const setPriority = createAsyncThunk(
  `${rulesStoreKey}/setPriority`,
  async (inputData: SetPriorityInputModel, store): Promise<null> => {
    try {
      const priorities = getNewPriorities(inputData);

      const { data } = await axios.post<RuleApiModel>(
        apiEndpoint.editRulePriority(),
        priorities,
      );

      if (!data) {
        throw Error('Failed request');
      }

      store.dispatch(fetchRules({ filters: {} }));

      return null;
    } catch (err) {
      store.dispatch(fetchRules({ filters: {} }));
      store.dispatch(notificationSlice.actions.networkError());

      return null;
    }
  },
);

export const fetchRules = createAsyncThunk(
  `${rulesStoreKey}/fetchRules`,
  async (
    {
      filters,
      archived,
    }: {
      filters: Partial<RulesFiltersModel>;
      archived?: boolean;
    },
    store,
  ): Promise<RulesResult> => {
    try {
      const enums = rulesSelectors.getRulesEnums(
        store.getState() as AppStateWithRules,
      );

      const defaultStatuses = (enums.RuleStatus || []).filter(
        (item) => item.value !== RuleStatus.Deactivated,
      );

      const otDataString = rulesFiltersToOData({
        filters: { ruleStatus: defaultStatuses, ...(filters || {}) },
      });
      const archivedOtDataString = rulesFiltersToOData({
        filters: {
          ...filters,
          ruleStatus: [{ value: RuleStatus.Deactivated } as ExtendedRuleEnum],
        },
      });

      const { data: rules } = await axios.post(
        apiEndpoint.rules(),
        otDataString,
      );
      const { data: archivedRules } = await axios.post(
        apiEndpoint.rules(),
        archivedOtDataString,
      );

      if (!rules) {
        throw Error('Failed request');
      }

      const result = {
        filters,
        rulesTotal: rules.length,
        archivedRulesTotal: archivedRules.length,
      };

      return archived
        ? {
            archivedRules,
            ...result,
          }
        : {
            rules,
            ...result,
          };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw Error('Failed request');
    }
  },
);

export const fetchRuleValidation = createAsyncThunk(
  `${rulesStoreKey}/fetchRuleValidation`,
  async (
    { rule, take = 15, skip = 0, firstFetch }: RulesValidationInput,
    store,
  ): Promise<
    ServerListResponse<FreightQuoteModel> & { firstFetch?: boolean }
  > => {
    try {
      const { data } = await axios.post<ServerListResponse<FreightQuoteModel>>(
        apiEndpoint.validateRule(),
        {
          Skip: skip,
          Take: take,
          Input: transformRuleFormToData(rule),
        },
      );

      if (!data || !data.Items) {
        throw Error('Failed request');
      }

      return {
        firstFetch,
        ...data,
        TotalCount: data.Items.length === take ? skip + take + 1 : skip,
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw Error('Failed request');
    }
  },
);
