import { Box, Stack, Typography, useTheme } from '@mui/material';
import { zIndexes } from '../../theme';
import { PropsWithChildren } from 'react';

interface Props {
  title: string;
  info1?: string;
  info2?: string;
}

export function InfoHeader({
  title,
  info1,
  info2,
  children,
}: PropsWithChildren<Props>): JSX.Element {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      paddingX={6}
      paddingY={3}
      position="fixed"
      width="100%"
      top="0"
      zIndex={zIndexes.HEADER}
      bgcolor={palette.common.white}
      borderBottom={`4px solid ${palette.background.default}`}
    >
      <Typography variant="h1">{title}</Typography>

      <Stack direction="row" justifyContent="end" alignItems="center">
        {info1 && (
          <Typography variant="h5" color="secondary.main">
            {info1}
          </Typography>
        )}
        {info2 && (
          <Typography variant="h5" color="secondary.main" marginLeft={3}>
            {info2}
          </Typography>
        )}
        {children && <Box marginLeft={3}>{children}</Box>}
      </Stack>
    </Stack>
  );
}
