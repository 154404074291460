import { FormikEntity, ShipperApiModelExtended } from '../../../../types';
import { CreateRuleFormModel } from '../../RuleForm.types';
import { Box, Stack, Typography } from '@mui/material';
import { decimalFormatter, is } from '../../../../utils';
import {
  datActionLabel,
  DatActionValue,
  DatRateDirection,
  datRateLabel,
  datRateValueLabel,
  INSERT_LINE_HAUL_ONLY_RATE_HERE,
  RATE_PER_TRIP_TYPE_ALL_IN,
  RATE_PER_TRIP_TYPE_LINE_HAUL_ONLY,
  RuleTypeName,
  SAGAN_OPERATES_WITH_DAT_RATES,
} from '../../../../constants';
import {
  getRateValueStartAdornment,
  SgSelect,
  SgTextField,
  submitStartAdornment,
  WarningItem,
} from '../../../../components';
import React from 'react';
import { datActionOptions, datRateOptions } from './RateForm.const';

export interface Props {
  formik: FormikEntity<CreateRuleFormModel>;
}

export function RateForm({ formik }: Props): JSX.Element | null {
  const {
    type,
    expressions,
    datRate,
    datRateAction,
    rateAmount,
    rateValue,
    bidMinLimit,
    bidMaxLimit,
  } = formik.values;

  const customer: ShipperApiModelExtended | undefined =
    expressions[0] && (expressions[0].value as ShipperApiModelExtended);

  if (is.undefined(type)) {
    return null;
  }

  const getDATFinalText = () => {
    const limitsText = [
      bidMinLimit ? `Not less than ${bidMinLimit} USD` : null,
      bidMaxLimit ? `Not more than ${bidMaxLimit} USD` : null,
    ]
      .filter((v) => v)
      .join(' and ');

    return `Final result: ${datRateLabel[datRate]} DAT rate ${
      datActionLabel[datRateAction]
    } ${rateAmount || 0} ${datRateValueLabel[rateValue]}${
      limitsText ? ` but ${limitsText}` : ''
    }`;
  };

  const renderRateLimitForm = (): JSX.Element => (
    <Box marginTop={2}>
      <Stack direction="row">
        <Typography variant="subtitle2" fontWeight={600} marginRight={0.5}>
          Limits
        </Typography>
        <Typography variant="subtitle2"> (optional)</Typography>
      </Stack>
      <Stack direction="row" marginTop={1}>
        <Box marginRight={2} width="calc(50% - 16px)">
          <SgTextField
            formik={formik}
            fullWidth
            name="bidMinLimit"
            label="Not less than"
            size="medium"
            placeholder="0.00"
            InputProps={{
              startAdornment: submitStartAdornment,
            }}
            variant="standard"
            valueFormatter={decimalFormatter}
          />
        </Box>
        <Box width="50%">
          <SgTextField
            formik={formik}
            fullWidth
            name="bidMaxLimit"
            label="Not more than"
            size="medium"
            placeholder="0.00"
            InputProps={{
              startAdornment: submitStartAdornment,
            }}
            variant="standard"
            valueFormatter={decimalFormatter}
          />
        </Box>
      </Stack>
    </Box>
  );

  const renderRateForm = (): JSX.Element => (
    <Box>
      <SgTextField
        formik={formik}
        fullWidth
        name="rateAmount"
        label={
          type === RuleTypeName.RatePerTrip ? 'Rate per trip' : 'Rate per mile'
        }
        size="medium"
        placeholder="0.00"
        InputProps={{
          startAdornment: submitStartAdornment,
        }}
        variant="standard"
        valueFormatter={decimalFormatter}
      />

      {type === RuleTypeName.RatePerMile && renderRateLimitForm()}

      <Box marginTop={2}>
        {customer &&
          !is.nullOrUndefined(customer.AllInBidding) &&
          type === RuleTypeName.RatePerTrip && (
            <WarningItem>
              {customer.AllInBidding
                ? RATE_PER_TRIP_TYPE_ALL_IN
                : RATE_PER_TRIP_TYPE_LINE_HAUL_ONLY}
            </WarningItem>
          )}
        {customer && type === RuleTypeName.RatePerMile && (
          <WarningItem>{INSERT_LINE_HAUL_ONLY_RATE_HERE}</WarningItem>
        )}
      </Box>
    </Box>
  );

  const renderDATForm = (): JSX.Element => (
    <Box>
      <Stack direction="row">
        <Box marginRight={2} width="calc(28% - 16px)">
          <SgSelect
            label="DAT rate"
            name="datRate"
            placeholder="Select mode"
            items={datRateOptions}
            formik={formik}
            defaultValue={DatRateDirection.Min}
          />
        </Box>
        <Box marginRight={2} width="calc(22% - 16px)">
          <SgSelect
            label="Action"
            name="datRateAction"
            placeholder="Select mode"
            items={datActionOptions}
            formik={formik}
            defaultValue={DatActionValue.Increase}
          />
        </Box>
        <Box width="50%">
          <SgTextField
            formik={formik}
            fullWidth
            name="rateAmount"
            label="Amount"
            size="medium"
            placeholder="0.00"
            InputProps={{
              startAdornment: getRateValueStartAdornment<CreateRuleFormModel>(
                formik,
                'rateValue',
              ),
            }}
            variant="standard"
            valueFormatter={decimalFormatter}
          />
        </Box>
      </Stack>

      {renderRateLimitForm()}

      <Typography variant="h4" marginTop={2}>
        {getDATFinalText()}
      </Typography>

      <Box marginTop={2}>
        <WarningItem>{SAGAN_OPERATES_WITH_DAT_RATES}</WarningItem>
      </Box>
    </Box>
  );

  return (
    <Box
      borderRadius="4px"
      border={`2px solid #EBEDED`}
      padding={3}
      minHeight="148px"
    >
      {type === RuleTypeName.DATBasedRate ? renderDATForm() : renderRateForm()}
    </Box>
  );
}
