const RULES_API = '/Rules';

export function rules(): string {
  return RULES_API;
}

export function editRulePriority(): string {
  return `${RULES_API}/EditPriority`;
}

export function datPodsCoverage(): string {
  return `${RULES_API}/DatPodsCoverage`;
}

export function validateRule(): string {
  return `${RULES_API}/Validate`;
}

export function createRule(): string {
  return `${RULES_API}/Create`;
}

export function editRule(ruleId: string | number): string {
  return `${RULES_API}/${ruleId}/Edit`;
}

export function deleteRule(ruleId: string | number): string {
  return `${RULES_API}/${ruleId}/Delete`;
}
