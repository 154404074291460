import { useEffect } from 'react';
import { appConfig } from './constants';
import { msalInstance } from './index';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { InterceptorService } from './services/interceptrors-service';
import { fetchUserData } from './redux/userData';
import { useAppDispatch } from './redux/hooks';

export function useMsalEffects(pca: IPublicClientApplication) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const init = () => {
      InterceptorService.init(appConfig, pca).then(() => {
        dispatch(fetchUserData());
      });
    };

    const addEvents = () =>
      msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
          const payload = event.payload as AuthenticationResult;
          const account = payload.account;
          msalInstance.setActiveAccount(account);

          init();
          return;
        }
      });

    msalInstance.enableAccountStorageEvents();

    const account = msalInstance.getActiveAccount();

    if (!account) {
      addEvents();

      return;
    }

    init();
  }, []);
}
