import { FiltersDataModel, FiltersDataState } from './filtersData.types';
import { filtersDataStoreKey } from './filtersData.const';
import { createSelector } from 'reselect';

interface AppStateWithFiltersData {
  [filtersDataStoreKey]: FiltersDataState;
}

const selectFiltersDataState = (state: AppStateWithFiltersData) => {
  return state[filtersDataStoreKey];
};

const getLoading = (state: AppStateWithFiltersData): boolean => {
  return (
    selectFiltersDataState(state).loading ||
    selectFiltersDataState(state).baseLoading ||
    selectFiltersDataState(state).usersLoading
  );
};

const getBaseFiltersLoading = (state: AppStateWithFiltersData): boolean => {
  return selectFiltersDataState(state).baseLoading;
};

const getFilters = (state: unknown): Partial<FiltersDataModel> => {
  return selectFiltersDataState(state as AppStateWithFiltersData).filters;
};

const getFiltersByPageName =
  (pageName: string) =>
  (state: AppStateWithFiltersData): Partial<FiltersDataModel> => {
    const filtersOnPage = selectFiltersDataState(
      state as AppStateWithFiltersData,
    ).filtersOnPage;

    return (filtersOnPage && filtersOnPage[pageName]) || {};
  };

const selectFiltersByPageName = (pageName: string, params?: string) =>
  createSelector(
    getFilters,
    getFiltersByPageName(pageName),
    (filters, filtersOnPage) => {
      if (params === 'Hot') {
        return filters;
      }
      return {
        ...filters,
        ...filtersOnPage,
      };
    },
  );

export const filtersDataSelectors = {
  getLoading,
  getBaseFiltersLoading,
  getFilters,
  selectFiltersByPageName,
};
