import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useSgTableStyles = (): SerializedStyles => {
  const { palette } = useTheme();

  return css`
    height: fit-content;

    &.MuiTable-root > .MuiTableBody-root > tr {
      &.submitted-row td {
        color: ${palette.sagan.contrastText};
      }

      &:not(.expandable-row) > td {
        border-bottom: none;
        border-top: 1px solid rgba(224, 224, 224, 1);
      }

      &:first-of-type > td {
        border-top: none;
      }

      &:last-of-type > td {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      &.focused-row:not(.expanded-main-row) > td,
      &.draggableRowMoving > td {
        border-bottom: 1px solid ${palette.primary.main};

        &:first-of-type {
          border-radius: 4px 0 0 4px;
        }

        &:last-of-type {
          border-radius: 0 4px 4px 0;
        }
      }

      &.expanded-main-row td {
        &:first-of-type {
          border-radius: 4px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 4px 0 0;
        }
      }

      &.expanded-main-row td,
      &.focused-row td,
      &.draggableRowMoving td {
        border-top: 1px solid ${palette.primary.main};

        &:first-of-type {
          border-left: 1px solid ${palette.primary.main};
        }

        &:last-of-type {
          border-right: 1px solid ${palette.primary.main};
        }
      }

      &.draggableRow {
        td .dragElement {
          cursor: grab;
        }

        td .inactiveDragElement {
          cursor: not-allowed;
        }

        &.draggableRowMoving td .dragElement {
          cursor: grabbing;
        }
      }

      &.expanded-details-row > td.MuiTableCell-root {
        border-bottom: 1px solid ${palette.primary.main};
        border-radius: 0;
      }

      &.expanded-details-row > td.MuiTableCell-root:first-of-type {
        border-bottom: 1px solid ${palette.primary.main};
        border-left: 1px solid ${palette.primary.main};
        border-radius: 0 0 0 4px;
      }

      &.expanded-details-row > td.MuiTableCell-root:last-of-type {
        border-bottom: 1px solid ${palette.primary.main};
        border-right: 1px solid ${palette.primary.main};
        border-radius: 0 0 4px 0;
      }

      &.expanded-details-row + tr > td,
      &.focused-row + tr + tr > td {
        border-top: none !important;
      }
    }

    &.MuiTable-stickyHeader .MuiTableHead-root .MuiTableCell-stickyHeader {
      top: 0;
    }

    .MuiTableCell-root.MuiTableCell-head {
      &:not(.MuiTableCell-alignRight)
        .MuiTableSortLabel-root
        .MuiTableSortLabel-icon {
        margin-right: -8px;
      }

      &.MuiTableCell-alignRight
        .MuiTableSortLabel-root
        .MuiTableSortLabel-icon {
        margin-left: -8px;
      }
    }
  `;
};
