import { ResponsivePie } from '@nivo/pie';
import { DashboardBreakdownResponse } from '../../../types';
import { Typography, Skeleton, Box, Stack, Tooltip } from '@mui/material';
import { is, percentFormatter } from '../../../utils';
import { pieLegendItemsOrder } from './PieChart.const';
import { useGetChartData, CenteredMetric } from './PieChart.utils';
import { PieDataItem } from './PieChart.types';
import { renderChartLegend } from '../tooltip/Tooltip';

interface Props {
  height: number;
  data?: DashboardBreakdownResponse | null;
}

export function PieChart({ data, ...props }: Props): JSX.Element {
  const [chartData, legendData] = useGetChartData(data);

  const renderPieItem = (
    { label, color, value, percent }: PieDataItem,
    tooltip: boolean,
  ) =>
    renderChartLegend(
      {
        label: !tooltip
          ? label
          : `${label}: ${value} (${percentFormatter(percent)})`,
        color,
      },
      tooltip,
    );

  if (is.undefined(data)) {
    return (
      <Skeleton
        sx={{ height: props.height }}
        animation="wave"
        variant="rectangular"
      />
    );
  }

  if (!chartData) {
    return <Typography padding={3}>No data</Typography>;
  }

  return (
    <Box>
      <Box height={props.height}>
        <ResponsivePie
          margin={{ top: 25, right: 85, bottom: 25, left: 85 }}
          animate
          activeOuterRadiusOffset={4}
          activeInnerRadiusOffset={4}
          cornerRadius={2}
          colors={{ datum: 'data.color' }}
          data={chartData}
          innerRadius={0.7}
          enableArcLabels={false}
          tooltip={({ datum: { data } }) => renderPieItem(data, true)}
          arcLinkLabel={(d) => {
            return `${d.formattedValue} (${percentFormatter(d.data.percent)})`;
          }}
          arcLinkLabelsDiagonalLength={7}
          arcLinkLabelsSkipAngle={0.1}
          layers={['arcs', 'arcLabels', 'arcLinkLabels', CenteredMetric]}
          {...props}
        />
      </Box>
      <Stack direction="row" flexWrap="wrap" padding={3} paddingTop={1}>
        {pieLegendItemsOrder.map((itemName) => (
          <Tooltip
            key={legendData[itemName].label}
            title={`${legendData[itemName].label}: ${
              legendData[itemName].value
            } (${percentFormatter(legendData[itemName].percent)})`}
            placement="top"
            enterDelay={400}
          >
            {renderPieItem(legendData[itemName], false)}
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
}
