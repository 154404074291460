import { FreightQuoteActionModel } from '../../types';
import { EmptyElement } from '../../components';
import { RuleModeValue } from '../../constants';
import React from 'react';
import { AutoBidInTimer } from '../common/components';
import { is } from '../../utils';

export function renderAutoBidInTimer(
  item: FreightQuoteActionModel,
  onSubmit: (bid: number | string) => void,
  disabled?: boolean,
): JSX.Element {
  if (
    is.nullOrUndefined(item.autoBidIn) ||
    (item.AutomatedProcessing?.RuleMode !== RuleModeValue.AutoBid &&
      item.AutomatedProcessing?.RuleMode !== RuleModeValue.AutoIgnore)
  ) {
    return EmptyElement;
  }

  return (
    <AutoBidInTimer quote={item} disabled={disabled} onSubmit={onSubmit} />
  );
}
