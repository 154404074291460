import { Box, Stack, Tooltip, Typography } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import {
  FreightQuoteActionModel,
  QuoteShortLocationUIExtended,
  StringDate,
  UserBaseModel,
} from '../../types';
import {
  BID_WAS_SUBMITTED_BY_UNKNOWN_USER,
  BID_WAS_SUBMITTED_NOT_IN_SAGAN,
  PC_MILER_DISTANCE_MESSAGE,
  QuoteStatus,
  quoteStatusToTabName,
  RuleModeValue,
  TabName,
} from '../../constants';
import pcMilerIcon from '../../assets/pcMiler.svg';
import { is } from '../is';
import { priceFormat, pricePerMileFormat } from './jsxFormats';
import { locationFormatter } from './locationFormatter';
import {
  isoToDateString,
  isoToMonthDateString,
  isoDateFormatter,
} from './dateFormatter';
import { numberFormat } from './numberFormatter';
import { PropsWithChildren } from 'react';
import { DescriptionItem, EmptyElement } from '../../components';

export function getBidUser(quote: FreightQuoteActionModel): string | null {
  const { FirstName = '', LastName = '', Email = '' } = quote.User || {};
  const name = quote.User
    ? `${FirstName} ${LastName}`.trim() ||
      Email ||
      BID_WAS_SUBMITTED_BY_UNKNOWN_USER
    : BID_WAS_SUBMITTED_NOT_IN_SAGAN;

  if (
    quote.Status === QuoteStatus.Open ||
    (quote.Status === QuoteStatus.Missed && is.nullOrUndefined(quote.SageBid))
  ) {
    return null;
  }

  return name;
}

export function AutomatedProcessingIndicator({
  quote,
  flex,
  children,
  hidden,
}: PropsWithChildren<{
  quote: FreightQuoteActionModel;
  flex?: boolean;
  hidden?: boolean;
}>): JSX.Element {
  if (
    hidden ||
    !quote.AutomatedProcessing ||
    (quote.AutomatedProcessing.RuleMode !== RuleModeValue.AutoIgnore &&
      quote.AutomatedProcessing.RuleMode !== RuleModeValue.AutoBid)
  ) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={
        flex
          ? { display: 'flex', justifyContent: 'end', alignItems: 'center' }
          : { position: 'relative' }
      }
    >
      <Tooltip
        arrow
        placement="left"
        PopperProps={{
          className: 'customStopsTooltip',
        }}
        title={
          <div style={{ width: '140px', paddingBottom: '4px' }}>
            <Typography
              sx={{ marginBottom: 1 }}
              variant="subtitle2"
              fontWeight={600}
            >
              {quote.AutomatedProcessing?.RuleMode === RuleModeValue.AutoIgnore
                ? 'Auto-Ignore'
                : 'Auto-Bid'}
            </Typography>
            <DescriptionItem
              sx={{ marginBottom: 1 }}
              title="Rule name"
              message={quote.AutomatedProcessing?.RuleName}
            />
            <DescriptionItem
              title="User"
              message={quote.AutomatedProcessing?.RuleAuthorEmail}
            />
          </div>
        }
      >
        <BoltIcon
          sx={
            flex
              ? { marginRight: '5px', marginLeft: '-16px' }
              : { position: 'absolute', left: '-20px', top: '5px' }
          }
          fontSize="small"
          color="primary"
        />
      </Tooltip>
      {children}
    </Box>
  );
}

export function rowSageBid(
  item: FreightQuoteActionModel,
  isSearchPage?: boolean,
  tabName?: TabName,
): JSX.Element {
  const currentTabName =
    tabName ||
    (isSearchPage && item.Status ? quoteStatusToTabName[item.Status] : null);
  const bidUserName = getBidUser(item);
  const hideAutoBidIndicator =
    currentTabName !== TabName.Submitted &&
    currentTabName !== TabName.Won &&
    currentTabName !== TabName.Lost &&
    currentTabName !== TabName.Ignored;

  if (bidUserName === null) {
    return (
      <AutomatedProcessingIndicator
        hidden={hideAutoBidIndicator}
        flex
        quote={item}
      >
        <Box>{priceFormat(item.SageBid)}</Box>
      </AutomatedProcessingIndicator>
    );
  }

  return (
    <AutomatedProcessingIndicator
      hidden={hideAutoBidIndicator}
      flex
      quote={item}
    >
      <Tooltip enterDelay={300} placement="top" title={bidUserName}>
        <Box>{priceFormat(item.SageBid)}</Box>
      </Tooltip>
    </AutomatedProcessingIndicator>
  );
}

export function quoteDistanceFormatter(
  item: FreightQuoteActionModel,
): JSX.Element | string {
  const { PCMilerDistance, Distance } = item;

  if (!Distance) {
    return (
      <Stack direction="row">
        {numberFormat(PCMilerDistance, { fractionDigits: 0 })}
        <Tooltip
          arrow
          enterDelay={300}
          placement="top"
          title={PC_MILER_DISTANCE_MESSAGE}
        >
          <img src={pcMilerIcon} style={{ marginLeft: '8px' }} />
        </Tooltip>
      </Stack>
    );
  }

  return numberFormat(Distance, { fractionDigits: 0 });
}

export function rowLocationFormatter(
  item: FreightQuoteActionModel,
  name: keyof FreightQuoteActionModel,
): string | JSX.Element {
  return locationFormatter(item[name] as QuoteShortLocationUIExtended);
}

export function rowIsoToDateString(
  item: FreightQuoteActionModel,
  name: keyof FreightQuoteActionModel,
): string | JSX.Element {
  return isoToDateString(item[name] as StringDate);
}

export function rowIsoToDateMonthString(
  item: FreightQuoteActionModel,
  name: keyof FreightQuoteActionModel,
): string | JSX.Element {
  return isoToMonthDateString(item[name] as StringDate);
}

export function rowIsoToDateWithTooltip({
  date,
  time,
  tooltip,
  timezone,
  style,
}: {
  date: StringDate;
  time?: string | null;
  tooltip?: string | null;
  timezone?: string | null;
  style?: object;
}): string | JSX.Element {
  const formattedDate = time
    ? isoToMonthDateString(date)
    : isoDateFormatter(date, 'MM/dd, h:mma', timezone);

  return (
    <Tooltip
      arrow
      enterDelay={300}
      placement="top"
      title={tooltip || (date ? isoDateFormatter(date, 'EEEE', timezone) : '')}
    >
      {date ? (
        <Box sx={style}>
          {formattedDate}
          {time ? `, ${time}` : ''}
        </Box>
      ) : (
        EmptyElement
      )}
    </Tooltip>
  );
}

export function rowBidBy(user: UserBaseModel | null): string | JSX.Element {
  return user ? (
    `${user.FirstName} ${user.LastName}`
  ) : (
    <Tooltip
      enterDelay={300}
      placement="top"
      title={BID_WAS_SUBMITTED_NOT_IN_SAGAN}
    >
      {EmptyElement}
    </Tooltip>
  );
}

export function rowSageBidPerMile(
  data: FreightQuoteActionModel,
): string | JSX.Element {
  const pricePerMile = pricePerMileFormat(
    data.Distance,
    data.SageBid || data.LowestBid,
  );

  return (
    <Box sx={{ minWidth: 125 }}>
      {`${priceFormat(data.SageBid || data.LowestBid)} (${
        pricePerMile ? `${pricePerMile}/m` : ''
      })`}
    </Box>
  );
}
