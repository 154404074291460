import {
  DialogWrapper,
  DialogWrapperBaseProps,
} from '../../components/dialogWrapper/DialogWrapper';
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Typography,
} from '@mui/material';
import { weightFormat } from '../../utils';
import { FreightQuoteCommodities } from '../../types';
import { ValueOrEmpty } from '../../components';

interface Props extends DialogWrapperBaseProps {
  data: FreightQuoteCommodities[];
  weight: number | null;
}

export function CommoditiesDialog({
  data,
  weight,
  onClosed,
}: Props): JSX.Element {
  const footer = () => (
    <Box
      sx={{
        backgroundColor: 'background.panel',
        paddingX: 1,
        paddingY: 2,
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Typography variant="h4" align="right">
        Total weight: {weightFormat(weight)}
      </Typography>
    </Box>
  );

  const content = data.length ? (
    data.map(({ Id, Description, Weight }, index) => (
      <TableRow key={Id}>
        <TableCell width={10}>{index + 1}</TableCell>
        <TableCell>{Description}</TableCell>
        <TableCell align="right">
          <ValueOrEmpty>{weightFormat(Weight)}</ValueOrEmpty>
        </TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={3}>No data</TableCell>
    </TableRow>
  );

  return (
    <DialogWrapper
      onClosed={onClosed}
      title="Commodities"
      renderFooter={footer}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell variant="head" width={10}>
              #
            </TableCell>
            <TableCell variant="head">Description</TableCell>
            <TableCell variant="head" align="right">
              Weight
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{content}</TableBody>
      </Table>
    </DialogWrapper>
  );
}
