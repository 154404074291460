/** @jsxImportSource @emotion/react */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect, PropsWithChildren } from 'react';
import { debounce } from '../../utils/debounce';
import { useDialogWrapperStyles } from './DialogWrapper.styles';
import { LoadingButton } from '@mui/lab';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { zIndexes } from '../../theme';

export interface DialogWrapperMainProps {
  loading?: boolean;
  width?: number;
  renderFooter?: () => JSX.Element;
  closeLabel?: string;
  closeVariant?: 'outlined' | 'contained';
  closeIcon?: OverridableComponent<SvgIconTypeMap>;
  onClosed?: () => void;
  submitLabel?: string;
  submitColor?: 'primary' | 'error';
  disabledSubmit?: boolean;
  noActions?: boolean;
  noPadding?: boolean;
  actionsPadding?: number;
  preventSubmit?: boolean;
  preventCloseIcon?: boolean;
}

export interface DialogWrapperBaseProps extends DialogWrapperMainProps {
  onSubmit?: () => void;
  onDecline?: () => void;
}

interface Props extends DialogWrapperBaseProps {
  title: string;
}

export function DialogWrapper({
  title,
  loading,
  width = 550,
  submitLabel = 'Submit',
  closeLabel = 'Close',
  closeVariant,
  closeIcon: CloseIconEntity = CloseIcon,
  children,
  renderFooter,
  onClosed,
  onSubmit,
  onDecline,
  disabledSubmit,
  noActions,
  noPadding,
  actionsPadding,
  preventSubmit,
  submitColor,
  preventCloseIcon,
}: PropsWithChildren<Props>): JSX.Element {
  const styles = useDialogWrapperStyles(width);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open || !onClosed) {
      return;
    }

    debounce(() => {
      onClosed();
    }, 500);
  }, [open]);

  useEffect(() => {
    return () => {
      debounce.decline();
    };
  }, []);

  const renderDialogContent = () => (
    <>
      <DialogTitle sx={{ padding: 4 }} variant="h2">
        {title}
        {!preventCloseIcon && (
          <IconButton
            type="button"
            size="small"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: '22px',
              top: '28px',
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <CloseIconEntity />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ paddingY: 0, paddingX: noPadding ? 0 : 4 }}>
        {children}
      </DialogContent>
      {renderFooter && (
        <DialogContent sx={{ paddingY: 0, paddingX: 4, flex: 'none' }}>
          {renderFooter()}
        </DialogContent>
      )}
      {noActions ? (
        <DialogContent sx={{ padding: 0, paddingBottom: 1 }} />
      ) : (
        <DialogActions sx={{ padding: 4, paddingTop: actionsPadding || 4 }}>
          <Button
            variant={closeVariant || 'outlined'}
            type="button"
            onClick={onDecline || handleClose}
          >
            {closeLabel}
          </Button>
          {onSubmit && (
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={onSubmit}
              disabled={disabledSubmit || loading}
              type={preventSubmit ? 'button' : 'submit'}
              color={submitColor}
            >
              {submitLabel}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </>
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      css={styles}
      sx={{ zIndex: zIndexes.DIALOG }}
    >
      {onSubmit ? (
        <form onSubmit={onSubmit}>{renderDialogContent()}</form>
      ) : (
        renderDialogContent()
      )}
    </Dialog>
  );
}
