import { Stack, Box, Typography } from '@mui/material';
import { is } from '../../../utils';

interface Props {
  label: string;
  color: string;
}

export const renderChartLegend = (
  data: Props | Props[],
  tooltip = true,
): JSX.Element => {
  const dataArr = is.array(data) ? data : [data];
  const isOne = dataArr.length === 1;

  const styles = {
    backgroundColor: 'rgba(97, 97, 97, 0.92)',
    borderRadius: '4px',
    color: '#FFF',
    padding: '4px 8px',
  };

  const renderItem = ({ label, color }: Props, tooltipStyle: boolean) => (
    <Stack
      key={label}
      sx={tooltipStyle ? styles : {}}
      direction="row"
      alignItems="baseline"
      marginRight={2}
    >
      <Box bgcolor={color} width="12px" height="12px" marginRight={1} />
      <Typography variant="h5">{label}</Typography>
    </Stack>
  );

  const renders = dataArr.map((item) => renderItem(item, isOne && tooltip));

  if (renders.length === 1) {
    return renders[0];
  }

  return <Box sx={tooltip ? styles : {}}>{renders}</Box>;
};
