import { ApexOptions } from 'apexcharts';
import { StringDate } from '../../../types';
import { TotalRevenueGroupBy } from '../../../constants';
import {
  isoToMonthYearString,
  isoToMonthDateString,
  numberFormat,
} from '../../../utils';

export function formatDateDueToGroupBy(
  date: StringDate,
  groupBy: TotalRevenueGroupBy,
): string {
  if (groupBy === TotalRevenueGroupBy.Month) {
    return isoToMonthYearString(date);
  }

  return isoToMonthDateString(date);
}

const labelProps = {
  formatter: (value: number) => {
    return numberFormat(value);
  },
};

export const getApexOptions = (
  categories: Array<string | number>,
  maxValue: number,
  fullScreen?: boolean,
): ApexOptions => ({
  chart: {
    stacked: false,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: [1, 1, 1, 1, 3],
  },
  xaxis: {
    categories,
  },
  yaxis: [
    {
      max: maxValue,
      min: 0,
      decimalsInFloat: 0,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#C1C6C8',
      },
      labels: {
        ...labelProps,
        style: {
          colors: '#000',
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    {
      show: false,
      max: maxValue,
      min: 0,
      opposite: false,
      axisTicks: {
        show: false,
      },
      labels: {
        ...labelProps,
      },
    },
    {
      max: maxValue,
      min: 0,
      show: false,
      opposite: false,
      axisTicks: {
        show: false,
      },
      labels: {
        ...labelProps,
      },
    },
    {
      max: maxValue,
      min: 0,
      show: false,
      opposite: false,
      axisTicks: {
        show: false,
      },
      labels: {
        ...labelProps,
      },
    },
    {
      seriesName: 'Revenue',
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#C1C6C8',
      },
      labels: {
        ...labelProps,
        style: {
          colors: '#000',
        },
      },
    },
  ],
  tooltip: {
    shared: true,
    intersect: false,
    fixed: fullScreen
      ? {
          enabled: true,
          position: 'topLeft',
          offsetX: 65,
        }
      : {},
  },
  legend: {
    itemMargin: {
      vertical: 16,
      horizontal: 8,
    },
    horizontalAlign: 'left',
    offsetX: -10,
  },
});
