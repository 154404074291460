import { Button, Stack, Typography } from '@mui/material';
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import React from 'react';
import { priceFormat } from '../../../../utils';

interface Props {
  bid: number;
  disabled?: boolean;
  onApply: () => void;
  align?: 'vertical' | 'horizontal';
}

export function SuggestedBid({
  bid,
  disabled,
  onApply,
  align = 'horizontal',
}: Props): JSX.Element {
  return (
    <Stack
      padding={1}
      paddingBottom={0}
      direction={align === 'horizontal' ? 'row' : 'column'}
      sx={{ backgroundColor: 'background.blue' }}
      borderRadius="4px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        marginRight={1}
        marginBottom={1}
        sx={{ width: '100%' }}
      >
        <Stack direction="row" marginRight={1}>
          <LightbulbRoundedIcon />
          <Typography marginLeft="4px">Suggested bid</Typography>
        </Stack>

        <Typography whiteSpace="nowrap">{priceFormat(bid, '$ ')}</Typography>
      </Stack>

      <Button
        sx={{ marginBottom: 1 }}
        size="small"
        variant="outlined"
        type="button"
        onClick={onApply}
        disabled={disabled}
      >
        <Typography variant="subtitle2" fontWeight={600} lineHeight="16px">
          Apply
        </Typography>
      </Button>
    </Stack>
  );
}
