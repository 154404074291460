import { RuleApiModel } from '../../types';
import { EmptyElement, StatusBadge, StatusBadgeProps } from '../../components';
import { Box, Stack, Tooltip } from '@mui/material';
import {
  datActionLabel,
  datRateLabel,
  DATRateValue,
  datRateValueLabel,
  RuleMode,
  RuleRateActionMode,
  ruleRateActionModeToDATActionValue,
  ruleRateActionModeToDATRateValue,
  RuleStatus,
  RuleType,
  ruleTypeLabels,
  ruleTypeToDATRateDirection,
} from '../../constants';
import React from 'react';

export function renderDescriptionFormatter(rule: RuleApiModel): JSX.Element {
  if (!rule.Description) {
    return EmptyElement;
  }

  return (
    <Tooltip title={rule.Description}>
      <span>{rule.Description}</span>
    </Tooltip>
  );
}

export function renderStatusFormatter(
  status: RuleStatus,
  ruleStatusLabels: Record<number, string>,
): JSX.Element {
  const statuses = {
    [RuleStatus.Active]: 'success',
    [RuleStatus.Deactivated]: 'error',
    [RuleStatus.Draft]: 'disabled',
  };

  return (
    <Stack direction="row">
      <StatusBadge
        type={statuses[status] as StatusBadgeProps['type']}
        variant="outlined"
        background="none"
        size="medium"
      >
        {ruleStatusLabels[status]}
      </StatusBadge>
    </Stack>
  );
}

export function renderRateActionModeFormatter(
  rule: RuleApiModel,
): JSX.Element | null {
  const { Type, Mode, RateActionMode, RateAmount } = rule;

  if (Mode === RuleMode.AutoIgnore) {
    return null;
  }

  const label = ruleTypeLabels[Type];

  const rateValue = ruleTypeToDATRateDirection[Type];
  const datRateValue =
    ruleRateActionModeToDATRateValue[
      RateActionMode || RuleRateActionMode.AddAbsolute
    ];
  const datActionValue =
    ruleRateActionModeToDATActionValue[
      RateActionMode || RuleRateActionMode.AddAbsolute
    ];

  const title =
    Type == RuleType.RatePerTrip || Type == RuleType.RatePerMile
      ? `${RateAmount || 0} ${datRateValueLabel[DATRateValue.USD]}`
      : `${datRateLabel[rateValue]} DAT rate ${
          datActionLabel[datActionValue]
        } ${RateAmount || 0} ${datRateValueLabel[datRateValue]}`;

  const content = (
    <StatusBadge type="disabled" hover size="medium">
      {label}
    </StatusBadge>
  );

  return (
    <Stack direction="row">
      <Tooltip
        arrow
        PopperProps={{
          className: 'white',
        }}
        title={title}
        placement="left"
        enterDelay={400}
      >
        <Box>{content}</Box>
      </Tooltip>
    </Stack>
  );
}
