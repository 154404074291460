import { BaseFilters, QuoteShortLocationUIExtended } from '../../../../types';
import {
  SGAutocomplete,
  SGAutocompleteValue,
} from '../../../formElements/autocomplete/SGAutocomplete';
import { renderFilterTag } from '../../Filters.utils';
import React from 'react';
import { filterLocationOptions } from './LocationAutocomplete.utils';

interface Props {
  label: string;
  name: keyof BaseFilters;
  value: QuoteShortLocationUIExtended[];
  disabled?: boolean;
  options: QuoteShortLocationUIExtended[];
  onChange?: (value: SGAutocompleteValue<QuoteShortLocationUIExtended>) => void;
}

export function RenderLocationAutocomplete({
  label,
  name,
  value,
  disabled,
  options,
  onChange,
}: Props): JSX.Element {
  const isLocationsEquals = (
    location1: QuoteShortLocationUIExtended | null,
    location2: QuoteShortLocationUIExtended | null,
  ): boolean => {
    if (!location1 && !location2) {
      return true;
    }

    return (
      !!location1 &&
      !!location2 &&
      location1.CityName === location2.CityName &&
      location1.CountryName === location2.CountryName &&
      location1.ProvinceAbbr === location2.ProvinceAbbr
    );
  };

  return (
    <SGAutocomplete<QuoteShortLocationUIExtended>
      label={label}
      name={name}
      id={`${name}-filter`}
      placeholder="All locations"
      disabled={disabled}
      options={options}
      value={value || []}
      selectAllLabel="All"
      groupBy={(option) => option?.ProvinceFullName || ''}
      getOptionLabel={(option) =>
        option && `${option.CityName}, ${option.ProvinceAbbr}`
      }
      getFilterOptionLabel={(option) =>
        option &&
        `${option.CityName} ${option.ProvinceName} ${option.ProvinceAbbr}`
      }
      filterOptions={filterLocationOptions}
      getOptionDisabled={(option) => !!option?.disabled}
      renderTags={renderFilterTag}
      onChange={onChange}
      isOptionEqualToValue={isLocationsEquals}
    />
  );
}
