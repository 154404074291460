import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { podCoverageStoreKey } from './podCoverage.const';
import { PodCoverageOrderBy, PodCoverageState } from './podCoverage.types';
import { fetchPodCoverage } from './podCoverage.thunks';
import { orderPodsByField } from './podCoverage.utils';
import { PodCoverageItem } from '../../types';
import { sortingDirection } from '../../constants';

const initialState: PodCoverageState = {
  loading: false,
  data: [],
  order: {
    orderBy: 'DatPod',
    orderDirection: sortingDirection.Asc,
  },
};

export const podCoverageSlice = createSlice({
  name: podCoverageStoreKey,
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.data = [];
      state.order = { ...initialState.order };
    },
    setOrder: (state, { payload }: PayloadAction<PodCoverageOrderBy>) => {
      state.data = orderPodsByField(state.data, { ...state.order, ...payload });
      state.order = { ...state.order, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPodCoverage.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPodCoverage.fulfilled,
        (state, { payload }: PayloadAction<PodCoverageItem[]>) => {
          state.loading = false;
          state.data = orderPodsByField(payload, state.order || {});
        },
      );
  },
});
