import {
  PaginationItem,
  Pagination,
  Stack,
  Box,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { itemsPerPage } from '../../../../constants';

interface Props {
  skip?: number;
  top?: number;
  total?: number;
  disabled?: boolean;
  onChange: (skip: number, top: number) => void;
}

export function SgPagination({
  skip = 0,
  top,
  total = 0,
  disabled,
  onChange,
}: Props): JSX.Element | null {
  const currentTop = top || itemsPerPage[0];

  if (!total || total <= itemsPerPage[0]) {
    return null;
  }

  const pages = total && Math.ceil(total / currentTop);
  const currentPage = (skip ? skip / currentTop : 0) + 1;

  const onPageChanged = (newPage: number) => {
    if (newPage === currentPage) {
      return;
    }

    onChange((newPage - 1) * currentTop, currentTop);
  };

  const onCountChanged = (newTop: number) => {
    if (newTop === currentTop) {
      return;
    }

    onChange(0, newTop);
  };

  return (
    <Stack direction="row" marginTop={3}>
      <Box sx={{ marginRight: 8 }}>
        <Pagination
          disabled={disabled}
          page={currentPage}
          count={pages}
          onChange={(_, val) => onPageChanged(val)}
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: (() => (
                  <Typography>Previous</Typography>
                )) as unknown as FunctionComponent<unknown>,
                next: (() => (
                  <Typography>Next</Typography>
                )) as unknown as FunctionComponent<unknown>,
              }}
              {...item}
            />
          )}
        />
      </Box>
      <Stack direction="row" alignItems="center">
        <Typography sx={{ marginRight: 1 }}>Items per page: </Typography>
        <Select
          size="small"
          variant="outlined"
          value={currentTop}
          disabled={disabled}
          onChange={(val) => onCountChanged(Number(val.target.value))}
        >
          {itemsPerPage.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
}
