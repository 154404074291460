import { Typography } from '@mui/material';
import { FreightQuoteActionModel } from '../../../../types';
import { EmptyElement } from '../../../../components';

interface Props {
  quote: FreightQuoteActionModel;
}

export function TimerCell({ quote }: Props): JSX.Element {
  if (!quote.RespondBy || !quote.respondByTimer) {
    return EmptyElement;
  }

  if (quote.respondByTimer.minutesLeft < 10) {
    return (
      <Typography color="error">{quote.respondByTimer.minutes}m</Typography>
    );
  }

  return <Typography>{quote.respondByTimer.string}</Typography>;
}
