import { createTheme } from '@mui/material/styles';
import './global-styles.css';
import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';

export * from './variables';

interface SaganStatusColors {
  open: string;
  submitted: string;
  ignored: string;
  lost: string;
  missed: string;
  won: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    sagan: Palette['primary'];
    saganStatus: SaganStatusColors;
  }

  interface PaletteOptions {
    sagan: PaletteOptions['primary'];
    saganStatus: SaganStatusColors;
  }

  interface TypeBackground {
    badge: string;
    default: string;
    paper: string;
    panel: string;
    blue: string;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    sagan: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    tiny: true;
  }
}

export const muiTheme = createTheme({
  palette,
  breakpoints,
  typography,
  spacing: (factor: number) => factor * 8,
  components: overrides,
});
