import { DashboardState } from './dashboard.types';
import { dashboardStoreKey } from './dashboard.const';
import {
  FiltersModel,
  DashboardBreakdownResponse,
  DashboardTotalRevenueResponse,
} from '../../types';

export interface AppStateWithDashboard {
  [dashboardStoreKey]: DashboardState;
}

const selectDashboardState = (state: AppStateWithDashboard) => {
  return state[dashboardStoreKey];
};

const getLoading = (state: AppStateWithDashboard): boolean => {
  return selectDashboardState(state).loading;
};

const getFilters = (state: AppStateWithDashboard): Partial<FiltersModel> => {
  return selectDashboardState(state).filters || {};
};

const getBreakdownData = (
  state: AppStateWithDashboard,
): DashboardBreakdownResponse | null | undefined => {
  return selectDashboardState(state).breakdownData;
};

const getTotalRevenueData = (
  state: AppStateWithDashboard,
): DashboardTotalRevenueResponse[] | null => {
  return selectDashboardState(state).totalRevenueData || null;
};

const getPerformanceData = (
  state: AppStateWithDashboard,
): number | null | undefined => {
  return selectDashboardState(state).performanceData;
};

export const dashboardSelectors = {
  getLoading,
  getFilters,
  getBreakdownData,
  getTotalRevenueData,
  getPerformanceData,
};
