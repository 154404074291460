import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiEndpoint } from '../../constants/api';
import { NotificationModel } from '../../types';
import { notificationStoreKey } from './notification.const';
import { QuoteActionResponse } from '../../types/bidToSubmit';
import { SubmitQuoteStatus } from '../../constants';

export const fetchAllNotifications = createAsyncThunk(
  `${notificationStoreKey}/fetchAllNotifications`,
  async (): Promise<NotificationModel[]> => {
    try {
      const { data } = await axios.get<NotificationModel[]>(
        apiEndpoint.notifications(),
      );

      return data;
    } catch (err) {
      return [];
    }
  },
);

export const readUserNotifications = createAsyncThunk(
  `${notificationStoreKey}/readUserNotifications`,
  async (notificationId: number): Promise<number | null> => {
    try {
      const { data } = await axios.post<QuoteActionResponse>(
        apiEndpoint.readNotifications(notificationId),
      );

      if (data && data.status === SubmitQuoteStatus.Success) {
        return notificationId;
      }

      return null;
    } catch (err) {
      return null;
    }
  },
);

export const readAllUserNotifications = createAsyncThunk(
  `${notificationStoreKey}/readAllUserNotifications`,
  async (): Promise<boolean> => {
    try {
      const { data } = await axios.post<QuoteActionResponse>(
        apiEndpoint.readAllNotifications(),
      );

      return data && data.status === SubmitQuoteStatus.Success;
    } catch (err) {
      return false;
    }
  },
);
