/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import React, { useEffect, useState } from 'react';
import { useTimeRangeInputStyles } from './TimeRangeInput.styles';
import { RangeFilter } from '../../../types';
import dayjs from 'dayjs';

interface Props {
  value: RangeFilter;
  onChange: (value?: RangeFilter) => void;
  label: string;
  labelTo?: string;
  placeholder: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}

export function TimeRangeInput({
  label,
  labelTo = '12:00 am',
  placeholder,
  value,
  onChange,
  disabled,
  error,
  helperText,
}: Props): JSX.Element {
  const styles = useTimeRangeInputStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState<RangeFilter>(value);

  const showValue =
    Object.keys(value).length > 0
      ? `${value.from || labelTo} - ${value.to || labelTo}`
      : placeholder;

  useEffect(() => {
    setRangeValue(value || []);
  }, [value]);

  const onRangeValueChange = (value: string, name: keyof RangeFilter) => {
    setRangeValue({
      ...rangeValue,
      [name]: String(value),
    });
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="group-by-select-label">{label}</InputLabel>
      <Select
        labelId="group-by-select-label"
        id="group-by-select"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        value={0}
        renderValue={() => showValue}
        disabled={disabled}
        error={error}
      >
        <MenuItem sx={{ display: 'none' }} value="0" />

        <Box css={styles}>
          <Box>
            <DesktopTimePicker
              value={rangeValue.from || ''}
              onChange={(newValue, rawValue) => {
                if (JSON.stringify(newValue) == 'null') {
                  onRangeValueChange(rawValue ?? '', 'from');
                  return;
                }
                const time = dayjs(newValue).format('hh:mm a');
                onRangeValueChange(time ?? '', 'from');
              }}
              renderInput={(params: any) => {
                params.inputProps.type = 'text';
                params.inputProps.readOnly = true;
                params.fullWidth;
                params.disabled = false;
                params.label = 'From';
                params.variant = 'standard';
                params.error = false;
                params.inputProps.value = rangeValue.from || '';
                return <TextField {...params} />;
              }}
            />
          </Box>
          <Box marginTop={1}>
            <DesktopTimePicker
              value={rangeValue.to || ''}
              onChange={(newValue, rawValue) => {
                if (JSON.stringify(newValue) == 'null') {
                  onRangeValueChange(rawValue ?? '', 'to');
                  return;
                }
                const time = dayjs(newValue).format('hh:mm a');
                onRangeValueChange(time ?? '', 'to');
              }}
              renderInput={(params: any) => {
                params.inputProps.type = 'text';
                params.fullWidth;
                params.disabled = false;
                params.label = 'To';
                params.variant = 'standard';
                params.inputProps.value = rangeValue.to || '';
                params.error = false;
                params.inputProps.readOnly = true;
                return <TextField {...params} />;
              }}
            />
          </Box>

          <Stack direction="row" marginTop={2}>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={() => {
                onChange();
                setRangeValue({});
                setIsOpen(false);
              }}
              sx={{ padding: '2px' }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={disabled}
              onClick={() => {
                onChange(rangeValue);
                setIsOpen(false);
              }}
              sx={{ padding: '2px', marginLeft: 1 }}
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </Select>
      {helperText && (
        <FormHelperText error={error}>
          <>{helperText}</>
        </FormHelperText>
      )}
    </FormControl>
  );
}
