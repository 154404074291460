/** @jsxImportSource @emotion/react */
import { Box, Collapse, TableCell, TableRow } from '@mui/material';
import { cn, isActionDisabled } from '../../../../utils';
import { FreightQuoteActionModel } from '../../../../types';
import React, { useEffect, useState } from 'react';
import { useDataGridRowStyles } from './DataGridRow.styles';
import { DataGridDetails } from '../dataGridDetails/DataGridDetails';
import { QuotePortalStatus, QuoteStatus } from '../../../../constants';
import { DataGridActions } from '../../DataGrid';
import { ColumnModel, SgTableRow } from '../../../../components';

interface Props extends DataGridActions {
  focused?: boolean;
  expanded?: boolean;
  columns?: ColumnModel<FreightQuoteActionModel>[];
  rowData: FreightQuoteActionModel;
  selected?: boolean;
  onSelect?: (value: boolean) => void;
}

export function DataGridRow({
  selectable,
  focused,
  expanded,
  rowData,
  columns,
  detailsSubmitColSpan = 1,
  disableActions,
  renderActions,
  renderDetailsSubmit,
  isRenderDetailsSubmit,
  selected,
  onSelect,
  onExpand,
}: Props): JSX.Element {
  const [open, setOpen] = useState(false);
  const styles = useDataGridRowStyles();

  useEffect(() => {
    setOpen(!!expanded);
  }, [expanded]);

  useEffect(() => {
    if (!onExpand) {
      return;
    }

    onExpand(rowData, open);
  }, [open]);

  const hasDetailsSubmit =
    (!isRenderDetailsSubmit || isRenderDetailsSubmit(rowData)) &&
    !!renderDetailsSubmit;

  const columnsCount =
    (columns || []).length +
    (renderActions && !hasDetailsSubmit ? 1 : 0) +
    (hasDetailsSubmit ? detailsSubmitColSpan * -1 : 0);
  const emptyColumnsCount = 1 + (selectable ? 1 : 0);
  const detailsSubmitColumnsCount =
    detailsSubmitColSpan + (renderActions ? 1 : 0);

  return (
    <>
      <SgTableRow<FreightQuoteActionModel>
        rowDisabled={
          rowData.Status === QuoteStatus.SubmitRequested ||
          rowData.Portal?.PortalStatus === QuotePortalStatus.NotAvailable ||
          (!!rowData.respondByTimer && !rowData.respondByTimer.minutesLeft)
        }
        focused={focused}
        rowExpanded={open}
        expandable
        onExpand={() => setOpen(!open)}
        selectable={selectable}
        checked={!!selected}
        checkboxDisabled={isActionDisabled(rowData)}
        onCheck={(e, value) => onSelect && onSelect(value)}
        columns={columns || []}
        rowData={rowData}
        disableActions={disableActions}
        renderActions={renderActions}
      />

      <TableRow
        sx={{ '&>td, &>th': { border: 0 }, verticalAlign: 'top' }}
        className={cn('expandable-row', open && 'expanded-details-row')}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={emptyColumnsCount}
        />
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columnsCount}
          css={styles}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DataGridDetails data={rowData} />
          </Collapse>
        </TableCell>
        {hasDetailsSubmit && !!renderDetailsSubmit && (
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingRight: '32px',
              position: 'relative',
            }}
            colSpan={detailsSubmitColumnsCount}
          >
            <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {renderDetailsSubmit(rowData, disableActions)}
              </Collapse>
            </Box>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}
