/** @jsxImportSource @emotion/react */
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { LookUpRatesModel } from '../../types';
import { numberFormat } from '../../utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { EqualIcon } from '../icons';
import { PropsWithChildren } from 'react';
import { useLookUpRateGridStyles } from './LookUpRateGrid.styles';

interface Props {
  lookUpRates?: Partial<LookUpRatesModel> | null;
}
interface RowProps {
  title: string;
  cost: string;
  fuel: string;
  allIn: string;
  fuelPerTrip: string;
  lineHaulPerMile: string;
  lineHaulPerTrip: string;
}

const BORDER_SIZE = 4;

function LookUpRateGridRow({
  cost,
  fuel,
  allIn,
  fuelPerTrip,
  lineHaulPerMile,
  lineHaulPerTrip,
  title,
  children,
}: PropsWithChildren<RowProps>): JSX.Element {
  return (
    <tr>
      <td>
        <Stack direction="row" alignItems="center">
          {children}
          <Typography variant="subtitle2" paddingLeft={1} color="common">
            {title}
          </Typography>
        </Stack>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {cost}
        </Typography>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {fuel}
        </Typography>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {lineHaulPerMile}
        </Typography>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {allIn}
        </Typography>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {fuelPerTrip}
        </Typography>
      </td>
      <td align="right">
        <Typography variant="subtitle2" textAlign="right" color="common">
          {lineHaulPerTrip}
        </Typography>
      </td>
    </tr>
  );
}

export function LookUpRateGrid({ lookUpRates }: Props): JSX.Element {
  const styles = useLookUpRateGridStyles();

  if (!lookUpRates) {
    return (
      <Skeleton sx={{ height: 80 }} animation="wave" variant="rectangular" />
    );
  }

  const thBorderStyle = {
    borderBottom: `${BORDER_SIZE}px solid #fff`,
  };

  const {
    MinRatePerTrip,
    MinRatePerMile,
    AvgRatePerTrip,
    AvgRatePerMile,
    MaxRatePerTrip,
    MaxRatePerMile,
    FuelPerMile,
    FuelPerTrip,

    LineHaulOnlyAvgRatePerMile,
    LineHaulOnlyAvgRatePerTrip,
    LineHaulOnlyMaxRatePerMile,
    LineHaulOnlyMaxRatePerTrip,
    LineHaulOnlyMinRatePerMile,
    LineHaulOnlyMinRatePerTrip,
  } = lookUpRates as Partial<LookUpRatesModel>;

  const format = (value?: number | null): string =>
    numberFormat(value, {
      cutZero: false,
    });

  return (
    <Box css={styles}>
      <table>
        <thead>
          <tr>
            <th
              rowSpan={2}
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            ></th>
            <th
              colSpan={3}
              style={{
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
              }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={600}
                textAlign="center"
                color="secondary"
              >
                Per mile
              </Typography>
            </th>
            <th
              colSpan={3}
              align="center"
              style={{ borderBottom: '1px solid #fff' }}
            >
              <Typography
                variant="subtitle2"
                fontWeight={600}
                textAlign="center"
                color="secondary"
              >
                Per trip
              </Typography>
            </th>
          </tr>
          <tr>
            <th
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            >
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                $
              </Typography>
            </th>
            <th
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            >
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                Fuel
              </Typography>
            </th>

            <th
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            >
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                LineHaul Only
              </Typography>
            </th>

            <th
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            >
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                All in
              </Typography>
            </th>
            <th
              style={{
                borderRight: '1px solid #fff',
                ...thBorderStyle,
              }}
            >
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                Fuel
              </Typography>
            </th>
            <th style={thBorderStyle}>
              <Typography
                variant="subtitle2"
                textAlign="right"
                color="secondary"
              >
                LineHaul Only
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          <LookUpRateGridRow
            title="Min"
            cost={format(MinRatePerMile)}
            fuel={format(FuelPerMile)}
            allIn={format(MinRatePerTrip)}
            fuelPerTrip={format(FuelPerTrip)}
            lineHaulPerMile={format(LineHaulOnlyMinRatePerMile)}
            lineHaulPerTrip={format(LineHaulOnlyMinRatePerTrip)}
          >
            <ArrowDropDownIcon color="error" />
          </LookUpRateGridRow>

          <LookUpRateGridRow
            title="Average"
            cost={format(AvgRatePerMile)}
            fuel={format(FuelPerMile)}
            allIn={format(AvgRatePerTrip)}
            fuelPerTrip={format(FuelPerTrip)}
            lineHaulPerMile={format(LineHaulOnlyAvgRatePerMile)}
            lineHaulPerTrip={format(LineHaulOnlyAvgRatePerTrip)}
          >
            <EqualIcon style={{ width: '12px', margin: '6px' }} />
          </LookUpRateGridRow>

          <LookUpRateGridRow
            title="Max"
            cost={format(MaxRatePerMile)}
            fuel={format(FuelPerMile)}
            allIn={format(MaxRatePerTrip)}
            fuelPerTrip={format(FuelPerTrip)}
            lineHaulPerMile={format(LineHaulOnlyMaxRatePerMile)}
            lineHaulPerTrip={format(LineHaulOnlyMaxRatePerTrip)}
          >
            <ArrowDropUpIcon color="primary" />
          </LookUpRateGridRow>
        </tbody>
      </table>
    </Box>
  );
}
