import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import { FormikEntity, PlainObject } from '../../../types';
import React from 'react';
import { getFormikError } from '../../../utils';

interface Item {
  value: string | number;
  label: string;
  disabled?: boolean;
}

interface Props<F extends PlainObject> extends RadioGroupProps {
  items: Item[];
  label: string;
  formik?: FormikEntity<F>;
  error?: boolean;
}

export function RadioButtonsGroup<F extends PlainObject>({
  label: groupLabel,
  items,
  formik,
  error,
  ...rgProps
}: Props<F>): JSX.Element {
  const name = rgProps.name;
  const showError =
    Boolean(formik && name && Boolean(getFormikError(formik, name))) || error;
  const helperText = (formik && name && getFormikError(formik, name)) || '';
  const formikProps =
    formik && name
      ? ({
          value: formik.values[name] || null,
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        } as Partial<RadioGroupProps>)
      : {};

  return (
    <FormControl error={showError}>
      {groupLabel && (
        <FormLabel
          error={showError}
          id="demo-row-radio-buttons-group-label"
          sx={{
            fontSize: 14,
            transform: 'translate(-19px, -1.5px) scale(0.75)',
          }}
        >
          {groupLabel}
        </FormLabel>
      )}

      <RadioGroup
        row
        aria-labelledby="row-radio-buttons-group-label"
        {...formikProps}
        {...rgProps}
      >
        {items.map(({ label, value, disabled }) => (
          <FormControlLabel
            key={value}
            label={label}
            value={value}
            disabled={disabled}
            control={<Radio disabled={disabled} size="small" />}
            sx={
              error
                ? {
                    color: 'red',
                  }
                : {}
            }
          />
        ))}
      </RadioGroup>
      {helperText && (
        <FormHelperText error={showError}>
          <>{helperText}</>
        </FormHelperText>
      )}
    </FormControl>
  );
}
