const MIN_PRICE_PER_MILE = 0.5;

export function isValidBidPerMile(
  distance: number,
  valueBid?: number | string,
): boolean {
  const bid = valueBid && parseFloat(String(valueBid));
  const pricePerMile = bid ? bid / distance : MIN_PRICE_PER_MILE;

  return pricePerMile >= MIN_PRICE_PER_MILE;
}
