import { PlainObject } from '../../types';
import { Box, Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { TabName } from '../../constants';

import {
  freightQuotesSelectors,
  freightQuotesSlice,
} from '../../redux/freightQuotes';

export function OpenHotIcon({
  style,
}: {
  style?: PlainObject;
}): JSX.Element | null {
  const { page } = useParams<{ page?: TabName }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getCurrentTabParams, getStatuses } = freightQuotesSelectors;

  const currentTabParams = useAppSelector(getCurrentTabParams);
  const statuses = useAppSelector(getStatuses);

  if (!statuses['HotCount'] || statuses['HotCount'] <= 0) {
    return null;
  }

  const isSelected = page === TabName.Open && currentTabParams === 'Hot';
  const color = isSelected ? '#006DAA' : '#E03F3F';

  const onSelectHFQ = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      freightQuotesSlice.actions.setQuoteTabParams({
        tabName: TabName.Open,
        params: isSelected ? '' : 'Hot',
      }),
    );
    navigate('/open');
  };

  return (
    <Tooltip
      title={
        <Box>
          Loads requiring immediate attention arrived to Sagan.
          <Box
            onClick={onSelectHFQ}
            sx={{
              display: 'inline',
              cursor: 'pointer',
              borderBottom: '1px solid white',
            }}
          >
            {` Click `}
          </Box>
          to select them
        </Box>
      }
    >
      <Box
        width="24px"
        height="24px"
        border={`1px solid ${color}`}
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={color}
        onClick={onSelectHFQ}
      >
        <svg
          style={style}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5439_119792)">
            <path
              d="M2 9.26238C2 7.99849 2.36111 6.70335 3.08333 5.37696C3.80556 4.05057 4.84028 2.81099 6.1875 1.65821C6.60417 1.2971 7.01042 1.17557 7.40625 1.29363C7.80208 1.41168 8 1.71377 8 2.19988V2.74155C8 3.15821 8.14583 3.51238 8.4375 3.80405C8.72917 4.09571 9.09028 4.24155 9.52083 4.24155C9.74305 4.24155 9.9375 4.20682 10.1042 4.13738C10.2708 4.06793 10.4097 3.96377 10.5208 3.82488C10.6597 3.65821 10.8333 3.56099 11.0417 3.53321C11.25 3.50543 11.4306 3.55405 11.5833 3.67905C12.3194 4.29016 12.9097 5.09918 13.3542 6.10613C13.7986 7.11307 14.0139 8.15821 14 9.24155C13.9861 10.3249 13.7257 11.3145 13.2187 12.2103C12.7118 13.1061 12 13.8318 11.0833 14.3874C11.3056 14.0679 11.4722 13.7277 11.5833 13.3665C11.6944 13.0054 11.75 12.6374 11.75 12.2624C11.75 11.804 11.6701 11.3596 11.5104 10.929C11.3507 10.4985 11.1111 10.1096 10.7917 9.76238L8 6.63738L5.20833 9.76238C4.88889 10.1096 4.64931 10.4985 4.48958 10.929C4.32986 11.3596 4.25 11.804 4.25 12.2624C4.25 12.6513 4.30903 13.0297 4.42708 13.3978C4.54514 13.7659 4.72222 14.1096 4.95833 14.429C4.04167 13.8874 3.31944 13.1582 2.79167 12.2415C2.26389 11.3249 2 10.3318 2 9.26238ZM8 8.88738L9.66667 10.7624C9.86111 10.9707 10.0069 11.2034 10.1042 11.4603C10.2014 11.7172 10.25 11.9846 10.25 12.2624C10.25 12.8874 10.0312 13.4186 9.59375 13.8561C9.15625 14.2936 8.625 14.5124 8 14.5124C7.375 14.5124 6.84375 14.2936 6.40625 13.8561C5.96875 13.4186 5.75 12.8874 5.75 12.2624C5.75 11.9846 5.79861 11.7172 5.89583 11.4603C5.99306 11.2034 6.13889 10.9707 6.33333 10.7624L8 8.88738Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5439_119792">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </Tooltip>
  );
}
