/** @jsxImportSource @emotion/react */
import React, { PropsWithChildren } from 'react';
import { PlainObject } from '../../types';
import { ColumnModel, SgTableCommonProps } from './SgTable.types';
import { SgTableRow } from './SgTableRow';
import { SgTable } from './SgTable';

export interface SgPlainTableProps<T extends PlainObject>
  extends SgTableCommonProps {
  rows: T[];
  columns: ColumnModel<T>[];
}

export function SgPlainTable<T extends PlainObject>({
  size = 'medium',
  columns,
  children,
  rows,
}: PropsWithChildren<SgPlainTableProps<T>>): JSX.Element {
  if (!rows.length) {
    return <>{children}</>;
  }

  return (
    <SgTable<T> size={size} columns={columns} stickyHeaderTop={0}>
      {rows.map((row, index) => (
        <SgTableRow<T>
          key={row.Id || index}
          size={size}
          rowData={row}
          columns={columns}
        />
      ))}
    </SgTable>
  );
}
