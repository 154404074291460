import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useSourceControlStyles = () => {
  const { palette } = useTheme();

  return css`
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0);
    }

    .MuiTableCell-root {
      padding: 12px 8px;

      .MuiTypography-body1 {
        cursor: default;

        .MuiLink-root {
          cursor: pointer;
        }
      }
    }

    .MuiSvgIcon-root {
      display: block;
    }

    .MuiLink-root {
      display: flex;
      text-decoration: none;
      color: #000000;

      .MuiSvgIcon-root {
        margin-left: 5px;
        opacity: 0;
      }

      &:hover {
        text-decoration: underline;
        color: ${palette.primary.main};

        .MuiSvgIcon-root {
          opacity: 1;
        }
      }
    }
  `;
};
