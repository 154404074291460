/** @jsxImportSource @emotion/react */
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Box,
} from '@mui/material';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DoneIcon from '@mui/icons-material/Done';
import sIcon from '../../assets/S.svg';
import powerBi from '../../assets/powerBi.svg';
import { SignOutButton } from './components/signOutButton/SignOutButton';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import { SourceControl } from './components/sourceControl/SourceControl';
import { useMemo, useState } from 'react';
import { filtersDataSelectors } from '../../redux/filtersData';
import { NotificationStatus, QuotePortalStatus } from '../../constants';
import { useHeaderStyles } from './Header.styles';
import { zIndexes } from '../../theme';
import { NotificationBar } from './components/notificationBar/NotificationBar';
import { notificationSelectors } from '../../redux/notification';
import { ToolbarButtons } from './components/toolbarButtons/ToolbarButtons';
import { OpenHotIcon } from '../../components/icons/OpenHotIcon';
import { cn } from '../../utils';

export function Header(): JSX.Element {
  const styles = useHeaderStyles();
  const [sourceControlOpened, setSourceControlOpened] = useState(false);
  const [notificationsOpened, setNotificationsOpened] = useState(false);

  const user = useAppSelector(userDataSelectors.getUserData);
  const config = useAppSelector(userDataSelectors.getConfig);
  const filters = useAppSelector(filtersDataSelectors.getFilters);
  const notifications = useAppSelector(
    notificationSelectors.getUserNotification,
  );
  const permissionDenied = useAppSelector(
    userDataSelectors.getUserPermissionDenied,
  );

  const { portals = [] } = filters;
  const hasNoFilters = Object.keys(filters).length === 0;

  const inactivePortals = useMemo(() => {
    return portals.filter(
      ({ PortalStatus }) => PortalStatus === QuotePortalStatus.NotAvailable,
    ).length;
  }, [portals]);

  const controlButtons = !permissionDenied && !hasNoFilters && (
    <>
      <Badge
        badgeContent={
          notifications.filter(
            (n) => n.Status === NotificationStatus.Unread && !n.opened,
          ).length
        }
        color="info"
      >
        <IconButton size="small" onClick={() => setNotificationsOpened(true)}>
          <NotificationsNoneIcon />
        </IconButton>
      </Badge>

      <Badge
        sx={{ ml: 2 }}
        badgeContent={
          inactivePortals || (
            <DoneIcon
              sx={{
                fontSize: '12px',
                width: '12px',
                height: '12px',
                margin: '-3px',
              }}
            />
          )
        }
        color={inactivePortals ? 'error' : 'success'}
      >
        <IconButton size="small" onClick={() => setSourceControlOpened(true)}>
          <DnsOutlinedIcon />
        </IconButton>
      </Badge>
    </>
  );

  const powerBiButton = (
    <Button
      disableElevation
      color="warning"
      variant="contained"
      type="button"
      className={cn(
        'powerBiButton',
        !config?.PowerBiUrl && 'powerBiButtonDisabled',
      )}
      sx={{ marginRight: 2 }}
      disabled={!config || !config.PowerBiUrl}
      href={config?.PowerBiUrl || ''}
      target="_blank"
    >
      <img src={sIcon} style={{ marginRight: '5px' }} />
      <img src={powerBi} />
    </Button>
  );

  return (
    <div css={styles}>
      <AppBar
        position="fixed"
        elevation={0}
        color="primary"
        sx={{ borderBottom: '4px solid #F5F6F6', zIndex: zIndexes.HEADER }}
      >
        <Toolbar>
          <ToolbarButtons />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            flexGrow={0}
          >
            <Box sx={{ marginRight: 2 }}>
              <OpenHotIcon />
            </Box>

            {powerBiButton}

            {controlButtons}

            <SignOutButton user={user} />
          </Stack>
        </Toolbar>
      </AppBar>

      <SourceControl
        portals={portals}
        open={sourceControlOpened}
        onClose={() => setSourceControlOpened(false)}
      />

      <NotificationBar
        notifications={notifications}
        open={notificationsOpened}
        onClose={() => setNotificationsOpened(false)}
      />
    </div>
  );
}
