import { Typography } from '@mui/material';
import {
  DialogWrapper,
  DialogWrapperBaseProps,
} from '../../components/dialogWrapper/DialogWrapper';

interface Props extends DialogWrapperBaseProps {
  title: string;
  message: string;
}

export function ConfirmationDialog({
  title,
  message,
  ...props
}: Props): JSX.Element {
  return (
    <DialogWrapper width={420} title={title} {...props}>
      <Typography variant="body1">{message}</Typography>
    </DialogWrapper>
  );
}
