import { SerializedStyles, css } from '@emotion/react';

export const rowClassNames = {
  openEmptyCell: 'openEmptyCell',
  openEmptyCellImmediately: 'openEmptyCellImmediately',
  closeEmptyCellImmediately: 'closeEmptyCellImmediately',
};

export const useSgDraggableRowStyles = (): SerializedStyles => {
  return css`
    &.MuiTableRow-root {
      .emptyCell {
        padding: 0;
        height: 0;
        transition: height 225ms cubic-bezier(0, 0, 0.2, 1);
        border-top-width: 0 !important;
      }

      &.${rowClassNames.openEmptyCell} .emptyCell {
        height: 61px;
        border-top-width: 1px !important;
      }

      &.${rowClassNames.openEmptyCellImmediately} .emptyCell {
        height: 61px;
        transition: height 0ms cubic-bezier(0, 0, 0.2, 1);
        border-top-width: 1px !important;
      }

      &.${rowClassNames.closeEmptyCellImmediately} .emptyCell {
        height: 0;
        transition: height 0ms cubic-bezier(0, 0, 0.2, 1);
        border-top-width: 0 !important;
      }
    }
  `;
};
