import * as yup from 'yup';
import { SchedulingAction, SchedulingCondition } from '../../constants';
import { ScheduleItem } from '../../types/scheduleItem';

function getDelaySchema(
  { Action: action }: ScheduleItem,
  condition?: SchedulingCondition,
) {
  const schema = yup
    .number()
    .moreThan(-1, 'Delay must be a positive number or 0')
    .typeError('The value must be a number');

  if (action === SchedulingAction.AutoIgnore) {
    return schema;
  }

  if (condition === SchedulingCondition.BeforeRespondBy) {
    return schema.moreThan(9, 'Please, set delay greater than or equal to ten');
  }

  if (condition === SchedulingCondition.AfterLoadArrives) {
    return schema.lessThan(481, 'Please, set delay less than or equal to 480');
  }

  return schema;
}

export const getScheduleFormSchema = (
  scheduleItem: ScheduleItem,
  condition?: SchedulingCondition,
) =>
  yup.object({
    condition: yup
      .number()
      .typeError('The value must be valid')
      .label('Condition')
      .required(),
    delay: getDelaySchema(scheduleItem, condition)
      .integer()
      .label('Delay')
      .required(),
  });
