import { Box, Stack } from '@mui/material';
import React from 'react';
import { DateRangeFilter } from '../../../types';
import { SgDatePicker } from '../sgDatePicker/SgDatePicker';
import { removeEmptyValues } from '../../../utils';

interface Props {
  label: string;
  disabled?: boolean;
  value: DateRangeFilter;
  onChange: (newValue: DateRangeFilter) => void;
}

export function DateRangePickerInput({
  label,
  disabled,
  value,
  onChange,
}: Props): JSX.Element {
  return (
    <Stack direction="row" alignItems="end">
      <Box marginRight={1}>
        <SgDatePicker
          label={label}
          value={value.fromDate || null}
          disabled={disabled}
          onChange={(newValue: Date | null) => {
            onChange(
              removeEmptyValues({
                ...value,
                fromDate: newValue?.toISOString() || null,
              }),
            );
          }}
          inputProps={{
            placeholder: 'From',
          }}
        />
      </Box>

      <Box>
        <SgDatePicker
          label=""
          value={value.toDate || null}
          disabled={disabled}
          onChange={(newValue: Date | null) => {
            onChange(
              removeEmptyValues({
                ...value,
                toDate: newValue?.toISOString() || null,
              }),
            );
          }}
          inputProps={{
            placeholder: 'To',
          }}
        />
      </Box>
    </Stack>
  );
}
