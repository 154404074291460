import { FreightQuoteActionModel, StringDate } from '../types';
import { DAY, HOUR, MINUTE } from '../constants';
import { QuoteTimer } from '../types/quoteTimer';

function getQuoteTimer(diff: number): QuoteTimer | null {
  const days = Math.floor(diff / DAY);
  const hoursLeft = Math.floor(diff / HOUR);
  const minutesLeft = Math.floor(diff / MINUTE);

  const hours = hoursLeft && hoursLeft % 24;
  const minutes = minutesLeft && minutesLeft % 60;

  const stringDays = days ? `${days}d : ` : '';
  const stringHours = hoursLeft ? `${hours}h : ` : '';

  return {
    hoursLeft,
    minutesLeft,
    days,
    hours,
    minutes,
    string: `${stringDays}${stringHours}${minutes}m`,
  };
}

function getTimerForQuote(
  currentTime: number,
  utcDate?: StringDate,
): QuoteTimer | null {
  if (!utcDate) {
    return null;
  }

  const finishTime = new Date(utcDate).getTime();

  if (finishTime <= currentTime) {
    return {
      minutesLeft: 0,
      minutes: 0,
      string: '0m',
    };
  }

  const diff = finishTime - currentTime;

  return getQuoteTimer(diff);
}

export function calculateTimerForQuotes(
  quotes?: FreightQuoteActionModel[],
): FreightQuoteActionModel[] {
  const time = new Date().getTime();

  // to avoid another one rerender in 3 seconds
  const currentTime = time % MINUTE > 57 * 1000 ? time + 3000 : time;

  return (quotes || []).map((quote) => {
    const timer = getTimerForQuote(currentTime, quote.RespondBy);
    const autoBidIn = getTimerForQuote(
      currentTime,
      quote.AutomatedProcessing?.ScheduledActionExecutionDateTime,
    );

    return quote.respondByTimer === timer ||
      (quote.respondByTimer &&
        timer &&
        quote.respondByTimer.string === timer.string)
      ? quote
      : {
          ...quote,
          respondByTimer: timer,
          autoBidIn,
        };
  });
}

export function formatTimerForAge(diff: number): string {
  const { string: stringValue } = getQuoteTimer(diff) || {};

  return stringValue || '';
}
