import FilterBuilder from 'odata-query-builder';
import {
  BaseEntityType,
  QuoteShortLocationUIExtended,
  RangeFilter,
  QuoteBaseEntityTypeUIExtended,
} from '../../types';
import { QuoteStatusIndex } from '../../constants';
import { is, isInRange } from '../is';

export function buildStatusFilter(
  builder: FilterBuilder,
  { id }: BaseEntityType,
) {
  /*
    
      Open:       Status eq 1 or (Bid eq null and (Status eq 7 or Status eq 8))
      Submitted:  Status eq 2 or (Bid ne null and (Status eq 7 or Status eq 8))
    
       */

  if (id === QuoteStatusIndex.Open) {
    builder.or((f) => {
      f.filterExpression('Status', 'eq', 1).and((f1) => {
        f1.filterExpression('Bid', 'eq', null as unknown as number).or((f2) => {
          f2.filterExpression('Status', 'eq', 7).filterExpression(
            'Status',
            'eq',
            8,
          );

          return f2;
        });

        return f1;
      });

      return f;
    });
  } else if (id === QuoteStatusIndex.Submitted) {
    builder.or((f) => {
      f.filterExpression('Status', 'eq', 2).and((f1) => {
        f1.filterExpression('Bid', 'ne', null as unknown as number).or((f2) => {
          f2.filterExpression('Status', 'eq', 7).filterExpression(
            'Status',
            'eq',
            8,
          );

          return f2;
        });

        return f1;
      });

      return f;
    });
  } else {
    builder.filterExpression('Status', 'eq', id);
  }
}

export function buildCityNameFilter(
  builder: FilterBuilder,
  name: string,
  { CityName, ProvinceAbbr }: QuoteShortLocationUIExtended,
) {
  builder.and((f1) =>
    f1
      .filterExpression(`${name}/CityName`, 'eq', CityName)
      .filterExpression(`${name}/ProvinceAbbr`, 'eq', ProvinceAbbr),
  );
}

export function buildRangeFilter(
  builder: FilterBuilder,
  name: string,
  filter: RangeFilter,
  includeNull?: boolean,
) {
  const build = (fb: FilterBuilder) => {
    if (!is.nullOrUndefined(filter.from)) {
      fb.filterExpression(name, 'ge', parseFloat(filter.from));
    }

    if (!is.nullOrUndefined(filter.to)) {
      fb.filterExpression(name, 'le', parseFloat(filter.to));
    }
  };

  if (includeNull && isInRange(0, filter.from, filter.to)) {
    builder.or((fb1) => {
      fb1.and((fb2) => {
        build(fb2);

        return fb2;
      });

      fb1.filterExpression(name, 'eq', null as unknown as string);

      return fb1;
    });

    return;
  }

  build(builder);
}

export function buildDistanceRangeFilter(
  builder: FilterBuilder,
  name: string,
  filter: RangeFilter,
) {
  /*
    (Distance ne 0 and Distance ge 100 and Distance le 200)
    or
     ((Distance eq null or Distance eq 0) and PCMilerDistance ge 100 and PCMilerDistance le 200)
     or
     ((Distance eq null or Distance eq 0)and PCMilerDistance eq null)
     */

  builder.or((fb1) => {
    // (Distance ne 0 and Distance ge 100 and Distance le 200)
    fb1.and((fb2) => {
      fb2.filterExpression(name, 'ne', 0);

      if (!is.nullOrUndefined(filter.from)) {
        fb2.filterExpression(name, 'ge', parseFloat(filter.from));
      }

      if (!is.nullOrUndefined(filter.to)) {
        fb2.filterExpression(name, 'le', parseFloat(filter.to));
      }

      return fb2;
    });

    // ((Distance eq null or Distance eq 0) and PCMilerDistance ge 100 and PCMilerDistance le 200)
    fb1.and((fb12) => {
      fb12.or((fb13) => {
        fb13.filterExpression(name, 'eq', null as unknown as string);
        fb13.filterExpression(name, 'eq', 0);

        return fb13;
      });

      if (!is.nullOrUndefined(filter.from)) {
        fb12.filterExpression('PCMilerDistance', 'ge', parseFloat(filter.from));
      }

      if (!is.nullOrUndefined(filter.to)) {
        fb12.filterExpression('PCMilerDistance', 'le', parseFloat(filter.to));
      }

      return fb12;
    });

    // ((Distance eq null or Distance eq 0) and PCMilerDistance eq null)
    if (isInRange(0, filter.from, filter.to)) {
      fb1.and((fb14) => {
        fb14.or((fb15) => {
          fb15.filterExpression(name, 'eq', null as unknown as string);
          fb15.filterExpression(name, 'eq', 0);

          return fb15;
        });

        fb14.filterExpression(
          'PCMilerDistance',
          'eq',
          null as unknown as string,
        );

        return fb14;
      });
    }

    return fb1;
  });
}
