import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useLookUpRatesStyles = (): SerializedStyles => {
  const { palette, spacing } = useTheme();

  return css`
    .routeItem {
      width: 47%;
      background-color: ${palette.background.default};
      padding: ${spacing(1, 1.5)};
    }

    .datRow {
      img {
        height: ${spacing(2)};
      }
    }
  `;
};
