import { styled, Tab, Tabs } from '@mui/material';
import { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';

interface StyledTabsProps {
  children?: ReactNode;
  value: number;
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
  value?: number;
}

interface TabContentProps {
  index: number;
  value: number;
}

export const SgTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  minHeight: 'auto',

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const SgTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} tabIndex={-1} />
))(({ theme }) => ({
  textTransform: 'none',
  padding: '8px 1px',
  fontWeight: 400,
  lineHeight: '20px',
  height: 'auto',
  minHeight: 'auto',
  minWidth: 'auto',
  marginRight: theme.spacing(3),
  '&.Mui-selected': {
    color: theme.palette.text.secondary,
  },
}));

export function SgTabPanel({
  children,
  ...props
}: PropsWithChildren<TabContentProps>) {
  const { value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
}
