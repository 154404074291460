/** @jsxImportSource @emotion/react */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Routes from './routes/Routes';
import { loginRequest } from './constants';
import { Loader, FallbackComponent } from './components';
import { useAppStyles } from './App.styles';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { IPublicClientApplication, InteractionType } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/navigationClient';
import { useMsalEffects } from './App.hooks';
import { useAppSelector } from './redux/hooks';
import { userDataSelectors } from './redux/userData';
import { Notification } from './features';

const App = ({ pca }: { pca: IPublicClientApplication }) => {
  const styles = useAppStyles();
  const nodeRef = React.useRef(null);

  const loading = useAppSelector(userDataSelectors.getUserLoading);

  const navigationClient = new CustomNavigationClient();
  pca.setNavigationClient(navigationClient);

  useMsalEffects(pca);

  const content = loading ? (
    <CSSTransition
      nodeRef={nodeRef}
      key="loader"
      timeout={300}
      classNames="loader"
    >
      <Loader fullPage nodeRef={nodeRef} />
    </CSSTransition>
  ) : (
    <Routes />
  );

  // todo
  // return (
  //   <BrowserRouter>
  //     <TransitionGroup css={styles} exit>
  //       {content}
  //     </TransitionGroup>
  //   </BrowserRouter>
  // );

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={FallbackComponent}
        loadingComponent={() => <Loader fullPage nodeRef={nodeRef} />}
      >
        <BrowserRouter>
          <TransitionGroup css={styles} exit>
            {content}
          </TransitionGroup>
        </BrowserRouter>

        <Notification />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

export default App;
