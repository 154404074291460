import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';
import typography from '../../theme/typography';

export const useStopsGridStyles = (): SerializedStyles => {
  const { palette, spacing } = useTheme();

  return css`
    overflow: hidden;

    th,
    td {
      &.MuiTableCell-root {
        vertical-align: top;
        padding: ${spacing(1, 1, 1, 0)};
      }

      &:first-of-type {
        border-bottom: none;
        padding-right: ${spacing(2)};
      }
    }

    .stopPoint {
      position: relative;
      width: fit-content;
      padding: 3px 0;
      margin: 0 auto;
      background-color: ${palette.common.white};

      &.firstStopPoint,
      &.lastStopPoint {
        .stopPointContent {
          background-color: ${palette.sagan.main};
          color: ${palette.common.white};
          border: 1px solid ${palette.sagan.main};
        }
      }

      .stopPointContent {
        box-sizing: border-box;
        border: 1px solid ${palette.primary.light};
        background-color: ${palette.common.white};
        color: ${palette.common.black};
        border-radius: 8px;
        text-align: center;
        padding: 1px 3px;
        font-size: ${typography.h6.fontSize}px;
        font-weight: ${typography.h6.fontWeight};
        line-height: 11px;
        width: fit-content;
        min-width: 15px;
        min-height: 15px;
      }

      &:not(.lastStopPoint):after {
        content: '';
        width: 0;
        height: 200px;
        border-left: 1px dashed ${palette.primary.main};
        position: absolute;
        bottom: -200px;
        left: calc(50% - 1px);
      }
      &:before {
        content: '';
        width: 10px;
        height: 202px;
        position: absolute;
        background-color: ${palette.common.white};
        bottom: -200px;
        left: calc(50% - 5px);
      }
    }
  `;
};
