import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import { userDataStoreKey } from './userData.const';
import { apiEndpoint } from '../../constants/api';
import { UserBaseModel } from '../../types';
import { ServerAppConfig } from '../../types/serverAppConfig';

export interface FetchUserDataResult {
  userData: AxiosResponse<UserBaseModel>;
  config?: ServerAppConfig;
}

export const fetchUserData = createAsyncThunk(
  `${userDataStoreKey}/fetchUserData`,
  async (): Promise<FetchUserDataResult> => {
    try {
      // todo
      // return getMeMockData();

      const { data, status } = await axios.get(apiEndpoint.userInfo());
      const { data: config } = await axios.get(apiEndpoint.appConfigApi());

      return {
        userData: { data, status } as AxiosResponse<UserBaseModel>,
        config,
      };
    } catch (err) {
      const { data, status } = err.response;

      return {
        userData: { data, status } as AxiosResponse<UserBaseModel>,
      };
    }
  },
);
