import { is } from '../../../utils';

const scrollMargin = 50;
const scrollSpeed = 1;

export class AutoScrollService {
  private isAnimationActive = false;
  private cursorY?: number;
  private container: HTMLElement | null = null;
  private containerRect: DOMRect = { bottom: 0, top: 0 } as DOMRect;

  constructor(containerId: string) {
    const containerElement = document.getElementById(containerId);

    if (!containerElement) {
      throw new Error('AutoScrollService: containerElement is undefined');
    }

    this.container = containerElement;
    this.containerRect = this.container.getBoundingClientRect();

    this.startAnimationFrame();
  }

  private scroll(): void {
    if (is.nullOrUndefined(this.cursorY)) {
      return;
    }

    const { top, bottom } = this.containerRect;

    const marginTop = top + scrollMargin;
    const marginBottom = bottom - scrollMargin;

    // scroll to top
    if (this.cursorY < marginTop) {
      const scrollY =
        marginTop - this.cursorY > scrollMargin
          ? scrollMargin
          : marginTop - this.cursorY;

      this.container?.scrollBy(0, scrollY * scrollSpeed * -1);

      return;
    }

    // scroll to bottom
    if (this.cursorY > marginBottom) {
      const scrollY =
        this.cursorY - marginBottom > scrollMargin
          ? scrollMargin
          : this.cursorY - marginBottom;

      this.container?.scrollBy(0, scrollY * scrollSpeed);

      return;
    }
  }

  private animationFrameCb(): void {
    this.scroll();

    if (!this.isAnimationActive) {
      return;
    }

    window.requestAnimationFrame(() => this.animationFrameCb());
  }

  private startAnimationFrame(): void {
    this.isAnimationActive = true;

    window.requestAnimationFrame(() => this.animationFrameCb());
  }

  public setCursorY(clientY: number): void {
    this.cursorY = clientY;
  }

  public stop() {
    this.isAnimationActive = false;
  }
}
