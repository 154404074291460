import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import { SGAutocomplete } from '../formElements/autocomplete/SGAutocomplete';
import {
  BaseEntityType,
  BaseFilters,
  DateRangeFilter,
  DateSearchFiltersModel,
  QuoteBaseEntityTypeUIExtended,
  QuoteShortLocationUIExtended,
  RangeFilter,
  SearchFiltersModel,
} from '../../types';
import { removeEmptyValues, getShippersByPortals } from '../../utils';
import { renderFilterTag } from './Filters.utils';
import { RangeInput } from '../formElements/rangeInput/RangeInput';
import { DateRangePickerInput } from '../formElements/dateRangePickerInput/DateRangePickerInput';
import { StatusFilterOptions } from './SearchFilters.const';
import { RenderLocationAutocomplete } from './components/locationAutocomplete/LocationAutocomplete';
import { FilterBaseProps } from './Filters.types';

interface Props extends FilterBaseProps {
  filters: Partial<SearchFiltersModel>;
  onChange: (values?: Partial<SearchFiltersModel>) => void;
  onUpdateFilters: (values?: Partial<SearchFiltersModel>) => void;
  disabled?: boolean;
}

export function SearchFilters({
  filters,
  onChange,
  onUpdateFilters,
  disabled,
  portals,
  equipmentTypes,
  origins,
  destinations,
}: Props): JSX.Element {
  const [filterValues, setFilterValues] = useState<Partial<SearchFiltersModel>>(
    {},
  );

  const styles = { width: 'calc(25% - 16px)', marginRight: 2 };
  const periodStyles = { width: '170px', minWidth: '170px', marginRight: 2 };
  const hasFilters = Object.values(filterValues).filter((v) => v).length > 0;

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  const shippers = useMemo(() => {
    return getShippersByPortals(portals);
  }, [portals]);

  const onValueChange = (values: Partial<SearchFiltersModel>) => {
    onChange(
      removeEmptyValues({
        ...filterValues,
        ...values,
      }),
    );
  };

  const changeValueAndUpdateFilter = (values: Partial<SearchFiltersModel>) => {
    const { origins, destinations, shippers } = {
      ...filterValues,
      ...values,
    };

    onUpdateFilters(removeEmptyValues({ origins, destinations, shippers }));

    onValueChange(values);
  };

  const onSubmit = () => {
    if (filterValues && Object.keys(filterValues).length) {
      onChange(filterValues);
      return;
    }

    onChange();
  };

  const renderLocationAutocomplete = (
    label: string,
    name: keyof BaseFilters,
    value: QuoteShortLocationUIExtended[],
    options: QuoteShortLocationUIExtended[],
  ) => (
    <Box sx={styles}>
      <RenderLocationAutocomplete
        label={label}
        name={name}
        disabled={disabled}
        options={options}
        value={value || []}
        onChange={(newValues) => {
          changeValueAndUpdateFilter({
            [name]: newValues && newValues.length ? newValues : undefined,
          });
        }}
      />
    </Box>
  );

  const renderDatePicker = (
    label: string,
    name: keyof DateSearchFiltersModel,
  ) => (
    <Box sx={periodStyles}>
      <DateRangePickerInput
        label={label}
        disabled={disabled}
        value={filterValues[name] || {}}
        onChange={(newValue: DateRangeFilter) =>
          onValueChange({
            [name]: newValue,
          })
        }
      />
    </Box>
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Box
        sx={{
          padding: 3,
          paddingTop: 2,
          marginBottom: 2,
          borderRadius: '4px',
          backgroundColor: 'background.default',
        }}
      >
        <Stack direction="row">
          <Box sx={{ width: 'calc(100% - 120px)' }}>
            <Stack direction="row">
              <Box sx={styles}>
                <TextField
                  fullWidth
                  label="Search by source ID"
                  placeholder="Enter source ID"
                  variant="standard"
                  disabled={disabled}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={filterValues.sourceId || ''}
                  onChange={(e) =>
                    setFilterValues({
                      ...filterValues,
                      sourceId: e.target.value,
                    })
                  }
                />
              </Box>

              {renderLocationAutocomplete(
                'Origin location',
                'origins',
                filterValues.origins || [],
                origins,
              )}
              {renderLocationAutocomplete(
                'Destination location',
                'destinations',
                filterValues.destinations || [],
                destinations,
              )}

              {renderDatePicker('Pick up date', 'shipDateRange')}
              {renderDatePicker('Delivery date', 'deliveryDateRange')}
              {renderDatePicker('Bid date', 'bidDateRange')}
            </Stack>
            <Stack direction="row" marginTop={2}>
              <Box sx={styles}>
                <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
                  label="Customer"
                  name="shippers"
                  id="customer-filter"
                  placeholder="All customers"
                  selectAllLabel="All"
                  disabled={disabled}
                  options={shippers}
                  value={filterValues.shippers || []}
                  getOptionLabel={(option) => (option && option.Name) || ''}
                  getOptionDisabled={(option) => !!option?.disabled}
                  renderTags={renderFilterTag}
                  onChange={(newValues) => {
                    changeValueAndUpdateFilter({ shippers: newValues });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.Id === value?.Id
                  }
                />
              </Box>
              <Box sx={{ ...styles, width: 'calc(50% - 16px)' }}>
                <SGAutocomplete<QuoteBaseEntityTypeUIExtended>
                  label="Equipment type"
                  name="equipmentTypes"
                  id="equipment-filter"
                  placeholder="All types"
                  disabled={disabled}
                  options={equipmentTypes}
                  value={filterValues.equipmentTypes || []}
                  getOptionLabel={(option) => (option && option.Name) || ''}
                  renderTags={renderFilterTag}
                  onChange={(newValues) => {
                    onValueChange({
                      equipmentTypes: newValues.filter((v) => v),
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.Id === value?.Id
                  }
                />
              </Box>

              <Box sx={periodStyles}>
                <SGAutocomplete<BaseEntityType>
                  label="Status"
                  name="equipmentTypes"
                  id="equipment-filter"
                  placeholder="All statuses"
                  disabled={disabled}
                  options={StatusFilterOptions}
                  value={filterValues.status || []}
                  getOptionLabel={(option) => (option && option.name) || ''}
                  renderTags={renderFilterTag}
                  onChange={(newValues) => {
                    onValueChange({
                      status: newValues.filter((v) => v),
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                />
              </Box>

              <Box sx={periodStyles}>
                <RangeInput
                  label="Distance, miles"
                  placeholder="Enter range"
                  rangePlaceholder="Enter distance"
                  disabled={disabled}
                  value={filterValues.distance || {}}
                  onChange={(newValue?: RangeFilter) =>
                    onValueChange({
                      distance: newValue,
                    })
                  }
                />
              </Box>

              <Box sx={periodStyles}>
                <RangeInput
                  label="Sage bid, $"
                  placeholder="Enter range"
                  rangePlaceholder="Enter bid"
                  disabled={disabled}
                  value={filterValues.bid || {}}
                  onChange={(newValue?: RangeFilter) =>
                    onValueChange({
                      bid: newValue,
                    })
                  }
                />
              </Box>
            </Stack>
          </Box>

          <Stack direction="row" alignItems="end" marginLeft={1} width="120px">
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              disabled={!hasFilters || disabled}
              onClick={() => {
                if (!hasFilters) {
                  return;
                }

                setFilterValues({});
                onChange();
              }}
              sx={{ minWidth: '40px', padding: 1 }}
            >
              <CleaningServicesOutlinedIcon fontSize="small" />
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="small"
              color="primary"
              disabled={!hasFilters || disabled}
              sx={{ padding: '7px', marginLeft: 2 }}
            >
              Search
            </Button>
          </Stack>
        </Stack>
      </Box>
    </form>
  );
}
