import React from 'react';
import { FormikEntity, PlainObject } from '../../../types';
import { getFormikError } from '../../../utils';
import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';

interface Props<T, F extends PlainObject>
  extends Omit<AutocompleteProps<T, false, boolean, false>, 'renderInput'> {
  formik: FormikEntity<F>;
  name: string;
  label: string;
  readonly?: boolean;
}

export function FormikPlainAutocomplete<T, F extends PlainObject>({
  formik,
  name,
  label,
  readonly,
  ...props
}: Props<T, F>): JSX.Element {
  const formikValue = formik.getFieldProps(name).value || null;
  const formikProps = {
    value: formikValue,
    onChange: (e, value: T) => formik.setFieldValue(name, value || undefined),
  } as Partial<AutocompleteProps<T, false, boolean, false>>;
  const error = getFormikError(formik, name);

  return (
    <Autocomplete<T, false, boolean, false>
      {...props}
      {...formikProps}
      renderInput={(params) => (
        <TextField
          {...params}
          color="sagan"
          size="medium"
          name={name}
          label={label}
          placeholder={formikProps.value ? undefined : props.placeholder}
          variant="standard"
          error={Boolean(error)}
          helperText={error}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...(params.inputProps || {}),
            readOnly: readonly || (params.inputProps || {}).readOnly,
          }}
          disabled={readonly || params.disabled}
        />
      )}
      getOptionDisabled={() => !!readonly}
      componentsProps={{
        popper: {
          sx: {
            minWidth: '220px',
          },
        },
      }}
      disableClearable={!!readonly}
    />
  );
}
