import { Snackbar, Alert } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  notificationSelectors,
  notificationSlice,
} from '../../redux/notification';
import { cn } from '../../utils';

export function Notification(): JSX.Element {
  const dispatch = useAppDispatch();

  const notification = useAppSelector(notificationSelectors.getNotification);

  const close = () => {
    dispatch(notificationSlice.actions.removeNotification());
  };

  return (
    <Snackbar
      key={notification?.type}
      open={!!notification?.open}
      autoHideDuration={6000}
      onClose={() => close()}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        key={notification?.type}
        elevation={6}
        variant="filled"
        onClose={() => close()}
        className={cn(notification?.type === 'draft' && 'draftAlertColor')}
        severity={
          !notification?.type || notification?.type === 'draft'
            ? 'success'
            : notification?.type
        }
        sx={{ width: '100%' }}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}
