/** @jsxImportSource @emotion/react */
import React from 'react';
import { Typography } from '@mui/material';
import { MsalAuthenticationResult } from '@azure/msal-react';

export function FallbackComponent({
  error,
}: MsalAuthenticationResult): JSX.Element {
  return (
    <Typography variant="h6">
      An Error Occurred: {error ? error.errorCode : 'unknown error'}
    </Typography>
  );
}
