import { FreightQuoteActionModel, FreightQuoteModel } from '../../../types';
import { Box, Stack, Typography } from '@mui/material';
import { CommonSubmitContent } from '../../submitBidDialog/components/commonSubmitContent/SubmitCommonContent';
import {
  ColumnModel,
  QuoteActions,
  renderRateTypeByCustomers,
} from '../../../components';
import React, { useState } from 'react';
import {
  quoteStatusToTabName,
  RuleModeValue,
  TabName,
} from '../../../constants';
import { BidForm } from '../../../types/bidToSubmit';
import {
  checkIsQuoteValidToSubmit,
  freightQuotesSelectors,
  freightQuotesSlice,
  submitBid,
} from '../../../redux/freightQuotes';
import {
  AutomatedProcessingIndicator,
  isDisabledToSubmit,
  isValidBidPerMile,
} from '../../../utils';
import { useAppSelector } from '../../../redux/hooks';
import { useControlFocusedQuota } from './useControlFocusedQuota';
import { getSubmitBidSchema } from '../submitBidSchema';
import { renderInlineBidInput } from './useInlineBidRenders.utils';
import { AutoBidInTimer } from '../components';
import { renderAutoBidInTimer } from '../../sharedPage/SharedPage.utils';

export function useInlineBidRenders(
  renderType: 'search' | 'quotes',
  dispatch: (action: unknown) => void,
  tabName?: TabName,
) {
  const [submittedQuote, setSubmittedQuote] =
    useState<FreightQuoteModel | null>(null);
  const { focusedQuote, setFocusedQuote, onRowExpanded } =
    useControlFocusedQuota(dispatch);

  const submitLoading = useAppSelector(freightQuotesSelectors.getSubmitLoading);
  const validToSubmitLoading = useAppSelector(
    freightQuotesSelectors.getValidToSubmitLoading,
  );

  const setQuotesToIgnore = (quote: FreightQuoteActionModel[] | null) => {
    dispatch(freightQuotesSlice.actions.setQuotesToIgnore(quote));
  };

  const onInlineBidChanged = (
    q: FreightQuoteActionModel,
    value: Partial<BidForm>,
  ) => {
    dispatch(
      freightQuotesSlice.actions.setInlineBidValue({
        ...q,
        ...value,
      }),
    );
  };

  const handleBidChanged = (
    q: FreightQuoteActionModel,
    bidValue: string | number,
  ) => {
    // first validation should be after BLUR action on input
    if (q.bidError === undefined) {
      onInlineBidChanged(q, {
        bid: bidValue,
        wrongBidPerMile: false,
      });

      return;
    }

    try {
      getSubmitBidSchema(q.MaxBid).validateSync(
        { bid: bidValue || undefined },
        { abortEarly: false },
      );

      onInlineBidChanged(q, {
        bid: bidValue,
        wrongBidPerMile: false,
        bidError: '',
      });
    } catch (err) {
      onInlineBidChanged(q, {
        bid: bidValue,
        wrongBidPerMile: false,
        bidError: err.message,
      });
    }
  };

  const handleFormBlur = (q: FreightQuoteActionModel) => {
    const { bid } = q;

    try {
      getSubmitBidSchema(q.MaxBid).validateSync({ bid }, { abortEarly: false });

      onInlineBidChanged(q, {
        bidError: '',
      });
    } catch (err) {
      onInlineBidChanged(q, {
        bidError: err.message,
      });
    }
  };

  const submitQuote = (q: FreightQuoteActionModel, form: BidForm) => {
    setSubmittedQuote(q);
    dispatch(
      submitBid({
        quotes: [q],
        data: form,
      }),
    );
  };
  const handleQuoteSubmit = (q: FreightQuoteActionModel) => {
    const { bid, comments } = q;

    if (!getSubmitBidSchema(q.MaxBid).isValidSync({ bid, comments })) {
      handleFormBlur(q);
      return;
    }

    if (
      !q.wrongBidPerMile &&
      (!q.bid || !isValidBidPerMile(q.Distance, q.bid))
    ) {
      onInlineBidChanged(q, {
        wrongBidPerMile: true,
      });
      return;
    }

    submitQuote(q, {
      bid,
      comments,
    });
  };

  const isSubmitLoading = (quote: FreightQuoteModel) => {
    return !!submittedQuote && submittedQuote.Id === quote.Id && submitLoading;
  };

  const renderActions = (
    rowData: FreightQuoteActionModel,
    disabled?: boolean,
  ): JSX.Element | null => {
    const currentTabName =
      tabName ||
      (rowData.Status ? quoteStatusToTabName[rowData.Status] : TabName.Missed);
    const disabledToSubmit = isDisabledToSubmit(currentTabName, rowData);

    return (
      <QuoteActions
        rowData={rowData}
        disableError={renderType === 'search'}
        loading={
          isSubmitLoading(rowData) ||
          (!!focusedQuote &&
            focusedQuote.Id === rowData.Id &&
            validToSubmitLoading)
        }
        disabled={
          disabled || disabledToSubmit || rowData.validToSubmit === false
        }
        disabledSubmit={!!rowData.bidError}
        currentTabName={currentTabName}
        onIgnore={(quote) => setQuotesToIgnore(quote && [quote])}
        onSubmit={handleQuoteSubmit}
      />
    );
  };

  const inlineBidColumn: ColumnModel<FreightQuoteActionModel> = {
    name: 'SageBid',
    label: 'Sage bid',
    styles: { paddingLeft: '16px !important' },
    headerStyles: { paddingLeft: '16px !important' },
    formatter: (quote, name, disabled) => {
      const currentTabName =
        tabName ||
        (quote.Status ? quoteStatusToTabName[quote.Status] : TabName.Missed);

      const bidInput = renderInlineBidInput(
        quote,
        handleBidChanged,
        (q) => {
          setFocusedQuote(q);
          dispatch(checkIsQuoteValidToSubmit(q));
        },
        (q) => handleFormBlur(q),
        tabName,
        disabled,
        {
          minWidth: '110px',
          maxWidth: '150px',
        },
      );

      if (
        currentTabName !== TabName.Submitted &&
        currentTabName !== TabName.Ignored
      ) {
        return bidInput;
      }

      return (
        <AutomatedProcessingIndicator quote={quote}>
          {bidInput}
        </AutomatedProcessingIndicator>
      );
    },
  };

  const autoBidInColumn: ColumnModel<FreightQuoteActionModel> = {
    name: 'autoBidIn',
    nameForOrdering: 'AutomatedProcessing/ScheduledActionExecutionDateTime',
    label: 'Auto bid in',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (item, key, disabled) =>
      renderAutoBidInTimer(
        item,
        (bid) => submitQuote(item, { bid }),
        disabled || isSubmitLoading(item),
      ),
  };

  const renderDetailsSubmit = (
    rowData: FreightQuoteActionModel,
    disabled?: boolean,
  ): JSX.Element | null => {
    const currentTabName =
      tabName ||
      (rowData.Status ? quoteStatusToTabName[rowData.Status] : TabName.Missed);

    const showAutoBidIn =
      !!rowData.autoBidIn &&
      (rowData.AutomatedProcessing?.RuleMode === RuleModeValue.AutoBid ||
        rowData.AutomatedProcessing?.RuleMode === RuleModeValue.AutoIgnore);

    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          minHeight: '235px',
          maxWidth: '260px',
        }}
        border="1px solid #E0E2E4"
        borderRadius="4px"
        padding={2}
        paddingTop={1}
        paddingBottom={3}
      >
        <CommonSubmitContent
          smallSize
          disabled={disabled}
          quote={rowData}
          currentTabName={currentTabName}
          wrongBidPerMile={!!rowData.wrongBidPerMile}
          commentValue={rowData.comments || ''}
          onCommentChange={(e) =>
            onInlineBidChanged(rowData, {
              comments: (e && e.target.value) || '',
            })
          }
          onBlur={() => handleFormBlur(rowData)}
          setSuggestedBid={(newBid) => handleBidChanged(rowData, newBid)}
        >
          <Stack direction="row" paddingBottom={1}>
            {renderRateTypeByCustomers(rowData)}
          </Stack>

          {renderType === 'search' && (
            <Box>
              {renderInlineBidInput(
                rowData,
                handleBidChanged,
                (q) => {
                  setFocusedQuote(q);
                  dispatch(checkIsQuoteValidToSubmit(q));
                },
                (q) => handleFormBlur(q),
                tabName,
                disabled,
              )}
            </Box>
          )}
        </CommonSubmitContent>
        {renderType === 'search' && (
          <>
            {showAutoBidIn && currentTabName === TabName.Open && (
              <Stack
                direction="row"
                justifyContent="space-between"
                paddingTop={2}
              >
                <Typography variant="h5">Auto bid in</Typography>
                <AutoBidInTimer
                  quote={rowData}
                  disabled={disabled || isSubmitLoading(rowData)}
                  onSubmit={(bid) => submitQuote(rowData, { bid })}
                />
              </Stack>
            )}

            <Box paddingTop={2}>{renderActions(rowData, disabled)}</Box>
          </>
        )}
      </Box>
    );
  };

  return {
    focusedQuote,
    inlineBidColumn,
    autoBidInColumn,
    renderActions,
    renderDetailsSubmit,
    onRowExpanded,
  };
}
