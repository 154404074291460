import { Button, Checkbox, TableCell, TableRow, Tooltip } from '@mui/material';
import { cn } from '../../utils';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { SgTableRowProps } from './SgTable.types';
import { PlainObject } from '../../types';
import { SgDraggableRow } from './SgDraggableRow';

export function SgTableRow<T extends PlainObject>({
  size = 'medium',
  dragConfig,

  rowDisabled,
  focused,
  rowExpanded,
  expandDisabled,
  expandable,
  onExpand,

  checked,
  checkboxDisabled,
  onCheck,

  selectable,
  columns,
  rowData,
  disableActions,
  renderActions,

  name,
}: SgTableRowProps<T>): JSX.Element {
  const columnsCount =
    (columns || []).length + (renderActions ? 2 : 1) + (selectable ? 1 : 0);

  const content = (
    <>
      {selectable && (
        <TableCell
          sx={{ padding: '0px !important', backgroundColor: 'white' }}
          className={`${size}TableCell`}
        >
          <Checkbox
            tabIndex={-1}
            checked={checked}
            disabled={checkboxDisabled}
            onChange={onCheck}
          />
        </TableCell>
      )}

      {expandable && (
        <TableCell sx={{ backgroundColor: 'white' }}>
          <Button
            tabIndex={-1}
            variant={rowExpanded ? 'contained' : 'outlined'}
            size="small"
            color="primary"
            className={`${size}TableCell nopadding`}
            disabled={expandDisabled}
            onClick={onExpand}
            sx={{ marginRight: 1, marginLeft: 1 }}
          >
            {rowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </TableCell>
      )}

      {(columns || []).map((column) => (
        <TableCell
          key={column.name as string}
          align={column.align}
          className={`${size}TableCell ${column.action ? 'action-cell' : ''}`}
          sx={{ backgroundColor: 'white', ...(column.styles || {}) }}
        >
          {column.formatter ? (
            column.formatter(rowData, column.name, disableActions)
          ) : column.useTooltip ? (
            <Tooltip title={String(rowData[column.name])} arrow placement="top">
              <span>{String(rowData[column.name])}</span>
            </Tooltip>
          ) : (
            String(rowData[column.name])
          )}
        </TableCell>
      ))}

      {renderActions && (
        <TableCell
          className={`${size}TableCell action-cell`}
          sx={{ backgroundColor: 'white' }}
        >
          {renderActions(rowData, disableActions)}
        </TableCell>
      )}
    </>
  );

  if (dragConfig) {
    return (
      <SgDraggableRow
        name={name || ''}
        disabled={dragConfig.disabled}
        onNewIndex={dragConfig.onNewIndex}
        cellCount={columnsCount}
        deps={[dragConfig.tableData]}
        className={cn(
          rowExpanded && 'expanded-main-row',
          focused && 'focused-row',
          rowDisabled && 'submitted-row',
        )}
      >
        {content}
      </SgDraggableRow>
    );
  }

  return (
    <TableRow
      className={cn(
        rowExpanded && 'expanded-main-row',
        focused && 'focused-row',
        rowDisabled && 'submitted-row',
      )}
    >
      {content}
    </TableRow>
  );
}
