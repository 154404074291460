import { DATRateValue, datRateValueLabel } from '../../../constants';

export const DATRateOptions = [
  {
    value: DATRateValue.USD,
    label: datRateValueLabel[DATRateValue.USD],
  },
  {
    value: DATRateValue.Percent,
    label: datRateValueLabel[DATRateValue.Percent],
  },
];
