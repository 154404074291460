import { createContext } from 'react';

export type UIGridSize = 'l' | 'm';

export const uiGridPaddings = <Record<UIGridSize, number>>{
  m: 2,
  l: 3,
};

export const UIGridContext = createContext<UIGridSize>('m');
