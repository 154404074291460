import { TabName } from '../tabs';

// const FREIGHT_QUOTES_MOCK = 'FreightQuotesMock';
const FREIGHT_QUOTES = 'FreightQuotes';

export const tabApiNames = {
  [TabName.Open]: 'Open',
  [TabName.Submitted]: 'Submitted',
  [TabName.Won]: 'Won',
  [TabName.Lost]: 'Lost',
  [TabName.Ignored]: 'Ignored',
  [TabName.Missed]: 'Missed',
};

export const freightQuotesOpen = () => `${FREIGHT_QUOTES}/Open`;
export const statusCount = () => `${FREIGHT_QUOTES}/StatusCount`; // StatusCountMock
export const ignoreQuote = (id: number) => `${FREIGHT_QUOTES}/${id}/Ignore`;
export const ignoreQuotes = () => `${FREIGHT_QUOTES}/BulkIgnore`;
export const similarQuotes = (id: number) => `${FREIGHT_QUOTES}/Similar/${id}`;
export const freightQuotesByTab = (
  tabName: TabName = TabName.Open,
  queryString?: string,
  params?: string,
) =>
  `${FREIGHT_QUOTES}/${tabApiNames[tabName]}${params ? `/${params}` : ''}${
    queryString || ''
  }`;
export const freightQuotes = (queryString?: string) =>
  `${FREIGHT_QUOTES}${queryString || ''}`;
