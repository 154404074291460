import { ExpressionAttribute } from '../features/ruleForm/RuleForm.const';

export const expressionsApiKeys = {
  [ExpressionAttribute.Customer]: 'ShipperId',
  [ExpressionAttribute.EquipmentType]: 'EquipmentTypeId',
  [ExpressionAttribute.OriginPod]: 'OriginZipCode/DatPodId',
  [ExpressionAttribute.DestinationPod]: 'DestinationZipCode/DatPodId',
  [ExpressionAttribute.PickUpDate]: 'ShipDate',
  [ExpressionAttribute.DeliveryDate]: 'DeliveryDate',
  [ExpressionAttribute.Distance]: 'Distance',
  [ExpressionAttribute.Stops]: 'StopsCount',
  [ExpressionAttribute.TransitTime]: 'TransitTime',
  [ExpressionAttribute.PickUpTime]: 'PickUpTime',
  [ExpressionAttribute.DropTime]: 'DropTime',
};

export const expressionsFormKeys = {
  [expressionsApiKeys[ExpressionAttribute.Customer]]:
    ExpressionAttribute.Customer,
  [expressionsApiKeys[ExpressionAttribute.EquipmentType]]:
    ExpressionAttribute.EquipmentType,
  [expressionsApiKeys[ExpressionAttribute.OriginPod]]:
    ExpressionAttribute.OriginPod,
  [expressionsApiKeys[ExpressionAttribute.DestinationPod]]:
    ExpressionAttribute.DestinationPod,
  [expressionsApiKeys[ExpressionAttribute.PickUpDate]]:
    ExpressionAttribute.PickUpDate,
  [expressionsApiKeys[ExpressionAttribute.DeliveryDate]]:
    ExpressionAttribute.DeliveryDate,
  [expressionsApiKeys[ExpressionAttribute.Distance]]:
    ExpressionAttribute.Distance,
  [expressionsApiKeys[ExpressionAttribute.Stops]]: ExpressionAttribute.Stops,
  [expressionsApiKeys[ExpressionAttribute.TransitTime]]:
    ExpressionAttribute.TransitTime,
  [expressionsApiKeys[ExpressionAttribute.PickUpTime]]:
    ExpressionAttribute.PickUpTime,
  [expressionsApiKeys[ExpressionAttribute.DropTime]]:
    ExpressionAttribute.DropTime,
};
