import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { is } from '../../../utils';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { InputBaseProps } from '@mui/material/InputBase';

interface Props {
  inputProps?: InputBaseProps['inputProps'];
  textFieldProps?: TextFieldProps;
}

export function SgDatePicker<TInputDate>({
  onChange,
  inputProps,
  textFieldProps = {},
  ...props
}: Omit<DatePickerProps<TInputDate, Date>, 'renderInput'> &
  Props): JSX.Element {
  return (
    <DatePicker
      {...props}
      onChange={(value: Date | null, keyboardInputValue?: string) => {
        if (value === null) {
          onChange(null, keyboardInputValue);
          return;
        }

        if (
          !is.validDate(value) ||
          value.getFullYear() < 1900 ||
          value.getFullYear() > 2050
        ) {
          return;
        }

        onChange(value, keyboardInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="mm/dd/yyyy"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...(params.inputProps || {}),
            ...(inputProps || {}),
          }}
          error={false}
          variant="standard"
          fullWidth
          disabled={props.disabled}
          {...textFieldProps}
        />
      )}
    />
  );
}
