import { is } from './is';
import { SOMETHING_WENT_WRONG } from '../constants';

export function getServerValidationError(error: unknown): string {
  if (error && is.object(error) && is.serverValidationError(error.response)) {
    return error.response.data[0].Message || SOMETHING_WENT_WRONG;
  }

  return SOMETHING_WENT_WRONG;
}
