import { PlainObject } from '../types';

export function getChanges(
  root: PlainObject,
  result: PlainObject,
): PlainObject {
  return Object.keys(result).reduce(
    (acum, key) =>
      root[key] !== result[key] ? { ...acum, [key]: true } : acum,
    {},
  );
}
