/** @jsxImportSource @emotion/react */
import { FreightQuoteActionModel } from '../../types';
import {
  PORTAL_IS_NOT_AVAILABLE,
  QuoteStatus,
  RESPOND_BY_TIME_PASSED,
} from '../../constants';
import { Tooltip } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { isQuoteCanNotBeSubmitted } from '../../utils';
import {
  useQuoteErrorIconStyles,
  useQuoteInfoIconStyles,
} from './QuoteErrorIcon.styles';

interface Props {
  quote: FreightQuoteActionModel;
}

export function QuoteErrorIcon({ quote }: Props): JSX.Element | null {
  const errorStyles = useQuoteErrorIconStyles();
  const infoStyles = useQuoteInfoIconStyles();

  const quoteCanNotBeSubmitted = isQuoteCanNotBeSubmitted(quote);
  const errorIconMessage = quoteCanNotBeSubmitted
    ? RESPOND_BY_TIME_PASSED
    : PORTAL_IS_NOT_AVAILABLE;
  const showErrorIcon =
    quoteCanNotBeSubmitted || quote.Status === QuoteStatus.SubmitRequestFailed;

  if (!showErrorIcon) {
    return null;
  }

  return (
    <Tooltip enterDelay={300} placement="top" title={errorIconMessage}>
      {quoteCanNotBeSubmitted ? (
        <InfoOutlinedIcon css={infoStyles} />
      ) : (
        <ErrorOutlineIcon
          css={errorStyles}
          className="quote_error"
          color="error"
        />
      )}
    </Tooltip>
  );
}
