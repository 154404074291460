import { PropsWithChildren } from 'react';
import { Header } from '../../features/header/Header';

export const PageLayout = (props: PropsWithChildren): JSX.Element => {
  return (
    <>
      <Header />
      {props.children}
    </>
  );
};
