import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit/src/mapBuilders';
import { NoInfer } from '@reduxjs/toolkit/src/tsHelpers';
import { freightQuotesStoreKey } from './freightQuotes.const';
import {
  checkIsQuoteValidToSubmit,
  fetchCarrierView,
  fetchFreightQuotesData,
  fetchLookupRates,
  fetchSearchResult,
  fetchSimilarQuotes,
  ignoreQuotes,
  setNewPage,
  setQuoteToSubmit,
  submitBid,
  updateStatusCounters,
} from './freightQuotes.thunks';
import { QuoteStatus, TabName } from '../../constants';
import {
  CarrierViewResult,
  FetchResult,
  FreightQuotesState,
  FreightQuotesTabState,
  IgnoreResult,
  LookupRatesResult,
  SearchQuoteResult,
  SearchQuoteState,
  SimilarQuotesResult,
} from './freightQuotes.types';
import {
  FreightQuoteActionModel,
  FreightQuoteModel,
  FreightQuotesStatusCount,
  ODataProps,
  QuotePortalUIExtended,
} from '../../types';
import {
  setForQuote,
  setQuotesPropsToState,
  updateQuotesProps,
} from './freightQuotes.utils';
import { calculateTimerForQuotes, mergeQuotesData } from '../../utils';

const initialAppliedFilters: ODataProps = {
  skip: 0,
  top: 10,
  filters: {},
};
export const initialSearchState: SearchQuoteState = {
  data: [],
  total: 0,
  appliedFilters: { ...initialAppliedFilters },
};

export const tabInitialData = <FreightQuotesTabState>{
  request: {
    filters: {},
    skip: 0,
    top: 10,
  },
  params: '',
  total: 0,
};

export const fqStoreInitialState: FreightQuotesState = {
  loading: false,
  procedureLoading: false,
  validToSubmitLoading: false,
  submitLoading: false,
  quotesToIgnore: null,
  quoteToSubmit: null,
  tabsData: {
    [TabName.Open]: { ...tabInitialData },
    [TabName.Submitted]: { ...tabInitialData },
    [TabName.Won]: { ...tabInitialData },
    [TabName.Lost]: { ...tabInitialData },
    [TabName.Ignored]: { ...tabInitialData },
    [TabName.Missed]: { ...tabInitialData },
  },
  searchLoading: false,
  searchState: { ...initialSearchState },
  statusCount: {
    IgnoredCount: 0,
    LostCount: 0,
    MissedCount: 0,
    OpenCount: 0,
    SubmittedCount: 0,
    WonCount: 0,
    HotCount: 0,
  },
};

export const freightQuotesSlice = createSlice({
  name: freightQuotesStoreKey,
  initialState: fqStoreInitialState,
  reducers: {
    setInlineBidValue(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<
          FreightQuoteActionModel,
          | 'bid'
          | 'comments'
          | 'bidError'
          | 'commentError'
          | 'wrongBidPerMile'
          | 'Id'
        >
      >,
    ) {
      const { Id, bid, comments, bidError, commentError, wrongBidPerMile } =
        payload;

      setQuotesPropsToState(state, [{ Id }], {
        bid,
        comments,
        wrongBidPerMile,
        bidError,
        commentError,
      });
    },
    setQuotesToIgnore(
      state,
      { payload }: PayloadAction<FreightQuoteActionModel[] | null>,
    ) {
      state.quotesToIgnore = payload;
    },
    setCurrentTime(state) {
      const { searchState, quoteToSubmit, tabName, tabsData } = state;

      if (searchState.data && searchState.data.length) {
        state.searchState.data = calculateTimerForQuotes(searchState.data);
      }

      if (quoteToSubmit) {
        state.quoteToSubmit = calculateTimerForQuotes(quoteToSubmit);
      }

      if (!tabName) {
        return;
      }

      const tabData = tabsData[tabName];

      if (tabData && tabData.result) {
        tabData.result = calculateTimerForQuotes(tabData.result);
      }
    },
    setQuoteTabParams(
      state,
      { payload }: PayloadAction<{ tabName: TabName; params: string }>,
    ) {
      const { tabsData } = state;
      const tabData = tabsData[payload.tabName];
      if (tabData) {
        tabData.params = payload.params || '';
      }
    },
    resetSimilarQuotes(
      state,
      { payload }: PayloadAction<FreightQuoteActionModel[]>,
    ) {
      setQuotesPropsToState(state, payload, {
        timestampForSimilarQuotes: undefined,
        wonSimilarQuotes: undefined,
        lostSimilarQuotes: undefined,
      });
    },
    resetQuoteDetails(
      state,
      { payload }: PayloadAction<FreightQuoteActionModel[]>,
    ) {
      setQuotesPropsToState(state, payload, {
        carrierView: undefined,
        carrierViewError: undefined,
        lookUpRates: undefined,
      });
    },
    resetSearchFilters: (state) => {
      state.searchState.appliedFilters.filters = {};
      state.searchState.data = [];
      state.searchState.total = 0;
    },
    resetSearch: (state) => {
      state.searchState.appliedFilters = { ...initialAppliedFilters };
      state.searchState.data = [];
      state.searchState.total = 0;
    },
  },
  extraReducers: (
    builder: ActionReducerMapBuilder<NoInfer<FreightQuotesState>>,
  ) => {
    builder.addCase(
      setNewPage.fulfilled,
      (state, { payload }: PayloadAction<TabName>) => {
        state.tabName = payload;

        if (state.tabsData[payload] && state.tabsData[payload].result) {
          state.tabsData[payload].result = calculateTimerForQuotes(
            state.tabsData[payload].result,
          );
        }
      },
    );

    // update status counters
    builder.addCase(
      updateStatusCounters.fulfilled,
      (state, { payload }: PayloadAction<FreightQuotesStatusCount>) => {
        state.statusCount = payload;
      },
    );

    // lookup rates
    builder.addCase(
      setQuoteToSubmit.fulfilled,
      (state, { payload }: PayloadAction<FreightQuoteModel[] | null>) => {
        state.quoteToSubmit = payload;
      },
    );

    builder.addCase(
      fetchLookupRates.fulfilled,
      (state, { payload }: PayloadAction<LookupRatesResult>) => {
        setQuotesPropsToState(
          state,
          [(state.quoteToSubmit && state.quoteToSubmit[0]) || payload.quote],
          {
            lookUpRates: payload.data,
          },
        );

        if (!state.quoteToSubmit || !state.quoteToSubmit.length) {
          return;
        }

        state.quoteToSubmit = updateQuotesProps(state.quoteToSubmit, {
          lookUpRates: payload.data,
        });
      },
    );

    builder.addCase(
      fetchCarrierView.fulfilled,
      (state, { payload }: PayloadAction<CarrierViewResult>) => {
        setQuotesPropsToState(state, [payload.quote], {
          carrierView: payload.data,
          carrierViewError: payload.error,
        });
      },
    );

    builder.addCase(
      fetchSimilarQuotes.fulfilled,
      (state, { payload }: PayloadAction<SimilarQuotesResult>) => {
        setQuotesPropsToState(state, [payload.quote], {
          timestampForSimilarQuotes: new Date().getTime(),
          wonSimilarQuotes: payload.similarQuotes[QuoteStatus.Won] || [],
          lostSimilarQuotes: payload.similarQuotes[QuoteStatus.Lost] || [],
        });
      },
    );

    builder
      .addCase(checkIsQuoteValidToSubmit.pending, (state) => {
        state.validToSubmitLoading = true;
      })
      .addCase(
        checkIsQuoteValidToSubmit.fulfilled,
        (state, { payload }: PayloadAction<FreightQuoteModel | null>) => {
          state.validToSubmitLoading = false;

          if (!payload) {
            return;
          }

          if (state.quoteToSubmit) {
            state.quoteToSubmit = updateQuotesProps(
              state.quoteToSubmit,
              payload || {},
            );
            state.quoteToSubmit[0].validToSubmit = true;
          }

          setQuotesPropsToState(state, [{ Id: payload.Id }], {
            validToSubmit: true,
            ...(payload || {}),
          });
        },
      );

    // ignore
    builder
      .addCase(ignoreQuotes.pending, (state) => {
        state.procedureLoading = true;
      })
      .addCase(
        ignoreQuotes.fulfilled,
        (state, { payload }: PayloadAction<IgnoreResult | null>) => {
          state.procedureLoading = false;

          if (!payload) {
            return;
          }

          // todo: refactor
          if (
            payload.quotes &&
            state.searchState.data &&
            state.searchState.data.length
          ) {
            const quote = payload.quotes[0];

            state.searchState.data = (state.searchState.data || []).map(
              (item: FreightQuoteActionModel) =>
                item.Id === quote.Id
                  ? {
                      ...item,
                      disabled: true,
                    }
                  : item,
            );
          }

          state.quotesToIgnore = null;

          if (payload.statuses) {
            state.statusCount = payload.statuses;
          }

          if (state.tabName && state.tabsData[state.tabName]) {
            const { result } = state.tabsData[state.tabName];
            const quotesMap = (payload.quotes || []).reduce<
              Record<number, true>
            >(
              (acum, quote) => ({
                ...acum,
                [quote.Id]: true,
              }),
              {},
            );

            state.tabsData[state.tabName].result = (result || []).map(
              (item: FreightQuoteActionModel) =>
                quotesMap[item.Id]
                  ? {
                      ...item,
                      disabled: true,
                    }
                  : item,
            );
          }
        },
      )
      .addCase(ignoreQuotes.rejected, (state) => {
        state.procedureLoading = false;
      });

    // submit bid
    builder
      .addCase(submitBid.pending, (state, { meta }) => {
        state.submitLoading = true;

        const { quotes } = meta.arg;

        setQuotesPropsToState(
          state,
          (state.quoteToSubmit && [state.quoteToSubmit[0]]) || quotes,
          {
            submitLoading: true,
          },
        );
      })
      .addCase(
        submitBid.fulfilled,
        (state, { payload }: PayloadAction<FreightQuoteModel | null>) => {
          state.submitLoading = false;

          setQuotesPropsToState(
            state,
            (state.quoteToSubmit && [state.quoteToSubmit[0]]) || [
              {
                Id: payload?.Id || 0,
              },
            ],
            {
              submitted: true,
              submitLoading: false,
              User: payload?.User,
              RequestedBid: payload?.RequestedBid,
              Status:
                (payload && payload.Status) || QuoteStatus.SubmitRequested,
              Portal: {
                ...(state.quoteToSubmit ? state.quoteToSubmit[0].Portal : {}),
                ...(payload ? payload.Portal : {}),
              } as QuotePortalUIExtended,
            },
          );

          state.quoteToSubmit = null;
        },
      )
      .addCase(submitBid.rejected, (state, { meta }) => {
        state.submitLoading = false;

        const { quotes } = meta.arg;

        setQuotesPropsToState(
          state,
          (state.quoteToSubmit && [state.quoteToSubmit[0]]) || quotes,
          {
            submitted: true,
            submitLoading: false,
          },
        );
      });

    // fetch data
    builder
      .addCase(fetchFreightQuotesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchFreightQuotesData.fulfilled,
        (state, { payload }: PayloadAction<FetchResult | null>) => {
          if (!payload) {
            return;
          }

          state.loading = false;
          state.tabName = payload.tabName;
          const { result } = state.tabsData[payload.tabName];

          state.tabsData[payload.tabName] = {
            ...state.tabsData[payload.tabName],
            request: payload.filters,
            result: calculateTimerForQuotes(
              mergeQuotesData(payload.data, result),
            ),
            total: payload.total,
          };
          state.statusCount = payload.statuses;
        },
      )
      .addCase(fetchFreightQuotesData.rejected, (state) => {
        state.loading = false;
      });

    // search
    builder
      .addCase(fetchSearchResult.pending, (state) => {
        state.searchLoading = true;
      })
      .addCase(
        fetchSearchResult.fulfilled,
        (state, { payload }: PayloadAction<Partial<SearchQuoteResult>>) => {
          state.searchLoading = false;

          if (!payload.filters) {
            return;
          }

          state.searchState.appliedFilters = payload.filters;
          state.searchState.data = calculateTimerForQuotes(
            mergeQuotesData(payload.data || [], state.searchState.data),
          );
          state.searchState.total = payload.total || 0;
        },
      );
  },
});
