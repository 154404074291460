import { useEffect, useState } from 'react';
import { PlainObject } from '../types';
import { MINUTE } from '../constants';

const intervalStamps: PlainObject = {};

/**
 * Returns clear function
 * @param cb: function
 * @param timeout: number
 * @param deps: []
 */
export function useInterval(
  cb: () => void,
  timeout: number,
  deps: unknown[],
): () => void {
  const [intervalStamp] = useState<string>(Symbol() as unknown as string);

  useEffect(() => {
    clearInterval(intervalStamps[intervalStamp]);

    if (!timeout) {
      return;
    }

    intervalStamps[intervalStamp] = setInterval(
      cb,
      timeout,
    ) as unknown as number;
  }, [...deps, timeout]);

  useEffect(
    () => () => {
      clearInterval(intervalStamps[intervalStamp]);

      delete intervalStamps[intervalStamp];
    },
    [],
  );

  return () => {
    clearInterval(intervalStamps[intervalStamp]);

    delete intervalStamps[intervalStamp];
  };
}

/**
 * Returns clear function
 * @param cb: function
 * @param deps: []
 */
export function useEachMinute(cb: () => void, deps: unknown[]) {
  const [firstRender, setFirstRender] = useState(true);

  const timeout = firstRender
    ? MINUTE - (new Date().getTime() % MINUTE) + 200
    : MINUTE;

  return useInterval(
    () => {
      cb();

      if (firstRender) {
        setFirstRender(false);
      }
    },
    timeout,
    deps,
  );
}
