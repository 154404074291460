import { ColumnModel, EmptyElement } from '../../components';
import { RuleApiModel } from '../../types';
import {
  renderDescriptionFormatter,
  renderRateActionModeFormatter,
  renderStatusFormatter,
} from './RulesDataGrid.utils';
import { isoToDateTimeString } from '../../utils';

const MIN_WIDTH = '90px';

const style = {
  minWidth: MIN_WIDTH,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

export const getRulesGridColumns = (
  ruleModeLabels: Record<number, string>,
  ruleStatusLabels: Record<number, string>,
  archivedRules: boolean,
): ColumnModel<RuleApiModel>[] =>
  [
    {
      name: 'Name',
      label: 'Rule name',
      useTooltip: true,

      styles: { ...style, maxWidth: '200px' },
      headerStyles: { ...style },
    },
    {
      name: 'Mode',
      label: 'Mode',
      formatter: (rule: RuleApiModel) => ruleModeLabels[rule.Mode],
    },
    {
      name: 'User',
      label: 'User',

      styles: { ...style, maxWidth: '200px' },
      headerStyles: { ...style, maxWidth: '200px' },
      formatter: (rule: RuleApiModel) =>
        (rule.User && rule.User.Email) || EmptyElement,
    },
    {
      name: 'LastChangeDateTime',
      label: 'Modified',

      styles: { ...style },
      headerStyles: { ...style },

      formatter: (rule: RuleApiModel) =>
        isoToDateTimeString(rule.LastChangeDateTime, 'CT', false, ','),
    },
    {
      name: 'Description',
      label: 'Description',

      styles: { ...style, maxWidth: '300px' },
      headerStyles: { ...style },
      formatter: renderDescriptionFormatter,
    },
    archivedRules
      ? null
      : {
          name: 'Status',
          label: 'Status',
          formatter: (rule: RuleApiModel) =>
            renderStatusFormatter(rule.Status, ruleStatusLabels),
        },
    {
      name: 'RateActionMode',
      label: 'Rate type',
      formatter: renderRateActionModeFormatter,
    },
  ].filter((v) => v) as ColumnModel<RuleApiModel>[];
