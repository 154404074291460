import { css, SerializedStyles } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useToolbarButtonsStyles = (): SerializedStyles => {
  const { palette, spacing } = useTheme();

  return css`
    .headerSubLink {
      cursor: pointer;
      text-decoration: none;
      color: ${palette.common.black};

      .MuiTypography-root {
        padding: ${spacing(1, 2)};
        line-height: 16px;
      }

      &:hover .MuiTypography-root,
      &.active .MuiTypography-root {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .MuiList-root li:not(:last-child) {
      margin-bottom: ${spacing(1)};
    }

    .headerLink {
      cursor: pointer;
      text-decoration: none;
      color: ${palette.common.black};
      position: relative;

      p.MuiTypography-root {
        padding: 20px 16px;

        .MuiSvgIcon-root {
          margin: 3px 0 -3px 4px;
        }
      }

      &:hover:not(.active) p.MuiTypography-root {
        background-color: ${palette.background.panel};
      }

      &.active {
        color: ${palette.primary.main};

        &:after {
          content: '';
          width: 100%;
          height: 4px;
          border-radius: 2px;
          position: absolute;
          bottom: -4px;
          left: 0;
          background-color: ${palette.primary.main};
        }
      }
    }
  `;
};
