import { QuoteShortLocationUIExtended } from '../../../../types';
import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled/useAutocomplete';

export function filterLocationOptions(
  options: QuoteShortLocationUIExtended[],
  { inputValue }: FilterOptionsState<QuoteShortLocationUIExtended>,
): QuoteShortLocationUIExtended[] {
  if (!inputValue) {
    return options;
  }

  return options.filter((option) => {
    const value =
      option &&
      `${option.CityName} ${option.ProvinceName} ${option.ProvinceAbbr}`;

    return new RegExp(`(^${inputValue})|(\\s${inputValue})`, 'gi').test(value);
  });
}
