import React, { CSSProperties } from 'react';
import { Box, Checkbox } from '@mui/material';

export const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement>,
  title: string,
  selected: boolean,
  indeterminate?: boolean,
  key?: string,
): React.ReactNode => {
  const checked = selected || props['aria-selected'];
  const disabled =
    !!(!checked && props['aria-disabled']) || props['aria-readonly'];
  const styles: CSSProperties = disabled
    ? {
        opacity: '0.38',
        pointerEvents: 'none',
      }
    : {};

  return (
    <li
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        ...styles,
      }}
      aria-selected={selected}
      key={key}
      {...props}
      aria-disabled={disabled}
    >
      <Checkbox
        style={{ marginRight: 8 }}
        checked={selected}
        indeterminate={indeterminate}
      />
      <Box paddingY={1}>{title}</Box>
    </li>
  );
};
