import { rowClassNames } from '../SgDraggableRow.styles';

let previousElement: HTMLTableRowElement | undefined;

interface Result {
  rowName?: string;
  index: number;
  isAbove: boolean;
  element: Element;
}

export function getHoveredItemId(elementY: number): Result {
  const list: Element[] = Array.from(
    document.getElementsByClassName('draggableRowInactive'),
  );

  const rects = list.map((item) => item.getBoundingClientRect());

  const target = rects.findIndex(({ y, height }) => {
    return elementY <= y + height;
  });

  const index1 = target < 0 ? rects.length - 1 : target;
  const index =
    index1 > 0 && elementY < rects[index1].y + rects[index1].height / 2
      ? index1 - 1
      : index1;

  const hoveredElement = list[index] as unknown as HTMLOrSVGElement;
  const { rowName } = hoveredElement.dataset;
  const isAbove =
    index === 0 && elementY < rects[index].y + rects[index].height / 2;

  return {
    rowName,
    isAbove,
    element: list[index],
    index,
  };
}

function removeClassNames(el?: HTMLTableRowElement) {
  if (!el) {
    return;
  }

  el.classList.remove(rowClassNames.closeEmptyCellImmediately);
  el.classList.remove(rowClassNames.openEmptyCellImmediately);
  el.classList.remove(rowClassNames.openEmptyCell);
}

export function openEmptyPlaceByClientY(
  elementY: number,
  immediately?: boolean,
): number | null {
  const { element, isAbove, index } = getHoveredItemId(elementY);

  if (
    !element ||
    !element.previousElementSibling ||
    !element.previousElementSibling.children[0] ||
    !element.nextElementSibling ||
    !element.nextElementSibling.children[0]
  ) {
    return null;
  }

  const emptyTr = isAbove
    ? (element.previousElementSibling as HTMLTableRowElement)
    : (element.nextElementSibling as HTMLTableRowElement);

  if (emptyTr === previousElement) {
    return null;
  }

  removeClassNames(emptyTr);
  removeClassNames(previousElement);
  emptyTr.classList.add(
    immediately
      ? rowClassNames.openEmptyCellImmediately
      : rowClassNames.openEmptyCell,
  );
  previousElement = emptyTr;

  return isAbove ? index : index + 1;
}

export function closeEmptyPlaceByClientY(): void {
  if (!previousElement) {
    return;
  }

  removeClassNames(previousElement);
  previousElement.classList.add(rowClassNames.closeEmptyCellImmediately);
  previousElement = undefined;
}

export function fixCellsSizes(row: HTMLTableRowElement): void {
  row.classList.remove('draggableRowInactive');

  row.childNodes.forEach((td) => {
    const width = getComputedStyle(td as Element)?.width;

    if (!width) {
      return;
    }

    (td as HTMLTableColElement).style.width = width;
    (td as HTMLTableColElement).style.minWidth = width;
    (td as HTMLTableColElement).style.maxWidth = width;
  });
}
export function unfixCellsSizes(row: HTMLTableRowElement): void {
  row.childNodes.forEach((td) => {
    (td as HTMLTableColElement).style.width = '';
    (td as HTMLTableColElement).style.minWidth = '';
    (td as HTMLTableColElement).style.maxWidth = '';
  });
}
