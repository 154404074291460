/** @jsxImportSource @emotion/react */
import { Card, Stack, Box, Typography } from '@mui/material';
import { useNavigationTabCardStyles } from './NavigationTabCard.styles';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../utils';
import { freightQuotesSlice } from '../../redux/freightQuotes';
import { TabName } from '../../constants';
import { useAppDispatch } from '../../redux/hooks';

interface Props {
  label: string;
  count: number | string;
  to: string;
  active?: boolean;
  sx?: SxProps<Theme>;
  link: TabName;
  additionalIcon?: JSX.Element;
}

export function NavigationTabCard({
  sx,
  to,
  link,
  active,
  label,
  count,
  children,
  additionalIcon,
}: PropsWithChildren<Props>): JSX.Element {
  const dispatch = useAppDispatch();
  const styles = useNavigationTabCardStyles();

  return (
    <NavLink
      onClick={() => {
        dispatch(
          freightQuotesSlice.actions.setQuoteTabParams({
            tabName: link,
            params: '',
          }),
        );
      }}
      to={to}
      css={styles}
      className={cn(!!active && 'active')}
    >
      <Card variant="outlined" sx={{ padding: 1, ...(sx || {}) }}>
        <Stack position="relative" direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" className="icon-container">
            {children}
          </Stack>
          <Box>
            <Typography variant="subtitle1">{count}</Typography>
            <Typography
              variant="subtitle2"
              color={active ? 'common.black' : 'secondary.main'}
            >
              {label}
            </Typography>
          </Box>
          <Box position="absolute" right="0" bottom="0">
            {additionalIcon}
          </Box>
        </Stack>
      </Card>
    </NavLink>
  );
}
