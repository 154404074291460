import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { schedulingStoreKey } from './scheduling.const';
import { FetchScheduleResult, SchedulingState } from './scheduling.types';
import { editSchedule, fetchSchedule } from './scheduling.thunks';
import { initEnumsData } from '../../utils/ruleEnums';
import { ScheduleItem } from '../../types/scheduleItem';
import { CreateRuleFormModel } from '../../features/ruleForm/RuleForm.types';

const initialState: SchedulingState = {
  loading: false,
  enums: {
    ...initEnumsData,
  },
};

function setLoading(
  schedule: ScheduleItem[],
  id: number,
  loading: boolean,
): ScheduleItem[] {
  return (schedule || []).map((item) =>
    item.Id === id ? { ...item, loading } : item,
  );
}

export const schedulingSlice = createSlice({
  name: schedulingStoreKey,
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false;
    },
    toggleScheduleEdit: (state, { payload }: PayloadAction<ScheduleItem>) => {
      state.schedule = (state.schedule || []).map((item) =>
        item.Id === payload.Id ? { ...item, edit: !item.edit } : item,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchSchedule.fulfilled,
        (state, { payload }: PayloadAction<FetchScheduleResult>) => {
          state.loading = false;
          state.schedule = payload.schedule;
          state.enums = payload.enums;
        },
      )
      .addCase(fetchSchedule.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(editSchedule.pending, (state, _) => {
        const { item } = _.meta.arg;

        state.schedule = setLoading(state.schedule || [], item.Id, true);
      })
      .addCase(
        editSchedule.fulfilled,
        (state, { payload }: PayloadAction<ScheduleItem>) => {
          state.schedule = (state.schedule || []).map((item) =>
            item.Id === payload.Id ? payload : item,
          );
        },
      )
      .addCase(editSchedule.rejected, (state, { meta }) => {
        const { item } = meta.arg;

        state.schedule = setLoading(state.schedule || [], item.Id, false);
      });
  },
});
