import { appConfig, MILES_IN_METER } from '../../constants';
import { azureMapsStyles, azureMapsLayerConfig } from './MapDialog.const';
import { AzureMapDataFeature } from '../../types/global';
import { locationFormatter } from '../../utils';
import { FreightQuoteActionModel } from '../../types';

export function initializeAzureMap(
  quote: FreightQuoteActionModel,
  containerId: string,
  onRouteCalculated: (mi: number | null) => void,
): void {
  if (!quote || !quote.StopDetails) {
    return;
  }

  const azureMap = new atlas.Map(containerId, {
    authOptions: {
      authType: 'subscriptionKey', // 'anonymous'
      subscriptionKey: appConfig.azureMapsKey,
    },
  });

  azureMap.events.add('ready', function () {
    if (!quote || !quote.StopDetails) {
      return;
    }

    //Create a data source and add it to the map.
    const datasource = new atlas.source.DataSource();
    azureMap.sources.add(datasource);

    //Add a layer for rendering the route lines and have it render under the map labels.
    azureMap.layers.add(
      new atlas.layer.LineLayer(datasource, null, azureMapsStyles),
      'labels',
    );

    //Add a layer for rendering point data.
    azureMap.layers.add(
      new atlas.layer.SymbolLayer(datasource, null, azureMapsLayerConfig),
    );

    const routePoints: AzureMapDataFeature[] = [];
    const coordinates: number[][] = [];

    quote.StopDetails.forEach((stop, index) => {
      if (!stop.Location) {
        return;
      }

      const address = `(${index + 1}) ${locationFormatter(
        stop.Location,
        false,
        '',
      )}, ${stop.Location.ZipCode}`;

      const point = new atlas.data.Feature(
        new atlas.data.Point([stop.Location.Longitude, stop.Location.Latitude]),
        {
          title: address,
          icon:
            index !== 0 && index !== (quote.StopDetails?.length || 1) - 1
              ? 'pin-round-blue'
              : 'pin-blue',
        },
      );

      routePoints.push(point);
      coordinates.push([
        point.geometry.coordinates[0],
        point.geometry.coordinates[1],
      ]);
    });

    //Add the data to the data source.
    datasource.add(routePoints);

    azureMap.setCamera({
      bounds: atlas.data.BoundingBox.fromData(routePoints),
      padding: 80,
    });

    //Use MapControlCredential to share authentication between a map control and the service module.
    const pipeline = atlas.service.MapsURL.newPipeline(
      new atlas.service.MapControlCredential(azureMap),
    );

    //Construct the RouteURL object
    const routeURL = new atlas.service.RouteURL(pipeline);

    //Make a search route request
    routeURL
      .calculateRouteDirections(
        atlas.service.Aborter.timeout(10000),
        coordinates,
        {
          avoid: 'ferries',
        },
      )
      .then((directions: AzureDirections) => {
        //Get data features from response
        const distance =
          (directions &&
            directions.routes[0] &&
            directions.routes[0]?.summary?.lengthInMeters) ||
          null;

        onRouteCalculated(distance ? distance * MILES_IN_METER : distance);

        const data = directions.geojson.getFeatures();
        datasource.add(data);
      });
  });
}
