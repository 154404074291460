import { Box, Typography, Button, Stack } from '@mui/material';
import {
  emptyStatesByTabName,
  emptyStatesByFilters,
  EmptyStateType,
} from './EmptyState.constants';

interface Props {
  reason: EmptyStateType;
  filtered?: boolean;
  fullHeight?: boolean;
  height?: string;
  message?: string;
  minHeight?: string;
  onActionClick?: () => void;
  actionHref?: string;
}

export function EmptyState({
  reason,
  filtered,
  fullHeight,
  height = '500px',
  minHeight = '300px',
  onActionClick = () => null,
  actionHref,
  message,
}: Props): JSX.Element {
  const emptyState = filtered
    ? emptyStatesByFilters
    : emptyStatesByTabName[reason];

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height={fullHeight ? 'calc(100vh - 110px)' : height}
      minHeight={minHeight}
    >
      <Box sx={{ marginBottom: emptyState.size === 'small' ? 2 : 4 }}>
        <img src={emptyState.img} />
      </Box>
      <Typography variant="h3">{emptyState.title}</Typography>

      {(message || emptyState.message) && (
        <Typography variant="h5" marginTop={0.5}>
          {message || emptyState.message}
        </Typography>
      )}

      {emptyState.hasAction && (
        <Button
          variant="contained"
          href={actionHref}
          onClick={actionHref ? undefined : onActionClick}
          sx={{ marginTop: 4 }}
        >
          {emptyState.actionTitle}
        </Button>
      )}
    </Stack>
  );
}
