import {
  ShipperApiModel,
  AccountManagerApiModel,
  QuoteBaseEntityTypeUIExtended,
  QuotePortalUIExtended,
  FiltersModel,
} from '../types';
import _uniqBy from 'lodash/uniqBy';
import { getShippersByPortals } from './getShippersByPortals';

interface ShipperApiModelExtended extends ShipperApiModel {
  disabled?: boolean | undefined;
}

interface AccountManagerApiModelExtended extends AccountManagerApiModel {
  disabled?: boolean | undefined;
}

export function getManagersByPortals(
  portals: QuotePortalUIExtended[],
  filters: Partial<FiltersModel>,
): QuoteBaseEntityTypeUIExtended[] {
  const locationFilterApplied =
    filters.origins?.length || filters.destinations?.length;
  const shippers: ShipperApiModelExtended[] = getShippersByPortals(portals);
  const availableManagers = shippers.reduce(
    (accum, { AccountManager }) =>
      AccountManager ? [...accum, AccountManager] : accum,
    [] as AccountManagerApiModel[],
  );

  const mappedManagers = availableManagers.map(
    ({ Id, FirstName, LastName }) => {
      let disabledOption = {};

      if (locationFilterApplied) {
        const hasFilteredShipperWithoutManager = shippers.some(
          (shipper) => !shipper.disabled && shipper.AccountManager === null,
        );
        const shippersByManagers = shippers.filter(
          ({ AccountManager }) => AccountManager?.Id === Id,
        );
        const allShippersDisabled = shippersByManagers.every(
          (shipper) => shipper.disabled,
        );
        disabledOption = {
          disabled: hasFilteredShipperWithoutManager
            ? false
            : allShippersDisabled,
        };
      }

      return {
        Id,
        Name: `${FirstName} ${LastName}`,
        ...disabledOption,
      };
    },
  );

  return _uniqBy(mappedManagers, 'Id').sort((a, b) =>
    String(a.Name).toLowerCase() > String(b.Name).toLowerCase() ? 1 : -1,
  );
}
