import { Box, Skeleton, Stack, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { EqualIcon, FuelIcon } from '../icons';
import { ValueOrEmpty } from '../valueOrEmpty/ValueOrEmpty';

export enum LookUpRateType {
  Fuel,
  Min,
  Max,
  Average,
}

interface Props {
  titleMode?: boolean;
  title?: string;
  value: string | JSX.Element;
  perMile?: string | JSX.Element;
  loading?: boolean;
  type?: LookUpRateType;
}

export const lookUpRateIcons: Record<LookUpRateType, JSX.Element> = {
  [LookUpRateType.Fuel]: <FuelIcon style={{ margin: '2px' }} />,
  [LookUpRateType.Average]: (
    <EqualIcon style={{ width: '12px', margin: '6px' }} />
  ),
  [LookUpRateType.Min]: <ArrowDropDownIcon color="error" />,
  [LookUpRateType.Max]: <ArrowDropUpIcon color="primary" />,
};

export function LookUpRate({
  titleMode,
  title,
  value,
  perMile,
  loading,
  type = LookUpRateType.Fuel,
}: Props): JSX.Element {
  const color = titleMode ? 'secondary' : 'common';

  return (
    <>
      {loading && (
        <Skeleton sx={{ height: 24 }} animation="wave" variant="rectangular" />
      )}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: 'background.default',
          height: loading ? 0 : 24,
          paddingX: 1,
          marginBottom: 0.5,
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" alignItems="center" width="40%">
          {title && (
            <>
              {lookUpRateIcons[type]}
              <Typography variant="subtitle2" paddingLeft={1} color={color}>
                {title}
              </Typography>
            </>
          )}
        </Stack>
        {perMile !== undefined && (
          <Box width="30%">
            <Typography variant="subtitle2" textAlign="right" color={color}>
              <ValueOrEmpty>{perMile}</ValueOrEmpty>
            </Typography>
          </Box>
        )}
        <Box width="30%">
          <Typography variant="subtitle2" textAlign="right" color={color}>
            <ValueOrEmpty>{value}</ValueOrEmpty>
          </Typography>
        </Box>
      </Stack>
    </>
  );
}
