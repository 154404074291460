import { BaseEntityType } from '../../types';
import { QuoteStatusIndex } from '../../constants';

export const StatusFilterOptions = <BaseEntityType[]>[
  {
    id: QuoteStatusIndex.Open,
    name: 'Open',
  },
  {
    id: QuoteStatusIndex.Submitted,
    name: 'Submitted',
  },
  {
    id: QuoteStatusIndex.Won,
    name: 'Won',
  },
  {
    id: QuoteStatusIndex.Lost,
    name: 'Lost',
  },
  {
    id: QuoteStatusIndex.Ignored,
    name: 'Ignored',
  },
  {
    id: QuoteStatusIndex.Missed,
    name: 'Missed',
  },
];
