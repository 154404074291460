import {
  ApiBaseEntityType,
  BaseFilters,
  PlainObject,
  QuoteBaseEntityTypeUIExtended,
  QuoteLocationByState,
  QuotePortal,
  QuotePortalUIExtended,
  QuoteShortLocation,
  QuoteShortLocationUIExtended,
  UserBaseModel,
  UserSelectModel,
} from '../../types';
import { FilterRelationsApi } from './filtersData.types';
import { getShippersByPortals } from '../../utils';

export function transformLocationByState(
  locations: QuoteLocationByState[],
): QuoteShortLocation[] {
  return locations.reduce(
    (acum, { CityNames, ...other }: QuoteLocationByState) => {
      const newLocations = (CityNames || []).map(
        (cityName): QuoteShortLocation => ({
          CityName: cityName,
          ProvinceFullName: `${other.ProvinceName}, ${other.ProvinceAbbr}`,
          ...other,
        }),
      );

      return [...acum, ...newLocations];
    },
    [] as QuoteShortLocation[],
  );
}

function createLocationKey({
  CityName,
  CountryName,
  ProvinceAbbr,
}: QuoteShortLocation): string {
  return `${CityName}-${ProvinceAbbr}-${CountryName}`;
}

function createKeyMapForLocations(
  locations: QuoteShortLocation[],
): PlainObject<boolean> {
  return locations.reduce(
    (acum, location) => ({
      ...acum,
      [createLocationKey(location)]: true,
    }),
    {},
  );
}

function createKeyMapForBaseEntity(
  locations: ApiBaseEntityType[],
): PlainObject<boolean> {
  return locations.reduce(
    (acum, item) => ({
      ...acum,
      [item.Id]: true,
    }),
    {},
  );
}

function createKeyMapForShippers(portals: QuotePortal[]): PlainObject<boolean> {
  const shippers = getShippersByPortals(portals);

  return (shippers || []).reduce(
    (acum, item) => ({
      ...acum,
      [item.Id]: true,
    }),
    {},
  );
}

function updateRelationForPortals(
  allItems: QuotePortal[],
  relatedItems: QuotePortal[],
): QuotePortalUIExtended[] {
  const map = createKeyMapForBaseEntity(relatedItems || []);
  const shippersMap = createKeyMapForShippers(relatedItems || []);

  return allItems.map((item): QuotePortalUIExtended => {
    return {
      ...item,
      disabled: !map[item.Id],
      Shippers: (item.Shippers || []).map(
        (shipper): QuoteBaseEntityTypeUIExtended => {
          return {
            ...shipper,
            disabled: !shippersMap[shipper.Id],
          };
        },
      ),
    };
  });
}

function updateRelationForLocations(
  allLocations: QuoteShortLocationUIExtended[],
  relatedLocations: QuoteShortLocationUIExtended[],
): QuoteShortLocationUIExtended[] {
  const map = createKeyMapForLocations(relatedLocations || []);

  return allLocations.map((location): QuoteShortLocationUIExtended => {
    if (!map[createLocationKey(location)]) {
      return {
        ...location,
        disabled: true,
      };
    }

    return location;
  });
}

export function updateRelations(
  allFilters: Partial<BaseFilters>,
  {
    OriginLocations,
    DestinationLocations,
    Portals,
  }: Partial<FilterRelationsApi>,
): Partial<BaseFilters> {
  const origins =
    OriginLocations && OriginLocations.length
      ? updateRelationForLocations(
          allFilters.origins || [],
          transformLocationByState(OriginLocations),
        )
      : allFilters.origins;
  const destinations =
    DestinationLocations && DestinationLocations.length
      ? updateRelationForLocations(
          allFilters.destinations || [],
          transformLocationByState(DestinationLocations),
        )
      : allFilters.destinations;
  const portals =
    Portals && Portals.length
      ? updateRelationForPortals(allFilters.portals || [], Portals)
      : allFilters.portals;

  return {
    portals,
    origins,
    destinations,
  };
}

export function UsersResponseToSelectModel(
  users: UserBaseModel[],
): UserSelectModel[] {
  return users.map(
    (user): UserSelectModel => ({
      ...user,
      value: user.Id,
      label: `${user.FirstName}, ${user.LastName}`,
    }),
  );
}
