/** @jsxImportSource @emotion/react */
import speedometer from '../../../assets/speedometer.svg';
import speedometerArrow from '../../../assets/speedometerArrow.svg';
import { Box, Typography, Skeleton, Stack } from '@mui/material';
import { useDataGridStyles } from './PerformanceChart.styles';
import { cn, is, percentFormatter } from '../../../utils';
import React from 'react';

const steps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

interface Props {
  performance?: number | null;
}

export function PerformanceChart({ performance }: Props) {
  const styles = useDataGridStyles();

  const rotateDeg = Number((((performance || 0) * 230) / 100).toFixed(1)) + 245;

  if (is.null(performance)) {
    return <Typography padding={3}>No data</Typography>;
  }

  return (
    <Stack direction="column" alignItems="center" paddingTop={3}>
      <Box
        css={styles}
        className={cn(is.nullOrUndefined(performance) && 'loading')}
      >
        {is.nullOrUndefined(performance) && (
          <Skeleton
            sx={{ height: 247 }}
            animation="wave"
            variant="rectangular"
          />
        )}

        <img className="speedometerBar" src={speedometer} />
        <Box
          className="speedometerArrowContainer"
          style={{ transform: `rotate(${rotateDeg}deg)` }}
        >
          <img className="speedometerArrow" src={speedometerArrow} />
        </Box>
        <Typography variant="h3" className="speedometerValue">
          {percentFormatter(performance)}
        </Typography>
        {steps.map((num) => (
          <Typography
            variant="h5"
            key={num}
            className={`speedometerNum speedometer_${num}`}
          >
            {num * 10}%
          </Typography>
        ))}
      </Box>
    </Stack>
  );
}
