import { SerializedStyles, css } from '@emotion/react';

export const useDataGridStyles = (): SerializedStyles => {
  return css`
    width: 375px;
    height: 247px;
    position: relative;

    &.loading {
      .speedometerBar,
      .speedometerArrowContainer,
      .speedometerNum {
        opacity: 0;
      }
    }

    .speedometerValue {
      position: absolute;
      text-align: center;
      bottom: 10px;
      width: 102%;
    }

    .speedometerBar {
      position: absolute;
      top: 25px;
      left: calc(50% - 153px);
    }

    .speedometerArrowContainer {
      position: absolute;
      height: 255px;
      width: 43px;
      left: calc(50% - 20px);
      top: 45px;
    }

    .speedometerNum {
      position: absolute;
    }

    .speedometer_0 {
      left: 8px;
      bottom: 0;
    }

    .speedometer_1 {
      top: 170px;
      left: 0;
    }

    .speedometer_2 {
      top: 112px;
      left: 6px;
    }

    .speedometer_3 {
      top: 60px;
      left: 35px;
    }

    .speedometer_4 {
      top: 20px;
      left: 85px;
    }

    .speedometer_5 {
      top: 0;
      left: calc(50% - 13px);
    }

    .speedometer_6 {
      top: 20px;
      right: 85px;
    }

    .speedometer_7 {
      top: 60px;
      right: 35px;
    }

    .speedometer_8 {
      top: 112px;
      right: 6px;
    }

    .speedometer_9 {
      top: 170px;
      right: 0;
    }

    .speedometer_10 {
      right: 0;
      bottom: 0;
    }
  `;
};
