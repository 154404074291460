import { StringDate } from '../../types';
import { useState } from 'react';
import { is } from '../is';
import { formatTimerForAge } from '../calculateTimerForQuotes';
import { EmptyElement } from '../../components';
import { useEachMinute } from '../useInterval';

interface Props {
  date: StringDate;
}

function getTimeDiff(startDate: Date, currentDate: Date): number {
  if (!is.validDate(startDate) || !is.validDate(currentDate)) {
    return 0;
  }

  return currentDate.getTime() - startDate.getTime();
}

export function AgeColumnFormatter({ date }: Props): JSX.Element {
  const getAge = () =>
    formatTimerForAge(getTimeDiff(new Date(date || ''), new Date()));

  const [value, setValue] = useState(getAge());

  useEachMinute(() => {
    setValue(getAge());
  }, [date]);

  return <>{value}</> || EmptyElement;
}

export function ageColumnFormatter(date: StringDate): JSX.Element {
  return <AgeColumnFormatter date={date} />;
}
