/** @jsxImportSource @emotion/react */
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAccordionGroupStyles } from './AccordionGroup.styles';

interface Props {
  index: string;
  expanded?: boolean;
  renderTitle: () => React.ReactNode;
}

export function AccordionGroup({
  index,
  children,
  renderTitle,
  expanded: initExpanded,
}: PropsWithChildren<Props>): JSX.Element {
  const [expanded, setExpanded] = useState<boolean>(!!initExpanded);
  const styles = useAccordionGroupStyles();

  const onExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      css={styles}
      key={index}
      classes={{
        rounded: '',
      }}
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
      expanded={expanded}
      onChange={onExpand}
      sx={{ borderRadius: 0, boxShadow: 'none', overflow: 'hidden' }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ExpandMoreIcon />}
      >
        {renderTitle()}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
