import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { muiTheme } from './theme';
import App from './App';
import { PublicClientApplication } from '@azure/msal-browser';
import { appConfig } from './constants';
import { msalServiceInstance } from './services/msal-service';

const isLogout = location.pathname === '/logout';

export const msalInstance = new PublicClientApplication({
  auth: appConfig.msalAuth,
  cache: appConfig.msalAuthCache,
  system: isLogout
    ? {
        allowRedirectInIframe: true,
      }
    : {},
});

msalInstance.enableAccountStorageEvents();

msalServiceInstance.setInstance(msalInstance);

const accounts = msalInstance.getAllAccounts();

if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

if (isLogout) {
  msalServiceInstance.logout();
} else {
  const container = document.getElementById('root');
  const root = createRoot(container as unknown as DocumentFragment);

  root.render(
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          <App pca={msalInstance} />
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
