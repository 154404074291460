import { ColumnModel, EmptyElement } from '../../../../components';
import {
  CarrierViewModel,
  FreightQuoteModel,
  StringDate,
} from '../../../../types';
import {
  isoToMonthDateString,
  numberFormat,
  rowLocationFormatter,
  weightFormat,
  rowIsoToDateWithTooltip,
  rowBidBy,
  rowSageBidPerMile,
} from '../../../../utils';
import { ageColumnFormatter } from '../../../../utils/dataFormatters/ageColumnFormatter';
import { isoDateFormatter } from '../../../../utils/dataFormatters/dateFormatter';
import { CT_TIMEZONE } from '../../../../constants';

const style = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '110px',
};

export const similarQuotesColumns: ColumnModel<FreightQuoteModel>[] = [
  {
    name: 'ShipDate',
    label: 'Pick Up',
    formatter: (data) => {
      const pickUp = data.StopDetails?.find(
        (stop) => stop.StopDetailType === 'Pick',
      );
      const creationDate = isoDateFormatter(
        data.CreateDateTime as StringDate,
        'MM/dd/yyyy h:mm a',
        CT_TIMEZONE,
      );
      const tooltip = `In Sagan at ${creationDate} CT`;

      return rowIsoToDateWithTooltip({
        date: pickUp?.Date as StringDate,
        time: pickUp?.TimeWithTimeZone as string,
        tooltip,
        style: { maxWidth: 220 },
      });
    },
  },
  {
    name: 'Shipper',
    label: 'Customer',
    useTooltip: true,
    styles: { whiteSpace: 'nowrap', ...style },
  },
  {
    name: 'OriginLocation',
    label: 'Origin',
    styles: { whiteSpace: 'nowrap', ...style },
    formatter: rowLocationFormatter,
  },
  {
    name: 'DestinationLocation',
    label: 'Destination',
    styles: { whiteSpace: 'nowrap', ...style },
    formatter: rowLocationFormatter,
  },
  {
    name: 'EquipmentType',
    label: 'ET',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) =>
      (data.EquipmentType && data.EquipmentType.Name) || EmptyElement,
  },
  {
    name: 'Distance',
    label: 'Mileage',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => numberFormat(data.Distance, { fractionDigits: 0 }),
  },
  {
    name: 'StopsCount',
    label: 'Stops',
  },
  {
    name: 'LowestBid',
    label: 'Sage Bid',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: rowSageBidPerMile,
  },
  {
    name: 'LastBidDateTime',
    label: 'Bid at',
    formatter: (data) =>
      rowIsoToDateWithTooltip({
        date: data.LastBidDateTime,
        timezone: CT_TIMEZONE,
        style: { minWidth: 105 },
      }),
  },
  {
    name: 'User',
    label: 'Bid by',
    formatter: (data) => rowBidBy(data.User),
  },
];

export const carrierViewColumns: ColumnModel<CarrierViewModel>[] = [
  {
    name: 'Age',
    label: 'Age',
    styles: { whiteSpace: 'nowrap' },
    formatter: (item) => ageColumnFormatter(item.Age),
  },
  {
    name: 'Pickup',
    label: 'Pickup',
    styles: { whiteSpace: 'nowrap' },
    formatter: (item) => isoToMonthDateString(item.Pickup),
  },
  {
    name: 'Origin',
    label: 'Origin',
    styles: { whiteSpace: 'nowrap', ...style },
    useTooltip: true,
  },
  {
    name: 'DHO',
    label: 'DH-O',
    headerStyles: { whiteSpace: 'nowrap' },
  },
  {
    name: 'Distance',
    label: 'Distance',
    styles: { whiteSpace: 'nowrap' },
    formatter: (data) => numberFormat(data.Distance, { fractionDigits: 0 }),
  },
  {
    name: 'Destination',
    label: 'Destination',
    styles: { whiteSpace: 'nowrap', ...style },
    useTooltip: true,
  },
  {
    name: 'DHD',
    label: 'DH-D',
    styles: { whiteSpace: 'nowrap' },
    headerStyles: { whiteSpace: 'nowrap' },
  },
  {
    name: 'EquipmentType',
    label: 'Equipm. type',
    styles: { whiteSpace: 'nowrap' },
    headerStyles: { whiteSpace: 'nowrap' },
  },
  {
    name: 'Weight',
    label: 'Weight',
    styles: { whiteSpace: 'nowrap' },
    formatter: (item) => weightFormat(item.Weight),
  },
  {
    name: 'Company',
    label: 'Company',
    styles: { whiteSpace: 'nowrap', ...style },
    useTooltip: true,
  },
  {
    name: 'Rate',
    label: 'Rate',
    styles: { whiteSpace: 'nowrap' },
  },
];
