import { Box, Typography } from '@mui/material';
import { EmptyElement } from '../valueOrEmpty/ValueOrEmpty';
import { PlainObject } from '../../types';

interface Props {
  title: string;
  message?: string | JSX.Element;
  sx?: PlainObject;
}

export function DescriptionItem({ title, message, sx }: Props): JSX.Element {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" lineHeight="16px" color="secondary">
        {title}
      </Typography>
      <Typography variant="subtitle2" lineHeight="16px">
        {message || EmptyElement}
      </Typography>
    </Box>
  );
}
