import { SerializedStyles, css } from '@emotion/react';

export const useDataGridDetailsStyles = (): SerializedStyles => {
  return css`
    .MuiSvgIcon-fontSizeSmall {
      font-size: 14px;
      margin: -2px 5px;
    }

    .MuiTypography-inherit {
      font-size: inherit;
      font-weight: inherit;
    }
  `;
};
