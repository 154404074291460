import { useLocation } from 'react-router-dom';
import { tabNamesList } from '../../constants';

export function useIsLocationContains(names: string[]): boolean {
  const { pathname } = useLocation();

  if (!names.length) {
    return false;
  }

  return new RegExp(`^\\/?(${names.join('|')})\\/?$`).test(pathname);
}

export function useIsTabContent(): boolean {
  return useIsLocationContains(tabNamesList);
}

export function useIsDashboard(): boolean {
  const { pathname } = useLocation();

  return !pathname || pathname === '/';
}
