import { ColumnModel, EmptyElement } from '../../../../components';
import { FreightQuoteActionModel } from '../../../../types';
import {
  numberFormat,
  priceFormat,
  rowIsoToDateMonthString,
  rowLocationFormatter,
} from '../../../../utils';
import { StopsTooltip } from '../../../dataGrid/components';

export const ruleValidationColumns: ColumnModel<FreightQuoteActionModel>[] = [
  {
    name: 'OriginLocation',
    label: 'Origin',
    formatter: rowLocationFormatter,
  },
  {
    name: 'DestinationLocation',
    label: 'Destination',
    formatter: rowLocationFormatter,
  },
  {
    name: 'ShipDate',
    label: 'Pick up date',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: rowIsoToDateMonthString,
  },
  {
    name: 'DeliveryDate',
    label: 'Delivery date',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: rowIsoToDateMonthString,
  },
  {
    name: 'Distance',
    label: 'Distance (mi)',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => numberFormat(data.Distance, { fractionDigits: 0 }),
  },
  {
    name: 'StopsCount',
    label: 'Stops',
    align: 'right',
    formatter: StopsTooltip,
  },
  {
    name: 'EquipmentType',
    label: 'Equipment type',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) =>
      (data.EquipmentType && data.EquipmentType.Name) || EmptyElement,
  },
  {
    name: 'SageBid',
    label: 'Sage bid',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => priceFormat(data.SageBid),
  },
  {
    name: 'LowestBid',
    label: 'Bid amount',
    align: 'right',
    headerStyles: { whiteSpace: 'nowrap' },
    formatter: (data) => priceFormat(data?.AutomatedProcessing?.SuggestedBid),
  },
];
