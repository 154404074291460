import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserBaseModel } from '../../types';
import { userDataStoreKey } from './userData.const';
import { fetchUserData, FetchUserDataResult } from './userData.thunks';
import { ServerStatusCode } from '../../constants';
import { ServerAppConfig } from '../../types/serverAppConfig';

export interface UserDataState {
  user: UserBaseModel | null;
  config?: ServerAppConfig;
  loading: boolean;
  permissionDenied: boolean;
}

const initialState: UserDataState = {
  user: null,
  loading: true,
  permissionDenied: false,
};

export const userDataSlice = createSlice({
  name: userDataStoreKey,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserData.fulfilled,
      (state, { payload }: PayloadAction<FetchUserDataResult>) => {
        state.loading = false;

        const { userData, config } = payload;

        if (userData.status === ServerStatusCode.OK && userData.data) {
          state.user = userData.data;
          state.config = config;
        }

        if (userData.status === ServerStatusCode.FORBIDDEN) {
          state.permissionDenied = true;
        }
      },
    );
  },
});
