import { FreightQuoteActionModel } from '../types';
import { is } from './is';

const ALL_IN = 'All-In';
const LINE_HAUL_ONLY = 'Line Haul only';
const NOT_DEFINED = 'Not defined';

export function getRateTypeByCustomers(quote: FreightQuoteActionModel): string {
  if (is.nullOrUndefined(quote.AllInBidding)) {
    return NOT_DEFINED;
  }

  return quote.AllInBidding ? ALL_IN : LINE_HAUL_ONLY;
}

/*

Portal	        Customer	    Rate Type

-Emerge	        All	            All-In
-E2OPEN	        All	            Line Haul only
-OTM	        Kraft Heinz	    Line Haul only
-OTM	        Darigold	    All-In
-SpotNow        Niagara	        Line Haul only
-CarrierPoint   Ryder	        Line Haul only
-Transplace	    All	            Line Haul only

 */
