import { QueryBuilder } from 'odata-query-builder';
import { is } from '../is';
import {
  ApiBaseEntityType,
  BaseEntityType,
  DateRangeFilter,
  FiltersModel,
  FreightQuoteActionModel,
  ODataProps,
  PlainObject,
  QuoteBaseEntityTypeUIExtended,
  QuoteShortLocationUIExtended,
  RangeFilter,
  SelectItemModel,
} from '../../types';
import { addDays } from 'date-fns';
import {
  buildCityNameFilter,
  buildDistanceRangeFilter,
  buildRangeFilter,
  buildStatusFilter,
} from './oDataBuilder.utils';
import { toNoTimezoneISOString } from '../dataFormatters/dateFormatter';
import { oDateFieldAliases } from './oDataBuilder.const';

const orderFieldNames = <Record<keyof FreightQuoteActionModel, string>>{
  OriginLocation: 'OriginZipCode/CityName',
  DestinationLocation: 'DestinationZipCode/CityName',
  Shipper: 'Shipper/Name',
  SageBid: 'Bid',
  RespondBy: 'RespondByDateTime',
  EquipmentType: 'EquipmentType/Name',
};

export interface FiltersODataResult {
  Filter: string;
  OrderBy: string | null;
  Skip: number;
  Take: number;
}

export type ODataItemValueType =
  | undefined
  | string
  | number
  | RangeFilter
  | DateRangeFilter
  | ApiBaseEntityType[]
  | QuoteBaseEntityTypeUIExtended[]
  | QuoteShortLocationUIExtended[]
  | SelectItemModel[]
  | BaseEntityType[];

export function filtersToOData(
  {
    filters,
    sortingDirection,
    sortingColumn,
    top,
    skip,
    groupBy,
  }: ODataProps<PlainObject<ODataItemValueType>>,
  removeTimeZone?: boolean,
): Partial<FiltersODataResult> {
  const resultData: Partial<FiltersODataResult> = {
    OrderBy: null,
  };

  if (!is.undefined(top) && !is.undefined(skip)) {
    Object.assign(resultData, {
      Skip: skip,
      Take: top,
    });
  }

  if (!is.undefined(sortingColumn)) {
    Object.assign(resultData, {
      OrderBy:
        (orderFieldNames[sortingColumn] || sortingColumn) +
        (sortingDirection ? ` ${sortingDirection}` : ''),
    });
  }

  if (!is.undefined(groupBy)) {
    Object.assign(resultData, {
      GroupBy: groupBy,
    });
  }

  const filterKeys = (Object.keys(filters) as Array<keyof FiltersModel>).filter(
    (key) => {
      const filter = filters[key];

      return is.array(filter) ? filter.length : !is.nullOrUndefined(filter);
    },
  );

  if (filterKeys.length) {
    const query = new QueryBuilder();

    query.filter((fMain) => {
      filterKeys.forEach((key: string) => {
        const filter = filters[key];

        if (is.number(filter)) {
          fMain.filterExpression(oDateFieldAliases[key] || key, 'eq', filter);
        }

        if (is.string(filter)) {
          const date = new Date(filter);
          const dateTomorrow = addDays(date, 1);

          if (key === 'dateFrom') {
            fMain.filterExpression(
              oDateFieldAliases[key] || key,
              'ge',
              `datetime"${toNoTimezoneISOString(date, removeTimeZone)}"`,
            );

            return;
          }

          if (key === 'dateTo') {
            fMain.filterExpression(
              oDateFieldAliases[key] || key,
              'lt',
              `datetime"${toNoTimezoneISOString(
                dateTomorrow,
                removeTimeZone,
              )}"`,
            );

            return;
          }

          if (key === 'shipDate' || key === 'deliveryDate') {
            fMain
              .filterExpression(
                oDateFieldAliases[key],
                'ge',
                `datetime"${toNoTimezoneISOString(date, removeTimeZone)}"`,
              )
              .filterExpression(
                oDateFieldAliases[key] || key,
                'lt',
                `datetime"${toNoTimezoneISOString(
                  dateTomorrow,
                  removeTimeZone,
                )}"`,
              );

            return;
          }

          fMain.filterExpression(oDateFieldAliases[key], 'eq', filter);

          return;
        }

        // is range filter
        if (filter && is.rangeFilter(filter)) {
          if (key === 'distance') {
            buildDistanceRangeFilter(fMain, oDateFieldAliases[key], filter);
            return;
          }

          buildRangeFilter(
            fMain,
            oDateFieldAliases[key],
            filter,
            key === 'distance',
          );

          return;
        }

        // is date range filter
        if (filter && is.dateRangeFilter(filter)) {
          if (!is.nullOrUndefined(filter.fromDate)) {
            const dateFrom = new Date(filter.fromDate);

            fMain.filterExpression(
              oDateFieldAliases[key],
              'ge',
              `datetime"${toNoTimezoneISOString(dateFrom, removeTimeZone)}"`,
            );
          }

          if (!is.nullOrUndefined(filter.toDate)) {
            const dateTo = new Date(filter.toDate);
            const dateToTomorrow = addDays(dateTo, 1);

            fMain.filterExpression(
              oDateFieldAliases[key],
              'lt',
              `datetime"${toNoTimezoneISOString(
                dateToTomorrow,
                removeTimeZone,
              )}"`,
            );
          }

          return;
        }

        if (is.array(filter)) {
          fMain.or((f) => {
            filter.forEach(
              (
                item:
                  | QuoteBaseEntityTypeUIExtended
                  | QuoteShortLocationUIExtended
                  | BaseEntityType
                  | SelectItemModel,
              ) => {
                if (key === 'status') {
                  buildStatusFilter(f, item as BaseEntityType);
                } else {
                  if ('Id' in item || 'id' in item) {
                    f.filterExpression(
                      oDateFieldAliases[key] || key,
                      'eq',
                      'Id' in item ? item.Id : item.id,
                    );
                  } else if ('value' in item) {
                    f.filterExpression(
                      oDateFieldAliases[key] || key,
                      'eq',
                      item.value,
                    );
                  } else if ('CityName' in item) {
                    buildCityNameFilter(f, oDateFieldAliases[key], item);
                  }
                }
              },
            );

            if (key === 'accountManagers') {
              f.or((f1) =>
                f1.filterExpression(
                  oDateFieldAliases[key],
                  'eq',
                  null as unknown as string,
                ),
              );
            }

            return f;
          });
        }
      });

      return fMain;
    }, 'and');

    const resultQueryString = query.toQuery();

    Object.assign(resultData, {
      Filter: resultQueryString
        .replace('?$filter=', '')
        .replace(/'(datetime"[^"]+")'/gi, '$1')
        // .replace(/t[\d\:\.]+z/gi, 'T00:00Z')
        .replace(/t[\d\:\.]+/gi, 'T00:00Z')
        .replace(/"/gi, "'"),
    });
  }

  return resultData;
}
