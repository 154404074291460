import { Typography } from '@mui/material/styles/createTypography';

export default <Typography>{
  fontFamily: '"Open Sans","Roboto","Helvetica","Arial",sans-serif',

  h1: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: '0em',
  },
  h2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '28px',
  },
  h3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  h4: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',
  },
  h5: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  h6: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
  },
  overline: {
    fontWeight: 500,
  },
  body1: {
    // main data in grid
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
  },
  body2: {
    // header in grid
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
  },
  subtitle1: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0em',
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0em',
  },

  button: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'initial',
  },

  /*
  
  data in expanded content in grid
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  
  
  font-size: 14px;
  font-weight: 400;
  line-height: 20px
 
  
  кнопка
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  
   */
};
