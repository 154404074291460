import { addDays } from 'date-fns';
import { TotalRevenueGroupBy } from '../../constants';

export function getInitDateFilter(groupBy?: TotalRevenueGroupBy): {
  dateFrom: string;
  dateTo: string;
  groupBy: TotalRevenueGroupBy;
} {
  const date = new Date();
  const dateTo = addDays(date, -6);

  return {
    dateFrom: dateTo.toISOString(),
    dateTo: date.toISOString(),
    groupBy: groupBy || TotalRevenueGroupBy.Day,
  };
}
