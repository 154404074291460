import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  AutocompleteProps,
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete/Autocomplete';
import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { PlainObject } from '../../../types';
import { AccordionGroup } from './components/accordionGroup/AccordionGroup';
import {
  getEnabledGroups,
  getGroupsFromValueMap,
} from './SGAutocomplete.utils';
import { renderOption } from './components/renderOption';
import { renderFilterTag } from './components/renderFilterTag';

export type SGAutocompleteValue<T> = AutocompleteValue<T, true, boolean, false>;

export interface SGAutocompleteProps<T>
  extends Omit<
    AutocompleteProps<T, true, boolean, false>,
    'renderInput' | 'onChange' | 'value'
  > {
  label?: string;
  selectAllLabel?: string;
  name?: string;
  placeholder?: string;
  value?: SGAutocompleteValue<T>;
  onChange?: (value: SGAutocompleteValue<T>, name?: string) => void;
  getFilterOptionLabel?: (option: T) => string;
  error?: string;
  readonly?: boolean;
}

export function SGAutocomplete<T>({
  label,
  placeholder,
  name,
  loading,
  disabled,
  value: currentValue,
  onChange,
  options,
  getFilterOptionLabel,
  selectAllLabel,
  error,
  readonly,
  ...props
}: SGAutocompleteProps<T>): JSX.Element {
  const [open, setIsOpen] = useState(false);
  const [onOpenValue, setOnOpenValue] = useState<SGAutocompleteValue<T>>([]);
  const [value, setValue] = useState<SGAutocompleteValue<T> | undefined>(
    currentValue,
  );

  const { getOptionLabel } = props;
  const groupsInValueMap = getGroupsFromValueMap(value || [], props.groupBy);
  const enabledGroups = getEnabledGroups([...options], props.groupBy);

  useEffect(() => {
    if (currentValue === value) {
      return;
    }

    setValue(currentValue);
  }, [currentValue]);

  useEffect(() => {
    if (open && (loading || disabled)) {
      setIsOpen(false);
    }
  }, [loading, disabled]);

  const close = (newValue?: T[]) => {
    setIsOpen(false);

    const val = newValue || value;

    if (!value || !onChange || currentValue === val) {
      return;
    }

    onChange(val as T[], name);
  };

  const onValueChange = (e: SyntheticEvent, newValue: T[]) => {
    const clearValue = newValue.filter((v) => v) as T[];

    if ((e.target as PlainObject).localName === 'input') {
      setValue(clearValue);
      return;
    }

    close(clearValue);
  };

  const isGroupSelected = (group: string) => {
    if (!props.groupBy) {
      return false;
    }

    const itemsByGroup = (options || []).filter(
      (option) => group === (props.groupBy && props.groupBy(option)),
    );
    const valueByGroup = (value || []).filter(
      (option) => group === (props.groupBy && props.groupBy(option)),
    );

    return itemsByGroup.length === valueByGroup.length;
  };

  const isAllSelected = () => {
    const availableOptions = (options || []).filter(
      (option) => !(props.getOptionDisabled && props.getOptionDisabled(option)),
    );

    return availableOptions.length <= (value || []).length;
  };

  const onGroupClick = (e: SyntheticEvent, group: string) => {
    e.preventDefault();
    e.stopPropagation();

    const selected = isGroupSelected(group);
    const indeterminate = groupsInValueMap[group] && !selected;

    const noGroupValues = (value || []).filter(
      (option) => group !== (props.groupBy && props.groupBy(option)),
    );
    const allValuesByGroup =
      selected || indeterminate
        ? []
        : (options || []).filter(
            (option) =>
              group === (props.groupBy && props.groupBy(option)) &&
              !(props.getOptionDisabled && props.getOptionDisabled(option)),
          );
    const newValues = [...noGroupValues, ...allValuesByGroup];

    if ((e.target as PlainObject).localName === 'input') {
      setValue(newValues);
      return;
    }

    close(newValues);
  };

  const onSelectAll = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const selected = isAllSelected();

    const newValues = selected
      ? []
      : (options || []).filter(
          (option) =>
            !(props.getOptionDisabled && props.getOptionDisabled(option)),
        );

    if ((e.target as PlainObject).localName === 'input') {
      setValue(newValues);
      return;
    }

    close(newValues);
  };

  const renderGroup = (groupParams: AutocompleteRenderGroupParams) => {
    const groupSelected = isGroupSelected(groupParams.group);
    const indeterminate = groupsInValueMap[groupParams.group] && !groupSelected;

    const chArray = React.Children.toArray(groupParams.children);
    const acGroup = chArray.length ? (
      <AccordionGroup
        key={groupParams.key}
        index={groupParams.key}
        renderTitle={() =>
          renderOption(
            {
              'aria-disabled':
                !enabledGroups[groupParams.group] &&
                !groupSelected &&
                !indeterminate,
              onClick: (e) => onGroupClick(e, groupParams.group),
            },
            groupParams.group,
            groupSelected,
            indeterminate,
          )
        }
      >
        {groupParams.children}
      </AccordionGroup>
    ) : null;

    if (Number(groupParams.key) !== 0) {
      return acGroup;
    }

    return (
      <div key={groupParams.key}>
        {!!selectAllLabel &&
          Number(groupParams.key) === 0 &&
          renderOption(
            {
              className: 'MuiAutocomplete-option',
              onClick: onSelectAll,
            },
            selectAllLabel,
            isAllSelected(),
          )}
        {acGroup}
      </div>
    );
  };

  const renderOptionCb = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    { selected }: AutocompleteRenderOptionState,
  ): React.ReactNode => {
    if (!option) {
      return null;
    }

    const isCurrentChecked = selected || optionProps['aria-selected'];
    const isCurrentDisabled = !!(
      !isCurrentChecked && optionProps['aria-disabled']
    );
    const showAll = !props.groupBy && !!selectAllLabel && option === options[0];
    const showOpt =
      !isCurrentDisabled ||
      onOpenValue.find(
        (v) =>
          v === option ||
          (getOptionLabel && getOptionLabel(v) === getOptionLabel(option)),
      );

    if (!showAll) {
      return showOpt
        ? renderOption(
            {
              ...optionProps,
              'aria-readonly': !!readonly,
            },
            getOptionLabel && option ? getOptionLabel(option) : String(option),
            selected,
          )
        : null;
    }

    return showOpt
      ? [
          renderOption(
            {
              className: 'MuiAutocomplete-option',
              onClick: onSelectAll,
              'aria-readonly': !!readonly,
            },
            selectAllLabel || '',
            isAllSelected(),
            false,
            'allItem',
          ),
          renderOption(
            { ...optionProps, 'aria-readonly': !!readonly },
            getOptionLabel && option ? getOptionLabel(option) : String(option),
            selected,
          ),
        ]
      : renderOption(
          {
            className: 'MuiAutocomplete-option',
            onClick: onSelectAll,
            'aria-readonly': !!readonly,
          },
          selectAllLabel || '',
          isAllSelected(),
          false,
          'allItem',
        );
  };

  const additionProps = {
    ...(!value ? {} : { value }),
    ...(value && value[0] ? {} : { clearIcon: null }),
    renderGroup: props.groupBy ? renderGroup : undefined,
  };

  const isOpen = open && !loading && !disabled;

  return (
    <Autocomplete<T, true, boolean, false>
      multiple
      disabled={loading || disabled}
      onFocus={() => setIsOpen(true)}
      onOpen={() => {
        setIsOpen(true);
        setOnOpenValue(value || []);
      }}
      onBlur={() => close()}
      disableCloseOnSelect
      open={isOpen}
      renderOption={renderOptionCb}
      renderInput={(params) => (
        <TextField
          {...params}
          color="sagan"
          size="medium"
          name={name}
          label={label}
          placeholder={value && value.length ? undefined : placeholder}
          variant="standard"
          error={Boolean(error)}
          helperText={error}
          InputLabelProps={{
            shrink: true,
            disabled: readonly,
          }}
          inputProps={{
            ...(params.inputProps || {}),
            readOnly: readonly || (params.inputProps || {}).readOnly,
          }}
        />
      )}
      options={[...options]}
      renderTags={(tags) => renderFilterTag(tags, readonly)}
      {...additionProps}
      {...props}
      disableClearable={!!readonly}
      getOptionLabel={getFilterOptionLabel || props.getOptionLabel}
      componentsProps={{
        popper: {
          sx: {
            minWidth: '220px',
          },
        },
      }}
      onChange={onValueChange}
    />
  );
}
