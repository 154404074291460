import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dashboardStoreKey } from './dashboard.const';
import { DashboardState, DashboardChartsData } from './dashboard.types';
import { fetchDashboardData } from './dashboard.thunks';

const initialState: DashboardState = {
  loading: false,
  filters: {},
};

export const dashboardSlice = createSlice({
  name: dashboardStoreKey,
  initialState,
  reducers: {
    reset(store) {
      store.filters = {};
      store.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchDashboardData.fulfilled,
        (state, { payload }: PayloadAction<DashboardChartsData | null>) => {
          state.loading = false;

          if (!payload) {
            return;
          }

          state.performanceData = payload.performanceData;
          state.totalRevenueData = payload.totalRevenueData;
          state.breakdownData = payload.breakdownData;
          state.filters = payload.filters || {};
        },
      );
  },
});
