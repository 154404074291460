/** @jsxImportSource @emotion/react */
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  FreightQuoteActionModel,
  QuoteLocationUIExtended,
} from '../../../../types';
import { ValueOrEmpty } from '../../../../components';
import { is, locationFormatter, plural } from '../../../../utils';
import datLogo from '../../../../assets/datLogo_2x.png';
import { useLookUpRatesStyles } from './LookUpRates.styles';
import { renderLookupRatesDescription } from './LookUpRates.utils';
import { LookUpRateGrid } from '../../../../components/lookUpRate/LookUpRateGrid';

interface Props {
  quote: FreightQuoteActionModel;
}

interface ShortRouteProps {
  location: QuoteLocationUIExtended | null;
  market?: string | null;
  loads?: number | null;
  ratio?: number | null;
  trucks?: number | null;
}

function ShortRoute({
  location,
  market,
  ratio,
  loads,
  trucks,
}: ShortRouteProps): JSX.Element {
  const ratioElement = is.nullOrUndefined(ratio) ? null : (
    <Tooltip
      arrow
      placement="top"
      title={
        <Box>
          <Typography variant="subtitle2">Outbound L/T ratio</Typography>

          <Typography color="text.disabled" variant="subtitle2">
            Loads: <ValueOrEmpty>{loads}</ValueOrEmpty>
          </Typography>
          <Typography color="text.disabled" variant="subtitle2">
            Trucks: <ValueOrEmpty>{trucks}</ValueOrEmpty>
          </Typography>
        </Box>
      }
    >
      <Typography
        variant="h4"
        sx={({ palette }) => ({ color: palette.success.main })}
      >
        {ratio}
      </Typography>
    </Tooltip>
  );

  return (
    <Box className="routeItem">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="subtitle2" fontWeight={600}>
            {locationFormatter(location)}
          </Typography>
          {market && <Typography variant="subtitle2">{market}</Typography>}
        </Box>
        {ratioElement}
      </Stack>
    </Box>
  );
}

export function LookUpRates({ quote }: Props): JSX.Element {
  const styles = useLookUpRatesStyles();

  const shortRoute = (
    <Stack direction="row" justifyContent="space-between" marginBottom={2}>
      <ShortRoute
        location={quote.OriginLocation}
        market={quote.lookUpRates && quote.lookUpRates.OriginMarket}
        ratio={quote.lookUpRates && quote.lookUpRates.OriginRatio}
        loads={quote.lookUpRates && quote.lookUpRates.OriginLoads}
        trucks={quote.lookUpRates && quote.lookUpRates.OriginTrucks}
      />

      <Stack direction="row" alignItems="center" paddingX={1}>
        <ArrowForwardIcon color="secondary" />
      </Stack>

      <ShortRoute
        location={quote.DestinationLocation}
        market={quote.lookUpRates && quote.lookUpRates.DestinationMarket}
        ratio={quote.lookUpRates && quote.lookUpRates.DestinationRatio}
        loads={quote.lookUpRates && quote.lookUpRates.DestinationLoads}
        trucks={quote.lookUpRates && quote.lookUpRates.DestinationTrucks}
      />
    </Stack>
  );

  const datSpot = (
    <Stack
      direction="row"
      alignItems="center"
      className="datRow"
      marginBottom={1}
    >
      <img src={datLogo} alt="DAT" />
      <Typography variant="subtitle2" marginLeft={1}>
        Broker spot - {quote.lookUpRates && quote.lookUpRates.Timeframe}
      </Typography>
    </Stack>
  );

  const reportsFromCompanies = quote.lookUpRates &&
    (!is.nullOrUndefined(quote.lookUpRates.Reports) ||
      !is.nullOrUndefined(quote.lookUpRates.Companies)) && (
      <Typography variant="subtitle2" marginTop={1}>
        Based upon{' '}
        <ValueOrEmpty noValue="N/A">{quote.lookUpRates.Reports}</ValueOrEmpty>{' '}
        {plural(quote.lookUpRates.Reports, 'report')} from{' '}
        <ValueOrEmpty noValue="N/A">{quote.lookUpRates.Companies}</ValueOrEmpty>{' '}
        {plural(quote.lookUpRates.Companies, 'company', 'companies')}
      </Typography>
    );

  return (
    <Box css={styles}>
      <Box marginBottom={2}>
        <Typography variant="h5">Indicative market rates, $:</Typography>
        {renderLookupRatesDescription(quote)}
      </Box>

      {shortRoute}

      {datSpot}

      <Box>
        <LookUpRateGrid lookUpRates={quote.lookUpRates} />

        {reportsFromCompanies}
      </Box>
    </Box>
  );
}
