import { SerializedStyles, css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useQuoteErrorIconStyles = (): SerializedStyles => {
  return css`
    position: absolute;
    top: calc(50% - 10px);
    right: -24px;
    font-size: 16px;
  `;
};

export const useQuoteInfoIconStyles = (): SerializedStyles => {
  const { palette } = useTheme();
  return css`
    color: ${palette.primary.main};
    position: absolute;
    top: calc(50% - 10px);
    right: -24px;
    font-size: 16px;
  `;
};
