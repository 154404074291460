import { TabName } from '../../constants';
import emptyOpenState from '../../assets/emptyOpenState.svg';
import emptySubmittedState from '../../assets/emptySubmittedState.svg';
import emptyWonState from '../../assets/emptyWonState.svg';
import emptyLostState from '../../assets/emptyLostState.svg';
import emptyMissedState from '../../assets/emptyMissedState.svg';
import emptyIgnoredState from '../../assets/emptyIgnoredState.svg';
import noFilteredState from '../../assets/noFilteredState.svg';
import accessDenied from '../../assets/accessDenied.svg';
import searchEmptyState from '../../assets/searchEmptyState.svg';
import noAutobiddingRules from '../../assets/noAutobiddingRules.svg';
import emptySimilarLost from '../../assets/emptySimilarLost.svg';
import emptySimilarWon from '../../assets/emptySimilarWon.svg';
import emptyDATCarrierView from '../../assets/emptyDATCarrierView.svg';

interface StateItem {
  img: string;
  title: string;
  message?: string;
  hasAction?: boolean;
  actionTitle?: string;
  size?: 'small' | 'medium';
}

export type EmptyStateType =
  | TabName
  | 'access'
  | 'search'
  | 'nothingFound'
  | 'similarWon'
  | 'similarWon60'
  | 'similarLost'
  | 'similarLost60'
  | 'datCarrierView'
  | 'rules';

export const emptyStatesByFilters: StateItem = {
  img: noFilteredState,
  title: 'No results match the filter criteria',
  message: 'Please refine your filter criteria.',
};

export const emptyStatesByTabName: Record<EmptyStateType, StateItem> = {
  [TabName.Open]: {
    img: emptyOpenState,
    title: 'No open freight quotes to display',
    message: 'Try to refresh this page',
    hasAction: true,
    actionTitle: 'Refresh',
  },
  [TabName.Submitted]: {
    img: emptySubmittedState,
    title: 'No submitted freight quotes to display',
    message: 'You can review open freight quotes and submit a bid',
  },
  [TabName.Won]: {
    img: emptyWonState,
    title: 'No won freight quotes to display',
    message: 'Please wait for submitted',
  },
  [TabName.Lost]: {
    img: emptyLostState,
    title: 'No lost freight quotes',
    message: 'Everything is alright',
  },
  [TabName.Ignored]: {
    img: emptyIgnoredState,
    title: 'No ignored freight quotes',
    message: 'Everything is alright',
  },
  [TabName.Missed]: {
    img: emptyMissedState,
    title: 'No missed freight quotes',
    message: 'Everything is alright',
  },
  access: {
    img: accessDenied,
    title: 'Permission denied',
    message: "You don't have access to this page.",
  },
  search: {
    img: searchEmptyState,
    title: 'Nothing to display',
    message: 'Enter Source ID or use filters to select Freight Quotes',
  },
  rules: {
    img: noAutobiddingRules,
    title: 'No auto-bidding rules to display',
    hasAction: true,
    actionTitle: 'Create rule',
    message:
      'No rules have been created.\nTo create rule click the button below',
  },
  nothingFound: {
    img: searchEmptyState,
    title: 'Nothing has been found by the current parameters',
    message: 'Refine your search to find the Freight Quotes',
  },

  datCarrierView: {
    img: emptyDATCarrierView,
    size: 'small',
    title: 'DAT did not return loads',
    message:
      'For this lane and equipment type within 100 miles radius from Origin and Destination',
  },

  similarWon: {
    img: emptySimilarWon,
    size: 'small',
    title: 'No similar Freight Quotes have been found within 30 days',
  },
  similarWon60: {
    img: emptySimilarWon,
    size: 'small',
    title: 'No similar Freight Quotes have been found within 60 days',
  },
  similarLost: {
    img: emptySimilarLost,
    size: 'small',
    title: 'No similar Freight Quotes have been found within 30 days',
  },
  similarLost60: {
    img: emptySimilarLost,
    size: 'small',
    title: 'No similar Freight Quotes have been found within 60 days',
  },
};
