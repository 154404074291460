import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { PropsWithChildren } from 'react';

export interface StatusBadgeProps {
  size?: 'tiny' | 'small' | 'medium';
  background?: string;
  type?: 'success' | 'error' | 'disabled';
  variant?: 'outlined' | 'contained';
  typographyVariant?: 'h5' | 'h6';
  hover?: boolean;
}

export function StatusBadge({
  children,
  size = 'small',
  background,
  type,
  variant,
  typographyVariant = 'h5',

  hover,
}: PropsWithChildren<StatusBadgeProps>): JSX.Element {
  const style =
    variant === 'outlined'
      ? {
          padding:
            size === 'tiny'
              ? '0 3px'
              : size === 'small'
              ? '3px 5px'
              : '5px 7px',
        }
      : {
          padding:
            size === 'tiny'
              ? '0 4px'
              : size === 'small'
              ? '4px 6px'
              : '6px 8px',
        };

  return (
    <Box
      sx={(theme) => {
        const borderColors = {
          success: theme.palette.success.main,
          error: theme.palette.error.main,
          disabled: theme.palette.text.disabled,
        };
        const bgColors = {
          success: theme.palette.success.main,
          error: theme.palette.error.main,
          disabled: theme.palette.background.badge,
        };
        const textColors = {
          success: theme.palette.text.secondary, // no use
          error: theme.palette.common.white,
          disabled: theme.palette.text.secondary,
        };

        return {
          ...style,
          whiteSpace: 'nowrap',
          borderRadius: '4px',
          border:
            variant === 'outlined'
              ? `1px solid ${borderColors[type || 'disabled']}`
              : 'none',
          background:
            background ||
            (type ? bgColors[type] : theme.palette.background.paper),
          color:
            type && variant !== 'outlined'
              ? textColors[type]
              : theme.palette.text.secondary,

          ...(hover
            ? {
                '&:hover': {
                  background: theme.palette.background.paper,
                  cursor: 'default',
                },
              }
            : {}),
        };
      }}
    >
      <Typography variant={typographyVariant} lineHeight="16px">
        {children}
      </Typography>
    </Box>
  );
}
