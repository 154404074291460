import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { freightQuotesStoreKey } from './freightQuotes.const';
import { apiEndpoint } from '../../constants/api';
import {
  CarrierViewResult,
  FetchFreightQuotesModel,
  FetchResult,
  IgnoreResult,
  LookupRatesResult,
  SearchQuoteResult,
  SimilarQuotesResult,
} from './freightQuotes.types';
import {
  BID_SUBMITTED,
  PORTAL_IS_NOT_AVAILABLE,
  SubmitQuoteStatus,
  TabName,
} from '../../constants';
import {
  AppStateWithFreightQuotes,
  freightQuotesSelectors,
} from './freightQuotes.selectors';
import {
  DefaultErrorResponse,
  ServerListResponse,
  ServerResponseStatus,
} from '../../types/server-response';
import {
  FreightQuoteActionModel,
  FreightQuoteModel,
  FreightQuotesStatusCount,
  ODataProps,
} from '../../types';
import {
  BidForm,
  BidToSubmit,
  QuoteActionResponse,
} from '../../types/bidToSubmit';
import { notificationSlice } from '../notification';
import { fetchPortals } from '../filtersData';
import { filtersToOData } from '../../utils/oDataBuilder/oDataBuilder';
import { plural } from '../../utils';

export const setNewPage = createAsyncThunk(
  `${freightQuotesStoreKey}/setNewPage`,
  async (tabName: TabName, store): Promise<TabName> => {
    store.dispatch(
      fetchFreightQuotesData({
        tabName,
        filters: { skip: 0 },
        noUpdate: true,
      }),
    );

    return tabName;
  },
);

export const fetchLookupRates = createAsyncThunk(
  `${freightQuotesStoreKey}/fetchLookupRates`,
  async (quote: FreightQuoteActionModel): Promise<LookupRatesResult> => {
    try {
      const { data } = await axios.get(apiEndpoint.lookupRates(quote.Id));

      return {
        quote,
        data: data || null,
      };
    } catch (e) {}

    return {
      quote,
      data: null,
    };
  },
);

export const fetchSimilarQuotes = createAsyncThunk(
  `${freightQuotesStoreKey}/fetchSimilarQuotes`,
  async (quote: FreightQuoteActionModel): Promise<SimilarQuotesResult> => {
    try {
      const { data } = await axios.get(apiEndpoint.similarQuotes(quote.Id));

      return {
        quote,
        similarQuotes: data,
      };
    } catch (e) {}

    return {
      similarQuotes: {},
      quote,
    };
  },
);

export const fetchCarrierView = createAsyncThunk(
  `${freightQuotesStoreKey}/fetchCarrierView`,
  async (quote: FreightQuoteActionModel): Promise<CarrierViewResult> => {
    try {
      const { data } = await axios.get(apiEndpoint.carrierView(quote.Id));

      return {
        quote,
        data,
      };
    } catch (e) {
      return {
        data: [],
        error: e?.response?.data || String(e),
        quote,
      };
    }
  },
);

export const checkIsQuoteValidToSubmit = createAsyncThunk(
  `${freightQuotesStoreKey}/checkIsQuoteValidToSubmit`,
  async (
    quote: FreightQuoteActionModel,
  ): Promise<FreightQuoteActionModel | null> => {
    try {
      const { data } = await axios.post<ServerListResponse<FreightQuoteModel>>(
        apiEndpoint.freightQuotes(),
        {
          Skip: 0,
          Take: 10,
          Filter: `Id eq ${quote.Id}`,
        },
      );

      if (!data || !data.Items || !data.Items.length) {
        throw new Error();
      }

      return data.Items[0] || { ...quote, validToSubmit: false };
    } catch (e) {}

    return null;
  },
);

export const setQuoteToSubmit = createAsyncThunk(
  `${freightQuotesStoreKey}/setQuoteToSubmit`,
  async (
    quotes: FreightQuoteActionModel[] | null,
    store,
  ): Promise<FreightQuoteActionModel[] | null> => {
    const quote = quotes && quotes[0];

    if (quote) {
      store.dispatch(checkIsQuoteValidToSubmit(quote));

      if (!quote.lookUpRates) {
        store.dispatch(fetchLookupRates(quote));
      }
    }

    return quotes;
  },
);

export const submitBid = createAsyncThunk(
  `${freightQuotesStoreKey}/submitBid`,
  async (
    {
      quotes,
      data,
    }: {
      quotes: FreightQuoteModel[];
      data: BidForm;
    },
    store,
  ): Promise<FreightQuoteModel | null> => {
    const tabName = freightQuotesSelectors.getCurrentTabName(
      store.getState() as AppStateWithFreightQuotes,
    );

    const quote = quotes[0];
    const isBulk = quotes.length > 1;

    try {
      if (!quote.Portal || !quote.Status) {
        throw new Error();
      }

      const tabData = tabName === TabName.Open ? { Tab: 'Open' } : {};

      const commonData: BidToSubmit = {
        Status: quote.Status,
        SourceId: quote.SourceId,
        Comments: data.comments,
        NewBid: String(data.bid),
        ...tabData,
      };

      const dataToSend: BidToSubmit = isBulk
        ? {
            Ids: quotes.map((q) => q.Id),
            ...commonData,
          }
        : {
            Id: quote.Id,
            ...commonData,
          };

      const { data: response } = await axios.post<QuoteActionResponse>(
        isBulk
          ? apiEndpoint.bulkSubmitBid()
          : apiEndpoint.submitBid(quote.Portal, quote.Id),
        dataToSend,
      );

      if (!response) {
        throw new Error(response);
      }

      if (response.status === SubmitQuoteStatus.Success) {
        store.dispatch(notificationSlice.actions.success(BID_SUBMITTED));
      } else {
        store.dispatch(checkIsQuoteValidToSubmit(quote));
        store.dispatch(
          notificationSlice.actions.error(
            response.message || PORTAL_IS_NOT_AVAILABLE,
          ),
        );
      }

      if (response.quotes) {
        store.dispatch(updateFreightQuotesData());

        return null;
      }

      return response.quote || null;
    } catch (e: unknown) {
      store.dispatch(checkIsQuoteValidToSubmit(quote));
      store.dispatch(notificationSlice.actions.networkError());

      throw new Error();
    }
  },
);

export const ignoreQuotes = createAsyncThunk(
  `${freightQuotesStoreKey}/ignoreQuote`,
  async (
    quotes: FreightQuoteActionModel[],
    store,
  ): Promise<IgnoreResult | null> => {
    try {
      const ids = quotes.map(({ Id }) => Id);

      const result =
        ids.length > 1
          ? await axios.post<DefaultErrorResponse>(apiEndpoint.ignoreQuotes(), {
              Ids: ids,
            })
          : await axios.post<DefaultErrorResponse>(
              apiEndpoint.ignoreQuote(ids[0]),
            );

      if (result?.data?.status === ServerResponseStatus.Error) {
        store.dispatch(checkIsQuoteValidToSubmit(quotes[0]));
        store.dispatch(notificationSlice.actions.error(result.data.message));

        return null;
      } else {
        store.dispatch(
          notificationSlice.actions.info(
            `${plural(ids.length, 'Quote')} ignored`,
          ),
        );
      }

      try {
        const { data } = await axios.get(apiEndpoint.statusCount());

        if (data) {
          return { quotes, statuses: data };
        }
      } catch (e) {}

      return { quotes };
    } catch (e) {
      store.dispatch(checkIsQuoteValidToSubmit(quotes[0]));
      store.dispatch(notificationSlice.actions.networkError());

      throw new Error(e);
    }
  },
);

export const updateStatusCounters = createAsyncThunk(
  `${freightQuotesStoreKey}/updateStatusCounters`,
  async (): Promise<FreightQuotesStatusCount> => {
    try {
      const statusResp = await axios.get(apiEndpoint.statusCount());

      return statusResp.data;
    } catch (e) {
      throw new Error(e);
    }
  },
);

export const fetchFreightQuotesData = createAsyncThunk(
  `${freightQuotesStoreKey}/fetchFreightQuotesData`,
  async (
    { tabName, filters, noUpdate }: FetchFreightQuotesModel,
    store,
  ): Promise<FetchResult | null> => {
    if (!noUpdate) {
      store.dispatch(fetchPortals(true));
    }

    const tabsData = freightQuotesSelectors.getTabsData(store.getState());
    const { request, params } = tabsData[tabName];
    const requestParams = {
      ...request,
      ...filters,
    };

    if (params === 'Hot') {
      requestParams.filters = {};
    }

    const otDataString = filtersToOData(requestParams);

    try {
      // todo
      // return {
      //   data: getFreightQuotesOpenMock(),
      //   total: 10,
      //   statuses: getFreightQuotesStatusCountMock(),
      //   tabName,
      //   filters: {
      //     ...request,
      //     ...filters,
      //   },
      // };

      const response = await axios.post<ServerListResponse<FreightQuoteModel>>(
        apiEndpoint.freightQuotesByTab(tabName, '', params),
        otDataString,
      );
      const statusResp = await axios.get(apiEndpoint.statusCount());

      if (!response || !response.data) {
        throw Error('Failed user data');
      }

      if (
        response.data.TotalCount &&
        (!response.data.Items || !response.data.Items.length)
      ) {
        const { TotalCount } = response.data;
        const top = filters.top || 10;
        const pages = (TotalCount && Math.ceil(TotalCount / top)) || 1;

        const newFilters = {
          ...filters,
          skip: (pages - 1) * top,
        };

        store.dispatch(
          fetchFreightQuotesData({ tabName, filters: newFilters }),
        );

        return null;
      }

      return {
        data: response.data.Items,
        total: response.data.TotalCount,
        statuses: statusResp.data,
        tabName,
        filters: {
          ...request,
          ...filters,
        },
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw new Error(err);
    }
  },
);

export const updateFreightQuotesData = createAsyncThunk(
  `${freightQuotesStoreKey}/updateFreightQuotesData`,
  async (_: void, store): Promise<void> => {
    const tabName = freightQuotesSelectors.getCurrentTabName(
      store.getState() as AppStateWithFreightQuotes,
    );

    if (!tabName) {
      return;
    }

    const tabsData = freightQuotesSelectors.getTabsData(store.getState());
    const request = tabsData[tabName].request;

    store.dispatch(fetchFreightQuotesData({ tabName, filters: request }));
  },
);

// search
export const fetchSearchResult = createAsyncThunk(
  `${freightQuotesStoreKey}/fetchSearchResult`,
  async (filters: ODataProps, store): Promise<Partial<SearchQuoteResult>> => {
    try {
      store.dispatch(fetchPortals(true));

      const oDataFilters = filtersToOData(filters);

      const response = await axios.post<ServerListResponse<FreightQuoteModel>>(
        apiEndpoint.freightQuotes(),
        oDataFilters,
      );

      if (!response || !response.data) {
        throw Error('Failed user data');
      }

      return {
        data: response.data.Items,
        total: response.data.TotalCount,
        filters,
      };
    } catch (err) {
      return {};
    }
  },
);
// ------
