import {
  QuoteBaseEntityTypeUIExtended,
  QuotePortalUIExtended,
} from '../../types';
import { Box } from '@mui/material';
import React from 'react';
import { getShippersByPortals } from '../../utils';

export function matchShippersToPortalsAndRemoveShippers(
  selectedPortals: QuotePortalUIExtended[] | null,
  shippers: QuoteBaseEntityTypeUIExtended[],
): QuoteBaseEntityTypeUIExtended[] {
  if (!selectedPortals || !selectedPortals.length) {
    return shippers;
  }

  const availableShipperIds = getShippersByPortals(selectedPortals).map(
    ({ Id }) => Id,
  );

  return shippers.filter((shipper) => availableShipperIds.includes(shipper.Id));
}

export function renderFilterTag(values: unknown[]): JSX.Element {
  return <Box paddingRight={1}>{values.length} selected</Box>;
}
