import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import React, { useEffect } from 'react';
import { LoaderContainer } from '../../components';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  fetchPodCoverage,
  podCoverageSelectors,
  podCoverageSlice,
} from '../../redux/podCoverage';
import { PodCoverageDataGrid } from '../../features';
import { routes } from '../../constants';

export function PodCoverage(): JSX.Element {
  const dispatch = useAppDispatch();

  const pods = useAppSelector(podCoverageSelectors.getPodCoverage);
  const loading = useAppSelector(podCoverageSelectors.getLoading);

  useEffect(() => {
    dispatch(fetchPodCoverage());

    return () => {
      dispatch(podCoverageSlice.actions.reset());
    };
  }, []);

  return (
    <LoaderContainer loading={loading} fullPage>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginY={4}
        >
          <Typography variant="h1">Pods coverage</Typography>

          <Box>
            <Button
              variant="contained"
              type="submit"
              size="medium"
              color="primary"
              href={routes.CreateRule}
            >
              Create rule
            </Button>
          </Box>
        </Stack>
      </Box>

      <PodCoverageDataGrid rows={pods} />
    </LoaderContainer>
  );
}
