import { Box, Button, IconButton, Stack, Tooltip } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import React from 'react';
import {
  ScheduleFormValue,
  ScheduleGridItem,
} from './SchedulingDataGrid.types';
import { LoadingButton } from '@mui/lab';
import { ExtendedRuleEnum } from '../../types';
import { EmptyElement, RadioButtonsGroup } from '../../components';
import { SchedulingCondition } from '../../constants';
import TextField from '@mui/material/TextField';
import { cn, is } from '../../utils';

export const renderActions = (
  rowData: ScheduleGridItem,
  onEdit: (data: ScheduleGridItem) => void,
  onCancel: (data: ScheduleGridItem) => void,
  onSubmit: (data: ScheduleGridItem) => void,
): JSX.Element | null => {
  return (
    <Box
      className={cn(
        'editOrReviewContainer',
        rowData.edit ? 'editMode' : 'reviewMode ',
      )}
    >
      <Box className="reviewContainer">
        <IconButton
          size="small"
          onClick={() => onEdit(rowData)}
          sx={{ margin: '-5px 0' }}
        >
          <ModeEditOutlinedIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
      <Box className="editContainer">
        <Stack direction="row" justifyContent="end" margin="-6px 0">
          <Button
            variant="outlined"
            type="button"
            size="small"
            disabled={rowData.loading}
            sx={{ marginRight: 1 }}
            onClick={() => onCancel(rowData)}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={rowData.loading}
            variant="contained"
            disabled={
              rowData.submitted &&
              !!rowData.errors &&
              !!Object.keys(rowData.errors).length
            }
            type="button"
            color="primary"
            size="small"
            onClick={rowData.loading ? undefined : () => onSubmit(rowData)}
          >
            Save changes
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};

export const renderScheduleCondition = (
  rowData: ScheduleGridItem,
  conditionEnums: ExtendedRuleEnum[],
  onValueChange: (id: number, value: Partial<ScheduleFormValue>) => void,
): JSX.Element | string => {
  return (
    <Stack
      className={cn(
        'editOrReviewContainer',
        rowData.edit ? 'editMode' : 'reviewMode ',
      )}
      maxWidth="320px"
    >
      <Box className="reviewContainer">
        {conditionEnums[rowData.Condition]
          ? conditionEnums[rowData.Condition].Description
          : EmptyElement}
      </Box>
      <Box className="editContainer">
        <Box margin="-5px 0">
          <Tooltip
            arrow
            placement="top"
            hidden={!rowData.errors?.condition}
            title={rowData.errors?.condition || ''}
          >
            <Stack display="inline">
              <RadioButtonsGroup
                label=""
                error={!!rowData.errors?.condition}
                value={
                  rowData.condition !== undefined
                    ? rowData.condition
                    : rowData.Condition
                }
                onChange={(e, value) =>
                  onValueChange(rowData.Id, {
                    condition: Number(value) as SchedulingCondition,
                  })
                }
                items={[
                  {
                    disabled: !rowData?.Shipper?.SupportsFreightQuoteExpiration,
                    label:
                      conditionEnums[SchedulingCondition.BeforeRespondBy]
                        .Description,
                    value: SchedulingCondition.BeforeRespondBy,
                  },
                  {
                    label:
                      conditionEnums[SchedulingCondition.AfterLoadArrives]
                        .Description,
                    value: SchedulingCondition.AfterLoadArrives,
                  },
                ]}
              />
            </Stack>
          </Tooltip>
        </Box>
      </Box>
    </Stack>
  );
};

export const renderScheduleDelay = (
  rowData: ScheduleGridItem,
  onValueChange: (id: number, value: Partial<ScheduleFormValue>) => void,
): JSX.Element | number => {
  return (
    <Stack
      className={cn(
        'editOrReviewContainer',
        rowData.edit ? 'editMode' : 'reviewMode ',
      )}
    >
      <Box className="reviewContainer">
        {is.nullOrUndefined(rowData.Delay) ? EmptyElement : rowData.Delay}
      </Box>
      <Box className="editContainer">
        <Box margin="-2px 0">
          <Tooltip
            arrow
            placement="top"
            hidden={!rowData.errors?.delay}
            title={rowData.errors?.delay || ''}
          >
            <TextField
              size="tiny"
              className="tinySize"
              placeholder="0"
              disabled={false}
              inputProps={{
                tabIndex: 0,
              }}
              sx={{
                minWidth: '60px',
                maxWidth: '80px',
              }}
              variant="outlined"
              value={rowData.delay !== undefined ? rowData.delay : ''}
              error={!!rowData.errors?.delay}
              onChange={(e) =>
                onValueChange(rowData.Id, {
                  delay: ((e && e.target.value) || '').trim() || undefined,
                })
              }
            />
          </Tooltip>
        </Box>
      </Box>
    </Stack>
  );
};
