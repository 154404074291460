import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { schedulingStoreKey } from './scheduling.const';
import { notificationSlice } from '../notification';
import { apiEndpoint } from '../../constants/api';
import { FetchScheduleResult, ScheduleEditParams } from './scheduling.types';
import { RuleEnumsResponse } from '../rules/rules.types';
import { ruleEnumsResponseToData } from '../../utils/ruleEnums';
import { ScheduleItem } from '../../types/scheduleItem';

export const fetchSchedule = createAsyncThunk(
  `${schedulingStoreKey}/fetchSchedule`,
  async (_: void, store): Promise<FetchScheduleResult> => {
    try {
      const { data } = await axios.get(apiEndpoint.schedules());
      const { data: enums } = await axios.get<RuleEnumsResponse[]>(
        apiEndpoint.getEnums(),
      );

      if (!data || !enums) {
        throw Error('Failed request');
      }

      return {
        schedule: data,
        enums: ruleEnumsResponseToData(enums),
      };
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());

      throw Error('Failed request');
    }
  },
);

export const editSchedule = createAsyncThunk(
  `${schedulingStoreKey}/editSchedule`,
  async (
    { item, formData }: ScheduleEditParams,
    store,
  ): Promise<ScheduleItem> => {
    try {
      const { data } = await axios.post(
        apiEndpoint.editSchedule(item.Id),
        formData,
      );

      if (!data) {
        throw Error('Failed request');
      }

      store.dispatch(
        notificationSlice.actions.success(
          'The delay has been saved & published',
        ),
      );

      return data;
    } catch (err) {
      store.dispatch(notificationSlice.actions.networkError());
      throw Error('Failed request');
    }
  },
);
