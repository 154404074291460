import { RuleEnum } from '../types';

export function getEnumLabels(ruleEnum: RuleEnum[]): Record<number, string> {
  return ruleEnum.reduce(
    (acum, en) => ({
      ...acum,
      [en.Key]: en.Description,
    }),
    {},
  );
}
